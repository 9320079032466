import React from 'react';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { TextareaFieldWithValidation } from '../../../../../../common/TextareaFieldWithValidation';
import { change, formValueSelector, getFormInitialValues } from 'redux-form';
import { connect } from 'react-redux';
import ValidFormattedMessage from '../../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../../intl/hdi/formMessages';
import { ticketConstants } from '../../../../../../../constants/form/formTicketConstants';
import InputSelectFieldWithValidation from '../../../../../../common/InputSelectFieldWithValidation';
import { getPriority } from '../../../../../../../constants/Utils';
import InputSelectFieldForObjectWithValidation from '../../../../../../common/InputSelectFieldForObjectWithValidation';
import {getAvailableTicketStatus, getUserRole, isVisibleForCountry, listEditorSolutionGroups} from '../../../../Utils';
import formStatesMessages from '../../../../../../../intl/common/formStatesMessages';
import get from 'get-value';
import {parseEmailOptionsArrayFrom} from '../../../../../../../utils/utils';
import {solutionGroupConstants} from '../../../../../../../constants/form/formSolutionGroupConstants';
import {size} from 'lodash';
import {HDI_TICKET_STATUS_REOPENED} from '../../../../../../../constants/form/statusConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const NotesSection = props => {
    const { userRights, readOnly, initialValues, status, roles, solutionGroup, loggedUser, userDetail, templateShortList, templateShortListAll, selectedSG, ticketCountry, change, internalNoteText } = props;

    const followups = get(initialValues, 'followups', []).filter(note => size(note.text) > 0);
    const isReopened = get(initialValues, 'followups', []).some(note => note.status === HDI_TICKET_STATUS_REOPENED);
    const topicsOptions = templateShortList.filter(v => v.solutionGroupId === selectedSG).map(v => ({value: v.id, label: v.subject}))
    const topicsOptionsAll = templateShortListAll.map(v => ({value: v.id, label: v.subject}))

    const handleSolutionGroupChange = () => {
        change(`${ticketConstants.FORM_NOTES_SECTION}.${ticketConstants.FORM_FIELD_SUBJECT}`, '');
    };

    const handleTopicGroupChange = (event) => {
        const templateId = event.target.value;
        const template = templateShortList.find(template => template.id === templateId);
        if (!template) {
            change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_COPY_EMAIL}`, '');
        } else {
            const copyTo = parseEmailOptionsArrayFrom(template.copy);
            change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_COPY_EMAIL}`, copyTo);
        }
    };

    return (
        <>
            {((userRights.canAddNotes && !readOnly) || (followups && (followups.length > 0))) &&
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.NOTE_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {(followups && (followups.length > 0)) &&
                    <div>
                        <div className="border-bottom border-bottom-secondary"
                             style={{ backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0' }}>
                            <label className="col-form-label pl-0 font-weight-bold ml-3">
                                <ValidFormattedMessage message={ticketConstants.FORM_LABEL_NOTES_HISTORY}
                                                       intlMessages={formMessages}/>
                            </label>
                        </div>
                        {followups.map((note, index) =>
                            <div key={index}>
                                <div className="row m-0 align-items-stretch"
                                     style={{ backgroundColor: '#f8f9fa' }}>
                                    <div className='col-sm-12 col-md-6'>
                                        <span
                                            className="font-weight-bold">{note.creatorName + ' (' + note.creatorIpn + ')'}</span><br/>
                                        <FormattedDate value={new Date(note.created)}/> <FormattedTime
                                        value={new Date(note.created)}/>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <span
                                            className="font-weight-bold"><FormattedMessage {...formMessages[ticketConstants.FORM_LABEL_PRIORITY]}/>: </span>
                                        <span className="font-italic">{note.priority}</span><br/>
                                        <span
                                            className="font-weight-bold"><FormattedMessage {...formMessages[ticketConstants.FORM_LABEL_STATUS]}/>: </span>
                                        <span
                                            className="font-italic"><FormattedMessage {...formStatesMessages[note.status]} /></span><br/>
                                        <span
                                            className="font-weight-bold"><FormattedMessage {...formMessages[isVisibleForCountry(userDetail.group) ? ticketConstants.FORM_LABEL_SOLUTION_GROUP_UK : ticketConstants.FORM_LABEL_SOLUTION_GROUP]}/>: </span>
                                        <span className="font-italic">{note.solutionGroup.shortName}</span>
                                        {isVisibleForCountry(ticketCountry) && get(note, "subject.shortName") &&
                                            <>
                                                <br/>
                                                <span
                                                    className="font-weight-bold"><FormattedMessage {...formMessages[ticketConstants.FORM_LABEL_TEMPLATE_TOPIC]}/>: </span>
                                                <span className="font-italic">{note.subject.shortName}</span>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div key={index} className="row m-0 align-items-stretch">
                                    <div className="col border-bottom py-3" style={{whiteSpace: "pre-wrap", overflowWrap: 'break-word'}}>
                                        {note.text}
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                    }
                </StyledDiv>
                <hr/>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {userRights.canAddNotes && !readOnly &&
                    <div className="col-sm-12 px-4 pb-4 pt-2">
                        <div className="form-row">
                            <div className="col-2 pb-2">
                                <InputSelectFieldWithValidation
                                    label={formMessages[ticketConstants.FORM_LABEL_PRIORITY]}
                                    field={ticketConstants.FORM_FIELD_PRIORITY}
                                    options={getPriority}
                                    isRequired
                                    //handleChange={handleChange}
                                    isSmall/>
                            </div>
                            <div className="col-4 pb-2">
                                <InputSelectFieldWithValidation
                                    label={formMessages[ticketConstants.FORM_LABEL_STATUS]}
                                    field={ticketConstants.FORM_FIELD_STATUS}
                                    intlMessages={formStatesMessages}
                                    options={getAvailableTicketStatus(status, roles, solutionGroup, isReopened)}
                                    isRequired
                                    //handleChange={handleChange}
                                    isSmall/>
                            </div>
                            {isVisibleForCountry(ticketCountry) ?
                                <>
                                <div className="col-3 pb-2">
                                    <InputSelectFieldForObjectWithValidation
                                        label={formMessages[isVisibleForCountry(userDetail.group) ? ticketConstants.FORM_LABEL_SOLUTION_GROUP_UK : ticketConstants.FORM_LABEL_SOLUTION_GROUP]}
                                        field={ticketConstants.FORM_FIELD_SOLUTION_GROUP}
                                        options={listEditorSolutionGroups(props.editorSolutionGroupList, props.solutionGroup)}
                                        isDisabled={getUserRole(loggedUser.roles) === 'USER' ? true : false}
                                        isRequired
                                        handleChange={handleSolutionGroupChange}
                                        isSmall/>
                                </div>
                                <div className="col-3 pb-2">
                                    <InputSelectFieldForObjectWithValidation
                                        label={formMessages[ticketConstants.FORM_LABEL_SUBJECT_UK]}
                                        field={ticketConstants.FORM_FIELD_SUBJECT}
                                        options={getUserRole(loggedUser.roles) === 'USER' ? topicsOptionsAll : topicsOptions}
                                        isDisabled={getUserRole(loggedUser.roles) === 'USER' ? true : false}
                                        isRequired
                                        handleChange={handleTopicGroupChange}
                                        isSmall/>
                                </div>
                                </>
                                :
                                <>
                                <div className="col-6 pb-2">
                                    <InputSelectFieldForObjectWithValidation
                                        label={formMessages[isVisibleForCountry(userDetail.group) ? ticketConstants.FORM_LABEL_SOLUTION_GROUP_UK : ticketConstants.FORM_LABEL_SOLUTION_GROUP]}
                                        field={ticketConstants.FORM_FIELD_SOLUTION_GROUP}
                                        options={listEditorSolutionGroups(props.editorSolutionGroupList, props.solutionGroup)}
                                        isDisabled={getUserRole(loggedUser.roles) === 'USER' ? true : false}
                                        isRequired
                                        //handleChange={handleChange}
                                        isSmall/>
                                </div>
                                </>
                            }
                        </div>
                        <div className="form-row">
                            <div className="col-sm-12 pb-2">
                                <label className="col-form-label pl-0 font-weight-bold">
                                    <ValidFormattedMessage message={ticketConstants.FORM_LABEL_NOTE}
                                                           intlMessages={formMessages}/>
                                </label>
                                <TextareaFieldWithValidation
                                    field={ticketConstants.FORM_FIELD_NOTE}
                                    rows={6}
                                    isRequired={size(internalNoteText) <= 0}
                                />
                            </div>
                        </div>
                    </div>
                    }
                </StyledDiv>
            </>}
        </>
    )
};

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
    ticketInfo: formValueSelector(props.form)(state, `ticket`),
    adminSolutionGroupList: state.adminSolutionGroupList,
    solutionGroup: state.ticket.ticket.solutionGroup,
    status: state.ticket.ticket.status,
    ticketCountry: state.ticket.ticket.country,
    roles: state.profile.userDetail.roles,
    userDetail: state.profile.userDetail,
    editorSolutionGroupList: state.adminEditor,
    loggedUser: state.profile.userDetail,
    templateShortList: state.adminTemplateList.shortListData,
    templateShortListAll: state.adminTemplateList.shortListAllData,
    selectedSG: formValueSelector(props.form)(state, `${ticketConstants.FORM_NOTES_SECTION}.${ticketConstants.FORM_FIELD_SOLUTION_GROUP}`),
    internalNoteText: formValueSelector(props.form)(state, `${ticketConstants.FORM_INTERNAL_NOTES_SECTION}.${ticketConstants.FORM_FIELD_INTERNAL_NOTE}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesSection);
