import { hdiEditorWebsocketCommands, } from '../../constants/hdiEditorWebsocketCommands';
import get from 'get-value';

export const adminEditor = (state = {
    correlationId: null,
    isEditorLoading: false,
    isEditorCreating: false,
    isEditorChecking: false,
    alreadyExist: false,
    showCreating: false,
    created: false,
    solutionGroups: [],
    availableSolutionGroups: [],
    editor: {},
    editorSolutionGroups: [],

}, action) => {
    switch (action.type) {
        case hdiEditorWebsocketCommands.HDI_EDITOR_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isEditorLoading: false,
                isEditorCreating: false,
                isEditorChecking: false,
                alreadyExist: false,
                showCreating: false,
                created: false,
                solutionGroups: [],
                availableSolutionGroups: [],
                editor: {},
                editorSolutionGroups: [],
            });
        }
        case hdiEditorWebsocketCommands.HDI_TICKET_EDITOR_SOLUTION_GROUP_PENDING: {
            return Object.assign({}, state, {
                isEditorLoading: true
            });
        }
        case hdiEditorWebsocketCommands.HDI_TICKET_EDITOR_SOLUTION_GROUP_SEND: {
            const { payload: editorSolutionGroups, correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }

            return Object.assign({}, state, {
                isEditorLoading: false,
                isEditorCreating: false,
                editorSolutionGroups
            });
        }
        case hdiEditorWebsocketCommands.HDI_TICKET_EDITOR_SOLUTION_GROUP_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }

            return Object.assign({}, state, {
                isEditorLoading: false,
                correlationId
            });
        }
        case hdiEditorWebsocketCommands.HDI_EDITOR_ROLE_PENDING: {
            return Object.assign({}, state, {
                isEditorChecking: true
            });
        }
        case hdiEditorWebsocketCommands.HDI_EDITOR_ROLE_SEND: {
            const { editor, correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }

            if (editor) {
                return Object.assign({}, state, {
                    isEditorChecking: false,
                    alreadyExist: editor,
                    showCreating: !editor,
                    correlationId
                });
            }

            return Object.assign({}, state, {
                isEditorChecking: false,
                alreadyExist: editor,
                showCreating: !editor,
                correlationId,
                solutionGroups: get(action.payload, 'solutionGroups', { default: [] }),
                availableSolutionGroups: get(action.payload, 'availableSolutionGroups', { default: [] })
            });
        }
        case hdiEditorWebsocketCommands.HDI_EDITOR_ROLE_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }

            return Object.assign({}, state, {
                isEditorChecking: false,
                showCreating: false,
                alreadyExist: false,
                correlationId
            });
        }
        case hdiEditorWebsocketCommands.HDI_EDITOR_UPDATE_PENDING:
        case hdiEditorWebsocketCommands.HDI_EDITOR_CREATE_PENDING: {
            return Object.assign({}, state, {
                isEditorCreating: true,
                isEditorLoading: true,
            });
        }

        case hdiEditorWebsocketCommands.HDI_EDITOR_COMMAND_ACCEPTED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isEditorCreating: false,
                created: true,
                isEditorLoading: true,
                correlationId
            });
        }

        case hdiEditorWebsocketCommands.HDI_EDITOR_CREATE_ERROR:
        case hdiEditorWebsocketCommands.HDI_EDITOR_UPDATE_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isEditorCreating: false,
                created: false,
                isEditorLoading: false,
                correlationId,
            });
        }

        case hdiEditorWebsocketCommands.HDI_EDITOR_DETAIL_FETCH_PENDING: {
            return Object.assign({}, state, { isEditorLoading: true });
        }

        case hdiEditorWebsocketCommands.HDI_EDITOR_SEND: {
            const { payload, error, correlationId } = action.payload;
            const { editor } = payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isEditorLoading: false, correlationId });
            }
            return Object.assign({}, state, {
                correlationId,
                isEditorLoading: false,
                isEditorCreating: false,
                editor,
                solutionGroups: get(payload, 'solutionGroups', { default: [] }),
                availableSolutionGroups: get(payload, 'availableSolutionGroups', { default: [] })
            });
        }
        case hdiEditorWebsocketCommands.HDI_EDITOR_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isEditorCreating: false,
                isEditorLoading: false,
            });
        }
        case hdiEditorWebsocketCommands.HDI_ADD_DEFAULT_SOLUTION_GROUP: {
            const { group } = action.payload;
            return Object.assign({}, state, { defaultSG: [...new Set([...state.defaultSG, group])] })
        }
        case hdiEditorWebsocketCommands.EDITOR_SAVE_NEW_ERROR:
        case hdiEditorWebsocketCommands.EDITOR_SAVE_UPDATE_ERROR: {
            return Object.assign({}, state, { isEditorLoading: false });
        }
        default:
            return state;
    }
};
