import { hdiTicketWebsocketCommands, } from '../../constants/hdiTicketWebsocketCommands';
import { HDI_ATTACHMENTS_UPLOADING_ERROR } from '../../actions/hdi/actionAttachments';
import { HDI_ATTACHMENTS_SAVED, HDI_ATTACHMENTS_SAVED_ERROR } from '../../constants/websocketCommands';
import { HDI_SUBSTITUTION_USER, HDI_SUBSTITUTION_USER_RESET } from '../../actions/hdi/actionTicket';

export const ticket = (state = {
    correlationId: null,
    isTicketLoading: false,
    isTicketCreating: false,
    created: false,
    ticket: {},
    followups: [],
    attachments: [],
    newAttachments: [],
    substitutionUser: null,
    editor: [],
}, action) => {
    switch (action.type) {
        case hdiTicketWebsocketCommands.HDI_TICKET_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLoading: false,
                isTicketCreating: false,
                created: false,
                ticket: {},
                followups: [],
                attachments: [],
                newAttachments: [],
                substitutionUser: null,
                editor: []
            });
        }
        case HDI_SUBSTITUTION_USER: {
            return Object.assign({}, state, {
                substitutionUser: action.payload
            });
        }
        case HDI_SUBSTITUTION_USER_RESET: {
            return Object.assign({}, state, {
                substitutionUser: null,
            });
        }
        case hdiTicketWebsocketCommands.HDI_TICKET_LOAD_DATA: {
            return Object.assign({}, state, {});
        }
        case hdiTicketWebsocketCommands.HDI_TICKET_UPDATE_PENDING:
        case hdiTicketWebsocketCommands.HDI_TICKET_CREATE_PENDING: {
            const { newAttachments } = action.payload.payload;
            return Object.assign({}, state, {
                newAttachments,
                isTicketCreating: true,
                isTicketLoading: true,
                followups: [],
                attachments: [],
            });
        }

        case hdiTicketWebsocketCommands.HDI_TICKET_CREATED:
        case hdiTicketWebsocketCommands.HDI_TICKET_UPDATED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketCreating: false,
                created: true,
                isTicketLoading: true,
                correlationId
            });
        }
        // this code seems not to be used
        // case hdiTicketWebsocketCommands.HDI_TICKET_CREATE_SEND:
        // case hdiTicketWebsocketCommands.HDI_TICKET_UPDATE_SEND:
        // case hdiTicketWebsocketCommands.HDI_TICKET_DETAIL_SEND: {
        //     const { correlationId, payload, error } = action.payload;
        //     const { ticket, attachments, followups, editor = [], internalNotes = [] } = payload;
        //
        //     console.log('payload');
        //     console.log(payload);
        //
        //
        //     if (correlationId < state.correlationId) {
        //         return state;
        //     }
        //     if (error) {
        //         return Object.assign({}, state, { isTicketLoading: false, correlationId });
        //     }
        //
        //     return Object.assign({}, state, {
        //         isTicketLoading: false,
        //         correlationId,
        //         ticket,
        //         followups: followups && followups.length > 0 ? followups.reverse() : [],
        //         attachments,
        //         internalNotes,
        //         editor
        //     });
        // }
        // case hdiTicketWebsocketCommands.HDI_TICKET_CREATE_SEND_ERROR:
        // case hdiTicketWebsocketCommands.HDI_TICKET_UPDATE_SEND_ERROR:
        // case hdiTicketWebsocketCommands.HDI_TICKET_DETAIL_SEND_ERROR: {
        //     const { correlationId } = action.payload;
        //
        //     if (correlationId < state.correlationId) {
        //         return state;
        //     }
        //     return Object.assign({}, state, {
        //         isTicketCreating: false,
        //         isTicketLoading: false,
        //         correlationId,
        //     });
        // }

        case hdiTicketWebsocketCommands.HDI_TICKET_CREATE_ERROR:
        case hdiTicketWebsocketCommands.HDI_TICKET_UPDATE_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketCreating: false,
                created: false,
                isTicketLoading: false,
                correlationId,
            });
        }

        case hdiTicketWebsocketCommands.HDI_TICKET_DETAIL_PENDING: {
            return Object.assign({}, state, { isTicketLoading: true });
        }

        case hdiTicketWebsocketCommands.HDI_TICKET_SEND: {
            const { payload, error, correlationId } = action.payload;
            const { ticket, attachments, followups, editor = [], internalNotes = [] } = payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isTicketLoading: false, correlationId });
            }
            return Object.assign({}, state, {
                isTicketLoading: false,
                correlationId,
                ticket,
                attachments,
                internalNotes,
                followups: followups && followups.length > 0 ? followups.reverse() : [],
                editor
            });
        }
        case hdiTicketWebsocketCommands.HDI_TICKET_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isTicketLoading: false, correlationId });

        }
        case HDI_ATTACHMENTS_UPLOADING_ERROR:
        case HDI_ATTACHMENTS_SAVED_ERROR: {
            return Object.assign({}, state, {
                newAttachments: [],
            });
        }
        case HDI_ATTACHMENTS_SAVED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketLoading: true,
                correlationId,
                newAttachments: [],
            });
        }
        case hdiTicketWebsocketCommands.TICKET_SAVE_NEW_ERROR:
        case hdiTicketWebsocketCommands.TICKET_SAVE_UPDATE_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        default:
            return state;
    }
};
