import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { addLocaleData } from 'react-intl';
import thunk from 'redux-thunk';
import csLocaleData from 'react-intl/locale-data/cs';
import skLocaleData from 'react-intl/locale-data/sk';
import enLocaleData from 'react-intl/locale-data/en';
import plLocaleData from 'react-intl/locale-data/pl';
import svLocaleData from 'react-intl/locale-data/sv';
import initialState from './initialState';
import history from '../history.js';
import { routerMiddleware } from 'react-router-redux';
import metisMenuReducer from 'react-metismenu/lib/reducers';
import { createHdiSocketMiddleware, } from './socketMiddleware';
import { intl } from '../reducers/intlReducer';
import { menu } from '../reducers/menuReducer';
import { profile } from '../reducers/roleReducer';
import { adminRightsManagement } from '../reducers/admin/adminRightsManagementReducer';
import { alerts } from '../reducers/alertReducer';
import { websocket } from '../reducers/websocketReducer';
import { supportModal } from '../reducers/supportReducer';
import { reducer as form } from 'redux-form';
import createSagaMiddleware from 'redux-saga'
import rightsManagementSaga from '../sagas/rightsManagementSaga';
import { ticket } from '../reducers/ticket/ticketReducer';
import { listTicket } from '../reducers/ticket/listReducer';
import { hdiAttachment } from '../reducers/ticket/attachmentReducer';
import { preferences } from '../reducers/preferencesReducer';
import { adminSolutionGroup } from '../reducers/admin/adminSolutionGroupReducer';
import { adminSolutionGroupList } from '../reducers/admin/adminSolutionGroupListReducer';
import { adminTemplate } from '../reducers/admin/adminTemplateReducer';
import ticketSaga from '../sagas/ticketSaga';
import supportModalSaga from '../sagas/supportModalSaga';
import { adminEditor } from '../reducers/admin/adminEditorReducer';
import { adminEditorList } from '../reducers/admin/adminEditorListReducer';
import { adminTemplateList } from '../reducers/admin/adminTemplateListReducer';
import { adminWelcome } from '../reducers/admin/adminWelcomeReducer';
import { adminSolutionGroupDefault } from '../reducers/admin/adminSolutionGroupDefaultReducer';
import editorSaga from '../sagas/editorSaga';
import solutionGroupSaga from '../sagas/solutionGroupSaga';
import templateSaga from '../sagas/templateSaga';
import { exportTickets } from '../reducers/ticket/exportReducer';
import {adminFrequentQuestion} from '../reducers/admin/adminFrequentQuestionReducer';
import {mainFiles} from '../reducers/fileReducer';
import frequentQuestionFileSaga from '../sagas/frequentQuestionFileSaga';


/**
 * Configuration of store, reducers, middlewares, enhancers management.
 */
const configureStore = (initialStoreContent = {}) => {
    const persistedState = Object.assign(initialState, initialStoreContent);
    const middlewares = process.env.NODE_ENV !== 'production' ?
        [require('redux-immutable-state-invariant').default(), thunk] :
        [thunk];
    const enhancers = [];
    const sagaMiddleware = createSagaMiddleware();

    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f);

    const appReducers = combineReducers({
        intl,
        menu,
        profile,
        alerts,
        websocket,
        form,
        adminRightsManagement,
        adminSolutionGroup,
        adminSolutionGroupList,
        adminSolutionGroupDefault,
        adminTemplate,
        adminTemplateList,
        adminEditor,
        adminEditorList,
        adminWelcome,
        adminFrequentQuestion,
        supportModal,
        listTicket,
        exportTickets,
        ticket,
        hdiAttachment,
        preferences,
        metisMenuStore: metisMenuReducer,
        mainFiles,
    });

    const store = createStore(
        appReducers,
        persistedState,
        compose(
            applyMiddleware(
                ...middlewares,
                createHdiSocketMiddleware(),
                routerMiddleware(history),
                sagaMiddleware
            ),
            ...enhancers
        )
    );

    addLocaleData(enLocaleData);
    addLocaleData(csLocaleData);
    addLocaleData(skLocaleData);
    addLocaleData(svLocaleData);
    addLocaleData(plLocaleData);

    sagaMiddleware.run(rightsManagementSaga);
    sagaMiddleware.run(ticketSaga);
    sagaMiddleware.run(solutionGroupSaga);
    sagaMiddleware.run(editorSaga);
    sagaMiddleware.run(supportModalSaga);
    sagaMiddleware.run(templateSaga);
    sagaMiddleware.run(frequentQuestionFileSaga);

    return store;
};

export default configureStore;
