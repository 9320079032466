import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import get from 'get-value';
import PropTypes from 'prop-types';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import { Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';
import { clearAlerts } from '../../../../actions/alertsActions';
import ListTable from './ListTable';
import listMessages from '../../../../intl/hdi/listMessages';
import Loader from '../../../Loader';
import PageHeader from '../../../common/PageHeader';
import DatePickerTableComponent from '../../../common/datePicker/DatePickerTableComponent';
import { getAllFormStates, getBrands } from '../../../../constants/Utils';
import formStatesMessages from '../../../../intl/common/formStatesMessages';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import { getPreferences, updateUserListPreference } from '../../../../actions/hdi/actionPreferences';
import formBrandMessages from '../../../../intl/common/formBrandMessages';
import FilterForm from './filter/FilterForm';
import { fetchSolutionGroupShortList } from '../../../../actions/admin/actionSolutionGroupListPage';
import { getUserRole, isVisibleForCountry } from '../Utils';
import { editorConstants } from '../../../../constants/form/formEditorConstants';
import { ticketConstants } from '../../../../constants/form/formTicketConstants';
import { moduleRoles } from '../../../../utils/roles';
import { getEditorSolutionGroups } from '../../../../actions/admin/actionAdminEditor';

const StyledPicky = styled(Picky)`
    button:first-child{
        border-radius: 0.3em;
    };
`;

class ListPage extends Component {
    constructor(props) {
        super(props);
        const { intl, userDetail } = props;
        this.state = {
            ratchet: false,
            ticketId: '',
            columnLibrary: {
                EDITOR: [
                    {
                        id: 'brand',
                        accessor: 'brand',
                        message: listMessages.TABLE_BRAND,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(listMessages.ALL)}</option>
                                {getBrands.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formBrandMessages[key] ? intl.formatMessage(formBrandMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formBrandMessages[e.value] ? intl.formatMessage(formBrandMessages[e.value]) : e.value}</div>,
                    },
                    {
                        id: 'status',
                        accessor: 'status',
                        message: listMessages.TABLE_STATUS,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(listMessages.ALL)}</option>
                                {getAllFormStates.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formStatesMessages[key] ? intl.formatMessage(formStatesMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                    },
                    {
                        id: 'comment',
                        accessor: 'comment',
                        message: listMessages.TABLE_COMMENT,
                    },
                    {
                        id: 'createdDate',
                        accessor: 'createdDate',
                        message: listMessages.TABLE_CREATION_TIME,
                        Filter: ({ filter, onChange }) => {
                            let value = filter && filter.value;
                            if (typeof value === 'string') {
                                value = moment(value).toDate();
                            }
                            return <DatePickerTableComponent
                                value={value || undefined}
                                onChange={onChange}
                                filter={filter}/>;
                        },
                        Cell: e => <><FormattedDate value={new Date(e.value)}/> <FormattedTime
                            value={new Date(e.value)}/></>,
                    },
                    {
                        id: 'creatorIpn',
                        accessor: 'creatorIpn',
                        message: listMessages.TABLE_CREATED_BY,
                    },
                    {
                        id: 'company',
                        accessor: 'company',
                        message: listMessages.TABLE_COMPANY,
                    },
                    {
                        id: 'lastModify',
                        accessor: 'lastModify',
                        message: listMessages.TABLE_LAST_MODIFY,
                        Filter: ({ filter, onChange }) => {
                            let value = filter && filter.value;
                            if (typeof value === 'string') {
                                value = moment(value).toDate();
                            }
                            return <DatePickerTableComponent
                                value={value || undefined}
                                onChange={onChange}
                                filter={filter}/>;
                        },
                        Cell: e => <><FormattedDate value={new Date(e.value)}/> <FormattedTime
                            value={new Date(e.value)}/></>,
                    },
                    {
                        id: 'solutionGroup',
                        accessor: 'solutionGroup.shortName',
                        message: isVisibleForCountry(userDetail.group) ? listMessages.TABLE_SOLUTION_GROUP_UK : listMessages.TABLE_SOLUTION_GROUP,
                        filterable: false,
                    },
                    {
                        id: 'subject',
                        accessor: 'subject',
                        message: listMessages.TABLE_SUBJECT,
                    },
                    {
                        id: 'templateId',
                        accessor: 'templateId',
                        message: isVisibleForCountry(userDetail.group) ? listMessages.TABLE_TEMPLATE_UK : listMessages.TABLE_TEMPLATE,
                    },
                ],
                USER: [
                    {
                        id: 'brand',
                        accessor: 'brand',
                        message: listMessages.TABLE_BRAND,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(listMessages.ALL)}</option>
                                {getBrands.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formBrandMessages[key] ? intl.formatMessage(formBrandMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formBrandMessages[e.value] ? intl.formatMessage(formBrandMessages[e.value]) : e.value}</div>,
                    },
                    {
                        id: 'createdDate',
                        accessor: 'createdDate',
                        message: listMessages.TABLE_CREATION_TIME,
                        Filter: ({ filter, onChange }) => {
                            let value = filter && filter.value;
                            if (typeof value === 'string') {
                                value = moment(value).toDate();
                            }
                            return <DatePickerTableComponent
                                value={value || undefined}
                                onChange={onChange}
                                filter={filter}/>;
                        },
                        Cell: e => <><FormattedDate value={new Date(e.value)}/> <FormattedTime
                            value={new Date(e.value)}/></>,
                    },
                    {
                        id: 'solutionGroup',
                        accessor: 'solutionGroup.shortName',
                        message: isVisibleForCountry(userDetail.group) ? listMessages.TABLE_SOLUTION_GROUP_UK : listMessages.TABLE_SOLUTION_GROUP,
                        filterable: false,
                    },
                    {
                        id: 'subject',
                        accessor: 'subject',
                        message: listMessages.TABLE_SUBJECT,
                    },
                    {
                        id: 'templateId',
                        accessor: 'templateId',
                        message: isVisibleForCountry(userDetail.group) ? listMessages.TABLE_TEMPLATE_UK : listMessages.TABLE_TEMPLATE,
                    },
                    {
                        id: 'status',
                        accessor: 'status',
                        message: listMessages.TABLE_STATUS,
                        Filter: ({ filter, onChange }) => (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%', border: '1px solid #999999' }}
                                value={filter ? filter.value : ''}>
                                <option value="">{intl.formatMessage(listMessages.ALL)}</option>
                                {getAllFormStates.map((key, index) =>
                                    <option key={index} value={key}>
                                        {formStatesMessages[key] ? intl.formatMessage(formStatesMessages[key]) : key}
                                    </option>)}
                            </select>
                        ),
                        Cell: e =>
                            <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                    },
                ]
            }
        };
    }

    componentDidMount() {
        this.props.getPreferences(this.props.loggedUser.ipn);
        const { userRoles } = this.props;
        if (moduleRoles.isEditor(userRoles)) {
            this.props.fetchSolutionGroupShortList();
            this.props.getEditorSolutionGroups()
        }
        //this.props.clearAlerts();
    }

    handleEditClick = ({ id }) => {
        this.setState({ ratchet: true, ticketId: id });
    };

    handleSelectSolutionGroup = () => {
        const { defaultProperties } = this.props;
        const { listTicketDefaultFilters } = defaultProperties;

        if (Array.isArray(listTicketDefaultFilters)) {
            const res = listTicketDefaultFilters.find(item => item.id === 'solutionGroup');
            if (res && get(res, 'value')) {
                return res.value;
            }
        }
        return '';
    };

    selectMultipleOption = (value) => {
        this.props.updateUserListPreference(this.props.loggedUser.ipn,
            this.state.columnLibrary[getUserRole(this.props.loggedUser.roles)].filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    render() {
        const { intl, preferences, filter, isSolutionGroupListLoading, isEditorLoading, userDetail } = this.props;
        const { columnLibrary, ticketId } = this.state;

        if (isSolutionGroupListLoading || isEditorLoading || preferences.isLoading ) {
            return <Loader/>
        }

        if (this.state.ratchet) {
            return <Redirect to={`/hdi/edit/${ticketId}`}/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={isVisibleForCountry(userDetail.group) ? <FormattedMessage {...listMessages.TITLE_REQUEST}/> : <FormattedMessage {...listMessages.TITLE}/>}/>
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span
                            className="font-weight-bold"><FormattedMessage {...listMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={columnLibrary[getUserRole(this.props.loggedUser.roles)].filter(item => !get(preferences, 'menuList', { default: [] }).includes(item.id)).map(col => {
                                return { id: col.id, message: intl.formatMessage(col.message) }
                            })}
                            options={columnLibrary[getUserRole(this.props.loggedUser.roles)].map(col => {
                                return { id: col.id, message: intl.formatMessage(col.message) }
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="col-12">
                        <FilterForm form={'TICKET_SEARCH'}
                                    initialValues={{
                                        [editorConstants.FORM_EDITOR_EDITOR_SECTION]: {
                                            [ticketConstants.FORM_FIELD_SEARCH_SOLUTION_GROUP]: this.handleSelectSolutionGroup()
                                        }
                                    }}
                                    enableReinitialize={true}
                                    role={getUserRole(this.props.loggedUser.roles)}
                                    handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
                <ListTable
                    columns={columnLibrary[getUserRole(this.props.loggedUser.roles)].filter(item => !get(preferences, 'menuList', { default: [] }).includes(item.id))}
                    handleEditClick={this.handleEditClick}
                    filter={filter}
                    intl={intl}/>
            </div>
        );
    }
}

ListPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    getPreferences: PropTypes.func.isRequired,
    fetchSolutionGroupShortList: PropTypes.func.isRequired,
    getEditorSolutionGroups: PropTypes.func.isRequired,
    updateUserListPreference: PropTypes.func.isRequired,
    domain: PropTypes.string.isRequired,
    loggedUser: PropTypes.object.isRequired,
    userRoles: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    preferences: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    defaultProperties: PropTypes.object.isRequired,
    isEditorLoading: PropTypes.bool,
};

const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    userRoles: state.profile.userDetail.roles,
    preferences: state.preferences,
    isSolutionGroupListLoading: state.adminSolutionGroupList.isSolutionGroupListLoading,
    isEditorLoading: state.adminEditor.isEditorLoading,
    defaultProperties: state.listTicket.defaultProperties,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateUserListPreference,
    getPreferences,
    fetchSolutionGroupShortList,
    getEditorSolutionGroups
})(injectIntl(ListPage))), ['HDI_EDITOR', 'HDI_ADMIN', 'HDI_USER']);

