import { hdiTicketWebsocketCommands } from '../../constants/hdiTicketWebsocketCommands';
import { validateNewTicket, validateUpdateTicket } from '../../api/validation/ticket';
import { errorAlert } from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';

export const HDI_SUBSTITUTION_USER = 'HDI_SUBSTITUTION_USER';
export const HDI_SUBSTITUTION_USER_RESET = 'HDI_SUBSTITUTION_USER_RESET';

export const resetSubstitutionUser = () => dispatch => {
    return dispatch({
        type: HDI_SUBSTITUTION_USER_RESET,
    });
};

export const setSubstitutionUser = user => ({
    type: HDI_SUBSTITUTION_USER,
    payload: user
});

export const updateTicket = (data) => dispatch => {
    const { error } = validateUpdateTicket(data);
    if (error) {
        dispatch(errorAlert(alertMessages.TICKET_UPDATE_SEND_ERROR, [error.toString()]));
        dispatch({ type: hdiTicketWebsocketCommands.TICKET_SAVE_UPDATE_ERROR });
    }
    return dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_UPDATE_PENDING,
        hdiWebsocket: true,
        command: hdiTicketWebsocketCommands.HDI_UPDATE_TICKET,
        payload: {
            payload: {
                ...data
            }
        }
    });
};

export const saveTicket = (data) => dispatch => {
    const { error } = validateNewTicket(data);
    if (error) {
        dispatch(errorAlert(alertMessages.TICKET_NEW_SEND_ERROR, [error.toString()]));
        dispatch({ type: hdiTicketWebsocketCommands.TICKET_SAVE_NEW_ERROR });
        return;
    } else {
        return dispatch({
            type: hdiTicketWebsocketCommands.HDI_TICKET_CREATE_PENDING,
            hdiWebsocket: true,
            command: hdiTicketWebsocketCommands.HDI_CREATE_TICKET,
            payload: {
                payload: {
                    ...data,
                }
            }
        });
    }
};

export const fetchTicket = (id, ipn) => dispatch => {
    return dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_DETAIL_PENDING,
        hdiWebsocket: true,
        command: hdiTicketWebsocketCommands.HDI_GET_TICKET,
        payload: {
            ticket: {
                id,
            },
            ipn
        }
    });
};

export const sendSatisfactionCheck = (id) => dispatch => {
    return dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_UPDATE_SATISFACTION_CHECK,
        hdiWebsocket: true,
        command: hdiTicketWebsocketCommands.HDI_UPDATE_TICKET_SATISFACTION_CHECK,
        payload: {
            payload: {
                ticket: {
                    id
                }
            }
        }
    });
};

export const fetchLoadAllTicketData = (id, ipn) => dispatch => {
    return dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_LOAD_DATA,
        payload: {
            id,
            ipn
        }
    });
};

export const resetTicket = () => dispatch => {
    return dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_RESET,
    });
};

