export const hdiEditorWebsocketCommands = {
    //Check existing
    HDI_EDITOR_ROLE_GET: 'getEditorRole',
    HDI_EDITOR_ROLE_PENDING: 'HDI_EDITOR_ROLE_PENDING',
    HDI_EDITOR_ROLE_SEND: 'HDI_EDITOR_ROLE_SEND',
    HDI_EDITOR_ROLE_SEND_ERROR: 'HDI_EDITOR_ROLE_SEND_ERROR',
    //Get List
    HDI_EDITOR_LIST: 'getEditorList',
    HDI_EDITOR_LIST_FETCH_PENDING: 'HDI_EDITOR_LIST_FETCH_PENDING',
    HDI_EDITOR_LIST_SEND: 'HDI_EDITOR_LIST_SEND',
    HDI_EDITOR_LIST_SEND_ERROR: 'HDI_EDITOR_LIST_SEND_ERROR',
    //Get Editor solution groups list
    HDI_TICKET_EDITOR_SOLUTION_GROUP: 'getEditorSolutionGroups',
    HDI_TICKET_EDITOR_SOLUTION_GROUP_PENDING: 'HDI_TICKET_EDITOR_SOLUTION_GROUP_PENDING',
    HDI_TICKET_EDITOR_SOLUTION_GROUP_SEND: 'HDI_TICKET_EDITOR_SOLUTION_GROUP_SEND',
    HDI_TICKET_EDITOR_SOLUTION_GROUP_SEND_ERROR: 'HDI_TICKET_EDITOR_SOLUTION_GROUP_SEND_ERROR',
    //Get Detail
    HDI_EDITOR_DETAIL: 'getEditor',
    HDI_EDITOR_DETAIL_FETCH_PENDING: 'HDI_EDITOR_DETAIL_FETCH_PENDING',
    HDI_EDITOR_DETAIL_SEND: 'HDI_EDITOR_DETAIL_SEND',
    HDI_EDITOR_DETAIL_SEND_ERROR: 'HDI_EDITOR_DETAIL_SEND_ERROR',
    //Create
    HDI_EDITOR_CREATE: 'setEditorRole',
    HDI_EDITOR_CREATE_PENDING: 'HDI_EDITOR_CREATE_PENDING',
    HDI_EDITOR_CREATE_ERROR: 'HDI_EDITOR_CREATE_ERROR',
    //Update
    HDI_EDITOR_UPDATE: 'updateEditorRole',
    HDI_EDITOR_UPDATE_PENDING: 'HDI_EDITOR_UPDATE_PENDING',
    HDI_EDITOR_UPDATE_ERROR: 'HDI_EDITOR_UPDATE_ERROR',
    //Delete
    HDI_EDITOR_DELETE: 'removeEditorRole',
    HDI_EDITOR_DELETE_PENDING: 'HDI_EDITOR_DELETE_PENDING',
    HDI_EDITOR_ROLE_DELETED: 'HDI_EDITOR_ROLE_DELETED',
    HDI_EDITOR_ROLE_DELETED_ERROR: 'HDI_EDITOR_ROLE_DELETED_ERROR',

    HDI_EDITOR_COMMAND_ACCEPTED: 'HDI_EDITOR_COMMAND_ACCEPTED',
    HDI_EDITOR_SEND: 'HDI_EDITOR_SEND',
    HDI_EDITOR_SEND_ERROR: 'HDI_EDITOR_CREATE_SEND_ERROR',

    HDI_EDITOR_RESET: 'HDI_EDITOR_RESET',
    HDI_ADD_DEFAULT_SOLUTION_GROUP: 'HDI_ADD_DEFAULT_SOLUTION_GROUP',

    EDITOR_SAVE_NEW_ERROR: 'EDITOR_SAVE_NEW_ERROR',
    EDITOR_SAVE_UPDATE_ERROR: 'EDITOR_SAVE_UPDATE_ERROR',
};
