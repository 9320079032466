import { hdiSolutionGroupWebsocketCommands } from '../../constants/hdiSolutionGroupWebsocketCommands';

export const updateDefaultSolutionGroup = (data) => dispatch => {
    return dispatch({
        type: hdiSolutionGroupWebsocketCommands.HDI_SOLUTION_GROUP_DEFAULT_UPDATE_PENDING,
        hdiWebsocket: true,
        command: hdiSolutionGroupWebsocketCommands.HDI_SOLUTION_GROUP_DEFAULT_UPDATE,
        payload: {
            payload: {
                ...data
            }
        }
    });
};

export const fetchDefaultSolutionGroup = () => dispatch => {
    return dispatch({
        type: hdiSolutionGroupWebsocketCommands.HDI_SOLUTION_GROUP_DEFAULT_LIST_PENDING,
        hdiWebsocket: true,
        command: hdiSolutionGroupWebsocketCommands.HDI_SOLUTION_GROUP_DEFAULT_LIST,
    });
};

export const resetDefaultSolutionGroup = () => dispatch => {
    return dispatch({
        type: hdiSolutionGroupWebsocketCommands.HDI_SOLUTION_GROUP_DEFAULT_RESET,
    });
};

