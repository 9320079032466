export const DEFAULT_DEBOUNCE_TIMEOUT = 500;
export const FILE_MAX_SIZE = 10485760;
export const NOT_SELECTED_OPTION = "---";
export const DEFAULT_NOTE_FIELD_MAX_LENGTH = 3000;

export const EMAIL_VALIDATION_REGEX = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/;
export const IPN_VALIDATION_REGEX = /^[a-zA-Z]{1}([a-zA-Z]|\d){5}\d{1}$/;
export const DATE_VALIDATION_REGEX = /(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.([12]\d{3})/;
export const COMMON_INPUT_VALIDATION_REGEX = /.*/;
export const DIGIT_STRING_VALIDATION_REGEX = /^[0-9]*$/;
export const DEFAULT_FIELD_MAX_LENGTH = 50;
