import { ALL_COUNTRIES_VALUE, countries } from './Utils';
import { ticketConstants } from './form/formTicketConstants';
import { solutionGroupConstants } from './form/formSolutionGroupConstants';
import { editorConstants } from './form/formEditorConstants';
import { templateConstants } from './form/formTemplateConstants';
import { welcomeConstants } from './form/formWelcomeConstants';

export const intlFieldMapping = {
    [ticketConstants.FORM_FIELD_NAME]: ticketConstants.FORM_LABEL_NAME,
    [ticketConstants.FORM_FIELD_EMAIL]: ticketConstants.FORM_LABEL_EMAIL,
    [ticketConstants.FORM_FIELD_TELEPHONE_NO]: ticketConstants.FORM_LABEL_TELEPHONE_NO,
    [ticketConstants.FORM_FIELD_TICKET_NUMBER]: ticketConstants.FORM_LABEL_TICKET_NUMBER,
    [ticketConstants.FORM_FIELD_NOTE]: ticketConstants.FORM_LABEL_NOTE,
    [ticketConstants.FORM_FIELD_INTERNAL_NOTE]: ticketConstants.FORM_LABEL_INTERNAL_NOTE,
    [ticketConstants.FORM_FIELD_COMMENT]: ticketConstants.FORM_LABEL_COMMENT,
    [ticketConstants.FORM_FIELD_ALIAS]: ticketConstants.FORM_LABEL_ALIAS,
    [ticketConstants.FORM_FIELD_COPY_EMAIL]: ticketConstants.FORM_LABEL_COPY_EMAIL,
    [ticketConstants.FORM_FIELD_DESCRIPTION]: ticketConstants.FORM_LABEL_DESCRIPTION,
    [ticketConstants.FORM_FIELD_SUBJECT]: ticketConstants.FORM_LABEL_SUBJECT,
    [ticketConstants.FORM_FIELD_PRIORITY]: ticketConstants.FORM_LABEL_PRIORITY,
    [ticketConstants.FORM_FIELD_STATUS]: ticketConstants.FORM_LABEL_STATUS,
    [ticketConstants.FORM_FIELD_SOLUTION_GROUP]: ticketConstants.FORM_LABEL_SOLUTION_GROUP_UK,
    [ticketConstants.FORM_FIELD_TEMPLATE_TYPE]: ticketConstants.FORM_LABEL_TEMPLATE_TOPIC,

    [ALL_COUNTRIES_VALUE]: ALL_COUNTRIES_VALUE,
};

export const intlSolutionGroupFieldMapping = {
    [solutionGroupConstants.FORM_FIELD_SG_NAME]: solutionGroupConstants.FORM_LABEL_SG_NAME,
    [solutionGroupConstants.FORM_FIELD_SG_LONG_NAME]: solutionGroupConstants.FORM_LABEL_SG_LONG_NAME,
    [solutionGroupConstants.FORM_FIELD_SG_ACTIVE]: solutionGroupConstants.FORM_LABEL_SG_ACTIVE,
    [solutionGroupConstants.FORM_FIELD_SG_COUNTRIES]: solutionGroupConstants.FORM_LABEL_SG_COUNTRIES,
    [solutionGroupConstants.FORM_FIELD_SG_LEVEL]: solutionGroupConstants.FORM_LABEL_SG_LEVEL,
    [countries['CZ']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['SK']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['HU']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['SE']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['NO']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['FI']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['DK']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['NL']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['BE']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['PL']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['IE']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['UK']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['CY']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
    [countries['MT']]: solutionGroupConstants.FORM_LABEL_DEFAULT_SG,
};
export const intlEditorFieldMapping = {
    [editorConstants.FORM_FIELD_EDITOR_DEFAULT_SG]: editorConstants.FORM_LABEL_EDITOR_DEFAULT_SG,
    [editorConstants.FORM_FIELD_EDITOR_SOLUTION_GROUP_ACCESS]: editorConstants.FORM_LABEL_EDITOR_SOLUTION_GROUP_ACCESS,
    [editorConstants.FORM_FIELD_EDITOR_NAME]: editorConstants.FORM_LABEL_EDITOR_NAME,
    [editorConstants.FORM_FIELD_EDITOR_ON_HOLD]: editorConstants.FORM_LABEL_EDITOR_ON_HOLD,
    [editorConstants.FORM_FIELD_EDITOR_SUSPEND]: editorConstants.FORM_LABEL_EDITOR_SUSPEND,
};
export const intlTemplateFieldMapping = {
    [templateConstants.FORM_FIELD_TEMPLATE_COPY]: templateConstants.FORM_LABEL_TEMPLATE_COPY,
    [templateConstants.FORM_FIELD_TEMPLATE_COUNTRY]: templateConstants.FORM_LABEL_TEMPLATE_COUNTRY,
    [templateConstants.FORM_FIELD_TEMPLATE_DESCRIPTION]: templateConstants.FORM_LABEL_TEMPLATE_DESCRIPTION,
    [templateConstants.FORM_FIELD_TEMPLATE_SOLUTION_GROUP]: templateConstants.FORM_LABEL_TEMPLATE_SOLUTION_GROUP,
    [templateConstants.FORM_FIELD_TEMPLATE_SUBJECT]: templateConstants.FORM_LABEL_TEMPLATE_SUBJECT,
};

export const intlWelcomeFieldMapping = {
    [welcomeConstants.FORM_FIELD_WELCOME_MESSAGE]: welcomeConstants.FORM_LABEL_WELCOME_MESSAGE,
    [welcomeConstants.FORM_FIELD_WELCOME_LANGUAGE]: welcomeConstants.FORM_LABEL_WELCOME_LANGUAGE,
};
