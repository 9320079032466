import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {ModalBody, ModalFooter, ModalHeader, ModalWindow} from '../../common/modal/Modal';
import {transformClosedTicketForReopen} from '../../hdi/ticket/Utils';
import SatisfactionForm from './SatisfactionForm';
import SatisfactionFormButtons from './SatisfactionFormButtons';
import {connect} from 'react-redux';
import {clearAlerts} from '../../../actions/alertsActions';
import {updateTicket} from '../../../actions/hdi/actionTicket';

function SatisfactionModal({show, handleClose, text, values, clearAlerts, updateTicket, ticket}) {
    return (
        <ModalWindow isOpen={show} onRequestClose={handleClose}>
            <ModalHeader>
                <div className="row">
                    <div className="col-sm-11">
                        {text instanceof Object && Object.keys(text).length !== 0 &&
                            <FormattedMessage {...text} values={values}/>}
                        {(text instanceof String || typeof text === 'string') && text}
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    <SatisfactionForm/>
                </div>
            </ModalBody>
            <ModalFooter>
                <SatisfactionFormButtons onSubmit={e => {
                    updateTicket(transformClosedTicketForReopen(ticket, e.text));
                    clearAlerts();
                }} close={handleClose}/>
            </ModalFooter>
        </ModalWindow>
    )
}

SatisfactionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    ticket: PropTypes.any,
    clearAlerts: PropTypes.func.isRequired,
    updateTicket: PropTypes.func.isRequired,
    text: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string
    ])
};

const mapStateToProps = state => ({
    ticket: state.ticket
});

export default connect(mapStateToProps, {
    clearAlerts,
    updateTicket
})(SatisfactionModal);
