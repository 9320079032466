import { hdiWebsocketCommands } from '../../constants/hdiWebsocketCommands';

export const HDI_UPDATE_USER_LIST_PREFERENCE = 'HDI_UPDATE_USER_LIST_PREFERENCE';
export const HDI_FETCH_PREFERENCES_PENDING = 'HDI_FETCH_PREFERENCES_PENDING';
export const HDI_UPDATE_USER_FULLSEARCH_PREFERENCE = 'HDI_UPDATE_USER_FULLSEARCH_PREFERENCE';

export const getPreferences = (ipn) => dispatch => {
    return dispatch({
        type: HDI_FETCH_PREFERENCES_PENDING,
        hdiWebsocket: true,
        command: hdiWebsocketCommands.HDI_PREFERENCE_GET,
        payload: { ipn }
    });
};

export const updateUserListPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: HDI_UPDATE_USER_LIST_PREFERENCE,
        hdiWebsocket: true,
        command: hdiWebsocketCommands.HDI_USER_LIST_PREFERENCE_SET,
        payload: { preferences: { ipn, hiddenColumns: preferences } }
    });
};

export const updateUserFullsearchPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: HDI_UPDATE_USER_FULLSEARCH_PREFERENCE,
        hdiWebsocket: true,
        command: hdiWebsocketCommands.HDI_USER_FULLSEARCH_PREFERENCE_SET,
        payload: { preferences: { ipn, hiddenColumns: preferences } }
    });
};

