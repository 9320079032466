import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchTemplate, resetTemplate, updateTemplate } from '../../../../actions/admin/actionAdminTemplate';
import Loader from '../../../Loader';
import { HDI_TEMPLATE_FORM, } from '../../../../constants/formConstants';
import TemplateForm from './forms/TemplateForm';

import {initLoadedTemplate, isVisibleForCountry, transformUpdateTemplateForSave} from '../utils';
import { createReqFields } from '../../../../constants/requiredFields';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import { fetchSolutionGroupShortList } from '../../../../actions/admin/actionSolutionGroupListPage';
import { fetchSolutionGroupCountry } from '../../../../actions/admin/actionSolutionGroup';
import templateMessages from '../../../../intl/admin/templateMessages';
import { FormattedMessage } from 'react-intl';

class EditTemplatePage extends Component {

    componentDidMount() {
        //this.props.clearAlerts();
        //this.props.resetTemplate();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchTemplate(this.props.match.params.templateUuid, this.props.loggedUser.ipn);
        }
        this.props.fetchSolutionGroupShortList();
    }

    handleSubmit = (values) => {
        const dataForSend = transformUpdateTemplateForSave(values, this.props.templateData);
        this.props.updateTemplate(dataForSend);
    };

    handleSelectSolutionGroup = (value) => {
        this.props.fetchSolutionGroupCountry(value);

    };

    render() {
        const {
            templateData,
            userDetail,
        } = this.props;

        let formType = false;
        if (get(templateData, 'template.id', { default: false })) {
            formType = HDI_TEMPLATE_FORM;
        }

        if (
            templateData.isTemplateLoading
            || templateData.isTemplateCreating
            || !formType
        ) {
            return <Loader/>;
        }

        const requiredFields = createReqFields['DEFAULT'][formType];
        const initializationValue = initLoadedTemplate(templateData);
        return (
            <div className="container-fluid">
                <PageHeader title={isVisibleForCountry(userDetail.group) ? <FormattedMessage {...templateMessages.TITLE_UK}/> : <FormattedMessage {...templateMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <TemplateForm
                            form={formType}
                            initialValues={initializationValue}
                            enableReinitialize={true}
                            reqFields={requiredFields}
                            handleSelectSolutionGroup={this.handleSelectSolutionGroup}
                            isCountryLoading={this.props.isSolutionGroupDetailLoading}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

EditTemplatePage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    resetTemplate: PropTypes.func.isRequired,
    fetchTemplate: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateTemplate: PropTypes.func.isRequired,
    intl: PropTypes.object,
    templateData: PropTypes.object.isRequired,
    isSolutionGroupDetailLoading: PropTypes.bool.isRequired,
    loggedUser: PropTypes.object.isRequired,
    location: PropTypes.object,
    fetchSolutionGroupShortList: PropTypes.func.isRequired,
    fetchSolutionGroupCountry: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    templateData: state.adminTemplate,
    isSolutionGroupDetailLoading: state.adminTemplate.isSolutionGroupDetailLoading,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchTemplate,
    updateTemplate,
    resetTemplate,
    fetchSolutionGroupShortList,
    fetchSolutionGroupCountry
})(EditTemplatePage)), ['HDI_ADMIN']);
