import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import formMessages from '../../../../../../../intl/hdi/formMessages';
import {change, formValueSelector} from 'redux-form';
import { ticketConstants } from '../../../../../../../constants/form/formTicketConstants';
import InputSelectFieldForObjectWithValidation from '../../../../../../common/InputSelectFieldForObjectWithValidation';
import { isVisibleForCountry } from '../../../../Utils';
import { uniqWith, isEqual } from 'lodash';
import {parseEmailOptionsArrayFrom} from '../../../../../../../utils/utils';
import buttonMessages from '../../../../../../../intl/common/buttonMessages';
import {PrimaryButton} from '../../../../../../common/Button';
import ValidFormattedMessage from '../../../../../../common/ValidFormattedMessage';
import get from 'get-value';
import FrequentQuestionModal from '../../../../../../common/frequentQuestion/FrequentQuestionModal';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

class TemplateSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenFrequentQuestionModal: false
        }
    }

    showModal = () =>
        this.setState({
            isOpenFrequentQuestionModal: true,
        });
    closeModal = () =>
        this.setState({
            isOpenFrequentQuestionModal: false
        });

    render() {
        const {change, data, userDetail, solutionGroup, isSubstituted} = this.props;
        const {isOpenFrequentQuestionModal} = this.state;

        const handleChange = (event, value) => {
            const {data} = this.props;

            if (!value) {
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_TEMPLATE_ID}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_TEMPLATE_TYPE}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_SUBJECT}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_DESCRIPTION}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_COPY_EMAIL}`, []);
            } else {
                const result = data.find(item => item.id === value);

                const copyEmail = result.copy ? (Array.isArray(result.copy)) ? result.copy : (!!result.copy.value) ? [{
                    value: result.copy.value,
                    label: result.copy.value
                }] : [{value: result.copy, label: result.copy}] : [];

                const modifiedCopyEmail = [].concat.apply([], copyEmail.map(emails => parseEmailOptionsArrayFrom(emails.value)));

                if (result) {
                    change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_TEMPLATE_ID}`, result.id);
                    change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_TEMPLATE_TYPE}`, result.subject);
                    change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_SUBJECT}`, result.subject);
                    change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_DESCRIPTION}`, result.description);
                    change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_COPY_EMAIL}`, modifiedCopyEmail);
                }
            }
        };

        const handleSolutionGroupChange = (event, value) => {
            if (!value) {
                change(`${ticketConstants.FORM_TEMPLATE_SECTION}.${ticketConstants.FORM_FIELD_TEMPLATE_TYPE}`, []);
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_TEMPLATE_ID}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_TEMPLATE_TYPE}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_SUBJECT}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_DESCRIPTION}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_COPY_EMAIL}`, []);
            } else {

                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_TEMPLATE_ID}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_TEMPLATE_TYPE}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_SUBJECT}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_DESCRIPTION}`, '');
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_COPY_EMAIL}`, []);
            }
        };

        return (
            <>
                {(isVisibleForCountry(userDetail.group)) ?
                    <>
                        <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                            <div className="form-row">
                                <div className="col-12 pb-2">
                                    <InputSelectFieldForObjectWithValidation
                                        label={formMessages[ticketConstants.FORM_LABEL_SOLUTION_GROUP_UK]}
                                        field={ticketConstants.FORM_FIELD_SOLUTION_GROUP}
                                        options={uniqWith([...data].sort((a, b) => a.solutionGroup.localeCompare(b.solutionGroup))
                                            .map(item => {
                                                return {label: item.solutionGroup}
                                            }), isEqual)}
                                        handleChange={handleSolutionGroupChange}
                                        isSmall
                                        isRequired/>
                                </div>
                            </div>
                        </StyledDiv>
                        <br></br>
                        <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                            <div className="form-row">
                                <div className="col-12 pb-2">
                                    <div className="form-row align-items-end">
                                        <div className="col-md-10 col-lg-10 col-xl-10 pb-2">
                                    <InputSelectFieldForObjectWithValidation
                                        label={formMessages[ticketConstants.FORM_LABEL_TEMPLATE_TOPIC]}
                                        field={ticketConstants.FORM_FIELD_TEMPLATE_TYPE}
                                        options={[...data].filter(a => a.solutionGroup === solutionGroup).sort((a, b) => a.subject.localeCompare(b.subject))
                                            .map(item => {
                                                return {value: item.id, label: item.subject}
                                            })}
                                        handleChange={handleChange}
                                        isSmall
                                        isRequired/>
                                        </div>
                                        <>
                                            <div className="col-md-2 col-lg-2 col-xl-2 pl-2 pb-2">
                                                <PrimaryButton type="button" className="btn btn-sm" onClick={this.showModal}>
                                                    <ValidFormattedMessage message={ticketConstants.FORM_LABEL_FREQUENT_QUESTION}
                                                                           intlMessages={buttonMessages}/>
                                                </PrimaryButton>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </StyledDiv>
                    </>
                    :
                    <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                        <div className="form-row">
                            <div className="col-12 pb-2">
                                <div className="form-row align-items-end">
                                    <div className="col-md-10 col-lg-10 col-xl-10 pb-2">
                                        <InputSelectFieldForObjectWithValidation
                                            label={formMessages[ticketConstants.FORM_LABEL_TEMPLATE_TYPE]}
                                            field={ticketConstants.FORM_FIELD_TEMPLATE_TYPE}
                                            options={[...data].sort((a, b) => a.subject.localeCompare(b.subject))
                                                .map(item => {
                                                    return {value: item.id, label: item.subject}
                                                })}
                                            handleChange={handleChange}
                                            isSmall/>
                                    </div>
                                        <>
                                            <div className="col-md-2 col-lg-2 col-xl-2 pl-2 pb-2">
                                                <PrimaryButton type="button" className="btn btn-sm" onClick={this.showModal}>
                                                    <ValidFormattedMessage message={ticketConstants.FORM_LABEL_FREQUENT_QUESTION}
                                                                           intlMessages={buttonMessages}/>
                                                </PrimaryButton>
                                            </div>
                                        </>
                                </div>
                            </div>
                        </div>
                    </StyledDiv>
                }
                {isOpenFrequentQuestionModal &&
                    <FrequentQuestionModal show={isOpenFrequentQuestionModal}
                                           closeModal={this.closeModal}
                                           group={get(isSubstituted, "group", false) ? get(isSubstituted, "group", false) : get(userDetail, "group", "XX")}
                                           solutionGroup={solutionGroup}/>
                }
            </>
        )
    }
};

const mapStateToProps = (state, props) => ({
    data: state.adminTemplateList.shortListData,
    userDetail: state.profile.userDetail,
    solutionGroup: formValueSelector(props.form)(state, `${ticketConstants.FORM_TEMPLATE_SECTION}.${ticketConstants.FORM_FIELD_SOLUTION_GROUP}`),
    isSubstituted: state.ticket.substitutionUser
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSection);
