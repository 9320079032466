import Joi from 'joi';
import {
    HDI_TICKET_PRIORITY_P1,
    HDI_TICKET_PRIORITY_P2,
    HDI_TICKET_PRIORITY_P3,
    HDI_TICKET_PRIORITY_P4
} from '../../constants/form/priorityConstants';
import {
    HDI_TICKET_STATUS_CLOSED,
    HDI_TICKET_STATUS_NEW,
    HDI_TICKET_STATUS_ON_HOLD,
    HDI_TICKET_STATUS_OPENED,
    HDI_TICKET_STATUS_REOPENED,
    HDI_TICKET_STATUS_SUSPENDED,
} from '../../constants/form/statusConstants';
import { getSolutionGroupSchema } from './solutionGroup';
import {
    HDI_TICKET_INCIDENT_TYPE_INCIDENT,
    HDI_TICKET_INCIDENT_TYPE_SERVICE_REQUEST
} from '../../constants/form/incidentTypeConstants';

export function validateNewTicket(ticketData) {
    return Joi.validate(
        ticketData,
        Joi.object().required().keys({
            ticket: getNewTicketFormat(),
            newAttachments: Joi.array().items(getNewAttachment()),
        }))
}

export function validateUpdateTicket(ticketData) {
    return Joi.validate(
        ticketData,
        Joi.object().required().keys({
            ticket: getUpdateTicketFormat().required(),
            newAttachments: Joi.array().items(getNewAttachment()),
            followups: setFolowup(),
            internalNote: Joi.object(),
        }))
}

export function validateFetchTicket(ticketData) {
    return Joi.validate(
        ticketData,
        Joi.object().required().keys({
            ticket: getTicket(),
            attachments: Joi.array().items(getAttachment()),
            internalNotes: Joi.array(),
            followups: Joi.array().items(getFollowup()),
            solutionGroups: Joi.array(),
            editor: Joi.array().items(getSolutionGroupSchema())
        }))
}

function getNewAttachment() {
    return Joi.object().keys({
        id: Joi.string().required(),
        name: Joi.string().required(),
        size: Joi.number().required(),
        contentType: Joi.string().allow(['', null]),
        localUrl: Joi.string().required()
    });
}

function getAttachment() {
    return Joi.object().keys({
        id: Joi.string().required(),
        name: Joi.string().required(),
        size: Joi.number().required(),
        contentType: Joi.string().allow(['', null]),
        created: Joi.string().required(),
        creatorName: Joi.string().required(),
        creatorIpn: Joi.string().required(),
        isDealer: Joi.bool(),
        isEditor: Joi.bool()
    })
}

function setFolowup() {
    return Joi.object().keys({
        priority: getValidPriority(),
        status: getValidStatus(),
        solutionGroup: Joi.object().keys({
            id: Joi.string().required(),
            shortName: Joi.string().required(),
        }),
        text: Joi.string(),
        subject: Joi,
    });
}

function getFollowup() {
    return Joi.object().keys({
        priority: getValidPriority().required(),
        status: getValidStatus().required(),
        solutionGroup: Joi.object().required().keys({
            id: Joi.string().required(),
            shortName: Joi.string().required(),
        }),
        subject: Joi,
        text: Joi.string(),
        creatorIpn: Joi.string().required(),
        creatorName: Joi.string().required(),
        created: Joi.date().required(),
        isEditor: Joi,
    });
}

function getNewTicketFormat() {
    return Joi.object().required().keys({
        description: Joi.string().required(),
        priority: getValidPriority().required(),
        alias: Joi.string().allow(['', null]).required(),
        copyEmail: Joi.alternatives().try(Joi.array().items(Joi.string().allow(['', null])), Joi.string().allow(['', null])),
        subject: Joi.string().required(),
        comment: Joi.string().allow(['', null]).required(),
        massIncident: Joi.bool().required(),
        incidentType: getValidIncidentType(),
        substitution: Joi.string().allow(['', null]),
        templateId: Joi.string().allow(['', null])
    });
}

function getUpdateTicketFormat() {
    return Joi.object().keys({
        id: Joi.string().required(),
        alias: Joi.string().allow(['', null]),
        copyEmail: Joi.alternatives().try(Joi.array().items(Joi.string().allow(['', null])), Joi.string().allow(['', null])),
        comment: Joi.string().allow(['', null]),
        massIncident: Joi.bool(),
        incidentType: getValidIncidentType(),
        templateId: Joi.string(),
        templateName: Joi.string().allow(null),
    });
}

function getTicket() {
    return Joi.object().required().keys({
        id: Joi.string().required(),
        ticketNumber: Joi.string().required(),
        pfxNumber: Joi.number().required(),
        sfxNumber: Joi.number().required(),
        priority: getValidPriority().required(),
        status: getValidStatus().required(),
        alias: Joi.string().allow(['', null]).required(),
        copyEmail: Joi.alternatives().try(Joi.array().items(Joi.string().allow(['', null])), Joi.string().allow(['', null])),
        comment: Joi.string().allow(['', null]).required(),
        massIncident: Joi.bool().required(),
        editorIpn: Joi.string().allow(['', null]).required(),
        creatorIpn: Joi.string().required(),
        userName: Joi.string().required(),
        email: Joi.string().required(),
        formType: Joi.string().allow(['', null]),
        telNo: Joi.string().allow(['', null]).required(),
        group: Joi.string().required(),
        dealerName: Joi.string().allow(['', null]).required(),
        dealerNumber: Joi.string().allow(['', null]).required(),
        description: Joi.string().required(),
        company: Joi.string().required(),
        client: Joi.string().required(),
        country: Joi.string().allow(null).required(),
        subject: Joi.string().required(),
        brand: Joi.string().required(),
        solutionGroup: getSolutionGroupSchema().required(),
        incidentType: getValidIncidentType(),
        substituteBy: Joi.string().allow(null),
        templateId: Joi.string().allow(null),
        templateName: Joi.string().allow(null),
        createdDate: Joi.date().required(),
        openedDate: Joi.date().allow(null).required(),
        closedBy: Joi.string().allow(['', null]),
        lastModify: Joi.date().allow(null).required(),
        closedDate: Joi.date().allow(null).required(),
        previousStatus: Joi.string(),
        ticketSatisfactionChecked: Joi.bool().allow(null),
    });
}

function getValidPriority() {
    return Joi.string()
        .valid(
            HDI_TICKET_PRIORITY_P1,
            HDI_TICKET_PRIORITY_P2,
            HDI_TICKET_PRIORITY_P3,
            HDI_TICKET_PRIORITY_P4);
}

function getValidIncidentType() {
    return Joi.string()
        .valid(
            HDI_TICKET_INCIDENT_TYPE_INCIDENT,
            HDI_TICKET_INCIDENT_TYPE_SERVICE_REQUEST
        );
}

function getValidStatus() {
    return Joi.string()
        .valid(
            HDI_TICKET_STATUS_ON_HOLD,
            HDI_TICKET_STATUS_CLOSED,
            HDI_TICKET_STATUS_NEW,
            HDI_TICKET_STATUS_SUSPENDED,
            HDI_TICKET_STATUS_OPENED,
            HDI_TICKET_STATUS_REOPENED);
}
