import {defineMessages} from 'react-intl';
import { templateConstants } from '../../constants/form/formTemplateConstants';

const messages = defineMessages({
    TITLE: {
        id: 'admin.hdi.template.title',
        defaultMessage: 'Typical Incidents'
    },
    TITLE_REQUEST: {
        id: 'admin.hdi.template.titleRequest',
        defaultMessage: 'Typical Requests'
    },
    TITLE_UK: {
        id: 'admin.hdi.template.titleTopic',
        defaultMessage: 'Topics'
    },
    TABLE_NAME: {
        id: 'admin.hdi.template.table.name',
        defaultMessage: 'Typical Incidents'
    },
    TABLE_SOLUTION_GROUP: {
        id: 'admin.hdi.template.table.solutionGroups',
        defaultMessage: 'Solution Group'
    },
    TABLE_SOLUTION_GROUP_UK: {
        id: 'admin.hdi.template.table.solutionGroupsUK',
        defaultMessage: 'Department'
    },
    TABLE_COUNTRY: {
        id: 'admin.hdi.template.table.country',
        defaultMessage: 'Country'
    },
    TABLE_SUBJECT: {
        id: 'admin.hdi.template.table.subject',
        defaultMessage: 'Subject'
    },
    TABLE_DESCRIPTION: {
        id: 'admin.hdi.template.table.description',
        defaultMessage: 'PRoblem Description'
    },
    [templateConstants.FORM_LABEL_TEMPLATE_SUBJECT]: {
        id: 'hdi.administration.template.label.subject',
        defaultMessage: 'Subject'
    },
    [templateConstants.FORM_LABEL_TEMPLATE_SOLUTION_GROUP]: {
        id: 'hdi.administration.template.label.solutionGroup',
        defaultMessage: 'Solution Group'
    },
    [templateConstants.FORM_LABEL_TEMPLATE_SOLUTION_GROUP_UK]: {
        id: 'hdi.administration.template.label.solutionGroupUK',
        defaultMessage: 'Department'
    },
    [templateConstants.FORM_LABEL_TEMPLATE_COPY]: {
        id: 'hdi.administration.template.label.copy',
        defaultMessage: 'Copy to (email)'
    },
    [templateConstants.FORM_LABEL_TEMPLATE_DESCRIPTION]: {
        id: 'hdi.administration.template.label.description',
        defaultMessage: 'Problem description'
    },
    [templateConstants.FORM_LABEL_TEMPLATE_DESCRIPTION_SECTION]: {
        id: 'hdi.administration.template.label.descriptionSection',
        defaultMessage: 'Template information'
    },
    [templateConstants.FORM_LABEL_TEMPLATE_VISIBLE_DEALER]: {
        id: 'hdi.administration.template.label.visible.dealer',
        defaultMessage: 'Dealer'
    },
    [templateConstants.FORM_LABEL_TEMPLATE_VISIBLE_SUBS]: {
        id: 'hdi.administration.template.label.visible.subs',
        defaultMessage: 'Subsidiary'
    },
});

export default messages;
