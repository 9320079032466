import { hdiFrequentQuestionsWebsocketCommands } from '../../constants/hdiFrequentQuestionsWebsocketCommands';

export const ADMIN_FREQUENT_QUESTION_SELECT_GROUP = 'ADMIN_FREQUENT_QUESTION_SELECT_GROUP';
export const ADMIN_FREQUENT_QUESTION_FETCH = 'ADMIN_FREQUENT_QUESTION_FETCH';
export const ADMIN_FREQUENT_QUESTION_ADD = 'ADMIN_FREQUENT_QUESTION_ADD';
export const ADMIN_FREQUENT_QUESTION_EDIT = 'ADMIN_FREQUENT_QUESTION_EDIT';
export const ADMIN_FREQUENT_QUESTION_UPDATE = 'ADMIN_FREQUENT_QUESTION_UPDATE';
export const ADMIN_FREQUENT_QUESTION_MOVE = 'ADMIN_FREQUENT_QUESTION_MOVE';
export const ADMIN_FREQUENT_QUESTION_SWITCH = 'ADMIN_FREQUENT_QUESTION_SWITCH';
export const ADMIN_FREQUENT_QUESTION_REMOVE = 'ADMIN_FREQUENT_QUESTION_REMOVE';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_FREQUENT_QUESTION_SELECT_GROUP,
        payload: countryGroup
    });

export const fetchFrequentQuestion = (group) => dispatch => {
    return dispatch({
        type: ADMIN_FREQUENT_QUESTION_FETCH,
        hdiWebsocket: true,
        command: hdiFrequentQuestionsWebsocketCommands.HDI_FREQUENT_QUESTION_GET,
        payload: {group}
    });
};

export const addFrequentQuestion = (frequentQuestion) => dispatch => {
    return dispatch({
        type: ADMIN_FREQUENT_QUESTION_ADD,
        hdiWebsocket: true,
        command: hdiFrequentQuestionsWebsocketCommands.HDI_FREQUENT_QUESTION_CREATE,
        payload: {frequentQuestion}
    });
};

export const editFrequentQuestion = index => dispatch => {
    dispatch({
        type: ADMIN_FREQUENT_QUESTION_EDIT,
        payload: {index}
    });
};

export const updateFrequentQuestion = (frequentQuestion) => dispatch => {
    return dispatch({
        type: ADMIN_FREQUENT_QUESTION_UPDATE,
        hdiWebsocket: true,
        command: hdiFrequentQuestionsWebsocketCommands.HDI_FREQUENT_QUESTION_UPDATE,
        payload: {frequentQuestion}
    });
};

export const moveFrequentQuestion = (frequentQuestion, oldIndex) => dispatch => {
    dispatch({
        type: ADMIN_FREQUENT_QUESTION_SWITCH,
        payload: {oldIndex, newIndex: frequentQuestion.position, frequentQuestion}
    });
    return dispatch({
        type: ADMIN_FREQUENT_QUESTION_MOVE,
        hdiWebsocket: true,
        command: hdiFrequentQuestionsWebsocketCommands.HDI_FREQUENT_QUESTION_MOVE,
        payload: {frequentQuestion}
    });
};

export const removeFrequentQuestion = (frequentQuestion) => dispatch => {
    return dispatch({
        type: ADMIN_FREQUENT_QUESTION_REMOVE,
        hdiWebsocket: true,
        command: hdiFrequentQuestionsWebsocketCommands.HDI_FREQUENT_QUESTION_DELETE,
        payload: {frequentQuestion}
    });
};


