import {defineMessages} from 'react-intl';

const messages = defineMessages({
    ADMIN_ROLE: {
        id: 'group.hdiAdmin',
        defaultMessage: 'HDI administrator'
    },
    SPECIAL_DEALER: {
        id: 'group.dealer.special',
        defaultMessage: 'Special dealer'
    },
    EDITOR_ROLE: {
        id: 'group.hdiEditor',
        defaultMessage: 'HDI Editor'
    },
    ALL: {
        id: 'group.all',
        defaultMessage: 'All Groups'
    },
    SELECT_GROUPS: {
        id: 'group.select.groups',
        defaultMessage: 'Select groups'
    },
    SELECT_GROUP: {
        id: 'group.select.group',
        defaultMessage: 'Select a group'
    }
});

export default messages;
