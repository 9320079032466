import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Select from 'react-select';
import {PRIMARY_GREY} from '../../theme/colors'

const getStyles = size => ({
    multiValueLabel: (base, state) => {
        return state.data.isFixed ? {...base, color: PRIMARY_GREY, paddingRight: '6px'} : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? {...base, display: 'none'} : base;
    },
    input: (base, state) => {
        const otherStyles = size === "l" ? { padding: 10 } : {}
        return { ...base, ...otherStyles }
    }
})


const SelectItemsField = (
    {
        input,
        label,
        placeholder,
        options,
        size = "m", // changes the input inner padding
        meta: {touched, error, warning}
    }
) => {
    return (
        <div className="row py-2 vertical-center">
            {!!label && <div className="col-3">{label}</div>}
            <div className={label ? "col-9" : "col-12"}>
                <Select
                    value={input.value || []}
                    onChange={input.onChange}
                    // onBlur={() => input.onBlur(input.value)}
                    options={options}
                    placeholder={placeholder}
                    isMulti
                    isSearchable
                    isClearable={options.some(item => !item.isFixed)}
                    styles={getStyles(size)}
                />
            </div>
            <div className="col-12">
                {touched &&
                    ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
                }
            </div>
        </div>
    );
};

SelectItemsField.propTypes = {
    input: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
    label: PropTypes.string,
    size: PropTypes.oneOf(['m', 'l']),
    placeholder: PropTypes.string,
    selectedCountryGroup: PropTypes.object
};

export default injectIntl(SelectItemsField);
