//export
export const HDI_EXPORT_FORM_GROUP = 'group';
export const HDI_EXPORT_FORM_SOLUTION_GROUP = 'solutionGroup';
export const HDI_EXPORT_FORM_FIELD_FROM = 'from';
export const HDI_EXPORT_FORM_FIELD_TO = 'to';
export const HDI_EXPORT_FORM_FIELD_TYPE = 'type';
export const HDI_EXPORT_FORM_FIELD_CHARSET = 'charset';

export const XLS = 'XLS';
export const CSV = 'CSV';

//charset
export const WINDOWS_1252 = 'WINDOWS_1252';
export const ISO8859_2 = 'ISO8859_2';
export const UTF_8_BOM = 'UTF_8_BOM';
export const UTF_8 = 'UTF_8';

export const ticketExportTypes = [
    XLS,
    CSV,
];

export const ticketCharsetTypes = [
    WINDOWS_1252,
    ISO8859_2,
    UTF_8,
    UTF_8_BOM,
];
