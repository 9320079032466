import { hdiSolutionGroupWebsocketCommands } from '../../constants/hdiSolutionGroupWebsocketCommands';


export const fetchSolutionGroupListPageData = (page, pageSize, sorted, filtered) => dispatch => {
    dispatch({
        type: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_LIST_FETCH_PENDING,
        hdiWebsocket: true,
        command: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_LIST,
        payload: { page: page, pageSize: pageSize, sorted: sorted, filtered: filtered }
    });
};

export const fetchSolutionGroupShortList = () => dispatch => {
    dispatch({
        type: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_SHORT_LIST_PENDING,
        hdiWebsocket: true,
        command: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_SHORT_LIST,
    });
};
