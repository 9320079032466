export const HDI_TICKET_FORM = 'BASE_TICKET';
export const HDI_DEFAULT_SOLUTION_GROUP_FORM = 'DEFAULT_SOLUTION_GROUP_FORM';
export const HDI_SOLUTION_GROUP_FORM = 'SOLUTION_GROUP_FORM';
export const HDI_TEMPLATE_FORM = 'TEMPLATE_FORM';
export const HDI_EDITOR_FORM = 'EDITOR_FORM';
export const HDI_WELCOME_FORM = 'WELCOME_FORM';

export const FORM_LABEL_PAGE = 'PAGE';
//Button mappings
export const FORM_BUTTON_CLOSE = 'closeButton';
export const FORM_BUTTON_SAVE = 'saveButton';
export const FORM_BUTTON_REOPEN = 'reopenButton';
export const FORM_BUTTON_BACK = 'backButton';
export const FORM_BUTTON_EDIT = 'editButton';

export const BUTTON_LABEL_SAVE = 'SAVE';
export const BUTTON_LABEL_DOWNLOAD = 'DOWNLOAD';
export const BUTTON_LABEL_EXPORT = 'EXPORT';

//Status history
// TOCHECK variable does not match value
export const FORM_STATUS_HISTORY_SECTION = 'followups';