import { hdiEditorWebsocketCommands
} from '../../constants/hdiEditorWebsocketCommands';

export const fetchEditorListPageData = (page, pageSize, sorted, filtered) => dispatch => {
    dispatch({
        type: hdiEditorWebsocketCommands.HDI_EDITOR_LIST_FETCH_PENDING,
        hdiWebsocket: true,
        command: hdiEditorWebsocketCommands.HDI_EDITOR_LIST,
        payload: { page: page, pageSize: pageSize, sorted: sorted, filtered: filtered }
    });
};
