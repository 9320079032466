import { hdiWelcomeWebsocketCommands, } from '../../constants/hdiWelcomeWebsocketCommands';

export const adminWelcome = (state = {
    correlationId: null,
    isLoading: false,
    message: {},
}, action) => {
    switch (action.type) {
        case hdiWelcomeWebsocketCommands.HDI_WELCOME_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isLoading: false,
                message: {},
            });
        }

        case hdiWelcomeWebsocketCommands.HDI_MAIN_MESSAGES_PENDING: {
            return Object.assign({}, state, {
                isLoading: true,
            });
        }
        case hdiWelcomeWebsocketCommands.HDI_MAIN_MESSAGES_SEND: {
            const { correlationId, error, mainMessage: message } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                correlationId,
                isLoading: false,
                message
            });
        }
        case hdiWelcomeWebsocketCommands.HDI_MAIN_MESSAGES_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isLoading: false,
            });
        }
        case hdiWelcomeWebsocketCommands.HDI_MAIN_MESSAGES_UPDATE_PENDING: {
            return Object.assign({}, state, {
                isWelcomeCreating: true,
                isLoading: true,
            });
        }

        default:
            return state;
    }
};
