import { hdiFrequentQuestionsWebsocketCommands } from '../constants/hdiFrequentQuestionsWebsocketCommands';

export const FILES_UPLOAD_PENDING = 'FILES_UPLOAD_PENDING';
export const FILES_DOWNLOAD_PENDING = 'FILES_DOWNLOAD_PENDING';
export const FILE_UPLOAD_STATUS = 'FILE_UPLOAD_STATUS';

export const downloadFile = ({uuid, name}, questionId) => dispatch => {
    return dispatch
    ({
        type: FILES_DOWNLOAD_PENDING,
        hdiWebsocket: true,
        command: hdiFrequentQuestionsWebsocketCommands.HDI_FREQUENT_QUESTION_FILE_DOWNLOAD,
        payload: {attachment: {uuid, name, questionId}}
    });
};

export const uploadFile = (uuid, questionId, contentType, localFileUrl) => dispatch => {
    dispatch({
        type: FILE_UPLOAD_STATUS,
        payload: {uuid}
    });
    return dispatch({
        type: FILES_UPLOAD_PENDING,
        hdiWebsocket: true,
        command: hdiFrequentQuestionsWebsocketCommands.HDI_FREQUENT_QUESTION_FILE_UPLOAD,
        payload: {attachment: {uuid, questionId, contentType, localFileUrl}}
    });
};

