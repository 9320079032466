import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    STATUS: {
        id: 'common.form.statusHistory.table.status',
        defaultMessage: 'Status'
    },
    DEPARTMENT: {
        id: 'common.form.statusHistory.table.department',
        defaultMessage: 'Department'
    },
    TOPIC: {
        id: 'common.form.statusHistory.table.topic',
        defaultMessage: 'Topic'
    },
    ROLE: {
        id: 'common.form.statusHistory.table.role',
        defaultMessage: 'Role'
    },
    IPN: {
        id: 'common.form.statusHistory.table.ipn',
        defaultMessage: 'Ipn'
    },
    NAME: {
        id: 'common.form.statusHistory.table.name',
        defaultMessage: 'Name'
    },
    DATE_TIME: {
        id: 'common.form.statusHistory.table.dateTime',
        defaultMessage: 'Date time'
    },
});

export default messages;
