import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { InputFieldWithValidation } from '../../../../../../common/InputFieldWithValidation';
import formMessages from '../../../../../../../intl/admin/solutionGroupMessages';
import { solutionGroupConstants } from '../../../../../../../constants/form/formSolutionGroupConstants';
import { InputCheckBox } from '../../../../../../common/InputCheckbox';
import InputSelectFieldWithValidation from '../../../../../../common/InputSelectFieldWithValidation';
import { getLevel } from '../../../../../../../constants/Utils';
import { fetchSolutionGroupsPromise } from '../../../../../../../actions/admin/actionSolutionGroup';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const InfoSection = (props) => {
    const {
        readOnly, reqFields, fetchSolutionGroupsPromise
    } = props;

    const handleChange = (event, value) => {
        fetchSolutionGroupsPromise(value);
    };

    return (
        <StyledDiv className="border border-secondary px-4 py-2 bg-white">
            <div className="form-row">
                <div className="col-12 pb-2">
                </div>
            </div>
            <div className="form-row">
                <div className="col pb-2">
                    <InputFieldWithValidation
                        label={formMessages[solutionGroupConstants.FORM_LABEL_SG_NAME]}
                        field={solutionGroupConstants.FORM_FIELD_SG_NAME}
                        isDisabled={readOnly || !reqFields.includes(solutionGroupConstants.FORM_FIELD_SG_NAME)}
                        isRequired={reqFields.includes(solutionGroupConstants.FORM_FIELD_SG_NAME) && !readOnly}
                        onChange={handleChange}
                        isSmall/>
                </div>
            </div>
            <div className="form-row">
                <div className="col pb-2">
                    <InputFieldWithValidation
                        label={formMessages[solutionGroupConstants.FORM_LABEL_SG_LONG_NAME]}
                        field={solutionGroupConstants.FORM_FIELD_SG_LONG_NAME}
                        isRequired={reqFields.includes(solutionGroupConstants.FORM_FIELD_SG_LONG_NAME)}
                        isDisabled={readOnly}
                        isSmall/>
                </div>
            </div>
            <div className="form-row">
                <div className="col pb-2">
                    <InputCheckBox
                        label={formMessages[solutionGroupConstants.FORM_LABEL_SG_ACTIVE]}
                        field={solutionGroupConstants.FORM_FIELD_SG_ACTIVE}
                        isDisabled={readOnly}
                        isSmall/>
                </div>
            </div>
            <div className="form-row">
                <div className="col pb-2">
                    <InputSelectFieldWithValidation
                        label={formMessages[solutionGroupConstants.FORM_LABEL_SG_LEVEL]}
                        field={solutionGroupConstants.FORM_FIELD_SG_LEVEL}
                        isRequired={reqFields.includes(solutionGroupConstants.FORM_FIELD_SG_LEVEL)}
                        isDisabled={readOnly}
                        options={getLevel}
                        //handleChange={handleChange}
                        isSmall/>
                </div>
            </div>
        </StyledDiv>
    )

};

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
    fetchSolutionGroupsPromise: (value) => dispatch(fetchSolutionGroupsPromise(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoSection);
