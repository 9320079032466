import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    fetchSolutionGroup,
    resetSolutionGroup,
    updateSolutionGroup
} from '../../../../actions/admin/actionSolutionGroup';
import Loader from '../../../Loader';
import { FORM_BUTTON_BACK, HDI_SOLUTION_GROUP_FORM, } from '../../../../constants/formConstants';
import SolutionGroupForm from './forms/SolutionGroupForm';

import { initLoadedSolutionGroup, transformUpdateSolutionGroupForSave } from '../utils';
import { editReqFields } from '../../../../constants/requiredFields';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import solutionGroupMessages from '../../../../intl/admin/solutionGroupMessages';
import { FormattedMessage } from 'react-intl';
import {isVisibleForCountry} from '../../template/utils';

class EditSolutionGroupPage extends Component {
    constructor(props) {
        super(props);
        this.state = { solutionGroupSubmittedAndShouldRedirect: false };
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetSolutionGroup();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchSolutionGroup(this.props.match.params.solutionGroupUuid, this.props.loggedUser.ipn);
        }
    }

    handleSubmit = (values) => {
        const { submitBy } = values;
        const dataForSend = transformUpdateSolutionGroupForSave(values, this.props.solutionGroupData);
        this.props.updateSolutionGroup(dataForSend);
        if (!([FORM_BUTTON_BACK].includes(submitBy))) {
            this.setState({ solutionGroupSubmittedAndShouldRedirect: true });
        }
    };

    render() {
        const {
            solutionGroupData,
            userDetail,
        } = this.props;

        let formType = false;
        if (get(solutionGroupData, 'solutionGroup.shortName', { default: false })) {
            formType = HDI_SOLUTION_GROUP_FORM;
        }

        if (
            solutionGroupData.isSolutionGroupLoading
            || solutionGroupData.isSolutionGroupCreating
            || !formType
        ) {
            return <Loader/>;
        }

        const requiredFields = editReqFields['DEFAULT']['EDITOR'][formType];
        const initializationValue = initLoadedSolutionGroup(solutionGroupData);
        return (
            <div className="container-fluid">
                <PageHeader title={isVisibleForCountry(userDetail.group) ? <FormattedMessage {...solutionGroupMessages.TITLE_UK}/> : <FormattedMessage {...solutionGroupMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <SolutionGroupForm
                            form={formType}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

EditSolutionGroupPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    resetSolutionGroup: PropTypes.func.isRequired,
    fetchSolutionGroup: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateSolutionGroup: PropTypes.func.isRequired,
    intl: PropTypes.object,
    solutionGroupData: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    location: PropTypes.object,
};


const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    solutionGroupData: state.adminSolutionGroup,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchSolutionGroup,
    updateSolutionGroup,
    resetSolutionGroup,
})(EditSolutionGroupPage)), ['HDI_ADMIN']);
