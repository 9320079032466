import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    HDI_TICKET_EXPORT_PATH,
    HDI_TICKET_LIST_PATH,
    HDI_TICKET_NEW_PATH,
    HDI_TICKET_SEARCH_PATH
} from '../../../../routes/paths';
import menuMessages from '../../../../intl/layout/menuMessages';

export const HdiMenuEditor = [
    {
        label: <FormattedMessage {...menuMessages.HDI_SEARCH_LIST}/>,
        to: HDI_TICKET_SEARCH_PATH
    }
];

export const HdiMenuAdmin = [
    {
        label: <FormattedMessage {...menuMessages.HDI_EXPORT}/>,
        to: HDI_TICKET_EXPORT_PATH
    }
];

export const HdiMenuUser = [
    {
        label: <FormattedMessage {...menuMessages.HDI_NEW}/>,
        to: HDI_TICKET_NEW_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.HDI_LIST}/>,
        to: HDI_TICKET_LIST_PATH
    }
];

export const HdiMenuUserUK = [
    {
        label: <FormattedMessage {...menuMessages.HDI_NEW_REQUEST}/>,
        to: HDI_TICKET_NEW_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.HDI_LIST_REQUEST}/>,
        to: HDI_TICKET_LIST_PATH
    }
];



