import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import formMessages from '../../../../../../../intl/admin/solutionGroupMessages';
import { solutionGroupConstants } from '../../../../../../../constants/form/formSolutionGroupConstants';
import PropTypes from 'prop-types';
import InputMultipleSelectField from '../../../../../../common/InputMultipleSelectField';
import { change, getFormInitialValues, getFormValues } from 'redux-form';
import { withRouter } from 'react-router-dom';
import InputMultipleInputField from '../../../../../../common/InputMultipleInputField';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const Label = styled.label`
  font-weight: bold;
`;

const createOption = (label) => ({
    label,
    value: label,
});

class UsersSection extends Component {

    state = {
        inputValue: '',
    };

    handleChange = (value) => {
        const { change } = this.props;
        change(`${solutionGroupConstants.FORM_SOLUTION_GROUP_USER_SECTION}.${solutionGroupConstants.FORM_FIELD_SG_MANUAL_EMAIL_LIST}`, value);
    };
    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    };

    handleKeyDown = (event) => {
        const { change, formValues: { usersSection: { emails } } } = this.props;
        const { inputValue } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
            case 'Space':    
                change(`${solutionGroupConstants.FORM_SOLUTION_GROUP_USER_SECTION}.${solutionGroupConstants.FORM_FIELD_SG_MANUAL_EMAIL_LIST}`, [...emails, createOption(inputValue)]);
                this.setState({
                    inputValue: ''
                });
                event.preventDefault();
                break;
            default: {

            }
        }
    };

    render() {
        const { intl: { formatMessage }, solutionGroupData: { userEmail }, readOnly, formValues: { usersSection: { emails = [] } } } = this.props;

        const { inputValue } = this.state;

        return (
            <>
                <hr />
                <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                    <div className="row mb-sm-2">
                        <Label>
                            <FormattedMessage {...formMessages[solutionGroupConstants.FORM_LABEL_SG_USERS]} />
                        </Label>
                    </div>
                    <div className="row mb-sm-3">
                        <div className="col">
                            <InputMultipleSelectField
                                field={solutionGroupConstants.FORM_FIELD_SG_USERS}
                                options={userEmail.map(user => ({ label: user.name, value: user.ipn }))}
                                placeholder={formatMessage(formMessages.PLACEHOLDER_SELECT_USERS)}
                                isDisabled={readOnly}
                                isMulti={true}
                                isClearable={!readOnly}
                                isSearchable={!readOnly}
                            />
                        </div>
                    </div>
                    <div className="row mb-sm-2">
                        <Label>
                            <FormattedMessage {...formMessages[solutionGroupConstants.FORM_LABEL_SG_MANUAL_EMAIL_LIST]} />
                        </Label>
                    </div>
                    <div className="form-row">
                        <div className="col-12 pb-2">
                            <InputMultipleInputField
                                placeholder=""
                                label={formMessages[solutionGroupConstants.FORM_LABEL_SG_MANUAL_EMAIL]}
                                field={solutionGroupConstants.FORM_FIELD_SG_MANUAL_EMAIL_LIST}
                                validations={[]}
                                value={emails}
                                inputValue={inputValue}
                                isMulti={true}
                                isClearable={true}
                                isSearchable={false}
                                isDisabled={readOnly}
                                onHandleChange={this.handleChange}
                                onHandleInputChange={this.handleInputChange}
                                onHandleKeyDown={this.handleKeyDown}
                                isSmall
                            />
                        </div>
                    </div>
                </StyledDiv>
            </>
        )
    }
}

UsersSection.propTypes = {
    intl: PropTypes.object.isRequired,
    solutionGroupData: PropTypes.object.isRequired,
};

const mapStateToProps = (state, initialProps) => ({
    solutionGroupData: state.adminSolutionGroup,
    formValues: getFormValues(initialProps.form)(state),
    initialValues: getFormInitialValues(initialProps.form)(state, `usersSection`),
    userDetail: state.profile.userDetail,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(UsersSection)));
