import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import AdministrationMainPage from './AdministrationMainPage';
import NotFoundPage from '../NotFoundPage';
import AdminRightsManagementPage from './right/AdminRightsManagementPage';

import {
    ADMINISTRATION_EDITOR_LIST_PATH,
    ADMINISTRATION_EDITOR_NEW_PATH,
    ADMINISTRATION_EDITOR_PATH, ADMINISTRATION_FREQUENT_QUESTION_PATH,
    ADMINISTRATION_PATH,
    ADMINISTRATION_RIGHTS_ADMIN_PATH,
    ADMINISTRATION_RIGHTS_DEALER_PATH,
    ADMINISTRATION_SOLUTION_GROUP_DEFAULT_PATH,
    ADMINISTRATION_SOLUTION_GROUP_LIST_PATH,
    ADMINISTRATION_SOLUTION_GROUP_NEW_PATH,
    ADMINISTRATION_SOLUTION_GROUP_PATH,
    ADMINISTRATION_TEMPLATE_LIST_PATH,
    ADMINISTRATION_TEMPLATE_NEW_PATH,
    ADMINISTRATION_TEMPLATE_PATH,
    ADMINISTRATION_WELCOME_PATH
} from '../../routes/paths';
import { Domain } from '../common/Domain';
import checkRoles from '../common/roleChecker/RoleChecker';
import ListSolutionGroupPage from './solutionGroup/list/ListSolutionGroupPage';
import NewSolutionGroupPage from './solutionGroup/detail/NewSolutionGroupPage';
import ViewSolutionGroupPage from './solutionGroup/detail/ViewSolutionGroupPage';
import EditSolutionGroupPage from './solutionGroup/detail/EditSolutionGroupPage';
import ViewEditorPage from './editor/detail/ViewEditorPage';
import EditEditorPage from './editor/detail/EditEditorPage';
import NewEditorPage from './editor/detail/NewEditorPage';
import NewTemplatePage from './template/detail/NewTemplatePage';
import EditTemplatePage from './template/detail/EditTemplatePage';
import ListEditorPage from './editor/list/ListEditorPage';
import ListTemplatePage from './template/list/ListTemplatePage';
import ViewWelcomePage from './welcome/ViewWelcomePage';
import DefaultSolutionGroupPage from './solutionGroup/default/DefaultSolutionGroupPage';
import DealerRightsManagementPage from './right/DealerRightsManagementPage';
import FrequentQuestionPage from './frequentQuestion/FrequentQuestionPage';

class Administration extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={ADMINISTRATION_PATH}
                       render={() => <AdministrationMainPage/>}/>
                <Route exact path={ADMINISTRATION_RIGHTS_ADMIN_PATH}
                       render={() => <Domain
                           component={checkRoles(AdminRightsManagementPage, ['HDI_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_RIGHTS_DEALER_PATH}
                       render={() => <Domain
                           component={checkRoles(DealerRightsManagementPage, ['HDI_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_SOLUTION_GROUP_PATH + '/:view(view)/:solutionGroupUuid(.*)'}
                       render={() => <ViewSolutionGroupPage/>}/>
                <Route exact path={ADMINISTRATION_SOLUTION_GROUP_PATH + '/:edit(edit)/:solutionGroupUuid(.*)'}
                       render={() => <EditSolutionGroupPage/>}/>
                <Route exact path={ADMINISTRATION_SOLUTION_GROUP_NEW_PATH}
                       render={() => <NewSolutionGroupPage/>}/>
                <Route exact path={ADMINISTRATION_SOLUTION_GROUP_DEFAULT_PATH}
                       render={() => <DefaultSolutionGroupPage/>}/>

                <Route exact path={ADMINISTRATION_SOLUTION_GROUP_LIST_PATH}
                       render={() => <ListSolutionGroupPage/>}/>

                <Route exact path={ADMINISTRATION_EDITOR_PATH + '/:view(view)/:editorUuid(.*)'}
                       render={() => <ViewEditorPage/>}/>
                <Route exact path={ADMINISTRATION_EDITOR_PATH + '/:edit(edit)/:editorUuid(.*)'}
                       render={() => <EditEditorPage/>}/>
                <Route exact path={ADMINISTRATION_EDITOR_NEW_PATH}
                       render={() => <NewEditorPage/>}/>
                <Route exact path={ADMINISTRATION_EDITOR_LIST_PATH}
                       render={() => <ListEditorPage/>}/>

                <Route exact path={ADMINISTRATION_TEMPLATE_PATH + '/:edit(edit)/:templateUuid(.*)'}
                       render={() => <EditTemplatePage/>}/>
                <Route exact path={ADMINISTRATION_TEMPLATE_NEW_PATH}
                       render={() => <NewTemplatePage/>}/>
                <Route exact path={ADMINISTRATION_TEMPLATE_LIST_PATH}
                       render={() => <ListTemplatePage/>}/>

                <Route exact path={ADMINISTRATION_FREQUENT_QUESTION_PATH}
                       render={() => <Domain component={checkRoles(FrequentQuestionPage, ['HDI_ADMIN'])}/>}/>

                <Route exact path={ADMINISTRATION_WELCOME_PATH}
                       render={() => <ViewWelcomePage/>}/>

                <Route component={NotFoundPage}/>
            </Switch>
        );
    }
}

Administration.propTypes = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(Administration);
