import { errorAlert } from '../alertsActions';
import { validateAdminRoles, validateDealerRoles } from '../../api/validation/role';
import { hdiWebsocketCommands } from '../../constants/hdiWebsocketCommands';

export const HDI_MANAGED_USER = 'HDI_MANAGED_USER';
export const HDI_MANAGED_USER_RESET = 'HDI_MANAGED_USER_RESET';
export const HDI_INITIAL_ADMIN_GROUP_OPTIONS = 'HDI_INITIAL_ADMIN_GROUP_OPTIONS';
export const HDI_SELECTED_ADMIN_GROUP_OPTIONS = 'HDI_SELECTED_ADMIN_GROUP_OPTIONS';
export const HDI_ADMIN_GROUPS_CHANGED = 'HDI_ADMIN_GROUPS_CHANGED';
export const HDI_ADMIN_RIGHTS_FETCH_PENDING = 'HDI_ADMIN_RIGHTS_FETCH_PENDING';
export const HDI_ADMIN_RIGHTS_SAVE_PENDING = 'HDI_ADMIN_RIGHTS_SAVE_PENDING';
export const HDI_ADMIN_RIGHTS_CLEAR = 'HDI_ADMIN_RIGHTS_CLEAR';
export const HDI_ADMIN_RIGHTS_FETCH_SUCCESS = 'HDI_ADMIN_RIGHTS_FETCH_SUCCESS';

export const HDI_INITIAL_DEALER_GROUP_OPTIONS = 'HDI_INITIAL_DEALER_GROUP_OPTIONS';
export const HDI_SELECTED_DEALER_GROUP_OPTIONS = 'HDI_SELECTED_DEALER_GROUP_OPTIONS';
export const HDI_DEALER_GROUPS_CHANGED = 'HDI_DEALER_GROUPS_CHANGED';
export const HDI_DEALER_RIGHTS_FETCH_PENDING = 'HDI_DEALER_RIGHTS_FETCH_PENDING';
export const HDI_DEALER_RIGHTS_SAVE_PENDING = 'HDI_DEALER_RIGHTS_SAVE_PENDING';
export const HDI_DEALER_RIGHTS_CLEAR = 'HDI_DEALER_RIGHTS_CLEAR';
export const HDI_DEALER_RIGHTS_FETCH_SUCCESS = 'HDI_DEALER_RIGHTS_FETCH_SUCCESS';
export const HDI_RIGHTS_ERROR = 'HDI_RIGHTS_ERROR';

export const resetManageUser = () => dispatch => {
    return dispatch({
        type: HDI_MANAGED_USER_RESET,
    });
};

export const setManagedUser = managedUser => ({
    type: HDI_MANAGED_USER,
    payload: managedUser
});

export const setInitialAdminGroupOptions = groupOptions => ({
    type: HDI_INITIAL_ADMIN_GROUP_OPTIONS,
    payload: groupOptions
});

export const setSelectedAdminGroupOptions = groupOptions => ({
    type: HDI_SELECTED_ADMIN_GROUP_OPTIONS,
    payload: groupOptions
});

export const setAdminGroupsChanged = adminGroupsChanged => ({
    type: HDI_ADMIN_GROUPS_CHANGED,
    payload: adminGroupsChanged
});

export const fetchAdminRightsManagementPageData = (user, domain) => dispatch => {
    return dispatch({
        type: HDI_ADMIN_RIGHTS_FETCH_PENDING,
        hdiWebsocket: true,
        command: hdiWebsocketCommands.HDI_ADMIN_ROLE_GET,
        payload: { payload: { ipn: user.ipn } }
    });
};

export const saveAdminRightsManagementPageData = (user, domain, adminData) => dispatch => {
    const { error } = validateAdminRoles(adminData);
    if (error) {
        dispatch({ type: HDI_RIGHTS_ERROR });
        dispatch(errorAlert(`Cannot send invalid admin rights to backend`, [error.toString()]));
    } else {
        return dispatch({
            type: HDI_ADMIN_RIGHTS_SAVE_PENDING,
            hdiWebsocket: true,
            command: hdiWebsocketCommands.HDI_ADMIN_ROLE_SET,
            payload: { payload: { admin: { groupPermission: adminData }, id: user.ipn } }
        });

    }
};

export const clearAdminRightsManagementPageData = () => ({
    type: HDI_ADMIN_RIGHTS_CLEAR
});

export const setInitialDealerGroupOptions = groupOptions => ({
    type: HDI_INITIAL_DEALER_GROUP_OPTIONS,
    payload: groupOptions
});

export const setSelectedDealerGroupOptions = groupOptions => ({
    type: HDI_SELECTED_DEALER_GROUP_OPTIONS,
    payload: groupOptions
});

export const setDealerGroupsChanged = dealerGroupsChanged => ({
    type: HDI_DEALER_GROUPS_CHANGED,
    payload: dealerGroupsChanged
});

export const fetchDealerRightsManagementPageData = (user, domain) => dispatch => {
    return dispatch({
        type: HDI_DEALER_RIGHTS_FETCH_PENDING,
        hdiWebsocket: true,
        command: hdiWebsocketCommands.HDI_DEALER_ROLE_GET,
        payload: { payload: { ipn: user.ipn } }
    });
};

export const saveDealerRightsManagementPageData = (user, domain, dealerData) => dispatch => {
    const { error } = validateDealerRoles(dealerData);
    if (error) {
        dispatch({ type: HDI_RIGHTS_ERROR });
        dispatch(errorAlert(`Cannot send invalid dealer rights to backend`, [error.toString()]));
    } else {
        return dispatch({
            type: HDI_DEALER_RIGHTS_SAVE_PENDING,
            hdiWebsocket: true,
            command: hdiWebsocketCommands.HDI_DEALER_ROLE_SET,
            payload: { payload: { dealer: { groupPermission: dealerData }, id: user.ipn } }
        });

    }
};

export const clearDealerRightsManagementPageData = () => ({
    type: HDI_DEALER_RIGHTS_CLEAR
});
