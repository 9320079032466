import React from 'react';
import styled from 'styled-components';
import { FormSection, reduxForm } from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import RenderSyncErrors from '../../../../common/RenderSyncErrors';
import { scrollContentWrapperToTop } from '../../../../../utils/utils';
import formMessages from '../../../../../intl/admin/solutionGroupMessages';
import { intlSolutionGroupFieldMapping } from '../../../../../constants/fieldLabelMapping';
import InfoSection from './sections/info/InfoSection';
import CountrySection from './sections/country/CountrySection';
import UsersSection from './sections/users/UsersSection';
import { solutionGroupConstants } from '../../../../../constants/form/formSolutionGroupConstants';
import { HDI_SOLUTION_GROUP_FORM } from '../../../../../constants/formConstants';
import asyncValidate from './sections/info/asyncValidate';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const TicketForm = (props) => {
    const { form, reqFields, readOnly, handleSubmit } = props;

    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form}
                              intlMessages={formMessages}
                              mapping={intlSolutionGroupFieldMapping}/>
            <FormSection name={solutionGroupConstants.FORM_SOLUTION_GROUP_INFO_SECTION}>
                <InfoSection form={form}
                             reqFields={reqFields}
                             readOnly={readOnly}/>
            </FormSection>
            <FormSection name={solutionGroupConstants.FORM_SOLUTION_GROUP_COUNTRY_SECTION}>
                <CountrySection form={form}
                                reqFields={reqFields}
                                readOnly={readOnly}/>
            </FormSection>
            <FormSection name={solutionGroupConstants.FORM_SOLUTION_GROUP_USER_SECTION}>
                <UsersSection form={form}
                              reqFields={reqFields}
                              readOnly={readOnly}
                />
            </FormSection>
            <SubmitButtons form={form}
                           reqFields={reqFields}
                           readOnly={readOnly}
                           onSubmit={handleSubmit}
                           onSubmitFail={scrollContentWrapperToTop}/>
        </StyledDiv>
    )
};

export default reduxForm({
    form: HDI_SOLUTION_GROUP_FORM,
    asyncValidate,
    asyncBlurFields: [`${solutionGroupConstants.FORM_SOLUTION_GROUP_INFO_SECTION}.${solutionGroupConstants.FORM_FIELD_SG_NAME}`]
})(TicketForm);
