import { hdiTicketWebsocketCommands, } from '../../constants/hdiTicketWebsocketCommands';
import get from 'get-value';

export const listTicket = (state = {
        correlationId: null,
        isLoading: false,
        isTableLoading: false,
        listData: [],
        pages: 0,
        isNextFromPivot: true,
        isNextPage: false,
        filtering: false,
        defaultProperties: {
            listTicketDefaultPageSize: 10,
            listTicketDefaultSort: [],
            listTicketDefaultFilters: [],
            listTicketFSDefaultPageSize: 10,
            listTicketFSDefaultSort: [{ id: 'ticketNumber', desc: true }],
            listTicketFSDefaultFilters: [],
        },
    }, action) => {
        switch (action.type) {
            case hdiTicketWebsocketCommands.HDI_TICKET_LIST_RESET: {
                return Object.assign({}, state, { listData: [] })
            }
            case hdiTicketWebsocketCommands.HDI_TICKET_LIST_PENDING: {
                return Object.assign({}, state, { isTableLoading: true });
            }
            case hdiTicketWebsocketCommands.HDI_TICKET_LIST_SEND: {
                const { correlationId } = action.payload;
                if (correlationId < state.correlationId) {
                    return state;
                } else {
                    return Object.assign({}, state, {
                        isTableLoading: false,
                        listData: get(action, 'payload.result.data', { default: [] }),
                        pages: get(action, 'payload.result.pages', { default: 0 }),
                        isNextFromPivot: get(action, 'payload.result.isNextFromPivot', false),
                        isNextPage: get(action, 'payload.result.isNextPage', false),
                        correlationId
                    });
                }
            }
            case hdiTicketWebsocketCommands.HDI_TICKET_LIST_SEND_ERROR: {
                const { correlationId } = action.payload;
                if (correlationId < state.correlationId) {
                    return state;
                } else {
                    return Object.assign({}, state, { isTableLoading: false, correlationId });
                }
            }
            case hdiTicketWebsocketCommands.HDI_TICKET_LIST_FILTER_SET_SG: {
                const { solutionGroup, isAdded } = action.payload;

                if (isAdded) {
                    return Object.assign({}, state, {
                        defaultProperties: {
                            ...state.defaultProperties,
                            listTicketDefaultFilters: [...state.defaultProperties.listTicketDefaultFilters.filter(item => item.id !== 'solutionGroup'),
                                { id: 'solutionGroup', value: solutionGroup }]
                        }
                    })
                } else {
                    return Object.assign({}, state, {
                        defaultProperties: {
                            ...state.defaultProperties,
                            listTicketDefaultFilters: state.defaultProperties.listTicketDefaultFilters.filter(item => item.id !== 'solutionGroup')
                        }
                    })
                }
            }
            case hdiTicketWebsocketCommands.HDI_TICKET_LIST_FILTER_SET_CLIENT: {
                const { client, isAdded } = action.payload;

                if (isAdded) {
                    return Object.assign({}, state, {
                        defaultProperties: {
                            ...state.defaultProperties,
                            listTicketDefaultFilters: [...state.defaultProperties.listTicketDefaultFilters.filter(item => item.id !== 'client'),
                                { id: 'client', value: client }]
                        }
                    })
                } else {
                    return Object.assign({}, state, {
                        defaultProperties: {
                            ...state.defaultProperties,
                            listTicketDefaultFilters: state.defaultProperties.listTicketDefaultFilters.filter(item => item.id !== 'client')
                        }
                    })
                }
            }
            case hdiTicketWebsocketCommands.HDI_TICKET_LIST_FILTER_SET_FULLTEXT: {
                const { text, isAdded } = action.payload;

                if (isAdded) {
                    return Object.assign({}, state, {
                        defaultProperties: {
                            ...state.defaultProperties,
                            listTicketFSDefaultFilters: [state.defaultProperties.listTicketFSDefaultFilters.filter(item => item.id !== 'fullsearch'),
                                { id: 'fullsearch', value: text }]
                        }
                    })
                } else {
                    return Object.assign({}, state, {
                        defaultProperties: {
                            ...state.defaultProperties,
                            listTicketFSDefaultFilters: state.defaultProperties.listTicketFSDefaultFilters.filter(item => item.id !== 'fullsearch')
                        }
                    })
                }
            }
            case hdiTicketWebsocketCommands.HDI_TICKET_LIST_SET_FILTERING: {
                const { filtering } = action.payload;
                return Object.assign({}, state, {
                    filtering
                })
            }
            case hdiTicketWebsocketCommands.HDI_TICKET_LIST_SET_DEFAULT_PROPERTIES: {
                const { listTicketDefaultPageSize = 10, listTicketDefaultSort = [], listTicketDefaultFilters = [], listTicketFSDefaultPageSize = 10, listTicketFSDefaultSort = [], listTicketFSDefaultFilters = [] } = action.payload;
                return Object.assign({}, state, {
                    defaultProperties: {
                        listTicketDefaultPageSize,
                        listTicketDefaultSort,
                        listTicketDefaultFilters,
                        listTicketFSDefaultPageSize,
                        listTicketFSDefaultSort,
                        listTicketFSDefaultFilters,
                    },
                    filtering: true
                })
            }
            default:
                return state;
        }
    }
;
