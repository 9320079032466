import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import AsyncSelect from 'react-select/lib/Async';
import authManagementMessages from '../../../../../../../intl/admin/authManagementMessages';
import { DEFAULT_DEBOUNCE_TIMEOUT } from '../../../../../../../constants/validationConstants';
import { mapUserToSelectionOption } from '../../../../../../../utils/userUtils';

const SelectUser = props => {
    const { isDisabled, style, initialValues, handleUserSelection, handleUserSearch, intl: { formatMessage } } = props;
    return (
        <AsyncSelect style={style} placeholder={formatMessage(authManagementMessages.PLACEHOLDER_SELECT_USER)}
                     noOptionsMessage={() => formatMessage(authManagementMessages.SELECT_USER_SEARCH)}
                     loadOptions={debounce(DEFAULT_DEBOUNCE_TIMEOUT, handleUserSearch)}
                     onChange={handleUserSelection}
                     value={initialValues && Object.getOwnPropertyNames(initialValues).length > 0 ?
                         mapUserToSelectionOption(initialValues) : {}}
                     isDisabled={isDisabled}
        />
    );
};

SelectUser.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleUserSelection: PropTypes.func.isRequired,
    handleUserSearch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
    initialValues: state.ticket.substitutionUser
});

export default connect(mapStateToProps)((injectIntl(SelectUser)))
