import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import formMessages from '../../../../../intl/hdi/filterMessages';
import { ticketConstants } from '../../../../../constants/form/formTicketConstants';
import { setFilteringVariable, setFullSearchFilter } from '../../../../../actions/hdi/actionListPage';
import { InputFieldWithValidation } from '../../../../common/InputFieldWithValidation';
import { debounce } from 'throttle-debounce';

const StyledDiv = styled.div`
  border-radius: 5px;
`;


const FullSearchFilter = (props) => {

    const handleOnChange = (e, v) => {
        if (v || v === '') {
            props.setFullSearchFilter(v, true);
        } else {
            v = '';
            props.setFullSearchFilter(v, false);
        }

        props.setFilteringVariable(true);
    };

    return (
        <StyledDiv className="">
            <div className="form-row">
                <div className="col-12 pb-2">
                    <InputFieldWithValidation
                        label={formMessages[ticketConstants.FORM_LABEL_SEARCH_FULL]}
                        field={ticketConstants.FORM_FIELD_SEARCH_FULL}
                        intlMessages={formMessages}
                        onChange={debounce(2500, handleOnChange)}
                        isSmall/>
                </div>
            </div>
        </StyledDiv>
    )
};


const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
    setFullSearchFilter: (value, bool) => dispatch(setFullSearchFilter(value, bool)),
    setFilteringVariable: (bool) => dispatch(setFilteringVariable(bool)),
});

export default connect(null, mapDispatchToProps)(FullSearchFilter);
