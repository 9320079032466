import React from 'react';
import styled from 'styled-components';
import { FormSection, reduxForm } from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import RenderSyncErrors from '../../../../common/RenderSyncErrors';
import { scrollContentWrapperToTop } from '../../../../../utils/utils';
import formMessages from '../../../../../intl/admin/editorMessages';
import { intlEditorFieldMapping } from '../../../../../constants/fieldLabelMapping';
import { editorConstants } from '../../../../../constants/form/formEditorConstants';
import EditorSection from './sections/editor/EditorSection';
import SolutionGroupSection from './sections/solutionGroup/SolutionGroupSection';
import UserSection from './sections/user/UserSection';
import ErrorSection from './sections/error/ErrorSection';
import Loader from '../../../../Loader';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const EditorNewForm = (props) => {
    const { form, reqFields, readOnly, showError, isLoadedData, isEditorChecking, handleSubmit, handleAddSG, handleUserSelection, handleUserSearch } = props;

    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form}
                              intlMessages={formMessages}
                              mapping={intlEditorFieldMapping}/>
            <FormSection name={editorConstants.FORM_EDITOR_EDITOR_SECTION}>
                <UserSection form={form}
                             reqFields={reqFields}
                             handleUserSelection={handleUserSelection}
                             handleUserSearch={handleUserSearch}
                             readOnly={readOnly}/>
            </FormSection>
            {isEditorChecking &&
            <Loader/>
            }
            {isLoadedData && <>
                <FormSection name={editorConstants.FORM_EDITOR_EDITOR_SECTION}>
                    <EditorSection form={form}
                                   reqFields={reqFields}
                                   handleAddSG={handleAddSG}
                                   readOnly={readOnly}/>
                </FormSection>
                <FormSection name={editorConstants.FORM_EDITOR_SOLUTION_GROUP_SECTION}>
                    <SolutionGroupSection form={form}
                                          reqFields={reqFields}
                                          readOnly={readOnly}/>
                </FormSection>
                <SubmitButtons form={form}
                               reqFields={reqFields}
                               readOnly={readOnly}
                               onSubmit={handleSubmit}
                               onSubmitFail={scrollContentWrapperToTop}/>
            </>}
            {showError && <ErrorSection/>}
        </StyledDiv>
    )
};

export default reduxForm({})(EditorNewForm);
