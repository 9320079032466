import { put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import get from 'get-value';
import { errorAlert } from '../actions/alertsActions';
import alertMessages from '../intl/common/alertMessages';
import { hdiEditorWebsocketCommands } from '../constants/hdiEditorWebsocketCommands';
import { validateEditor } from '../api/validation/editor';

export function* handleEditorError(action) {
    const stateCorrelationId = get(yield select(state => state.adminEditor), `correlationId`, { default: undefined });
    const { correlationId } = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('../list'));
    yield put({ type: hdiEditorWebsocketCommands.HDI_EDITOR_RESET });
}

export function* handleEditorFetchData(action) {
    const stateCorrelationId = get(yield select(state => state.adminEditor), `correlationId`, { default: undefined });
    const { correlationId, payload } = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    const { error } = validateEditor(payload);
    if (error) {
        yield put(errorAlert(alertMessages.EDITOR_FETCH_SEND_ERROR, [error.toString()]));
        //yield put ({ type: hdiTicketWebsocketCommands.EDITOR_SAVE_NEW_ERROR });
        yield put({ type: hdiEditorWebsocketCommands.HDI_EDITOR_SEND_ERROR, payload: { correlationId } });
    }
}

function* editorSaga() {
    yield takeEvery(hdiEditorWebsocketCommands.HDI_EDITOR_SEND_ERROR, handleEditorError);
    yield takeEvery(hdiEditorWebsocketCommands.HDI_EDITOR_SEND, handleEditorFetchData);
}

export default editorSaga;
