import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    RENAULT: {
        id: 'form.brand.renault',
        defaultMessage: 'Renault'
    },
    NISSAN: {
        id: 'form.brand.nissan',
        defaultMessage: 'Nissan'
    },
});

export default messages;
