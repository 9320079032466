import { hdiSolutionGroupWebsocketCommands } from '../../constants/hdiSolutionGroupWebsocketCommands';
import { errorAlert } from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';
import { validateSolutionGroupCreateEvent, validateSolutionGroupUpdateEvent } from '../../api/validation/solutionGroup';
import { getSolutionGroupsName } from '../../api/restSolutionGroup';
import { extractErrorText } from '../actionUtils';
import validationMessage from '../../intl/common/validationMessages';

export const updateSolutionGroup = (data) => dispatch => {
    const { error } = validateSolutionGroupUpdateEvent(data);
    if (error) {
        dispatch(errorAlert(alertMessages.SOLUTION_GROUP_UPDATE_SEND_ERROR, [error.toString()]));
        dispatch({ type: hdiSolutionGroupWebsocketCommands.SOLUTION_GROUP_SAVE_UPDATE_ERROR });
    } else {
        return dispatch({
            type: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_UPDATE_PENDING,
            hdiWebsocket: true,
            command: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_UPDATE,
            payload: {
                payload: {
                    ...data
                }
            }
        });
    }
};

export const saveSolutionGroup = (data) => dispatch => {
    const { error } = validateSolutionGroupCreateEvent(data);
    if (error) {
        dispatch(errorAlert(alertMessages.SOLUTION_GROUP_NEW_SEND_ERROR, [error.toString()]));
        dispatch({ type: hdiSolutionGroupWebsocketCommands.SOLUTION_GROUP_SAVE_NEW_ERROR });
    } else {
        return dispatch({
            type: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_CREATE_PENDING,
            hdiWebsocket: true,
            command: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_CREATE,
            payload: {
                payload: {
                    ...data,
                }
            }
        });
    }
};

export const fetchSolutionGroup = (id, ipn) => dispatch => {
    return dispatch({
        type: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_DETAIL_FETCH_PENDING,
        hdiWebsocket: true,
        command: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_DETAIL,
        payload: {
            solutionGroup: {
                id,
            },
        }
    });
};

export const fetchSolutionGroupCountry = (id) => dispatch => {
    return dispatch({
        type: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_COUNTRY_PENDING,
        hdiWebsocket: true,
        command: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_COUNTRY,
        payload: {
            solutionGroup: {
                id,
            }
        }
    });
};

export const resetSolutionGroup = () => dispatch => {
    return dispatch({
        type: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_RESET,
    });
};

export const addCustomEmail = (emails) => dispatch => {
    return dispatch({
        type: hdiSolutionGroupWebsocketCommands.HDI_ADD_CUSTOM_EMAIL,
        payload: {
            emails
        }
    })
};

export const setAvailableSolutionGroupName = (availableName) => dispatch => {
    return dispatch({
        type: hdiSolutionGroupWebsocketCommands.SOLUTION_GROUP_SET_AVAILABLE_NAME,
        payload: {
            availableName
        }
    })
};

export const fetchSolutionGroupsPromise = (textSearch) => dispatch => {
    if (textSearch && textSearch.length > 0) {
        getSolutionGroupsName(textSearch).then(
            ({ data }) => {
                if (Array.isArray(data) && data.length > 0) {
                    return dispatch({
                        type: hdiSolutionGroupWebsocketCommands.SOLUTION_GROUP_SET_AVAILABLE_NAME,
                        payload: {
                            availableName: false
                        }
                    })
                }
                return dispatch({
                    type: hdiSolutionGroupWebsocketCommands.SOLUTION_GROUP_SET_AVAILABLE_NAME,
                    payload: {
                        availableName: true
                    }
                })
            },
            error => {
                dispatch(errorAlert(...extractErrorText(error, alertMessages.SOLUTION_GROUP_FETCH_SEND_ERROR)));
            }
        );
    } else {
        return dispatch({
            type: hdiSolutionGroupWebsocketCommands.SOLUTION_GROUP_SET_AVAILABLE_NAME,
            payload: {
                availableName: true
            }
        })
    }
};

export const fetchSolutionGroupsPromiseError = () => dispatch => {
    dispatch(errorAlert(validationMessage.INVALID_UNIQUE_NAME));
};
