import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    SAVE: {
        id: 'button.save',
        defaultMessage: 'Save'
    },
    ACCEPT: {
        id: 'button.accept',
        defaultMessage: 'Accept'
    },
    RESET: {
        id: 'button.reset',
        defaultMessage: 'Reset'
    },
    CANCEL: {
        id: 'button.cancel',
        defaultMessage: 'Cancel'
    },
    BACK: {
        id: 'button.back',
        defaultMessage: 'Back'
    },
    BACK_CONFIRMATION: {
        id: 'button.backConfirmation',
        defaultMessage: 'Are you sure you want to leave ticket without saving?'
    },
    EDIT: {
        id: 'button.edit',
        defaultMessage: 'Edit'
    },
    PREVIOUS: {
        id: 'button.previous',
        defaultMessage: 'Previous'
    },
    NEXT: {
        id: 'button.next',
        defaultMessage: 'Next'
    },
    CLOSE: {
        id: 'button.close',
        defaultMessage: 'Close'
    },
    ADD: {
        id: 'button.add',
        defaultMessage: 'Add'
    },
    LOAD: {
        id: 'button.load',
        defaultMessage: 'Load'
    },
    FIRST: {
        id: 'button.first',
        defaultMessage: 'First'
    },
    LAST: {
        id: 'button.last',
        defaultMessage: 'Last'
    },
    REOPEN: {
        id: 'button.reopen',
        defaultMessage: 'Reopen'
    },
    SEARCH: {
        id: 'button.search',
        defaultMessage: 'Search'
    },
    ALL: {
        id: 'button.all',
        defaultMessage: 'All'
    },
    CLEAR_ATTACHMENTS: {
        id: 'button.clearAttachments',
        defaultMessage: 'Clear attachments'
    },
    NO: {
        id: 'button.no',
        defaultMessage: 'No'
    },
    YES: {
        id: 'button.yes',
        defaultMessage: 'Yes'
    },
    EXPORT: {
        id: 'button.export',
        defaultMessage: 'Export'
    },
    DOWNLOAD: {
        id: 'button.download',
        defaultMessage: 'Download'
    },
    FREQUENT_QUESTION: {
        id: 'button.frequentQuestions',
        defaultMessage: 'Frequent Questions'
    }
});

export default messages;
