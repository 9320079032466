import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchTicket, resetSubstitutionUser, resetTicket, saveTicket } from '../../../../actions/hdi/actionTicket';
import Loader from '../../../Loader';

import TicketForm from './forms/TicketForm';
import { getViewType, initNewTicket, transformNewTicketForSave, isVisibleForCountry } from '../Utils';
import { createRights } from '../../../../constants/ticketRights';
import { createReqFields } from '../../../../constants/requiredFields';
import PageHeader from '../../../common/PageHeader';
import get from 'get-value';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import UploadingAttachments from './UploadingAttachments';
import { fetchTemplateShortList } from '../../../../actions/admin/actionAdminTemplateListPage';
import formMessages from '../../../../intl/hdi/formMessages';
import { FormattedMessage } from 'react-intl';


class NewTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = { ticketSubmittedAndShouldRedirect: false };
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetTicket();
        this.props.resetSubstitutionUser();
        this.props.fetchTemplateShortList();
    }

    handleSubmit = (values) => {
        const dataForSend = transformNewTicketForSave(values.newValues);
        this.props.saveTicket(dataForSend);
        this.setState({ ticketSubmittedAndShouldRedirect: true });
    };

    handleResetSubstitution = () => {
        this.props.resetTicket();
    };

    render() {
        const {
            ticketData,
            userDetail,
            loggedUser,
            formType,
            attachmentsData,
            isTemplateLoading
        } = this.props;

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingAttachments attachments={attachmentsData.attachmentsForUpload}
                                         attachmentsUrl={ticketData.newAttachments}
                                         correlationId={ticketData.correlationId}
                                         isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                         formType={formType}/>
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.id', { default: false }) && ((get(ticketData, "newAttachments.length") > 0) ? attachmentsData.attachmentUploaded : true)) {
            return <Redirect to={{
                pathname: `/hdi/view/${ticketData.ticket.id}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (ticketData.isTicketCreating
            || ticketData.isTicketLoading
            || !formType
            || this.state.ticketSubmittedAndShouldRedirect
            || isTemplateLoading
        ) {
            return <Loader/>;
        }

        let initializationValue = initNewTicket(userDetail);

        const requiredFields = createReqFields[createReqFields[userDetail.group] ? userDetail.group : 'DEFAULT'][formType];

        return (
            <div className="container-fluid">
                <PageHeader title={isVisibleForCountry(userDetail.group) ? <FormattedMessage {...formMessages.TITLE_REQUEST}/> : <FormattedMessage {...formMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <TicketForm
                            form={formType}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            userRights={createRights}
                            viewType={getViewType(loggedUser)}
                            userCountry={userDetail.group}
                            enableReinitialize={true}
                            handleResetSubstitution={this.handleResetSubstitution}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

NewTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    ticketData: PropTypes.object.isRequired,
    fetchTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any,
    resetTicket: PropTypes.func.isRequired,
    saveTicket: PropTypes.func.isRequired,
    fetchTemplateShortList: PropTypes.func.isRequired,
    isLoadingProfile: PropTypes.bool.isRequired,
    attachmentsData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail,
    clearAlerts: PropTypes.func.isRequired,
    ticketData: state.ticket,
    userDetail: state.profile.userDetail,
    isLoadingProfile: state.profile.isLoading,
    attachmentsData: state.hdiAttachment,
    isTemplateLoading: state.adminTemplateList.isLoading
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchTicket,
    saveTicket,
    resetTicket,
    resetSubstitutionUser,
    fetchTemplateShortList

})(NewTicketPage)), ['HDI_USER', 'HDI_EDITOR', 'HDI_ADMIN']);

