import React from 'react';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { TextareaFieldWithValidation } from '../../../../../../common/TextareaFieldWithValidation';
import { change, formValueSelector, getFormInitialValues } from 'redux-form';
import { connect } from 'react-redux';
import ValidFormattedMessage from '../../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../../intl/hdi/formMessages';
import { ticketConstants } from '../../../../../../../constants/form/formTicketConstants';
import get from 'get-value';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const InternalNoteSection = props => {
    const {userRights, readOnly, initialValues} = props;

    const internalNotes = get(initialValues, 'internalNotes', []);

    return (
        <>
            {((userRights.canAddInternalNotes && !readOnly) ||
                (userRights.canShowInternalNotes && internalNotes && (internalNotes.length > 0))) &&
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.INTERNAL_NOTE_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {(internalNotes && (internalNotes.length > 0)) &&
                    <div>
                        <div className="border-bottom border-bottom-secondary"
                             style={{backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0'}}>
                            <label className="col-form-label pl-0 font-weight-bold ml-3">
                                <ValidFormattedMessage message={ticketConstants.FORM_LABEL_INTERNAL_NOTES_HISTORY}
                                                       intlMessages={formMessages}/>
                            </label>
                        </div>
                        {internalNotes.map((editorsNote, index) =>
                            <div key={index} className="row m-0 align-items-stretch">
                                <div style={{backgroundColor: '#f8f9fa'}}
                                     className="col-sm-12 col-lg-4 flex-column border-bottom">
                                    <div>
                                        <FormattedDate value={new Date(editorsNote.created)}/>
                                        {' '}
                                        <FormattedTime value={new Date(editorsNote.created)}/>
                                        {' '}
                                        <span className="font-weight-bold">{editorsNote.creatorName}</span>
                                        {' '}({editorsNote.creatorIpn})
                                    </div>
                                </div>
                                <div className="col border-bottom" style={{whiteSpace: 'pre-wrap'}}>
                                    {editorsNote.text}
                                </div>
                            </div>
                        )}
                    </div>
                    }
                    {userRights.canAddInternalNotes && !readOnly &&
                    <div className="col-sm-12 px-4 pb-4 pt-2">
                        <div className="form-row">
                            <div className="col-sm-12 pb-2">
                                <label className="col-form-label pl-0 font-weight-bold">
                                    <ValidFormattedMessage message={ticketConstants.FORM_LABEL_INTERNAL_NOTE}
                                                           intlMessages={formMessages}/>
                                </label>
                                <TextareaFieldWithValidation
                                    field={ticketConstants.FORM_FIELD_INTERNAL_NOTE}
                                    rows={6}
                                />
                            </div>
                        </div>
                    </div>
                    }
                </StyledDiv>
            </>}
        </>
    );
};

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
});

export default connect(mapStateToProps)(InternalNoteSection);
