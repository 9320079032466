import React from 'react';
import {FormattedMessage, FormattedDate, FormattedTime} from "react-intl";
import styled from "styled-components";
import {
    FORM_STATUS_HISTORY_SECTION
} from "../../constants/formConstants";
import formStatesMessages from "../../intl/common/formStatesMessages";
import formStatusHistoryMessages from "../../intl/common/formStatusHistoryMessages";
import {formValueSelector, getFormInitialValues} from 'redux-form';
import {connect} from 'react-redux';
import ValidFormattedMessage from "./ValidFormattedMessage";
import commonMessages from "../../intl/common/commonMessages";
import get from 'get-value';
import {isVisibleForCountry} from '../hdi/ticket/Utils';

const StyledTd = styled.td`
    &&{
      padding: 0.25rem;
      vertical-align: middle;
    };
`;

const TicketStatusHistory = ({history, roles, ticket: {ticketSection}}) => {
    const canReadHistoryRecord = (item) => {
        return true
    }

    const readableHistory = history ? history.filter(item => canReadHistoryRecord(item)) : [];

    return (
        <>
            <div className="pt-5 d-flex justify-content-center">
                <div className="col-lg-12 col-xl-8">
                    <div className="card">
                        <h5 className="card-header text-center">
                            <FormattedMessage {...formStatesMessages.STATUS_HISTORY}/>
                        </h5>
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <tbody>
                                    <tr className="card-header">
                                        <StyledTd><FormattedMessage {...formStatusHistoryMessages.STATUS}/></StyledTd>
                                        <StyledTd><FormattedMessage {...formStatusHistoryMessages.DEPARTMENT}/></StyledTd>
                                        {isVisibleForCountry(ticketSection.country) &&
                                            <StyledTd><FormattedMessage {...formStatusHistoryMessages.TOPIC}/></StyledTd>
                                        }
                                        <StyledTd><FormattedMessage {...formStatusHistoryMessages.ROLE}/></StyledTd>
                                        <StyledTd><FormattedMessage {...formStatusHistoryMessages.IPN}/></StyledTd>
                                        <StyledTd><FormattedMessage {...formStatusHistoryMessages.NAME}/></StyledTd>
                                        <StyledTd><FormattedMessage {...formStatusHistoryMessages.DATE_TIME}/></StyledTd>
                                    </tr>
                                    <tr>
                                        <StyledTd>
                                            <ValidFormattedMessage
                                                message={'NEW'}
                                                intlMessages={formStatesMessages}
                                            />
                                        </StyledTd>
                                        <StyledTd>{get(ticketSection, 'solutionGroup.shortName', '')}</StyledTd>
                                        {isVisibleForCountry(get(ticketSection, 'country', 'XX')) &&
                                            <StyledTd>{get(ticketSection, 'templateName', '')}</StyledTd>
                                        }
                                        <StyledTd>
                                            <ValidFormattedMessage
                                                message={'DEALER'}
                                                intlMessages={commonMessages}
                                            />
                                        </StyledTd>
                                        <StyledTd>{get(ticketSection, 'creatorIpn', '')}</StyledTd>
                                        <StyledTd>{get(ticketSection, 'userName', '')}</StyledTd>
                                        <StyledTd>
                                            <FormattedDate value={new Date(get(ticketSection, 'createdDate', ''))}/>
                                            <FormattedTime value={new Date(get(ticketSection, 'createdDate', ''))}/>
                                        </StyledTd>
                                    </tr>
                                {readableHistory.reverse().map((row, index) =>
                                    <tr key={index}>
                                        <StyledTd>
                                            <ValidFormattedMessage
                                                message={get(row, 'status', '')}
                                                intlMessages={formStatesMessages}
                                            />
                                        </StyledTd>
                                        <StyledTd>{get(row, 'solutionGroup.shortName', '')}</StyledTd>
                                        {isVisibleForCountry(get(ticketSection, 'country', '')) &&
                                            <StyledTd>{get(row, 'subject.shortName', '')}</StyledTd>
                                        }
                                        <StyledTd>
                                            <ValidFormattedMessage
                                                message={(get(row, 'isEditor', '') === false) ? 'DEALER' : (get(row, 'isEditor', false) ? 'EDITOR' : '')}
                                                intlMessages={commonMessages}
                                            />
                                        </StyledTd>
                                        <StyledTd>{get(row, 'creatorIpn', '')}</StyledTd>
                                        <StyledTd>{get(row, 'creatorName', '')}</StyledTd>
                                        <StyledTd>
                                            <FormattedDate value={new Date(get(row, 'created', ''))}/>
                                            <FormattedTime value={new Date(get(row, 'created', ''))}/>
                                        </StyledTd>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state, props) => {
    return {
    history: formValueSelector(props.form)(state, `${FORM_STATUS_HISTORY_SECTION}`),
    ticket: getFormInitialValues(props.form)(state, `ticketSection`),
    roles: state.profile.userDetail.roles,
}
};

export default connect(mapStateToProps, null)(TicketStatusHistory);
