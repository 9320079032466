import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import Table from '../../../common/table/Table';
import { IconDelete, IconEdit, PrimaryIcon } from '../../../common/Button';
import { debounce } from 'throttle-debounce';
import moment from 'moment';
import { fetchEditorListPageData } from '../../../../actions/admin/actionAdminEditorListPage';
import listMessages from '../../../../intl/admin/editorMessages';
import { Link } from 'react-router-dom';

class ListEditorTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.loadStateFromSessionStorage({
            listEDefaultPageSize: 10,
            listEDefaultSort: [],
            listEDefaultFilters: [],
        });
        this.filtering = false;

        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleFetchDataDebounced = debounce(1000, this.handleFetchData);
    }

    componentDidMount() {
        window.addEventListener(
            'beforeunload',
            this.saveStateToSessionStorage.bind(this)
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveStateToSessionStorage.bind(this)
        );
        this.saveStateToSessionStorage();
    }

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults)
            .reduce((acc, key) => {
                if (sessionStorage.hasOwnProperty(key) && key.match(/^listE*/)) {
                    let value = sessionStorage.getItem(key);
                    value = JSON.parse(value);
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, defaults);
    }

    saveStateToSessionStorage() {
        for (let key in this.state) {
            if (key.match(/^listE*/)) {
                sessionStorage.setItem(key, JSON.stringify(this.state[key]));
            }
        }
    }

    handleFetchData = state => {
        this.props.fetchEditorListPageData(state.page, state.pageSize, state.sorted, this.normalizeFiltered(state));
        this.filtering = false;
        this.setState({
            listEDefaultPageSize: state.pageSize,
            listEDefaultSort: state.sorted,
            listEDefaultFilters: state.filtered
        });
    };

    fetchStrategy = (state) => {
        this.filtering ? this.handleFetchDataDebounced(state) : this.handleFetchData(state);
    };

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date) {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const { columns, data, pages, isLoading, handleEditClick, handleDeleteClick } = this.props;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'ipn',
                                    accessor: 'ipn',
                                    message: listMessages.TABLE_IPN,
                                    Cell: (row) => <Link
                                        to={`view/${row.original.ipn}`}>{row.value}</Link>,
                                },
                                ...columns]}
                            toolButtons={[
                                {
                                    handleClick: handleEditClick,
                                    component: <PrimaryIcon className="btn btn-sm mr-sm-1" title="Edit" type="button"
                                                            key="editButton">
                                        <IconEdit/>
                                    </PrimaryIcon>
                                },
                                {
                                    handleClick: handleDeleteClick,
                                    component: <PrimaryIcon className="btn btn-sm" title="Delete" type="button"
                                                            key="deleteButton">
                                        <IconDelete/>
                                    </PrimaryIcon>
                                }
                            ]}
                            isLoading={isLoading}
                            data={data}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            handleOnFilteredChange={() => {
                                this.filtering = true;
                            }}
                            defaultFiltered={this.state.listEDefaultFilters}
                            defaultSorting={this.state.listEDefaultSort}
                            defaultPageSize={this.state.listEDefaultPageSize}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ListEditorTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number.isRequired,
    fetchEditorListPageData: PropTypes.func.isRequired,
    columns: PropTypes.array,
    userRoles: PropTypes.object.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminEditorList.isTableLoading,
    data: state.adminEditorList.listData,
    pages: state.adminEditorList.pages,
    userRoles: state.profile.userDetail.roles
});

export default connect(mapStateToProps, {
    fetchEditorListPageData
})(ListEditorTable);
