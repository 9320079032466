import {hdiTicketWebsocketCommands} from '../../constants/hdiTicketWebsocketCommands';

export const createExport = (exportFilter) => (dispatch) => {
    dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_EXPORT_CREATE,
        hdiWebsocket: true,
        command: hdiTicketWebsocketCommands.HDI_TICKET_EXPORT_CREATE,
        payload: {exportFilter},
    });
};

export const fetchExport = () => (dispatch) => {
    dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_EXPORT_GET,
        hdiWebsocket: true,
        command: hdiTicketWebsocketCommands.HDI_TICKET_EXPORT_GET,
        payload: {},
    });
};

export const downloadExport = (exportRecord) => (dispatch) => {
    dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_EXPORT_DOWNLOAD_URL_GET,
        hdiWebsocket: true,
        command: hdiTicketWebsocketCommands.HDI_TICKET_EXPORT_DOWNLOAD_URL_GET,
        payload: {exportRecord},
    });
};
