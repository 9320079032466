import get from 'get-value';
import { diff } from 'deep-object-diff';

export const transformSGtoSelectOptions = values => {
    return values.map(value => ({ label: value.shortName, value: value.id }));
};

export const transformBackToSG = values => {
    return values.map(value => ({ id: value.value, shortName: value.label }));
};

export const initLoadedEditor = (editorData) => {
    const { ...rest } = editorData.editor;
    const { solutionGroups, } = editorData;

    return {
        solutionGroupSection: {
            solutionGroups: transformSGtoSelectOptions(solutionGroups)
        },
        editorSection: {
            ...rest
        }
    };
};

export const initNewEditor = (userData, editorData) => {
    const { solutionGroups } = editorData;
    return {
        solutionGroupSection: {
            solutionGroups: transformSGtoSelectOptions(solutionGroups),
        },
        editorSection: {
            name: '',
            ipn: '',
            isOnHold: false,
            isSuspend: false,
            defaultSolutionGroup: '',
        },
    };
};

export const transformNewEditorForSave = (values) => {
    const data = {};

    data.editor = {
        ...values.editorSection,
    };
    data.addedSolutionGroup = transformBackToSG(values.solutionGroupSection.solutionGroups);

    data.id = values.editorSection.ipn;

    return data;
};

export const transformUpdateEditorForSave = (values, editorData) => {
    const { oldValues, newValues } = values;
    const difference = diff(oldValues, newValues);

    const data = {};
    const { id } = editorData.editor;
    data.id = id;

    data.editor = {
        ...get(difference, 'editorSection'),
        id
    };

    const { addedSolutionGroup, removedSolutionGroup } = checkResultFromSelectField(
        oldValues.solutionGroupSection.solutionGroups,
        newValues.solutionGroupSection.solutionGroups
    );
    data.addedSolutionGroup = transformBackToSG(addedSolutionGroup);
    data.removedSolutionGroup = transformBackToSG(removedSolutionGroup);

    return data;
};

export const checkResultFromSelectField = (oldValues = [], newValues = []) => {
    const addedSolutionGroup = [];
    const removedSolutionGroup = [];

    if (!(Array.isArray(oldValues) && Array.isArray(newValues))) {
        return { addedSolutionGroup, removedSolutionGroup };
    }

    oldValues.forEach(oldValue => {
        const res = newValues.find(newValue => newValue.value === oldValue.value);
        if (!res) {
            removedSolutionGroup.push(oldValue);
        }
    });
    newValues.forEach(newValue => {
        const res = oldValues.find(oldValue => oldValue.value === newValue.value);
        if (!res) {
            addedSolutionGroup.push(newValue);
        }
    });
    return { addedSolutionGroup, removedSolutionGroup };
};
