import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import { Redirect, withRouter } from 'react-router-dom';
import { clearAlerts } from '../../../../actions/alertsActions';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import templateMessages from '../../../../intl/admin/templateMessages';
import commonMessages from '../../../../intl/common/commonMessages';
import ListTemplateTable from './ListTemplateTable';
import { ADMINISTRATION_TEMPLATE_PATH } from '../../../../routes/paths';
import { countries } from '../../../../constants/Utils';
import formCountryMessages from '../../../../intl/common/countryGroupMessages';
import ConfirmationDialog from '../../../common/modal/ConfirmationDialog';
import { deleteTemplate } from '../../../../actions/admin/actionAdminTemplate';
import { isVisibleForCountry } from '../utils';

class ListTemplatePage extends Component {
    constructor(props) {
        super(props);
        const { intl, userDetail } = props;
        this.state = {
            ratchet: false,
            templateId: '',
            columnLibrary: [
                {
                    id: 'subject',
                    accessor: 'template.subject',
                    message: templateMessages.TABLE_SUBJECT,
                },
                {
                    id: 'solutionGroup',
                    accessor: 'solutionGroup.shortName',
                    message: isVisibleForCountry(userDetail.group) ? templateMessages.TABLE_SOLUTION_GROUP_UK : templateMessages.TABLE_SOLUTION_GROUP,
                },
                {
                    id: 'country',
                    accessor: row => this.formatCountryLabel(row.dealer, row.subsidiary, intl),
                    message: templateMessages.TABLE_COUNTRY,
                    Filter: ({ filter, onChange }) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%', border: '1px solid #999999' }}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {Object.keys(countries).map((key, index) =>
                                <option key={index} value={key}>
                                    {formCountryMessages[key] ? intl.formatMessage(formCountryMessages[key]) : key}
                                </option>)}
                        </select>
                    ),
                    Cell: e =>
                        <div>{formCountryMessages[e.value] ? intl.formatMessage(formCountryMessages[e.value]) : e.value}</div>,
                },
            ],
            isOpenDeleteDialog: false,
            templateIdToBeDeleted: null,
        };
    }

    formatCountryLabel = (dealer = [], subsidiary = [], intl) => {
        let result = '';
        let array = [...new Set([...dealer, ...subsidiary])];
        array.sort().forEach(country => result += intl.formatMessage(formCountryMessages[country]) + ', ');
        return result.replace(/,\s$/, '');
    };

    componentDidMount() {
        this.props.clearAlerts();
    }

    handleEditClick = ({ id }) => {
        this.setState({ ratchet: true, templateId: id });
    };

    handleDeleteClick = template => {
        this.setState({
            isOpenDeleteDialog: true,
            templateIdToBeDeleted: template.id
        });
    };

    closeDeleteModal = e => {
        e.preventDefault();
        this.setState({
            isOpenDeleteDialog: false,
            templateIdToBeDeleted: null
        });
    };

    saveDeleteModal = e => {
        e.preventDefault();
        this.props.deleteTemplate(this.state.templateIdToBeDeleted);
        this.setState({
            isOpenDeleteDialog: false,
            templateIdToBeDeleted: null
        });
    };

    render() {
        const { filter, userDetail } = this.props;
        const { columnLibrary, templateId } = this.state;

        if (this.state.ratchet) {
            return <Redirect to={`${ADMINISTRATION_TEMPLATE_PATH}/edit/${templateId}`}/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={isVisibleForCountry(userDetail.group) ? <FormattedMessage {...templateMessages.TITLE_UK}/> : <FormattedMessage {...templateMessages.TITLE}/>}/>
                <ListTemplateTable
                    columns={columnLibrary}
                    handleEditClick={this.handleEditClick} handleDeleteClick={this.handleDeleteClick} filter={filter}/>
                <ConfirmationDialog show={this.state.isOpenDeleteDialog} closeModal={this.closeDeleteModal}
                                    saveModal={this.saveDeleteModal} actionIdentifier="delete the template"/>
            </div>
        );
    }
}

ListTemplatePage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    deleteTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    deleteTemplate
})(injectIntl(ListTemplatePage))), ['HDI_ADMIN']);
