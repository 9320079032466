import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import countryFormMessages from '../../../../../../intl/common/countryGroupMessages';
import InputSelectFieldForObjectWithValidation from '../../../../../common/InputSelectFieldForObjectWithValidation';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const MainSection = (props) => {
    const {
        data
    } = props;
    return (
        <StyledDiv className="border border-secondary px-4 py-2 bg-white">
            {
                data && Object.keys(data).map((country, index) => {
                    return (
                        <div key={index} className="form-row">
                            <div className="col pb-2">
                                <InputSelectFieldForObjectWithValidation
                                    label={countryFormMessages[country]}
                                    field={`${country}`}
                                    isRequired
                                    options={data[country].solutionGroups.map(item => ({
                                        label: item.shortName,
                                        value: item.id
                                    }))}
                                    //handleChange={handleChange}
                                    isSmall/>
                            </div>
                        </div>
                    )
                })
            }
        </StyledDiv>
    )
};

const mapStateToProps = (state, props) => ({
    data: state.adminSolutionGroupDefault.data,
    isLoading: state.adminSolutionGroupDefault.isLoading,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainSection);
