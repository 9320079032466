import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import formMessages from '../../../../../../../intl/admin/editorMessages';
import PropTypes from 'prop-types';
import InputMultipleSelectField from '../../../../../../common/InputMultipleSelectField';
import { editorConstants } from '../../../../../../../constants/form/formEditorConstants';
import { change } from 'redux-form';
import {isVisibleForCountry} from '../../../../../../hdi/ticket/Utils';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const Label = styled.label`
  font-weight: bold;
`;

const SolutionGroupSection = (props) => {
    const { intl: { formatMessage }, editorData, readOnly, userDetail } = props;
    const { availableSolutionGroups } = editorData;

    return (
        <>
            <hr/>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="row mb-sm-2">
                    <Label>
                        <FormattedMessage {...formMessages[isVisibleForCountry(userDetail.group) ? editorConstants.FORM_LABEL_EDITOR_SOLUTION_GROUP_SECTION_UK : editorConstants.FORM_LABEL_EDITOR_SOLUTION_GROUP_SECTION]} />
                    </Label>
                </div>
                <div className="row mb-sm-3">
                    <div className="col">
                        <InputMultipleSelectField
                            field={editorConstants.FORM_FIELD_EDITOR_SOLUTION_GROUP_ACCESS}
                            options={availableSolutionGroups.map(sg => ({ label: sg.shortName, value: sg.id }))}
                            isDisabled={readOnly}
                            isMulti={true}
                            isClearable={!readOnly}
                            isSearchable={!readOnly}
                            isRequired
                            closeMenuOnSelect={false}
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )

};

SolutionGroupSection.propTypes = {
    intl: PropTypes.object.isRequired,
    editorData: PropTypes.object.isRequired,
    userDetail: PropTypes.object,
};

const mapStateToProps = (state) => ({
    editorData: state.adminEditor,
    userDetail: state.profile.userDetail,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SolutionGroupSection));
