import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    NO_DEALER_CODE: {
        id: 'error.noDealerCode',
        defaultMessage: 'You don\'t have a dealer code!'
    },
    DEALER_ROLE_SET: {
        id: 'error.dealerRoleSet',
        defaultMessage: 'Cannot grant editor access for this user! Reason: User has already dealer profile'
    },
    EDITOR_ROLE_SET: {
        id: 'error.editorRoleSet',
        defaultMessage: 'Cannot grant dealer access for this user! Reason: User has already editor profile'
    },
    DEALER_ROLE_NO_BIR: {
        id: 'error.dealerRoleNoBir',
        defaultMessage: 'Cannot grant dealer access for this user! Reason: User has no BIR number'
    },
    HDI_ERROR: {
        id: 'error.code',
        defaultMessage: 'Error message.'
    },
    ERR001: {
        id: 'error.invalid.country',
        defaultMessage: 'Invalid user country.'
    },
    ERR002: {
        id: 'error.invalid.rights',
        defaultMessage: 'Invalid user role specified.'
    },
    ERR003: {
        id: 'error.invalid.profile',
        defaultMessage: 'Invalid user profile specified.'
    },
    ERR011: {
        id: 'error.invalid.ticket.rights',
        defaultMessage: 'Invalid user role specified for ticket'
    },
    ERR012: {
        id: 'error.invalid.ticket.status',
        defaultMessage: 'Wrong next ticket status specified.'
    },
    ERR013: {
        id: 'error.invalid.ticket.update',
        defaultMessage: 'User cannot update ticket.'
    },
    ERR021: {
        id: 'error.exist.editor',
        defaultMessage: 'Editor with ipn already exists.'
    },
    ERR031: {
        id: 'error.invalid.solutionGroup.name',
        defaultMessage: 'Solution group name is not unique.'
    },
    ERR032: {
        id: 'error.exist.solutionGroup.default',
        defaultMessage: 'Solution group is specific as default solution group.'
    },
    ERR033: {
        id: 'error.exist.solutionGroup.template',
        defaultMessage: 'For this solution group exist template.'
    },
});

export default messages;
