import get from 'get-value';
import { diff } from 'deep-object-diff';
import { countries } from '../../../constants/Utils';

export const transformUsersNotifications = (reducer, formValue) => {
    const diff = [];
    reducer.forEach(origin => {
        const res = formValue.find(item => item.value === origin.ipn);
        if (!origin.isActive && res) {
            diff.push({ ipn: origin.ipn, isActive: true });
        } else if (origin.isActive && !res) {
            diff.push({ ipn: origin.ipn, isActive: false });
        }
    });
    return diff;
};

export const initLoadedSolutionGroup = (solutionGroupData) => {
    const { ...rest } = solutionGroupData.solutionGroup;
    const { country, userEmail, emailList } = solutionGroupData;

    return {
        solutionGroupSection: {
            ...rest,
        },
        usersSection: {
            emails: Array.isArray(emailList) ? emailList.map(item => ({ label: item, value: item })) : (emailList && emailList.length > 0) ? [{ value: emailList, label: emailList }] : [],
            users: userEmail
                .filter(user => user.isEmailActive === true)
                .map(user => ({ label: user.name, value: user.ipn })),
        },
        countrySection: {
            country: {
                ...country
            }
        },

    };
};

const countryRight = () => {
    const result = {};
    Object.keys(countries).map(country => {
        return result[country] = false;
    });
    return result;
};

export const initNewSolutionGroup = () => {
    return {
        solutionGroupSection: {
            shortName: '',
            longName: '',
            level: '',
            isActive: true,
        },
        countrySection: {
            country: {
                ...countryRight()
            }
        },
        usersSection: {
            emails: [],
        },
        users: []

    };
};

export const transformNewSolutionGroupForSave = (values) => {
    const data = {};
    data.solutionGroup = {
        ...values.solutionGroupSection,
    };
    data.country = {
        ...values.countrySection.country
    };
    data.emailList = get(values, 'usersSection.emails', { default: [] }).map(item => item.value);
    data.userEmail = get(values, 'users', { default: [] });

    return data;
};

export const transformUpdateSolutionGroupForSave = (values, solutionGroup) => {
    const { oldValues, newValues } = values;
    const difference = diff(oldValues, newValues);

    const data = {};
    data.id = oldValues.solutionGroupSection.id;

    data.solutionGroup = {
        ...get(difference, 'solutionGroupSection'),
        id: oldValues.solutionGroupSection.id
    };

    if (get(difference, 'countrySection', { default: false })) {
        data.country = {
            ...get(difference, 'countrySection.country')
        };
    }
    if (get(difference, 'usersSection.users', { default: false })) {
        const { addedUsers, removedUsers } = checkResultFromSelectField(
            oldValues.usersSection.users,
            newValues.usersSection.users
        );
        data.addedEditorEmail = transformBackToUser(addedUsers);
        data.removedEditorEmail = transformBackToUser(removedUsers);


        //data.userList = transformUsersNotifications(solutionGroup.users, get(newValues, 'users'));
    }

    data.emailList = get(newValues, 'usersSection.emails', { default: [] }).map(item => item.value);
    return data;
};

export const transformBackToUser = values => {
    return values.map(item => (item.value));
};

export const checkResultFromSelectField = (oldValues = [], newValues = []) => {
    const addedUsers = [];
    const removedUsers = [];

    if (!(Array.isArray(oldValues) && Array.isArray(newValues))) {
        return { addedUsers, removedUsers };
    }

    oldValues.forEach(oldValue => {
        const res = newValues.find(newValue => newValue.value === oldValue.value);
        if (!res) {
            removedUsers.push(oldValue);
        }
    });
    newValues.forEach(newValue => {
        const res = oldValues.find(oldValue => oldValue.value === newValue.value);
        if (!res) {
            addedUsers.push(newValue);
        }
    });
    return { addedUsers, removedUsers };
};
