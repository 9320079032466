import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import formMessages from '../../../../../../../intl/admin/editorMessages';
import { editorConstants } from '../../../../../../../constants/form/formEditorConstants';
import { InputSelectUserFieldWithValidation } from './InputSelectUserFieldWithValidation';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const UserSection = (props) => {
    const {
        readOnly, reqFields, handleUserSelection, handleUserSearch, change
    } = props;

    const handleSelect = (value) => {
        handleUserSelection(value);
        change(`${editorConstants.FORM_EDITOR_EDITOR_SECTION}.${editorConstants.FORM_FIELD_EDITOR_IPN}`, value.ipn);
        change(`${editorConstants.FORM_EDITOR_EDITOR_SECTION}.${editorConstants.FORM_FIELD_EDITOR_NAME}`, value.name);
        change(`${editorConstants.FORM_EDITOR_EDITOR_SECTION}.${editorConstants.FORM_FIELD_EDITOR_ON_HOLD}`, false);
        change(`${editorConstants.FORM_EDITOR_EDITOR_SECTION}.${editorConstants.FORM_FIELD_EDITOR_SUSPEND}`, false);
    };

    return (
        <StyledDiv className="border border-secondary px-4 py-2 bg-white">
            <div className="form-row">
                <div className="col pb-2">
                    <InputSelectUserFieldWithValidation
                        label={formMessages[editorConstants.FORM_LABEL_EDITOR_IPN]}
                        field={'searchUser'}
                        handleUserSelection={handleSelect}
                        handleUserSearch={handleUserSearch}
                        isDisabled={readOnly}
                        isRequired={reqFields.includes(editorConstants.FORM_FIELD_EDITOR_IPN) && !readOnly}
                        isSmall
                    />
                </div>
            </div>
        </StyledDiv>
    )
};

const mapStateToProps = (state) => ({
    editorData: state.adminEditor,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSection);
