export const templateConstants = {
    //Sections
    FORM_TEMPLATE_SOLUTION_GROUP_SECTION: 'solutionGroupSection',
    FORM_TEMPLATE_DESCRIPTION_SECTION: 'descriptionSection',
    FORM_TEMPLATE_COUNTRY_SECTION: 'countrySection',
    //Fields
    FORM_FIELD_TEMPLATE_SUBJECT: 'subject',
    FORM_FIELD_TEMPLATE_COPY: 'copy',
    FORM_FIELD_TEMPLATE_DESCRIPTION: 'description',
    FORM_FIELD_TEMPLATE_COUNTRY: 'country',
    FORM_FIELD_TEMPLATE_VISIBLE_DEALER: 'visible.dealer',
    FORM_FIELD_TEMPLATE_VISIBLE_SUBS: 'visible.subsidiary',
    FORM_FIELD_TEMPLATE_SOLUTION_GROUP: 'solutionGroup',
    //Labels
    FORM_LABEL_TEMPLATE_SUBJECT: 'TEMPLATE_SUBJECT',
    FORM_LABEL_TEMPLATE_COPY: 'TEMPLATE_COPY',
    FORM_LABEL_TEMPLATE_DESCRIPTION: 'TEMPLATE_DESCRIPTION',
    FORM_LABEL_TEMPLATE_COUNTRY: 'TEMPLATE_COUNTRY',
    FORM_LABEL_TEMPLATE_VISIBLE_DEALER: 'TEMPLATE_VISIBLE_DEALER',
    FORM_LABEL_TEMPLATE_VISIBLE_SUBS: 'TEMPLATE_VISIBLE_SUBS',
    FORM_LABEL_TEMPLATE_SOLUTION_GROUP: 'TEMPLATE_SOLUTION_GROUP',
    FORM_LABEL_TEMPLATE_SOLUTION_GROUP_UK: 'TEMPLATE_SOLUTION_GROUP_UK',

    FORM_LABEL_TEMPLATE_DESCRIPTION_SECTION: 'FORM_LABEL_TEMPLATE_DESCRIPTION_SECTION',
    FORM_LABEL_TEMPLATE_SOLUTION_GROUP_SECTION: 'FORM_LABEL_TEMPLATE_SOLUTION_GROUP_SECTION',
    FORM_LABEL_TEMPLATE_COUNTRY_SECTION: 'FORM_LABEL_TEMPLATE_COUNTRY_SECTION',

};
