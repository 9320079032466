import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    ADMINISTRATION_EDITOR_LIST_PATH,
    ADMINISTRATION_EDITOR_NEW_PATH, ADMINISTRATION_FREQUENT_QUESTION_PATH,
    ADMINISTRATION_RIGHTS_ADMIN_PATH, ADMINISTRATION_RIGHTS_DEALER_PATH, ADMINISTRATION_SOLUTION_GROUP_DEFAULT_PATH,
    ADMINISTRATION_SOLUTION_GROUP_LIST_PATH,
    ADMINISTRATION_SOLUTION_GROUP_NEW_PATH,
    ADMINISTRATION_TEMPLATE_LIST_PATH,
    ADMINISTRATION_TEMPLATE_NEW_PATH,
    ADMINISTRATION_WELCOME_PATH,
} from '../../../../routes/paths';
import { moduleRoles } from '../../../../utils/roles';
import menuMessages from '../../../../intl/layout/menuMessages';

export const getAdministrationMenuAdmin = (roles, country) => {
    let result = [];

    if (moduleRoles.isAdmin(roles) || moduleRoles.isPowerUser(roles)) {
        let content = [];
        if (moduleRoles.isAdmin(roles)) {
            content = [];
        }

        const authorizationManagementSubmenu = buildAuthorizationManagementSubmenu(roles);
        if (authorizationManagementSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_HDI_AUTH_MANAGEMENT}/>,
                    content: authorizationManagementSubmenu
                },
            );
        }
        const solutionGroupManagementSubmenu = buildSolutionGroupManagementSubmenu(roles, country);
        if (solutionGroupManagementSubmenu.length > 0) {
            if(country === "UK" || country === "CY" || country === "IE" || country === "MT"){
                content.push(
                    {
                        label: <FormattedMessage {...menuMessages.ADMIN_HDI_TICKET_SOLUTION_GROUP_UK}/>,
                        content: solutionGroupManagementSubmenu
                    },
                );
            } else {
                content.push(
                    {
                        label: <FormattedMessage {...menuMessages.ADMIN_HDI_TICKET_SOLUTION_GROUP}/>,
                        content: solutionGroupManagementSubmenu
                    },
                );
            }
        }
        const editorManagementSubmenu = buildEditorManagementSubmenu(roles);
        if (editorManagementSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_HDI_EDITOR}/>,
                    content: editorManagementSubmenu
                },
            );
        }
        const templateManagementSubmenu = buildTemplateManagementSubmenu(roles);
        if (templateManagementSubmenu.length > 0) {
            if(country === "UK" || country === "CY" || country === "IE" || country === "MT"){
                content.push(
                    {
                        label: <FormattedMessage {...menuMessages.ADMIN_HDI_TICKET_TEMPLATE_TOPICS}/>,
                        content: templateManagementSubmenu
                    },
                );
            } else {
                content.push(
                    {
                        label: <FormattedMessage {...menuMessages.ADMIN_HDI_TICKET_TEMPLATE_GROUP}/>,
                        content: templateManagementSubmenu
                    },
                );
            }
        }
        if (moduleRoles.isAdmin(roles)) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_FREQUENT_QUESTION}/>,
                    to: ADMINISTRATION_FREQUENT_QUESTION_PATH
                }
            )
        }
        const welcomeManagementSubmenu = buildWelcomeManagementSubmenu(roles);
        if (welcomeManagementSubmenu) {
            content.push(welcomeManagementSubmenu);
        }
        result = content;
    }
    return result;
};

const buildEditorManagementSubmenu = roles => {
    const result = [];

    if (moduleRoles.isAdmin(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_HDI_EDITOR_NEW}/>,
                to: ADMINISTRATION_EDITOR_NEW_PATH
            },
            {
                label: <FormattedMessage {...menuMessages.ADMIN_HDI_EDITOR_LIST}/>,
                to: ADMINISTRATION_EDITOR_LIST_PATH
            }
        );
    }
    return result;
};

const buildSolutionGroupManagementSubmenu = (roles, country) => {
    const result = [];

    if (moduleRoles.isAdmin(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_HDI_SOLUTION_GROUP_NEW}/>,
                to: ADMINISTRATION_SOLUTION_GROUP_NEW_PATH
            },
            {
                label: <FormattedMessage {...menuMessages.ADMIN_HDI_SOLUTION_GROUP_LIST}/>,
                to: ADMINISTRATION_SOLUTION_GROUP_LIST_PATH
            },
            {
                label: ['UK', 'CY', 'IE', 'MT'].includes(country) ? <FormattedMessage {...menuMessages.ADMIN_HDI_SOLUTION_GROUP_DEFAULT_UK}/> : <FormattedMessage {...menuMessages.ADMIN_HDI_SOLUTION_GROUP_DEFAULT}/>,
                to: ADMINISTRATION_SOLUTION_GROUP_DEFAULT_PATH
            }
        );
    }
    return result;
};

const buildTemplateManagementSubmenu = roles => {
    const result = [];

    if (moduleRoles.isAdmin(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_HDI_TEMPLATE_NEW}/>,
                to: ADMINISTRATION_TEMPLATE_NEW_PATH
            },
            {
                label: <FormattedMessage {...menuMessages.ADMIN_HDI_TEMPLATE_LIST}/>,
                to: ADMINISTRATION_TEMPLATE_LIST_PATH
            }
        );
    }
    return result;
};

const buildAuthorizationManagementSubmenu = roles => {
    const result = [];

    if (moduleRoles.isPowerUser(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_HDI_AUTH_MANAGEMENT_ADMIN}/>,
                to: ADMINISTRATION_RIGHTS_ADMIN_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_HDI_AUTH_MANAGEMENT_DEALER}/>,
                to: ADMINISTRATION_RIGHTS_DEALER_PATH
            }
        );
    }
    return result;
};

const buildWelcomeManagementSubmenu = roles => {
    if (moduleRoles.isAdmin(roles) || moduleRoles.isPowerUser(roles)) {
        return {
            label: <FormattedMessage {...menuMessages.ADMIN_HDI_WELCOME_TEXT}/>,
            to: ADMINISTRATION_WELCOME_PATH
        };
    }
    return false;
};
