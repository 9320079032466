import React from 'react'
import {reduxForm} from 'redux-form'
import {PrimaryButton, SecondaryButton} from '../Button';
import {FormattedMessage} from 'react-intl';
import buttonMessages from '../../../intl/common/buttonMessages';

let SatisfactionFormButtons = props => {
    const {handleSubmit, close} = props;
    return (
        <form onSubmit={handleSubmit} className="d-flex justify-content-center">
            <div className="px-2">
                <PrimaryButton type="submit" className="btn" >
                    <FormattedMessage {...buttonMessages.REOPEN}/>
                </PrimaryButton>
            </div>
            <div className="px-2">
                <SecondaryButton type="button" className="btn" onClick={close} >
                    <FormattedMessage {...buttonMessages.CLOSE}/>
                </SecondaryButton>
            </div>
        </form>
    )
}

SatisfactionFormButtons = reduxForm({
    // a unique name for the form
    form: 'satisfaction'
})(SatisfactionFormButtons);

export default SatisfactionFormButtons;
