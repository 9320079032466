import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconCancel, PrimaryButton} from '../Button';
import {ALERT_ERROR, ALERT_INFO, ALERT_WARN} from '../../../actions/alertsActions';
import AlertDetailsModal from './AlertDetailsModal';
import alertMessages from '../../../intl/common/alertMessages';
import moment from 'moment';
import SatisfactionModal from './SatisfactionModal';

const DivWithMinLength = styled.div`
    display: inline-block;
`;

const TypeIconCol = styled.div`
    text-align: left;
    font-size: x-large;
`;

const CloseIcon = styled(FontAwesomeIcon)`
    vertical-align: text-top;
`;

const DivWithCenteredContent = styled.div`
    text-align: center;
`;

const DetailsLink = styled.a`
    cursor: pointer;
`;

class AlertItem extends Component {
    constructor(props) {
        super(props);
        this.state = {isDetailsModalOpen: false, isSatisfactionModalOpen: false };
    }

    showDetailsModal = () => this.setState({isDetailsModalOpen: true});

    closeDetailsModal = () => this.setState({isDetailsModalOpen: false});

    showSatisfactionModal = () => this.setState({isSatisfactionModalOpen: true});

    closeSatisfactionModal = () => this.setState({isSatisfactionModalOpen: false});

    checkForDates = (values) => {
        const result = {};
        Object.keys(values).forEach(param => {
            if (param.toLowerCase().includes('date') && moment(values[param], moment.ISO_8601, true).isValid()) {
                result[param] = this.props.intl.formatDate(values[param]);
            } else if (param.toLowerCase().includes('time') && moment(values[param], moment.ISO_8601, true).isValid()) {
                result[param] = this.props.intl.formatTime(values[param]);
            } else {
                result[param] = values[param];
            }
        });
        return result;
    };

    renderMessage = (type, text, details, values) => {
        if (Array.isArray(text)) {
            return text.map((item, key) => <span key={key}>{item}<br/></span>);
        }
        return (
            <span>
            {text instanceof Object && Object.keys(text).length !== 0 && <FormattedMessage {...text} values={values}/>}
            {(text instanceof String || typeof text === 'string') && text}
                <br/>
                {
                    type === ALERT_INFO &&
                    <div className="d-flex justify-content-center">
                        {details.map(e => (
                            <div className="px-2">
                                <PrimaryButton type="button" className="btn" onClick={e.handleClick || this.showSatisfactionModal}>
                                    <FormattedMessage {...e.buttonLabel}/>
                                </PrimaryButton>
                            </div>
                        ))}
                    </div>
                }
                {
                    type !== ALERT_INFO &&
                    details && (
                        (details.constructor === Array && details.length > 0) ||
                        ((details.constructor === Object || details.constructor === Event) && Object.keys(details).length > 0) ||
                        (typeof details === 'string' || details instanceof String)
                    ) &&
                    <div>
                        <DetailsLink className="alert-link"
                                     onClick={this.showDetailsModal}><FormattedMessage {...alertMessages.DETAILS_FOR_SUPPORT}/></DetailsLink>
                    </div>
                }
        </span>
        );
    };

    render() {
        const {text, id, remove, details, type, values} = this.props;
        const formattedValues = this.checkForDates(values);
        return (
            <>
                <DivWithCenteredContent>
                    <DivWithMinLength
                        className={`alert alert-${type === ALERT_ERROR ? 'danger' : type === ALERT_WARN ? 'warning' : 'success'} show`}
                        role="alert">
                        <div className="row">
                            <TypeIconCol className="col-sm-6">
                                <CloseIcon
                                    icon={`${type === ALERT_ERROR ? 'exclamation-circle' : type === ALERT_WARN ? 'info-circle' : 'check-circle'}`}/>
                            </TypeIconCol>
                            <div className="col-sm-6">
                                <button type="button" className="close" onClick={() => remove(id)}>
                                    <IconCancel/>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {this.renderMessage(type, text, details, formattedValues)}
                            </div>
                        </div>
                    </DivWithMinLength>
                </DivWithCenteredContent>
                <AlertDetailsModal show={this.state.isDetailsModalOpen} handleClose={this.closeDetailsModal}
                                   text={text} values={formattedValues} details={details}/>
                <SatisfactionModal show={this.state.isSatisfactionModalOpen} handleClose={this.closeSatisfactionModal}
                                   text={text} values={formattedValues}/>
            </>
        );
    }
}

AlertItem.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string
    ]),
    id: PropTypes.string.isRequired,
    remove: PropTypes.func.isRequired,
    details: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string
    ]),
    type: PropTypes.string.isRequired,
    intl: PropTypes.any.isRequired
};


export default injectIntl(AlertItem);
