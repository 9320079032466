import { defineMessages } from 'react-intl';
import { editorConstants } from '../../constants/form/formEditorConstants';

const messages = defineMessages({
    TITLE: {
        id: 'admin.hdi.editor.title',
        defaultMessage: 'Operators'
    },
    TABLE_IPN: {
        id: 'admin.hdi.editor.table.editor.ipn',
        defaultMessage: 'Operator ipn'
    },
    TABLE_NAME: {
        id: 'admin.hdi.editor.table.editor.name',
        defaultMessage: 'Name'
    },
    TABLE_STATE: {
        id: 'admin.hdi.editor.table.editor.state',
        defaultMessage: 'State'
    },
    TABLE_SOLUTION_GROUP: {
        id: 'admin.hdi.editor.table.solutionGroups',
        defaultMessage: 'Solution groups'
    },
    TABLE_SOLUTION_GROUP_UK: {
        id: 'admin.hdi.editor.table.solutionGroupsUK',
        defaultMessage: 'Departments'
    },
    PLACEHOLDER_SELECT_SOLUTION_GROUP: {
        id: 'hdi.administration.editor.sg.placeholder',
        defaultMessage: 'Select solution group'
    },
    ERROR_EDITOR_ALREADY_EXIST: {
        id: 'hdi.administration.editor.exist',
        defaultMessage: 'Editor with this IPN already exist!'
    },
    [editorConstants.FORM_LABEL_EDITOR_IPN]: {
        id: 'hdi.administration.editor.label.ipn',
        defaultMessage: 'IPN'
    },
    [editorConstants.FORM_LABEL_EDITOR_NAME]: {
        id: 'hdi.administration.editor.label.name',
        defaultMessage: 'Name'
    },
    [editorConstants.FORM_LABEL_EDITOR_ON_HOLD]: {
        id: 'hdi.administration.editor.label.onHold',
        defaultMessage: 'On hold'
    },
    [editorConstants.FORM_LABEL_EDITOR_SUSPEND]: {
        id: 'hdi.administration.editor.label.suspend',
        defaultMessage: 'Suspend'
    },
    [editorConstants.FORM_LABEL_EDITOR_DEFAULT_SG]: {
        id: 'hdi.administration.editor.label.defaultSG',
        defaultMessage: 'Default solving group'
    },
    [editorConstants.FORM_LABEL_EDITOR_DEFAULT_SG_UK]: {
        id: 'hdi.administration.editor.label.defaultSGUK',
        defaultMessage: 'Default department'
    },
    [editorConstants.FORM_LABEL_EDITOR_SOLUTION_GROUP_ACCESS]: {
        id: 'hdi.administration.editor.label.addSG',
        defaultMessage: 'Solution group'
    },
    [editorConstants.FORM_LABEL_EDITOR_SECTION]: {
        id: 'hdi.administration.editor.label.editorSection',
        defaultMessage: 'Operator information'
    },
    [editorConstants.FORM_LABEL_EDITOR_SOLUTION_GROUP_SECTION]: {
        id: 'hdi.administration.editor.label.sgSection',
        defaultMessage: 'Solution Group Section'
    },
    [editorConstants.FORM_LABEL_EDITOR_SOLUTION_GROUP_SECTION_UK]: {
        id: 'hdi.administration.editor.label.sgSectionUK',
        defaultMessage: 'Department Section'
    },
});

export default messages;
