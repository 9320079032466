import { hdiSolutionGroupWebsocketCommands, } from '../../constants/hdiSolutionGroupWebsocketCommands';
import get from 'get-value';

export const adminSolutionGroupDefault = (state = {
    correlationId: null,
    isLoading: false,
    data: {},
}, action) => {
    switch (action.type) {
        case hdiSolutionGroupWebsocketCommands.HDI_SOLUTION_GROUP_DEFAULT_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isLoading: false,
                data: {},
            });
        }
        case hdiSolutionGroupWebsocketCommands.HDI_SOLUTION_GROUP_DEFAULT_UPDATE_PENDING:
        case hdiSolutionGroupWebsocketCommands.HDI_SOLUTION_GROUP_DEFAULT_LIST_PENDING: {
            return Object.assign({}, state, {
                isLoading: true
            });
        }

        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_DEFAULT_SOLUTION_GROUP_UPDATED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: true,
                correlationId
            });
        }

        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_DEFAULT_SOLUTION_GROUP_SAVED: {
            const { correlationId, payload: { defaultSolutionGroup, id } } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }

            let result = JSON.parse(JSON.stringify(get(state, 'data')));
            if (get(result[id], 'defaultSolutionGroup', { default: false })) {
                result[id].defaultSolutionGroup = defaultSolutionGroup;
            }
            return Object.assign({}, state, {
                isLoading: false,
                correlationId,
                data: result
            });
        }

        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_DEFAULT_SOLUTION_GROUP_SAVE_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                correlationId
            });
        }

        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_DEFAULT_SOLUTION_GROUP_UPDATE_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isLoading: false
            });
        }

        case hdiSolutionGroupWebsocketCommands.HDI_SOLUTION_GROUP_DEFAULT_LIST_SEND: {
            const { correlationId, payload: data, error } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isLoading: false, correlationId });
            }


            return Object.assign({}, state, {
                correlationId,
                isLoading: false,
                data
            });
        }
        case hdiSolutionGroupWebsocketCommands.HDI_SOLUTION_GROUP_DEFAULT_LIST_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isLoading: false,
            });
        }
        default:
            return state;
    }
};
