import {put, takeEvery, select} from 'redux-saga/effects';
import {FILE_UPLOAD_STATUS} from '../actions/actionFile';
import {loadFile, uploadRecurrentProblemFile} from '../api/restFile';
import {errorAlert} from '../actions/alertsActions';
import {extractErrorText} from '../actions/actionUtils';
import FileSaver from 'file-saver';
import alertMessages from '../intl/common/alertMessages';
import {hdiFrequentQuestionsWebsocketCommands} from '../constants/hdiFrequentQuestionsWebsocketCommands';

function* handleUploadFrequentQuestionFile(action) {
    try {
        const fileToUpload = yield select(state => state.mainFiles.attachmentsToBeUploaded.get(action.payload.attachment.attachmentId));
        yield uploadRecurrentProblemFile(action.payload.attachment.url, fileToUpload.localFileUrl, fileToUpload.contentType);
        window.URL.revokeObjectURL(fileToUpload.localFileUrl);
        yield put({
            type: FILE_UPLOAD_STATUS,
            payload: {uuid: action.payload.attachment.attachmentId, status: 'DONE'}
        });
    } catch (e) {
        yield put(errorAlert(...extractErrorText(e, alertMessages.FILE_UPLOAD_ERROR)));
    }
}

function* handleDownloadFrequentQuestionFile(action) {
    try {
        const file = yield loadFile(action.payload.attachment.url);
        const fileToDownload = yield select(state => state.mainFiles.attachmentsToBeDownloaded.get(action.payload.attachment.attachmentId));
        FileSaver.saveAs(file.data, fileToDownload.name);
    } catch (e) {
        yield put(errorAlert(...extractErrorText(e, alertMessages.FILE_DOWNLOAD_ERROR)));
    }
}

function* frequentQuestionFileSaga() {
    yield takeEvery(hdiFrequentQuestionsWebsocketCommands.HDI_FREQUENT_QUESTION_ATTACHMENTS_UPLOAD, handleUploadFrequentQuestionFile);
    yield takeEvery(hdiFrequentQuestionsWebsocketCommands.HDI_FREQUENT_QUESTION_ATTACHMENTS_DOWNLOAD, handleDownloadFrequentQuestionFile);
}

export default frequentQuestionFileSaga;
