import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import '../../css/formControl.css';
import validationMessages from '../../intl/common/validationMessages';
import ValidFormattedMessage from './ValidFormattedMessage';

const ErrorDiv = styled.label`
    display: inherit;
`;

const MaybeClickableDiv = styled.div`
    cursor: ${props => props.onClick ? 'pointer' : 'inherit'};
`;

const StyledInput = styled.input`
    color: black;
`;

const StyledSelect = styled.select`
    color: black;
`;

export class FieldWithValidation extends Component {
    render() {
        const {
            input, id, isDisabled, isSmall, type = 'text', style, placeholder, maxLength, handleNameChange, autoFocus,
            setRef = () => {
            }, meta: { touched, error, invalid }
        } = this.props;
        return (
            <div>
                <StyledInput {...input} type={type} placeholder={placeholder} maxLength={maxLength}
                             disabled={isDisabled}
                             className={'form-control ' + (isSmall ? ' form-control-sm' : '') + (touched && invalid ? ' is-invalid' : '')}
                             onKeyUp={(e) => handleNameChange && handleNameChange(e.target.value, id)}
                             autoFocus={autoFocus}
                             style={style}
                             ref={input => setRef(input)}
                />
                {touched && error && <ErrorDiv className="invalid-feedback">
                    <ValidFormattedMessage message={error} intlMessages={validationMessages}/></ErrorDiv>
                }
            </div>
        );
    }
}

FieldWithValidation.propTypes = {
    input: PropTypes.object.isRequired,
    id: PropTypes.any,
    isDisabled: PropTypes.bool,
    isSmall: PropTypes.bool,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    handleNameChange: PropTypes.func,
    autoFocus: PropTypes.bool
};

const SelectFieldWithValidation = props => {
    const {
        input, isDisabled, isSmall, options, noEmpty,
        meta: { touched, error, invalid }
    } = props;
    return (
        <div>
            <StyledSelect {...input}
                          disabled={isDisabled}
                          className={'form-control ' + (isSmall ? ' form-control-sm' : '') + (touched && invalid ? ' is-invalid' : '')}>
                {!noEmpty && <option></option>}
                {options ? options.map((option, index) => <option key={option.value} value={option.value}>
                    {option.label}</option>) : null}
            </StyledSelect>
            {touched && error && <ErrorDiv className="invalid-feedback">
                <ValidFormattedMessage message={error} intlMessages={validationMessages}/></ErrorDiv>}
        </div>
    );
};
export default injectIntl(SelectFieldWithValidation);

SelectFieldWithValidation.propTypes = {
    input: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool,
    isSmall: PropTypes.bool,
    options: PropTypes.array,
    intlMessages: PropTypes.any,
    intl: PropTypes.any,
};

export const ReadOnlyField = props => {
    const { input: { value }, handleFieldClick } = props;
    return (
        <MaybeClickableDiv onClick={handleFieldClick}>
            <span>{Array.isArray(value) ? value.length : value}</span>
        </MaybeClickableDiv>
    );
};

ReadOnlyField.propTypes = {
    input: PropTypes.object.isRequired,
    handleFieldClick: PropTypes.func
};

