import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    clearDealerRightsManagementPageData,
    fetchDealerRightsManagementPageData,
    resetManageUser,
    saveDealerRightsManagementPageData,
    setDealerGroupsChanged,
    setInitialDealerGroupOptions,
    setManagedUser,
    setSelectedDealerGroupOptions
} from '../../../actions/admin/actionAdminRightsManagement';
import SelectUser from './SelectUser';
import Loader from '../../Loader';
import { dealerRoles } from '../../../constants/Utils';
import { clearAlerts } from '../../../actions/alertsActions';
import { handleFetchUsersError } from '../../../actions/actionRole';
import { handleUserSearch } from '../../../utils/userUtils';
import commonMessages from '../../../intl/common/commonMessages';
import PageHeader from '../../common/PageHeader';
import SelectDealerGroups from './SelectDealerGroups';

class DealerRightsManagementPage extends Component {
    componentDidMount() {
        this.props.clearDealerRightsManagementPageData();
        this.props.resetManageUser();
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setDealerGroupsChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleGroupSelectionChange = newOptions => {
        this.setSelectedOptionsChanged(newOptions);
        this.props.setSelectedDealerGroupOptions(newOptions);
    };

    handleAllGroupsClick = () => {
        const selectedGroups = {};

        Object.keys(this.props.dealerData || {}).forEach(
            groupIso => selectedGroups[groupIso] = true
        );

        const selectedOptions = this.setDealerDataAsGroupOptions(selectedGroups);
        this.setSelectedOptionsChanged(selectedOptions);
    };

    handleSaveClick = () => {
        const selectedGroups = {};
        Object.keys(dealerRoles).forEach(
            groupKey => selectedGroups[dealerRoles[groupKey]] = false
        );

        if (this.props.selectedOptions) {
            this.props.selectedOptions.forEach(
                ({ value }) => {
                    selectedGroups[value] = true;
                }
            )
        }

        this.props.saveDealerRightsManagementPageData(this.props.managedUser, this.props.domain, selectedGroups);
    };

    handleCancelClick = () => {
        this.props.setSelectedDealerGroupOptions(cloneDeep(this.props.initialOptions));
        this.props.setDealerGroupsChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearDealerRightsManagementPageData();
        this.props.fetchDealerRightsManagementPageData(user, this.props.domain);
    };

    setDealerDataAsGroupOptions = dealerData => {
        const result = Object.keys(dealerData || {}).map(groupIso => ({ value: groupIso }));
        this.props.setSelectedDealerGroupOptions(cloneDeep(result));
        return result;
    };

    setSelectedOptionsChanged = newOptions => this.props.setDealerGroupsChanged(
        JSON.stringify(
            cloneDeep(this.props.initialOptions)
                .sort((option1, option2) => option1.value.localeCompare(option2.value))
        ) !== JSON.stringify(newOptions.sort((option1, option2) => option1.value.localeCompare(option2.value)))
    );

    render() {
        const { isLoading, managedUser, dealerData, domain } = this.props;
        if (isLoading) {
            return <Loader/>;
        }

        return (
            <div>
                <PageHeader title={
                    <FormattedMessage id="dealer.title.auth.mng"
                                      defaultMessage="Authorization Management - {domain} - Dealer"
                                      values={{
                                          domain: <FormattedMessage {...commonMessages[domain]}/>
                                      }}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {
                    Object.keys(dealerData).length > 0 && managedUser && managedUser.ipn &&
                    <SelectDealerGroups handleGroupSelectionChange={this.handleGroupSelectionChange}
                                        handleAllGroupsClick={this.handleAllGroupsClick}
                                        handleSaveClick={this.handleSaveClick}
                                        handleCancelClick={this.handleCancelClick}/>
                }
            </div>
        );
    }
}

DealerRightsManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    setSelectedDealerGroupOptions: PropTypes.func.isRequired,
    dealerData: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    saveDealerRightsManagementPageData: PropTypes.func.isRequired,
    initialOptions: PropTypes.array.isRequired,
    setDealerGroupsChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearDealerRightsManagementPageData: PropTypes.func.isRequired,
    fetchDealerRightsManagementPageData: PropTypes.func.isRequired,
    setInitialDealerGroupOptions: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired,
    resetManageUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    managedUser: state.adminRightsManagement.managedUser,
    dealerData: state.adminRightsManagement.dealerData,
    initialOptions: state.adminRightsManagement.initialDealerOptions,
    selectedOptions: state.adminRightsManagement.selectedDealerOptions
});

export default connect(mapStateToProps, {
    setManagedUser,
    fetchDealerRightsManagementPageData,
    setSelectedDealerGroupOptions,
    setDealerGroupsChanged,
    setInitialDealerGroupOptions,
    saveDealerRightsManagementPageData,
    clearDealerRightsManagementPageData,
    clearAlerts,
    handleFetchUsersError,
    resetManageUser
})(injectIntl(DealerRightsManagementPage));
