import {
    HDI_FETCH_PREFERENCES_PENDING, HDI_UPDATE_USER_FULLSEARCH_PREFERENCE,
    HDI_UPDATE_USER_LIST_PREFERENCE,
} from '../actions/hdi/actionPreferences';
import { hdiWebsocketCommands } from '../constants/hdiWebsocketCommands';

export const preferences = (state = {
    isLoading: true,
    menuList: [],
    menuFullsearch: [],
}, action) => {
    switch (action.type) {
        case HDI_UPDATE_USER_LIST_PREFERENCE: {
            return Object.assign({}, state, { menuList: action.payload.preferences.hiddenColumns });
        }
        case HDI_UPDATE_USER_FULLSEARCH_PREFERENCE: {
            return Object.assign({}, state, { menuFullsearch: action.payload.preferences.hiddenColumns });
        }
        case hdiWebsocketCommands.HDI_PREFERENCES_SEND: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { ...action.payload.preferences, isLoading: false, correlationId: action.payload.correlationId });
        }
        case hdiWebsocketCommands.HDI_PREFERENCES_SEND_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoading: false, correlationId: action.payload.correlationId });
        }
        case hdiWebsocketCommands.HDI_PREFERENCES_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoading: false, correlationId: action.payload.correlationId });
        }
        case HDI_FETCH_PREFERENCES_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        default:
            return state;
    }
};
