import React from 'react';
import { required, validInput } from './validationCommons';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';


export const InputCheckBox = (props) => {
    const {
        field, isRequired, validations, label, isDisabled
    } = props;
    let newValidations = validations;

    if (!newValidations) {
        newValidations = [validInput];
    } else {
        newValidations.push(validInput);
    }
    if (isRequired) {
        newValidations.push(required);
    }

    const Checkbox = props => {
        const { input, isDisabled, type } = props;
        return (
            <input {...input} type={type} disabled={isDisabled}/>
        );
    };

    return (
        <div>
            <Field name={field}
                   component={Checkbox}
                   type="checkbox"
                   isDisabled={isDisabled}
                   validate={newValidations}
                   onChange={props.onChange}/>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-2 font-weight-bold">
                <FormattedMessage {...label}/>
            </label>}
        </div>
    )
};



