import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import tableMessages from '../../../intl/common/tableMessages';
import { StyledReactTable } from '../../../theme/styledTable'
import TableHeader from './TableHeader';
import buttonMessages from '../../../intl/common/buttonMessages';
import { getTicketOwner } from '../../hdi/ticket/Utils';

const ButtonDiv = styled.div`
  display: table-cell;
`;

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const { defaultSorting = [] } = this.props;
        this.setState({
            sorted: defaultSorting
        });
    }

    hasData = () => !this.props.isLoading && this.props.data && this.props.data.length !== 0;

    render() {
        const {
            intl, isLoading = false, withPagination = true, columns, data, toolButtons = [], handleFetchData,
            handleOnFilteredChange, pages, handlerOnSortChange, allowTooltipsInHeader = false, tableType = false,
            loggedUser, buttonVisibility, editorSG
        } = this.props;
        const decoratedColumns = columns.map(
            column =>
                Object.assign({}, column,
                    {
                        Header: () => <TableHeader id={column.id} message={column.message}
                                                   filterable={column.filterable}
                                                   sorted={this.state.sorted ? this.state.sorted : []}/>,

                        filterMethod: (filter, row) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined ?
                                String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
                                : true;
                        }
                    })
        );

        if (toolButtons.length > 0) {
            decoratedColumns.unshift({
                id: 'tools',
                accessor: 'tools',
                sortable: false,
                filterable: false,
                maxWidth: toolButtons.length * 41,
                Cell: row => {
                    return <React.Fragment>
                        {
                            toolButtons.map((toolButton, index) => {
                                let toolButtonObject = toolButton;
                                if (toolButtonObject instanceof Function) {
                                    toolButtonObject = toolButtonObject(row);
                                }

                                if (tableType === 'TICKET') {
                                    if (loggedUser && buttonVisibility) {
                                        const { creatorIpn, solutionGroup, status } = row.original;
                                        const result = getTicketOwner(loggedUser, {
                                            creatorIpn,
                                            solutionGroup
                                        }, editorSG);
                                        if (!buttonVisibility[result].includes(status)) {
                                            return <div/>;
                                        }
                                    } else {
                                        return <div/>;
                                    }
                                }

                                if (toolButton.path) {
                                    return <Route key={`${row.original.id}route${index}`}
                                                  render={({ history, history: { location: { pathname } } }) => (
                                                      <ButtonDiv key={`${row.original.id}button${index}`}
                                                                 onClick={() => history.push(
                                                                     `${pathname}/${toolButton.path.base}/${row.original[toolButton.path.rowField]}`
                                                                 )}>
                                                          {toolButtonObject.component}
                                                      </ButtonDiv>
                                                  )}/>;
                                } else {
                                    return <ButtonDiv key={`${row.original.id}button${index}`}
                                                      onClick={() => toolButtonObject.handleClick(row.original, toolButtonObject.onClickCallback)}>
                                        {toolButtonObject.component}
                                    </ButtonDiv>;
                                }
                            })
                        }
                    </React.Fragment>;
                }
            });
        }
        return (
            <StyledReactTable
                columns={decoratedColumns}
                manual={handleFetchData !== undefined}
                loading={isLoading}
                data={data}
                pages={pages}
                filterable
                showPagination={withPagination && this.hasData()}
                minRows={this.hasData() ? 1 : 4}
                previousText={<FormattedMessage {...buttonMessages.PREVIOUS}/>}
                nextText={<FormattedMessage {...buttonMessages.NEXT}/>}
                noDataText={<FormattedMessage {...tableMessages.TABLE_NO_DATA}/>}
                pageText={<FormattedMessage {...tableMessages.TABLE_PAGE}/>}
                ofText={<FormattedMessage {...tableMessages.TABLE_OF}/>}
                rowsText={intl.formatMessage(tableMessages.TABLE_ROWS)}
                className="-highlight"
                onFetchData={handleFetchData === undefined ? () => null : handleFetchData}
                onSortedChange={handlerOnSortChange === undefined ? (sorted => this.setState({ sorted })) : handlerOnSortChange}
                onFilteredChange={handleOnFilteredChange === undefined ? () => null : handleOnFilteredChange}
                defaultFiltered={this.props.defaultFiltered === undefined ? [] : this.props.defaultFiltered}
                defaultSorted={this.props.defaultSorting === undefined ? [] : this.props.defaultSorting}
                defaultPageSize={this.props.defaultPageSize === undefined ? 10 : this.props.defaultPageSize}
                getTheadFilterThProps={allowTooltipsInHeader ? () => ({ style: { overflow: 'inherit' } }) : () => ({})}
            />
        );
    }
}

Table.propTypes = {
    intl: PropTypes.any.isRequired,
    isLoading: PropTypes.bool,
    withPagination: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    handleFetchData: PropTypes.func,
    toolButtons: PropTypes.array,
    defaultSorting: PropTypes.array,
    allowTooltipsInHeader: PropTypes.bool,
    manual: PropTypes.bool,
    tableType: PropTypes.string,
    loggedUser: PropTypes.object,
    editorSG: PropTypes.array
};

export default injectIntl(Table);
