import {
    CONNECT_HDI_WEBSOCKET_ERROR,
    CONNECT_HDI_WEBSOCKET_PENDING,
    CONNECT_HDI_WEBSOCKET_SUCCESS
} from '../actions/actionWebsocket';
import { cloneDeep } from 'lodash';
import { modules } from '../constants/Utils';

export const websocket = (state = {
    isConnected: {},
}, action) => {
    switch (action.type) {
        case CONNECT_HDI_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules] = false;
            return Object.assign({}, state, { isConnected });
        }
        case CONNECT_HDI_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules] = true;
            return Object.assign({}, state, { isConnected });
        }
        case CONNECT_HDI_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules] = false;
            return Object.assign({}, state, { isConnected });
        }
        default:
            return state;
    }
};
