import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import formMessages from '../../../../../../intl/admin/welcomeMessages';
import PropTypes from 'prop-types';
import { welcomeConstants } from '../../../../../../constants/form/formWelcomeConstants';
import { change } from 'redux-form';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import countryGroupMessages from '../../../../../../intl/common/countryGroupMessages';
import { countries } from '../../../../../../constants/Utils';
import { fetchWelcomeText } from '../../../../../../actions/admin/actionAdminWelcomeText';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const SelectSection = (props) => {
    const { change } = props;

    const handleChange = (event, value) => {
        change(`${welcomeConstants.FORM_WELCOME_SELECT_SECTION}.${welcomeConstants.FORM_FIELD_WELCOME_LANGUAGE}`, value);
        props.fetchWelcomeText(value);
    };

    return (
        <StyledDiv className="border border-secondary px-4 py-2 bg-white">
            <div className="form-row">
                <div className="col pb-2">
                    <InputSelectFieldWithValidation
                        label={formMessages[welcomeConstants.FORM_LABEL_WELCOME_LANGUAGE]}
                        options={Object.keys(countries).map(item => item)}
                        field={welcomeConstants.FORM_FIELD_WELCOME_LANGUAGE}
                        handleChange={handleChange}
                        intlMessages={countryGroupMessages}
                        isSmall/>
                </div>
            </div>
        </StyledDiv>
    )

};

SelectSection.propTypes = {
    intl: PropTypes.object.isRequired,
    welcomeData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    welcomeData: state.adminWelcome,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
    fetchWelcomeText: (value) => dispatch(fetchWelcomeText(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SelectSection));
