import { hdiEditorWebsocketCommands } from '../../constants/hdiEditorWebsocketCommands';
import { validateEditorCreateEvent, validateEditorUpdateEvent } from '../../api/validation/editor';
import { errorAlert } from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';

export const getEditorSolutionGroups = () => dispatch => {
    return dispatch({
        type: hdiEditorWebsocketCommands.HDI_TICKET_EDITOR_SOLUTION_GROUP_PENDING,
        hdiWebsocket: true,
        command: hdiEditorWebsocketCommands.HDI_TICKET_EDITOR_SOLUTION_GROUP,
    })
};

export const checkEditor = (id) => dispatch => {
    return dispatch({
        type: hdiEditorWebsocketCommands.HDI_EDITOR_ROLE_PENDING,
        hdiWebsocket: true,
        command: hdiEditorWebsocketCommands.HDI_EDITOR_ROLE_GET,
        payload: {
            payload: {
                id
            }
        }
    })
};

export const updateEditor = (data) => dispatch => {
    const { error } = validateEditorUpdateEvent(data);
    if (error) {
        dispatch(errorAlert(alertMessages.EDITOR_UPDATE_SEND_ERROR, [error.toString()]));
        dispatch({ type: hdiEditorWebsocketCommands.EDITOR_SAVE_UPDATE_ERROR });
    } else {
        return dispatch({
            type: hdiEditorWebsocketCommands.HDI_EDITOR_UPDATE_PENDING,
            hdiWebsocket: true,
            command: hdiEditorWebsocketCommands.HDI_EDITOR_UPDATE,
            payload: {
                payload: {
                    ...data
                }
            }
        });
    }
};

export const saveEditor = (data) => dispatch => {
    const { error } = validateEditorCreateEvent(data);
    if (error) {
        dispatch(errorAlert(alertMessages.EDITOR_NEW_SEND_ERROR, [error.toString()]));
        dispatch({ type: hdiEditorWebsocketCommands.EDITOR_SAVE_NEW_ERROR });
    } else {
        return dispatch({
            type: hdiEditorWebsocketCommands.HDI_EDITOR_CREATE_PENDING,
            hdiWebsocket: true,
            command: hdiEditorWebsocketCommands.HDI_EDITOR_CREATE,
            payload: {
                payload: {
                    ...data,
                }
            }
        });
    }
};

export const deleteEditor = (id) => dispatch => {
    return dispatch({
        type: hdiEditorWebsocketCommands.HDI_EDITOR_DELETE_PENDING,
        hdiWebsocket: true,
        command: hdiEditorWebsocketCommands.HDI_EDITOR_DELETE,
        payload: {
            payload: {
                id
            }
        }
    });
};

export const fetchEditor = (id) => dispatch => {
    return dispatch({
        type: hdiEditorWebsocketCommands.HDI_EDITOR_DETAIL_FETCH_PENDING,
        hdiWebsocket: true,
        command: hdiEditorWebsocketCommands.HDI_EDITOR_DETAIL,
        payload: {
            payload: {
                id,
            }
        }
    });
};

export const resetEditor = () => dispatch => {
    return dispatch({
        type: hdiEditorWebsocketCommands.HDI_EDITOR_RESET,
    });
};

export const addDefaultSolvingGroupList = (value) => dispatch => {
    return dispatch({
        type: hdiEditorWebsocketCommands.HDI_ADD_DEFAULT_SOLUTION_GROUP,
        payload: {
            group: value
        }
    })
};
