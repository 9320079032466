import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import CreatableSelect from 'react-select/lib/Creatable';
import { FormattedMessage, injectIntl } from 'react-intl';
import '../../css/formControl.css';
import validationMessages from '../../intl/common/validationMessages';
import ValidFormattedMessage from './ValidFormattedMessage';
import Select from 'react-select';
import get from 'get-value';
import set from 'set-value';

const ErrorDiv = styled.label`
    display: inherit;
`;

const MaybeClickableDiv = styled.div`
    cursor: ${props => props.onClick ? 'pointer' : 'inherit'};
`;

const ClickableInput = styled.input`
    cursor: pointer;
`;

const StyledInput = styled.input`
    color: black;
`;

const StyledSelect = styled.select`
    color: black;
`;

export class FieldWithValidation extends Component {
    render() {
        const {
            input, id, isDisabled, isSmall, type = 'text', style, placeholder, maxLength, handleNameChange, autoFocus,
            setRef = () => {
            }, meta: { touched, error, invalid }
        } = this.props;

        if (!get(input.value)) {
            set(input, 'value', '');
        }

        return (
            <div>
                <StyledInput {...input}
                    type={type}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    disabled={isDisabled}
                    className={'form-control ' + (isSmall ? ' form-control-sm' : '') + (touched && invalid ? ' is-invalid' : '')}
                    onKeyUp={(e) => handleNameChange && handleNameChange(e.target.value, id)}
                    autoFocus={autoFocus}
                    style={style}
                    ref={setRef}
                />
                {touched && error && <ErrorDiv className="invalid-feedback">
                    <ValidFormattedMessage message={error} intlMessages={validationMessages} /></ErrorDiv>
                }
            </div>
        );
    }
}

FieldWithValidation.propTypes = {
    input: PropTypes.object.isRequired,
    id: PropTypes.any,
    isDisabled: PropTypes.bool,
    isSmall: PropTypes.bool,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    handleNameChange: PropTypes.func,
    autoFocus: PropTypes.bool
};

const SelectFieldWithValidation = props => {
    const {
        input, isDisabled, isSmall, options, intlMessages, intl, noEmpty, customOptions,
        meta: { touched, error, invalid }
    } = props;

    let sortedOptions = options ? options.filter(option => option && (typeof option === 'string' || option instanceof String)).map((option, index) => ({
        id: option,
        translation: intlMessages ? (intlMessages[option] ? intl.formatMessage(intlMessages[option]) : option) : option
    })).sort((option1, option2) => option1.translation.localeCompare(option2.translation)) : [];

    if(customOptions) {
        sortedOptions = (customOptions || []).sort((option1, option2) => option1.translation.localeCompare(option2.translation))
    };

    return (
        <div>
            <StyledSelect {...input}
                disabled={isDisabled}
                className={'form-control ' + (isSmall ? ' form-control-sm' : '') + (touched && invalid ? ' is-invalid' : '')}>
                {!noEmpty && <option></option>}
                {sortedOptions.length && sortedOptions.map((option, index) => <option key={index}
                    value={option.id}>{option.translation}</option>)}
                {!sortedOptions.some(option => option.id === input.value) && input.value !== '' && input.value !== undefined && input.value !== null}
                {/*&& <option disabled key={options.length} value="">{`${input.value}(deprecated)`}</option>}*/}
            </StyledSelect>
            {touched && error && <ErrorDiv className="invalid-feedback">
                <ValidFormattedMessage message={error} intlMessages={validationMessages} /></ErrorDiv>}
        </div>
    );
};
export default injectIntl(SelectFieldWithValidation);

SelectFieldWithValidation.propTypes = {
    input: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool,
    isSmall: PropTypes.bool,
    options: PropTypes.array,
    intlMessages: PropTypes.any,
    intl: PropTypes.any,
};

export const ReadOnlyField = props => {
    const { input: { value }, handleFieldClick } = props;
    return (
        <MaybeClickableDiv onClick={handleFieldClick}>
            <span>{Array.isArray(value) ? value.length : value}</span>
        </MaybeClickableDiv>
    );
};

ReadOnlyField.propTypes = {
    input: PropTypes.object.isRequired,
    handleFieldClick: PropTypes.func
};

export const SwitchableField = props => {
    const { input, handleFieldClick, type, meta: { touched, error, invalid } } = props;
    return (
        <div onClick={handleFieldClick} onBlur={handleFieldClick} onFocus={handleFieldClick}>
            <ClickableInput {...input} type={type}
                className={'form-control ' + (touched && invalid ? ' is-invalid' : '')} />
            {touched && error && <ErrorDiv className="invalid-feedback">{validationMessages[error] ?
                <FormattedMessage {...validationMessages[error]} /> : error}</ErrorDiv>}
        </div>
    );
};

SwitchableField.propTypes = {
    input: PropTypes.object.isRequired,
    handleFieldClick: PropTypes.func,
    type: PropTypes.string.isRequired
};

export const SelectField = props => {
    const {
        input, placeholder, isMulti, noOptionsMessage, menuPosition = 'absolute', loadOptions, defaultValue,
        onSelectChange = () => {
        }
    } = props;

    return (
        <AsyncSelect placeholder={placeholder} isMulti={isMulti} noOptionsMessage={noOptionsMessage}
            menuPosition={menuPosition} loadOptions={loadOptions} defaultValue={defaultValue}
            onBlur={() => input.onBlur(input.value)}
            onChange={(options, modification) => {
                input.onChange(options, modification);
                onSelectChange(options, modification);
            }} onDragStart={input.onDragStart} onDrop={input.onDrop}
            onFocus={input.onFocus} />
    );
};

SelectField.propTypes = {
    input: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    isMulti: PropTypes.bool,
    noOptionsMessage: PropTypes.func.isRequired,
    menuPosition: PropTypes.string,
    loadOptions: PropTypes.func.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onSelectChange: PropTypes.func
};


export const RenderSelectInput = ({ input, options, name, id, isMulti, isSearchable, isDisabled, isClearable, closeMenuOnSelect = true }) => {
    return (
        <Select
            {...input}
            id={id}
            name={name}
            options={options}
            onChange={value => input.onChange(value)}
            onBlur={() => input.onBlur(input.value)}
            isMulti={isMulti}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            isClearable={isClearable}
            closeMenuOnSelect={closeMenuOnSelect}
        />
    )
};

export const RenderMultipleInput = ({ input, inputValue, placeholder, name, id,
    isMulti, isDisabled, isClearable, handleKeyDown, handleInputChange,
    handleChange, meta: { touched, error, invalid } }) => {

    const components = {
        DropdownIndicator: null,
    };

    return (
        <>
            <CreatableSelect
                {...input}
                id={id}
                name={name}
                components={components}
                isClearable={isClearable}
                isMulti={isMulti}
                isDisabled={isDisabled}
                menuIsOpen={false}
                onChange={handleChange}
                onBlur={() => {
                }}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                inputValue={inputValue}
            />
            {touched && error && <ErrorDiv className="invalid-feedback">{validationMessages[error] ?
                <FormattedMessage {...validationMessages[error]} /> : error}</ErrorDiv>}
        </>
    );
};


