import {
    hdiTicketWebsocketCommands
} from '../../constants/hdiTicketWebsocketCommands';
import {extractErrorText} from '../actionUtils';
import {uploadHdiAttachment} from '../../api/restHdiAttachments';
import {errorAlert} from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';

export const HDI_ATTACHMENTS_UPLOADING = 'HDI_ATTACHMENTS_UPLOADING';
export const HDI_ATTACHMENTS_UPLOADING_ERROR = 'HDI_ATTACHMENTS_UPLOADING_ERROR';

export const startUploadAttachments = () => dispatch => {
    return dispatch({type: HDI_ATTACHMENTS_UPLOADING});
};

export const downloadAttachment = (id, ticketId, name) => dispatch => {
    return dispatch({
        type: hdiTicketWebsocketCommands.HDI_DOWNLOAD_PRESIGNED_URL,
        hdiWebsocket: true,
        command: hdiTicketWebsocketCommands.HDI_DOWNLOAD_PRESIGNED_URL,
        payload: {
            payload: {
                attachment: {
                    id,
                    ticketId,
                    name
                }
            }
        }
    });
};

export const uploadAttachments = (attachments = [], correlationId) => dispatch => {
    const promises = [];
    const savedAttachments = [];
    attachments.forEach(attachment => {
        promises.push(
            uploadHdiAttachment(attachment.url, attachment.localUrl, attachment.name, attachment.contentType).then(
                () => {
                    window.URL.revokeObjectURL(attachment.localUrl);
                    savedAttachments.push(attachment);
                }, error => {
                    dispatch({type: HDI_ATTACHMENTS_UPLOADING_ERROR});
                    dispatch(errorAlert(...extractErrorText(error, alertMessages.FILE_UPLOAD_ERROR)));
                }
            )
        )
    });
    return Promise.all(promises).then(() => {
            if (savedAttachments.length > 0) {
                dispatch({
                    type: hdiTicketWebsocketCommands.HDI_CREATE_ATTACHMENTS,
                    hdiWebsocket: true,
                    command: hdiTicketWebsocketCommands.HDI_CREATE_ATTACHMENTS,
                    payload: {
                        payload: {
                            attachments: savedAttachments,
                            correlationId,
                            id: savedAttachments[0].ticketId,
                        }
                    }
                })
            } else {
                dispatch({
                    type: HDI_ATTACHMENTS_UPLOADING_ERROR,
                })
            }
        }
    )
};
