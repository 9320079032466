import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import { Redirect, withRouter } from 'react-router-dom';
import { clearAlerts } from '../../../../actions/alertsActions';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import editorMessages from '../../../../intl/admin/editorMessages';
import ListEditorTable from './ListEditorTable';
import { ADMINISTRATION_EDITOR_PATH } from '../../../../routes/paths';
import ConfirmationDialog from '../../../common/modal/ConfirmationDialog';
import { deleteEditor } from '../../../../actions/admin/actionAdminEditor';
import Select from 'react-select';
import { transformSGtoSelectOptions } from '../utils';
import { editorConstants } from '../../../../constants/form/formEditorConstants';
import {isVisibleForCountry} from '../../template/utils';
import solutionGroupMessages from '../../../../intl/admin/solutionGroupMessages';

class ListEditorPage extends Component {
    constructor(props) {
        super(props);
        const { intl, userDetail } = props;
        this.state = {
            ratchet: false,
            editorId: '',
            columnLibrary: [
                {
                    id: 'name',
                    accessor: 'name',
                    message: editorMessages.TABLE_NAME,
                },
                {
                    id: 'suspend',
                    accessor: row =>
                        <>
                            <label>
                                {intl.formatMessage(editorMessages[editorConstants.FORM_LABEL_EDITOR_SUSPEND])}:&nbsp;
                                <input
                                    type="checkbox"
                                    checked={row.isSuspend}
                                    disabled/>
                            </label><br/>
                            <label>
                                {intl.formatMessage(editorMessages[editorConstants.FORM_LABEL_EDITOR_ON_HOLD])}:&nbsp;
                                <input
                                    type="checkbox"
                                    checked={row.isOnHold}
                                    disabled/>
                            </label>
                        </>
                    ,
                    message: editorMessages.TABLE_STATE,
                    sortable: false,
                    filterable: false,
                },
                {
                    id: 'solutionGroups',
                    accessor: row =>
                        <Select
                            value={transformSGtoSelectOptions(row.solutionGroups)}
                            isMulti
                            isDisabled
                            isSearchable={false}
                            isClearable={false}
                        />,
                    message: isVisibleForCountry(userDetail.group) ? editorMessages.TABLE_SOLUTION_GROUP_UK : editorMessages.TABLE_SOLUTION_GROUP,
                    sortable: false,
                    filterable: false,
                },
            ],
            isOpenDeleteDialog: false,
            editorIdToBeDeleted: null,
        };
    }

    componentDidMount() {
        //this.props.clearAlerts();
    }

    handleEditClick = ({ id }) => {
        this.setState({ ratchet: true, editorId: id });
    };

    handleDeleteClick = editor => {
        this.setState({
            isOpenDeleteDialog: true,
            editorIdToBeDeleted: editor.id
        });
    };

    closeDeleteModal = e => {
        e.preventDefault();
        this.setState({
            isOpenDeleteDialog: false,
            editorIdToBeDeleted: null
        });
    };

    saveDeleteModal = e => {
        e.preventDefault();
        this.props.deleteEditor(this.state.editorIdToBeDeleted);
        this.setState({
            isOpenDeleteDialog: false,
            editorIdToBeDeleted: null
        });
    };

    render() {
        const { filter } = this.props;
        const { columnLibrary, editorId } = this.state;

        if (this.state.ratchet) {
            return <Redirect to={`${ADMINISTRATION_EDITOR_PATH}/edit/${editorId}`}/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...editorMessages.TITLE}/>}/>
                <ListEditorTable
                    columns={columnLibrary}
                    handleEditClick={this.handleEditClick} handleDeleteClick={this.handleDeleteClick} filter={filter}/>
                <ConfirmationDialog show={this.state.isOpenDeleteDialog} closeModal={this.closeDeleteModal}
                                    saveModal={this.saveDeleteModal} actionIdentifier="delete the editor"/>
            </div>
        );
    }
}

ListEditorPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    deleteEditor: PropTypes.func.isRequired,
    userDetail: PropTypes.object,
};

const mapStateToProps = state => ({
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    deleteEditor
})(injectIntl(ListEditorPage))), ['HDI_ADMIN']);
