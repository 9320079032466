import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import AsyncSelect from 'react-select/lib/Async';
import authManagementMessages from '../../../../../../../intl/admin/authManagementMessages';
import { DEFAULT_DEBOUNCE_TIMEOUT } from '../../../../../../../constants/validationConstants';

const SelectUser = props => {
    const { isDisabled, style, handleUserSelection, handleUserSearch, intl: { formatMessage } } = props;
    return (
        <AsyncSelect style={style} placeholder={formatMessage(authManagementMessages.PLACEHOLDER_SELECT_USER)}
                     noOptionsMessage={() => formatMessage(authManagementMessages.SELECT_USER_SEARCH)}
                     loadOptions={debounce(DEFAULT_DEBOUNCE_TIMEOUT, handleUserSearch)}
                     onChange={handleUserSelection}
                     isDisabled={isDisabled}
        />
    );
};

SelectUser.propTypes = {
    handleUserSelection: PropTypes.func.isRequired,
    handleUserSearch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)((injectIntl(SelectUser)))
