import { hdiTemplateWebsocketCommands } from '../../constants/hdiTemplateWebsocketCommands';

export const fetchTemplateListPageData = (page, pageSize, sorted, filtered) => dispatch => {
    dispatch({
        type: hdiTemplateWebsocketCommands.HDI_TEMPLATE_LIST_PENDING,
        hdiWebsocket: true,
        command: hdiTemplateWebsocketCommands.HDI_TEMPLATE_LIST_RECEIVE,
        payload: { page: page, pageSize: pageSize, sorted: sorted, filtered: filtered }
    });
};

export const fetchTemplateShortList = () => dispatch => {
    dispatch({
        type: hdiTemplateWebsocketCommands.HDI_TEMPLATE_SHORT_LIST_PENDING,
        hdiWebsocket: true,
        command: hdiTemplateWebsocketCommands.HDI_TEMPLATE_SHORT_LIST,
    });
};

export const fetchTemplateShortListAll = () => dispatch => {
    dispatch({
        type: hdiTemplateWebsocketCommands.HDI_TEMPLATE_SHORT_LIST_ALL_PENDING,
        hdiWebsocket: true,
        command: hdiTemplateWebsocketCommands.HDI_TEMPLATE_SHORT_LIST_ALL,
    });
};
