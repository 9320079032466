import { defineMessages } from 'react-intl';
import {
    HDI_TICKET_INCIDENT_TYPE_INCIDENT,
    HDI_TICKET_INCIDENT_TYPE_SERVICE_REQUEST
} from '../../constants/form/incidentTypeConstants';

export const messages = defineMessages({
    [HDI_TICKET_INCIDENT_TYPE_INCIDENT]: {
        id: 'ticket.type.incident',
        defaultMessage: 'Incident'
    },
    [HDI_TICKET_INCIDENT_TYPE_SERVICE_REQUEST]: {
        id: 'ticket.type.serviceRequest',
        defaultMessage: 'Service request'
    }
});

export default messages;
