export const hdiWelcomeWebsocketCommands = {
    HDI_WELCOME_DETAIL_GET: 'getMainMessage',
    HDI_WELCOME_UPDATE: 'setMainMessage',

    HDI_MAIN_MESSAGES_PENDING: 'HDI_MAIN_MESSAGES_PENDING',
    HDI_MAIN_MESSAGES_UPDATE_PENDING: 'HDI_MAIN_MESSAGES_UPDATE_PENDING',

    HDI_MAIN_MESSAGES_SEND: 'HDI_MAIN_MESSAGES_SEND',
    HDI_MAIN_MESSAGES_SEND_ERROR: 'HDI_MAIN_MESSAGES_SEND_ERROR',

    HDI_WELCOME_RESET: 'HDI_WELCOME_RESET',
};
