export const hdiTemplateWebsocketCommands = {
    //Templates
    HDI_UPDATE_TEMPLATE: 'updateTemplate',
    HDI_DELETE_TEMPLATE: 'deleteTemplate',
    HDI_CREATE_TEMPLATE: 'createTemplate',
    HDI_GET_TEMPLATE: 'getTemplate',

    HDI_TEMPLATE_DETAIL_PENDING: 'HDI_TEMPLATE_DETAIL_PENDING',
    HDI_TEMPLATE_DETAIL_SEND: 'HDI_TEMPLATE_DETAIL_SEND',
    HDI_TEMPLATE_DETAIL_SEND_ERROR: 'HDI_TEMPLATE_DETAIL_SEND_ERROR',

    HDI_TEMPLATE_SEND: 'HDI_TEMPLATE_SEND',
    HDI_TEMPLATE_SEND_ERROR: 'HDI_TEMPLATE_SEND_ERROR',


    HDI_TEMPLATE_CREATE_PENDING: 'HDI_TEMPLATE_CREATE_PENDING',
    HDI_TEMPLATE_CREATED: 'HDI_TEMPLATE_CREATED',
    HDI_TEMPLATE_CREATE_ERROR: 'HDI_TEMPLATE_CREATE_ERROR',

    HDI_TEMPLATE_UPDATE_PENDING: 'HDI_TEMPLATE_UPDATE_PENDING',
    HDI_TEMPLATE_UPDATED: 'HDI_TEMPLATE_UPDATED',
    HDI_TEMPLATE_UPDATE_ERROR: 'HDI_TEMPLATE_UPDATE_ERROR',

    HDI_TEMPLATE_DELETE_PENDING: 'HDI_TEMPLATE_DELETE_PENDING',
    HDI_TEMPLATE_DELETED: 'HDI_TEMPLATE_DELETED',
    HDI_TEMPLATE_DELETE_ERROR: 'HDI_TEMPLATE_DELETE_ERROR',
    HDI_TEMPLATE_DELETE_FATAL: 'HDI_TEMPLATE_DELETE_FATAL',

    HDI_TEMPLATE_RESET: 'HDI_TEMPLATE_RESET',

    HDI_TEMPLATE_LIST_RECEIVE: 'getTemplateList',

    HDI_TEMPLATE_LIST_PENDING: 'HDI_TEMPLATE_LIST_PENDING',
    HDI_TEMPLATE_LIST_SEND: 'HDI_TEMPLATE_LIST_SEND',
    HDI_TEMPLATE_LIST_SEND_ERROR: 'HDI_TEMPLATE_LIST_SEND_ERROR',

    //Get Short List Solution Group
    HDI_TEMPLATE_SHORT_LIST: 'getTemplateShortList',
    HDI_TEMPLATE_SHORT_LIST_PENDING: 'HDI_TEMPLATE_SHORT_LIST_PENDING',
    HDI_TEMPLATE_SHORT_LIST_SEND: 'HDI_TEMPLATE_SHORT_LIST_SEND',
    HDI_TEMPLATE_SHORT_LIST_SEND_ERROR: 'HDI_TEMPLATE_SHORT_LIST_SEND_ERROR',

    //Get Short List all otions Solution Group
    HDI_TEMPLATE_SHORT_LIST_ALL: 'getTemplateShortListAll',
    HDI_TEMPLATE_SHORT_LIST_ALL_PENDING: 'HDI_TEMPLATE_SHORT_LIST_ALL_PENDING',
    HDI_TEMPLATE_SHORT_LIST_ALL_SEND: 'HDI_TEMPLATE_SHORT_LIST_ALL_SEND',
    HDI_TEMPLATE_SHORT_LIST_ALL_SEND_ERROR: 'HDI_TEMPLATE_SHORT_LIST_ALL_SEND_ERROR',
};
