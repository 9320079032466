import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import formMessages from '../../../../../../../intl/admin/editorMessages';

const ErrorSection = (props) => {
    return (
        <>
            <hr/>
            <div className="border border-secondary border-danger px-4 py-2 bg-white">
                <div className="mb-sm-2">
                    <div className="col">
                        <p className="text-danger font-weight-bold text-center">
                            <FormattedMessage {...formMessages.ERROR_EDITOR_ALREADY_EXIST} />
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
};

export default injectIntl(ErrorSection);
