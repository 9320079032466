import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { InputFieldWithValidation } from '../../../../../../common/InputFieldWithValidation';
import { getFormInitialValues } from 'redux-form';
import formMessages from '../../../../../../../intl/hdi/formMessages';
import { isDealerAccount } from '../../../../Utils';
import { ticketConstants } from '../../../../../../../constants/form/formTicketConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const InfoSection = (props) => {
    const {
        initialValues
    } = props;

    const { ticketSection } = initialValues;

    return (
        <>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-6 pb-2">
                        <InputFieldWithValidation
                            label={formMessages[ticketConstants.FORM_LABEL_NAME]}
                            field={ticketConstants.FORM_FIELD_NAME}
                            isDisabled
                            isSmall/>
                    </div>
                    <div className="col-6 pb-2">
                        <InputFieldWithValidation
                            label={formMessages[ticketConstants.FORM_LABEL_CREATOR_IPN]}
                            field={ticketConstants.FORM_FIELD_CREATOR_IPN}
                            isDisabled
                            isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-6 pb-2">
                        <InputFieldWithValidation
                            label={formMessages[ticketConstants.FORM_LABEL_EMAIL]}
                            field={ticketConstants.FORM_FIELD_EMAIL}
                            isDisabled
                            isSmall/>
                    </div>
                    <div className="col-6 pb-2">
                        <InputFieldWithValidation
                            label={formMessages[ticketConstants.FORM_LABEL_TELEPHONE_NO]}
                            field={ticketConstants.FORM_FIELD_TELEPHONE_NO}
                            isDisabled
                            isSmall/>
                    </div>
                </div>
                {isDealerAccount(ticketSection) &&
                <div className="form-row">
                    <div className="col-6 pb-2">
                        <InputFieldWithValidation
                            label={formMessages[ticketConstants.FORM_LABEL_DEALER_NAME]}
                            field={ticketConstants.FORM_FIELD_DEALER_NAME}
                            isDisabled
                            isSmall/>
                    </div>
                    <div className="col-6 pb-2">
                        <InputFieldWithValidation
                            label={formMessages[ticketConstants.FORM_LABEL_DEALER_NO]}
                            field={ticketConstants.FORM_FIELD_DEALER_NO}
                            isDisabled
                            isSmall/>
                    </div>
                </div>
                }
            </StyledDiv>
        </>
    )
};

const mapStateToProps = (state, props) => ({
    roles: state.profile.userDetail.roles,
    initialValues: getFormInitialValues(props.form)(state),
});

export default connect(mapStateToProps, {})(InfoSection);
