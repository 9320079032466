import Joi from 'joi';
import { getSolutionGroupSchema } from './solutionGroup';

export function validateNewTemplate(templateData) {
    return Joi.validate(
        templateData,
        Joi.object().required().keys({
            template: getCreateTemplateFormat().required(),
            solutionGroup: getSolutionGroupSchema().required(),
            dealer: Joi.array().required(),
            subsidiary: Joi.array().required()
        }))
}

export function validateUpdateTemplate(templateData) {
    return Joi.validate(
        templateData,
        Joi.object().required().keys({
            id: Joi.string().required(),
            template: getUpdateTemplateFormat().required(),
            solutionGroup: getSolutionGroupSchema(),
            dealer: Joi.array(),
            subsidiary: Joi.array()
        }))
}

export function validateFetchTemplate(templateData) {
    return Joi.validate(
        templateData,
        Joi.object().required().keys({
            template: getTemplateFormat().required(),
            solutionGroup: getSolutionGroupSchema(),
            dealer: Joi.array(),
            subsidiary: Joi.array()
        }))
}

function getUpdateTemplateFormat() {
    return Joi.object().keys({
        id: Joi.string().required(),
        description: Joi.string(),
        copy: Joi.string().allow([null, '']).required(),
        subject: Joi.string()
    });
}

function getCreateTemplateFormat() {
    return Joi.object().keys({
        description: Joi.string().required(),
        copy: Joi.string().allow([null, '']).required(),
        subject: Joi.string().required()
    });
}

function getTemplateFormat() {
    return Joi.object().required().keys({
        id: Joi.string().required(),
        description: Joi.string().required(),
        copy: Joi.string().allow([null, '']).required(),
        subject: Joi.string().required()
    });
}

