//import { diff } from 'deep-object-diff';

export const transformCountryToGroup = (value, isNew, dealer, subsidiary) => {
    if (!value) {
        return {};
    }
    const result = {};
    if (isNew) {
        Object.keys(value).filter(key => value[key] === true).forEach(key =>
            result[key] = { subsidiary: false, dealer: false }
        );
    } else {
        Object.keys(value).filter(key => value[key] === true).forEach(key => {
            result[key] = {
                subsidiary: subsidiary.includes(key),
                dealer: dealer.includes(key)
            }
        })
    }
    return result;
};

export const initLoadedTemplate = (templateData) => {
    const { ...rest } = templateData.template;
    const { solutionGroup, dealer, subsidiary } = templateData;

    return {
        solutionGroupSection: {
            solutionGroup: solutionGroup.id,
        },
        countrySection: {
            country: transformCountryToGroup(solutionGroup.country, false, dealer, subsidiary),
        },
        descriptionSection: {
            ...rest
        }
    };
};

export const initNewTemplate = (userData, templateData) => {
    return {
        solutionGroupSection: {
            solutionGroup: templateData.solutionGroup.id,
        },
        countrySection: {
            country: transformCountryToGroup(templateData.solutionGroup.country, true)
        },
        descriptionSection: {
            subject: '',
            copy: '',
            description: ``,
        },
    };
};

export const transformNewTemplateForSave = (values, templateData) => {
    const data = {};
    data.template = {
        ...values.descriptionSection,
    };
    data.solutionGroup = {
        id: templateData.solutionGroup.solutionGroup.id,
        shortName: templateData.solutionGroup.solutionGroup.shortName
    };
    const dealer = Object.keys(values.countrySection.country)
        .filter(item => values.countrySection.country[item].dealer)
        .map(item => {
            return item
        });
    const subsidiary = Object.keys(values.countrySection.country)
        .filter(item => values.countrySection.country[item].subsidiary)
        .map(item => {
            return item
        });
    data.dealer = dealer;
    data.subsidiary = subsidiary;
    return data;
};

export const transformUpdateTemplateForSave = (values, templateData) => {
    const { oldValues, newValues } = values;
    //const difference = diff(oldValues, newValues);

    const data = {};
    const id = oldValues.descriptionSection.id;
    data.id = id;

    data.template = {
        ...newValues.descriptionSection,
        id
    };

    data.solutionGroup = {
        id: templateData.solutionGroup.id,
        shortName: templateData.solutionGroup.shortName || templateData.solutionGroup.solutionGroup.shortName
    };

    const dealer = Object.keys(newValues.countrySection.country)
        .filter(item => newValues.countrySection.country[item].dealer)
        .map(item => {
            return item
        });
    const subsidiary = Object.keys(newValues.countrySection.country)
        .filter(item => newValues.countrySection.country[item].subsidiary)
        .map(item => {
            return item
        });
    data.dealer = Array.isArray(dealer) ? dealer : [];
    data.subsidiary = Array.isArray(subsidiary) ? subsidiary : [];


    /* data.template = {
         ...get(difference, 'descriptionSection'),
         id
     };

     if (get(difference, 'solutionGroupSection', { default: false })) {
         data.solutionGroup = {
             ...get(difference, 'solutionGroupSection.solutionGroup'),
         };
     }
     if (get(difference, 'countrySection', { default: false })) {
         const dealer = Object.keys(newValues.countrySection.country)
             .filter(item => newValues.countrySection.country[item].dealer)
             .map(item => {
                 return item
             });
         const subsidiary = Object.keys(newValues.countrySection.country)
             .filter(item => newValues.countrySection.country[item].subsidiary)
             .map(item => {
                 return item
             });
         data.dealer = dealer;
         data.subsidiary = subsidiary;
     }*/
    return data;
};

export function isVisibleForCountry(userCountry) {
    if(userCountry === "UK" || userCountry === "CY" || userCountry === "IE" || userCountry === "MT"){
        return true
    } else {
        return false
    }
}
