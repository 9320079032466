const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const asyncValidate = (values, dispatch, props, currentFieldName) => {
    return sleep(1000).then(() => { // simulate server latency
        /*console.log('asyncValidate username');
        console.log(props);
        const search = values[solutionGroupConstants.FORM_SOLUTION_GROUP_INFO_SECTION][solutionGroupConstants.FORM_FIELD_SG_NAME];
        if (['john', 'paul', 'george', 'ringo'].includes(search)) {
            throw {
                [solutionGroupConstants.FORM_SOLUTION_GROUP_INFO_SECTION]: {
                    [solutionGroupConstants.FORM_FIELD_SG_NAME]: 'Name already exists'
                }
            };
        }*/
    });
};

export default asyncValidate
