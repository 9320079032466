import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {debounce} from 'throttle-debounce';
import AsyncSelect from 'react-select/lib/Async';
import authManagementMessages from '../../../intl/admin/authManagementMessages';
import {mapUserToSelectionOption} from '../../../utils/userUtils';
import {DEFAULT_DEBOUNCE_TIMEOUT} from "../../../constants/validationConstants";

const Label = styled.label`
  font-weight: bold;
  padding: 6px 0;
`;


const SelectUser = props => {
    const {initialValues, handleUserSelection, handleUserSearch, intl: {formatMessage}} = props;
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-1">
                    <Label>
                        <FormattedMessage {...authManagementMessages.SELECT_USER}/>
                    </Label>
                </div>
                <div className="col-sm-8">
                    <AsyncSelect placeholder={formatMessage(authManagementMessages.PLACEHOLDER_SELECT_USER)}
                                 noOptionsMessage={() => formatMessage(authManagementMessages.SELECT_USER_SEARCH)}
                                 loadOptions={debounce(DEFAULT_DEBOUNCE_TIMEOUT, handleUserSearch)}
                                 defaultValue={
                                     initialValues && Object.getOwnPropertyNames(initialValues).length > 0 ?
                                         mapUserToSelectionOption(initialValues) : {}
                                 }
                                 onChange={handleUserSelection}/>
                </div>
            </div>
        </div>
    );
};

SelectUser.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleUserSelection: PropTypes.func.isRequired,
    handleUserSearch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    initialValues: state.adminRightsManagement.managedUser
});

export default connect(mapStateToProps)((injectIntl(SelectUser)))
