import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { change, formValueSelector, getFormInitialValues } from 'redux-form';
import formMessages from '../../../../../../../intl/admin/templateMessages';
import formCountryMessages from '../../../../../../../intl/common/countryGroupMessages';
import { InputCheckBox } from '../../../../../../common/InputCheckbox';
import { templateConstants } from '../../../../../../../constants/form/formTemplateConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const Label = styled.label`
  font-weight: bold;
`;

const CountrySection = (props) => {
    const { readOnly, selectCountries } = props;

    return (
        <>
            <hr/>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    {selectCountries && Object.keys(selectCountries).map((key, index) => {
                        return (
                            <div key={index} className="col-auto m-2 p-2 bg-white">
                                <Label className="">
                                    <FormattedMessage {...formCountryMessages[key]}/>
                                </Label>
                                <InputCheckBox
                                    label={formMessages[templateConstants.FORM_LABEL_TEMPLATE_VISIBLE_DEALER]}
                                    field={`${templateConstants.FORM_FIELD_TEMPLATE_COUNTRY}.${key}.dealer`}
                                    isDisabled={readOnly}
                                    isSmall/>
                                <InputCheckBox
                                    label={formMessages[templateConstants.FORM_LABEL_TEMPLATE_VISIBLE_SUBS]}
                                    field={`${templateConstants.FORM_FIELD_TEMPLATE_COUNTRY}.${key}.subsidiary`}
                                    isDisabled={readOnly}
                                    isSmall/>
                            </div>
                        )
                    })
                    }
                </div>
            </StyledDiv>
        </>
    )
};

CountrySection.propTypes = {
    intl: PropTypes.object.isRequired,
    templateData: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => ({
    templateData: state.adminTemplate,
    initialValues: getFormInitialValues(props.form)(state),
    selectCountries: formValueSelector(props.form)(state, `${templateConstants.FORM_TEMPLATE_COUNTRY_SECTION}.${templateConstants.FORM_FIELD_TEMPLATE_COUNTRY}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CountrySection));
