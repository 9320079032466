import { hdiWelcomeWebsocketCommands } from '../../constants/hdiWelcomeWebsocketCommands';

export const updateWelcomeText = (data) => dispatch => {
    return dispatch({
        type: hdiWelcomeWebsocketCommands.HDI_MAIN_MESSAGES_UPDATE_PENDING,
        hdiWebsocket: true,
        command: hdiWelcomeWebsocketCommands.HDI_WELCOME_UPDATE,
        payload: {
            mainMessage: {
                ...data
            }
        }
    });
};

export const fetchWelcomeText = (group) => dispatch => {
    if (!group) {
        return dispatch({
            type: hdiWelcomeWebsocketCommands.HDI_WELCOME_RESET,
        });
    }
    return dispatch({
        type: hdiWelcomeWebsocketCommands.HDI_MAIN_MESSAGES_PENDING,
        hdiWebsocket: true,
        command: hdiWelcomeWebsocketCommands.HDI_WELCOME_DETAIL_GET,
        payload: {
            group
        }
    });
};

export const resetWelcome = () => dispatch => {
    return dispatch({
        type: hdiWelcomeWebsocketCommands.HDI_WELCOME_RESET,
    });
};
