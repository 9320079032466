import { hdiTicketWebsocketCommands } from '../../constants/hdiTicketWebsocketCommands';


export const fetchListPageData = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_LIST_PENDING,
        hdiWebsocket: true,
        command: hdiTicketWebsocketCommands.HDI_LIST_RECEIVE,
        payload: { pageSize: pageSize, sorted: sorted, cursor, isNextFromPivot, filtered: filtered }
    });
};

export const setClientFilter = (client, isAdded) => dispatch => {
    dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_LIST_FILTER_SET_CLIENT,
        payload: {
            client,
            isAdded
        }
    })
};

export const setSolutionGroupFilter = (solutionGroup, isAdded) => dispatch => {
    dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_LIST_FILTER_SET_SG,
        payload: {
            solutionGroup,
            isAdded
        }
    })
};

export const setFullSearchFilter = (text, isAdded) => dispatch => {
    if (!text && text !== '') {
        dispatch({type: hdiTicketWebsocketCommands.HDI_TICKET_LIST_RESET});
    } else {
        dispatch({
            type: hdiTicketWebsocketCommands.HDI_TICKET_LIST_FILTER_SET_FULLTEXT,
            payload: {
                text,
                isAdded
            }
        })
    }
};

export const setFilteringVariable = (filtering) => dispatch => {
    dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_LIST_SET_FILTERING,
        payload: {
            filtering
        }
    })
};

export const setDefaultTableProperties = ({ listTicketDefaultPageSize, listTicketDefaultSort, listTicketDefaultFilters, listTicketFSDefaultPageSize, listTicketFSDefaultSort, listTicketFSDefaultFilters }) => dispatch => {
    dispatch({
        type: hdiTicketWebsocketCommands.HDI_TICKET_LIST_SET_DEFAULT_PROPERTIES,
        payload: {
            listTicketDefaultPageSize,
            listTicketDefaultSort,
            listTicketDefaultFilters,
            listTicketFSDefaultPageSize,
            listTicketFSDefaultSort,
            listTicketFSDefaultFilters
        }
    })
};
