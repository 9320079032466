import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    DATE_FROM: {
        id: 'hdi.export.dateFrom',
        defaultMessage: 'Date From'
    },
    DATE_TO: {
        id: 'hdi.export.dateTo',
        defaultMessage: 'Date To'
    },
    COUNTRY: {
        id: 'hdi.export.country',
        defaultMessage: 'Country'
    },
    SOLUTION_GROUP: {
        id: 'hdi.export.solutionGroup',
        defaultMessage: 'Solution Group'
    },
    SOLUTION_GROUP_UK: {
        id: 'hdi.export.solutionGroupUK',
        defaultMessage: 'Department'
    },
    INCIDENT_TYPE: {
        id: 'hdi.export.incidentType',
        defaultMessage: 'Incident Type'
    },
    TYPE: {
        id: 'hdi.export.type',
        defaultMessage: 'Type'
    },
    XLS: {
        id: 'hdi.export.xls',
        defaultMessage: 'XLS'
    },
    CSV: {
        id: 'hdi.export.csv',
        defaultMessage: 'CSV'
    },
    EXPORT_STATUS: {
        id: 'hdi.export.exportStatus',
        defaultMessage: 'Export status:'
    },
    LAST_EXPORT: {
        id: 'hdi.export.lastExport',
        defaultMessage: 'Last export:'
    },
    EXPORT_CHARSET: {
        id: 'hdi.export.exportCharSet',
        defaultMessage: 'Export charset'
    }
});

export default messages;
