import get from 'get-value';
import { adminRoles, countries } from '../constants/Utils';

function hasAtLeastOneRoleSet(roles, allowedValues) {
    return Object.keys(roles)
        .filter(role => roles[role] === true && allowedValues.includes(role))
        .length > 0;
};

export const moduleRoles = {
    isPowerUser(roles) {
        return get(roles, `powerUser`, { default: false });
    },
    isAdmin(roles) {
        return hasAtLeastOneRoleSet(
            get(roles, `admin.groupPermission`, { default: {} }),
            Object.values(adminRoles)
        );
    },
    isEditor(roles) {
        return get(roles, `editor`, { default: false });
    },
    isAllowedCountry(group) {
        return Object.keys(countries).find(item => item === group);
    }
};
