import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearAlerts, infoAlert } from '../../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../../Loader';
import {
    FORM_BUTTON_CLOSE,
    FORM_BUTTON_EDIT,
    FORM_BUTTON_REOPEN,
    HDI_TICKET_FORM,
} from '../../../../constants/formConstants';

import TicketForm from './forms/TicketForm';
import { Redirect, withRouter } from 'react-router-dom';
import { fetchTicket, resetTicket, updateTicket, sendSatisfactionCheck } from '../../../../actions/hdi/actionTicket';
import PropTypes from 'prop-types';
import {
    getViewType,
    initLoadedTicket,
    setUserRights,
    transformUpdateTicketForSave,
    isVisibleForCountry, getTicketOwner, canShowSatisfactionPopUp
} from '../Utils';
import { HDI_TICKET_NEW_PATH } from '../../../../routes/paths';
import { formatTicketNumber } from '../../../../utils/utils';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import formMessages from '../../../../intl/hdi/formMessages';
import { ticketConstants } from '../../../../constants/form/formTicketConstants';
import alertMessages from '../../../../intl/common/alertMessages';
import buttonMessages from '../../../../intl/common/buttonMessages';

class ViewTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToNew: false,
            redirectToEdit: false,
            ticketSubmitted: false,
            hasToBeUpdated: false,
            storedTicketData: {},
            newInitData: {},
        };
    }

    componentDidMount() {
        //this.props.clearAlerts();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchTicket(this.props.match.params.ticketUuid, this.props.userDetail.ipn);
        }
    }

    componentWillUnmount() {
        this.props.resetTicket();
    }

    handleSubmit = (values) => {
        const { submitBy } = values;

        switch (submitBy) {
            case FORM_BUTTON_REOPEN:
            case FORM_BUTTON_CLOSE: {
                this.setState({
                    ticketSubmitted: true,
                    storedTicketData: transformUpdateTicketForSave(values)
                });
                break;
            }
            case FORM_BUTTON_EDIT: {
                this.setState({
                    ticketSubmitted: true,
                    redirectToEdit: true
                });
                break;
            }
            default: {

            }
        }
    };

    render() {
        const {
            ticketData,
            loggedUser,
            userDetail,
        } = this.props;

        let formType = false;
        if (get(ticketData, 'ticket.status', { default: false })) {
            formType = HDI_TICKET_FORM;
        }

        if (this.state.redirectToNew && this.state.ticketSubmitted) {
            if (this.state.hasToBeUpdated) {
                this.props.updateTicket(this.state.storedTicketData);
            }
            return <Redirect to={{
                pathname: HDI_TICKET_NEW_PATH,
                initData: this.state.newInitData,
            }}/>;
        } else if (this.state.ticketSubmitted && this.state.redirectToEdit) {
            return <Redirect to={{
                pathname: `/hdi/edit/${ticketData.ticket.id}`,
            }}/>;
        } else if (this.state.ticketSubmitted) {
            this.props.updateTicket(this.state.storedTicketData);
            this.setState({ ticketSubmitted: false, storedTicketData: {}, hasToBeUpdated: false });
        }

        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !formType
        ) {
            return <Loader/>;
        }
        const initializationValue = initLoadedTicket(ticketData);
        if(canShowSatisfactionPopUp(ticketData, userDetail)) {
            this.props.infoAlert(
                alertMessages.SATISFACTION_CHECK,
                [
                    {
                        buttonLabel: buttonMessages.YES,
                        handleClick: () => {
                            this.props.sendSatisfactionCheck(get(ticketData, 'ticket.id'));
                            this.props.clearAlerts();
                        }
                    },
                    {
                        buttonLabel: buttonMessages.NO
                    }
                ],
                {},
                'solutionSatisfaction'
            );
        }
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <>
                        {isVisibleForCountry(userDetail.group) ? <FormattedMessage {...formMessages.TITLE_REQUEST}/> : <FormattedMessage {...formMessages.TITLE}/>}{' - '}
                        {formatTicketNumber(ticketData.ticket.country, ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}
                        <small className="text-uppercase font-italic pull-right">
                            <FormattedMessage {...formMessages[ticketConstants.FORM_LABEL_STATUS]}/>: {initializationValue.ticketSection.status}
                        </small>
                    </>}
                />
                <div className="container-fluid">
                    <div className="col-12">
                        {formType === HDI_TICKET_FORM &&
                        <TicketForm form={formType}
                                    initialValues={initializationValue}
                                    userRights={setUserRights('VIEW', loggedUser, ticketData.ticket, ticketData.editor)}
                                    enableReinitialize={true}
                                    reqFields={[]}
                                    viewType={getViewType(loggedUser)}
                                    userCountry={userDetail.group}
                                    readOnly
                                    handleSubmit={this.handleSubmit}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail,
    ticketData: state.ticket,
    userDetail: state.profile.userDetail,
});

ViewTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
    fetchTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    updateTicket: PropTypes.func.isRequired,
    resetTicket: PropTypes.func.isRequired,
    sendSatisfactionCheck: PropTypes.func.isRequired,
    infoAlert: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchTicket,
    clearAlerts,
    resetTicket,
    updateTicket,
    infoAlert,
    sendSatisfactionCheck
})(injectIntl(ViewTicketPage))), ['HDI_USER', 'HDI_EDITOR', 'HDI_ADMIN']);

