import React from 'react';
import styled from 'styled-components';
import { FormSection, reduxForm } from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import RenderSyncErrors from '../../../common/RenderSyncErrors';
import { scrollContentWrapperToTop } from '../../../../utils/utils';
import formMessages from '../../../../intl/admin/welcomeMessages';
import { intlWelcomeFieldMapping } from '../../../../constants/fieldLabelMapping';
import DescriptionSection from './sections/description/DescriptionSection';
import SelectSection from './sections/select/SelectSection';
import { welcomeConstants } from '../../../../constants/form/formWelcomeConstants';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const WelcomeForm = (props) => {
    const { form, reqFields, readOnly, showForm, handleSubmit, handleSelectLanguage } = props;

    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form}
                              intlMessages={formMessages}
                              mapping={intlWelcomeFieldMapping}/>
            <FormSection name={welcomeConstants.FORM_WELCOME_SELECT_SECTION}>
                <SelectSection form={form}
                               handleSelectLanguage={handleSelectLanguage}
                               reqFields={reqFields}
                               readOnly={readOnly}/>
            </FormSection>
            {showForm && <>
                <FormSection name={welcomeConstants.FORM_WELCOME_DESCRIPTION_SECTION}>
                    <DescriptionSection form={form}
                                        reqFields={reqFields}
                                        readOnly={readOnly}/>
                </FormSection>
                < SubmitButtons form={form}
                                reqFields={reqFields}
                                readOnly={readOnly}
                                onSubmit={handleSubmit}
                                onSubmitFail={scrollContentWrapperToTop}/>
            </>}
        </StyledDiv>
    )
};

export default reduxForm({})(WelcomeForm);
