export const hdiSolutionGroupWebsocketCommands = {
    //Solution Groups
    //Get List Solution Group
    HDI_TICKET_SOLUTION_GROUP_LIST: 'getSolutionGroupList',
    HDI_TICKET_SOLUTION_GROUP_LIST_FETCH_PENDING: 'HDI_TICKET_SOLUTION_GROUP_LIST_FETCH_PENDING',
    HDI_TICKET_SOLUTION_GROUP_LIST_SEND: 'HDI_TICKET_SOLUTION_GROUP_LIST_SEND',
    HDI_TICKET_SOLUTION_GROUP_LIST_SEND_ERROR: 'HDI_TICKET_SOLUTION_GROUP_LIST_SEND_ERROR',
    //Get Short List Solution Group
    HDI_TICKET_SOLUTION_GROUP_SHORT_LIST: 'getSolutionGroupShortList',
    HDI_TICKET_SOLUTION_GROUP_SHORT_LIST_PENDING: 'HDI_TICKET_SOLUTION_GROUP_SHORT_LIST_PENDING',
    HDI_TICKET_SOLUTION_GROUP_SHORT_LIST_SEND: 'HDI_TICKET_SOLUTION_GROUP_SHORT_LIST_SEND',
    HDI_TICKET_SOLUTION_GROUP_SHORT_LIST_SEND_ERROR: 'HDI_TICKET_SOLUTION_GROUP_SHORT_LIST_SEND_ERROR',
    //Get Detail Solution Group for country list
    HDI_TICKET_SOLUTION_GROUP_COUNTRY: 'getSolutionGroupCountry',
    HDI_TICKET_SOLUTION_GROUP_COUNTRY_PENDING: 'HDI_TICKET_SOLUTION_GROUP_COUNTRY_PENDING',
    HDI_TICKET_SOLUTION_GROUP_COUNTRY_SEND: 'HDI_TICKET_SOLUTION_GROUP_COUNTRY_SEND',
    HDI_TICKET_SOLUTION_GROUP_COUNTRY_SEND_ERROR: 'HDI_TICKET_SOLUTION_GROUP_COUNTRY_SEND_ERROR',
    //Get Detail Solution Group
    HDI_TICKET_SOLUTION_GROUP_DETAIL: 'getSolutionGroup',
    HDI_TICKET_SOLUTION_GROUP_DETAIL_FETCH_PENDING: 'HDI_TICKET_SOLUTION_GROUP_DETAIL_FETCH_PENDING',
    HDI_TICKET_SOLUTION_GROUP_DETAIL_SEND: 'HDI_TICKET_SOLUTION_GROUP_DETAIL_SEND',
    HDI_TICKET_SOLUTION_GROUP_DETAIL_SEND_ERROR: 'HDI_TICKET_SOLUTION_GROUP_DETAIL_SEND_ERROR',
    //Create Solution Group
    HDI_TICKET_SOLUTION_GROUP_CREATE: 'createSolutionGroup',
    HDI_TICKET_SOLUTION_GROUP_CREATE_PENDING: 'HDI_TICKET_SOLUTION_GROUP_CREATE_PENDING',
    HDI_TICKET_SOLUTION_GROUP_CREATED: 'HDI_TICKET_SOLUTION_GROUP_CREATED',
    HDI_TICKET_SOLUTION_GROUP_CREATE_ERROR: 'HDI_TICKET_SOLUTION_GROUP_CREATE_ERROR',
    //Update Solution Group
    HDI_TICKET_SOLUTION_GROUP_UPDATE: 'updateSolutionGroup',
    HDI_TICKET_SOLUTION_GROUP_UPDATE_PENDING: 'HDI_TICKET_SOLUTION_GROUP_UPDATE_PENDING',
    HDI_TICKET_SOLUTION_GROUP_UPDATED: 'HDI_TICKET_SOLUTION_GROUP_UPDATED',
    HDI_TICKET_SOLUTION_GROUP_UPDATE_ERROR: 'HDI_TICKET_SOLUTION_GROUP_UPDATE_ERROR',

    //Update Solution Group - NAME, USERS and RESET
    HDI_TICKET_SOLUTION_GROUP_RESET: 'HDI_TICKET_SOLUTION_GROUP_RESET',

    HDI_TICKET_SOLUTION_GROUP_SEND: 'HDI_TICKET_SOLUTION_GROUP_SEND',
    HDI_TICKET_SOLUTION_GROUP_SEND_ERROR: 'HDI_TICKET_SOLUTION_GROUP_CREATE_SEND_ERROR',

    HDI_ADD_CUSTOM_EMAIL: 'HDI_ADD_CUSTOM_EMAIL',

    //Get Default Solution group
    HDI_SOLUTION_GROUP_DEFAULT_LIST: 'getDefaultSolutionGroups',
    HDI_SOLUTION_GROUP_DEFAULT_LIST_PENDING: 'HDI_SOLUTION_GROUP_DEFAULT_LIST_PENDING',
    HDI_SOLUTION_GROUP_DEFAULT_LIST_SEND: 'HDI_SOLUTION_GROUP_DEFAULT_LIST_SEND',
    HDI_SOLUTION_GROUP_DEFAULT_LIST_SEND_ERROR: 'HDI_SOLUTION_GROUP_DEFAULT_LIST_SEND_ERROR',
    //Update Default Solution group
    //Update Solution Group
    HDI_SOLUTION_GROUP_DEFAULT_UPDATE: 'updateDefaultSolutionGroup',
    HDI_SOLUTION_GROUP_DEFAULT_UPDATE_PENDING: 'HDI_SOLUTION_GROUP_DEFAULT_UPDATE_PENDING',
    HDI_TICKET_DEFAULT_SOLUTION_GROUP_UPDATED: 'HDI_TICKET_DEFAULT_SOLUTION_GROUP_UPDATED',
    HDI_TICKET_DEFAULT_SOLUTION_GROUP_UPDATE_ERROR: 'HDI_TICKET_DEFAULT_SOLUTION_GROUP_UPDATE_ERROR',
    HDI_TICKET_DEFAULT_SOLUTION_GROUP_SAVED: 'HDI_TICKET_DEFAULT_SOLUTION_GROUP_SAVED',
    HDI_TICKET_DEFAULT_SOLUTION_GROUP_SAVE_ERROR: 'HDI_TICKET_DEFAULT_SOLUTION_GROUP_SAVE_ERROR',

    HDI_SOLUTION_GROUP_DEFAULT_RESET: 'HDI_SOLUTION_GROUP_DEFAULT_RESET',

    SOLUTION_GROUP_SAVE_NEW_ERROR: 'SOLUTION_GROUP_SAVE_NEW_ERROR',
    SOLUTION_GROUP_SAVE_UPDATE_ERROR: 'SOLUTION_GROUP_SAVE_UPDATE_ERROR',

    SOLUTION_GROUP_SET_AVAILABLE_NAME: 'SOLUTION_GROUP_SET_AVAILABLE_NAME'
};
