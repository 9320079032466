export const ticketConstants = {
    //Filter
    FORM_FIELD_SEARCH_CLIENT: 'searchClient',
    FORM_FIELD_SEARCH_SOLUTION_GROUP: 'searchSolutionGroup',
    FORM_FIELD_SEARCH_FULL: 'searchFull',

    FORM_LABEL_SEARCH_CLIENT: 'SEARCH_CLIENT',
    FORM_LABEL_SEARCH_SOLUTION_GROUP: 'SEARCH_SOLUTION_GROUP',
    FORM_LABEL_SEARCH_SOLUTION_GROUP_UK: 'SEARCH_SOLUTION_GROUP_UK',
    FORM_LABEL_SEARCH_FULL: 'SEARCH_FULL',

    //Sections
    FORM_INFO_SECTION: 'infoSection',
    FORM_TICKET_SECTION: 'ticketSection',
    FORM_TEMPLATE_SECTION: 'templateSection',
    FORM_NOTES_SECTION: 'notesSection',
    FORM_INTERNAL_NOTES_SECTION: 'internalNotesSection',
    FORM_MESSAGES_SECTION: 'messagesSection',
    FORM_ATTACHMENTS_SECTION: 'attachmentsSection',

    FORM_LABEL_TICKET_SECTION: 'TICKET_SECTION',
    FORM_LABEL_NOTE_SECTION: 'NOTE_SECTION',

    FORM_LABEL_MESSAGES_SECTION: 'MESSAGES_SECTION',


    //Template section
    //Fields
    FORM_FIELD_TEMPLATE_TYPE: 'type',
    //Labels
    FORM_LABEL_TEMPLATE_TYPE: 'TYPE',
    FORM_LABEL_TEMPLATE_TYPE_REQUEST: 'TYPE_REQUEST',
    FORM_LABEL_TEMPLATE_TOPIC: 'TOPIC',
    FORM_LABEL_FREQUENT_QUESTION: 'FREQUENT_QUESTION',

    //Information section
    FORM_FIELD_SUBSTITUTION: 'substitution',
    FORM_LABEL_SUBSTITUTION: 'SUBSTITUTION_LABEL',
    //Fields
    FORM_FIELD_NAME: 'userName',
    FORM_FIELD_CREATOR_IPN: 'creatorIpn',
    FORM_FIELD_EMAIL: 'email',
    FORM_FIELD_TELEPHONE_NO: 'telNo',
    FORM_FIELD_DEALER_NAME: 'dealerName',
    FORM_FIELD_DEALER_NO: 'dealerNumber',
    FORM_FIELD_GROUP: 'group',
    //Labels
    FORM_LABEL_NAME: 'NAME',
    FORM_LABEL_CREATOR_IPN: 'CREATOR_IPN',
    FORM_LABEL_EMAIL: 'EMAIL',
    FORM_LABEL_TELEPHONE_NO: 'TELEPHONE_NUMBER',
    FORM_LABEL_DEALER_NO: 'DEALER_NUMBER',
    FORM_LABEL_DEALER_NAME: 'DEALER_NAME',
    //Ticket section
    //Fields
    FORM_FIELD_TICKET_NUMBER: 'ticketNumber',
    FORM_FIELD_SFX: 'sfxNumber',
    FORM_FIELD_PFX: 'pfxNumber',
    FORM_FIELD_COUNTRY: 'country',
    FORM_FIELD_CREATION_DATE: 'creationDate',
    FORM_FIELD_STATUS: 'status',
    FORM_FIELD_DESCRIPTION: 'description',
    FORM_FIELD_COPY_EMAIL: 'copyEmail',
    FORM_FIELD_SUBJECT: 'subject',
    FORM_FIELD_TEMPLATE_ID: 'templateId',
    FORM_FIELD_ALIAS: 'alias',
    FORM_FIELD_COMMENT: 'comment',
    FORM_FIELD_TYPE_INCIDENT: 'incidentType',
    //Labels
    FORM_LABEL_TICKET_NUMBER: 'TICKET_NUMBER',
    FORM_LABEL_CREATION_DATE: 'CREATION_DATE',
    FORM_LABEL_STATUS: 'STATUS',
    FORM_LABEL_DESCRIPTION: 'DESCRIPTION',
    FORM_LABEL_COPY_EMAIL: 'COPY_EMAIL',
    FORM_LABEL_SUBJECT: 'SUBJECT',
    FORM_LABEL_SUBJECT_UK: 'SUBJECT_UK',
    FORM_LABEL_ALIAS: 'ALIAS',
    FORM_LABEL_COMMENT: 'COMMENT',
    FORM_LABEL_TYPE_INCIDENT: 'TYPE_INCIDENT',
    FORM_LABEL_TYPE_REQUEST: 'TYPE_REQUEST',

    //Notes section
    //Fields
    FORM_FIELD_NOTE: 'text',
    FORM_FIELD_PRIORITY: 'priority',
    FORM_FIELD_SOLUTION_GROUP: 'solutionGroup',

    //Labels
    FORM_LABEL_NOTES_HISTORY: 'NOTES_HISTORY',
    FORM_LABEL_NOTE: 'NOTE',
    FORM_LABEL_PRIORITY: 'PRIORITY',
    FORM_LABEL_SOLUTION_GROUP: 'SOLUTION_GROUP',
    FORM_LABEL_SOLUTION_GROUP_UK: 'SOLUTION_GROUP_UK',
    //Attachments section
    //Fields
    FORM_FIELD_NEW_ATTACHMENTS: 'newAttachments',
    FORM_FIELD_ATTACHMENTS: 'attachments',
    FORM_FIELD_ATTACHMENT_INPUT: 'attachmentInput',
    //Labels

    //Internal Notes section
    //Fields
    FORM_FIELD_INTERNAL_NOTE: 'text',

    //Labels
    FORM_LABEL_INTERNAL_NOTES_HISTORY: 'INTERNAL_NOTES_HISTORY',
    FORM_LABEL_INTERNAL_NOTE: 'INTERNAL_NOTE',
};
