import v4 from 'uuid/v4';
import get from 'get-value';
import { diff } from 'deep-object-diff';
import { moduleRoles } from '../../../utils/roles';
import {
    HDI_TICKET_STATUS_CLOSED,
    HDI_TICKET_STATUS_NEW,
    HDI_TICKET_STATUS_ON_HOLD,
    HDI_TICKET_STATUS_OPENED,
    HDI_TICKET_STATUS_REOPENED,
    HDI_TICKET_STATUS_SUSPENDED
} from '../../../constants/form/statusConstants';
import {
    HDI_VIEW_TYPE_ADMIN,
    HDI_VIEW_TYPE_EDITOR,
    HDI_VIEW_TYPE_USER
} from '../../../constants/form/viewTypeConstants';
import { HDI_TICKET_PRIORITY_P3 } from '../../../constants/form/priorityConstants';
import { noRights, ticketRights } from '../../../constants/ticketRights';
import { getAllFormStates } from '../../../constants/Utils';
import { HDI_SG_LEVEL_L2 } from '../../../constants/form/levelConstants';

export function isDealerAccount(data) {
    return !!(data.dealerNumber && data.dealerName);
}

export function isVisibleForCountry(userCountry) {
    return userCountry === 'UK' || userCountry === 'CY' || userCountry === 'IE' || userCountry === 'MT'
}

export function isEditorView(viewType) {
    return viewType === HDI_VIEW_TYPE_EDITOR;
}

export const listSolutionGroups = (sgData, sg) => {
    const { shortListData = [] } = sgData;
    if (shortListData.length > 0)
        return shortListData.map(item => ({ label: item.shortName, value: item.id }));
    if (sg)
        return [{ label: sg.shortName, value: sg.id }];
    return [];
};

export const listEditorSolutionGroups = (sgData, sg) => {
    const { editorSolutionGroups = [] } = sgData;
    if (editorSolutionGroups.length > 0)
        return editorSolutionGroups.map(item => ({ label: item.shortName, value: item.id }));
    if (sg)
        return [{ label: sg.shortName, value: sg.id }];
    return [];
};

export const getTicketOwner = ({ roles, ipn, dealerName, dealerNumber }, {
    creatorIpn,
    solutionGroup,
    dealerName: dName,
    dealerNumber: dNumber
}, editorSG = []) => {
    if (moduleRoles.isEditor(roles)) {
        if (editorSG.find(item => item.id === solutionGroup.id))
            return 'EDITOR';
        return 'NO_RIGHTS';
    } else if (ipn === creatorIpn || (dealerNumber && dealerNumber === dNumber)) {
        return 'OWNER';
    } else {
        return 'NO_RIGHTS';
    }
};

export const getUserRole = roles => {
    return moduleRoles.isEditor(roles) ? 'EDITOR' : 'USER';
};

export const setUserRights = (type, loggedUser, ticket, editor) => {
    const userType = getTicketOwner(loggedUser, ticket, editor);
    if (userType === 'NO_RIGHTS')
        return noRights;
    else {
        if (type === 'VIEW') {
            if (userType === 'OWNER' && ticket.status === HDI_TICKET_STATUS_NEW)
                return noRights;
            return ticketRights[type][userType];
        }
        if (type === 'EDIT') {
            return ticketRights[type][userType][ticket.status];
        }
        return noRights;
    }
};

export function getViewType({ roles }) {
    if (moduleRoles.isEditor(roles)) {
        return HDI_VIEW_TYPE_EDITOR;
    }
    if (moduleRoles.isAdmin(roles)) {
        return HDI_VIEW_TYPE_ADMIN;
    }
    return HDI_VIEW_TYPE_USER;
}

export function getAvailableTicketStatus(status, roles, solutionGroup, isReopened) {
    const isEditor = moduleRoles.isEditor(roles);
    const editor = get(roles, 'editor');
    const level = get(solutionGroup, 'level');
    //Status check
    if (!getAllFormStates.includes(status)) {
        return [];
    }

    if (status === HDI_TICKET_STATUS_NEW) {
        return [
            HDI_TICKET_STATUS_OPENED,
            HDI_TICKET_STATUS_CLOSED
        ];
    }

    if (status === HDI_TICKET_STATUS_CLOSED) {
        return [
            HDI_TICKET_STATUS_REOPENED
        ];
    }
    if (status === HDI_TICKET_STATUS_OPENED || status === HDI_TICKET_STATUS_REOPENED) {
        const array = [
            HDI_TICKET_STATUS_CLOSED,
            isReopened ? HDI_TICKET_STATUS_REOPENED : HDI_TICKET_STATUS_OPENED
        ];
        if (isEditor) {
            if (editor.isSuspend && level === HDI_SG_LEVEL_L2) {
                array.push(HDI_TICKET_STATUS_SUSPENDED);
            }
            if (editor.isOnHold) {
                array.push(HDI_TICKET_STATUS_ON_HOLD);
            }
        }
        return array;
    }
    if (status === HDI_TICKET_STATUS_ON_HOLD) {
        const array = [
            isReopened ? HDI_TICKET_STATUS_REOPENED : HDI_TICKET_STATUS_OPENED
        ];
        if (isEditor) {
            if (editor.isSuspend && level === HDI_SG_LEVEL_L2) {
                array.push(HDI_TICKET_STATUS_SUSPENDED);
            }
            if (editor.isOnHold) {
                array.push(HDI_TICKET_STATUS_ON_HOLD);
            }
        }
        return array;
    }
    if (status === HDI_TICKET_STATUS_SUSPENDED) {
        const array = [];
        if (isEditor) {
            array.push(isReopened ? HDI_TICKET_STATUS_REOPENED : HDI_TICKET_STATUS_OPENED);
            if (editor.isSuspend && level === HDI_SG_LEVEL_L2) {
                array.push(HDI_TICKET_STATUS_SUSPENDED);
            }
            if (editor.isOnHold) {
                array.push(HDI_TICKET_STATUS_ON_HOLD);
            }
        }
        return array;
    }

    return [];
}

const setNoteStatus = (status) => {
    switch (status) {
        case HDI_TICKET_STATUS_NEW: {
            return HDI_TICKET_STATUS_OPENED;
        }
        case HDI_TICKET_STATUS_CLOSED:
        case HDI_TICKET_STATUS_OPENED:
        case HDI_TICKET_STATUS_REOPENED:
        case HDI_TICKET_STATUS_ON_HOLD:
        case HDI_TICKET_STATUS_SUSPENDED: {
            return '';
        }
        default: {
            return status;
        }
    }
}


export const initLoadedTicket = (ticketData) => {
    const { ...rest } = ticketData.ticket;
    const { copyEmail } = ticketData.ticket;
    const { priority, status, solutionGroup, templateId } = ticketData.ticket;

    return {
        ticketSection: {
            ...rest,
            copyEmail: Array.isArray(copyEmail) ? copyEmail.map(item => ({
                label: item,
                value: item
            })) : (copyEmail && copyEmail.length > 0) ? [{ value: copyEmail, label: copyEmail }] : [],
        },
        notesSection: {
            priority,
            status: setNoteStatus(status),
            solutionGroup: solutionGroup.id,
            subject: templateId,
        },
        followups: ticketData.followups,
        internalNotes: ticketData.internalNotes,
        attachmentsSection: { attachments: ticketData.attachments }
    };
};


export const initNewTicket = () => {
    return {
        ticketSection: {
            description: '',
            priority: HDI_TICKET_PRIORITY_P3,
            alias: '',
            copyEmail: [],
            comment: '',
            massIncident: false,
            templateId: ''
        }
    };
};

export const transformNewTicketForSave = (values) => {
    const data = {};

    const {
        templateId = null,
        description,
        priority,
        alias = null,
        subject,
        comment = null,
        massIncident = false,
        copyEmail = [],
        substitution = null
    } = values.ticketSection;

    data.ticket = {
        description,
        priority,
        alias,
        subject,
        comment,
        massIncident,
        copyEmail: copyEmail.map(item => item.value),
        substitution,
        templateId
    };
    if (get(values, 'attachmentsSection.newAttachments', { default: false }) && values.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        values.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                ...attachment,
                id: v4(),
            })
        });
    }
    return data;
};

export const transformUpdateTicketForSave = (values, sgList, subjectList) => {
    const { oldValues, newValues } = values;
    const difference = diff(oldValues, newValues);
    const data = {};
    data.ticket = {
        ...get(difference, 'ticketSection'),
        id: oldValues.ticketSection.id,
    };

    if (get(difference.ticketSection, 'copyEmail')) {
        data.ticket.copyEmail = get(newValues.ticketSection, 'copyEmail').map(item => item.value);
    }

    if (get(newValues, 'notesSection.subject')) {
        data.ticket.templateId = get(newValues, 'notesSection.subject')
    }

    const note = get(newValues, 'notesSection');
    if (note) {
        const solutionGroup = findSolutionGroup(get(newValues, 'notesSection.solutionGroup'), sgList, newValues);
        data.followups = {
            ...note,
            solutionGroup
        };
        if(get(newValues, 'notesSection.subject')){
            data.followups.subject = findSubject(get(newValues, 'notesSection.subject'), subjectList)
        }
    }
    if (get(difference, 'attachmentsSection.newAttachments', { default: false }) && difference.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        difference.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                id: v4(),
                ...attachment,
            })
        });
    }
    if (get(difference, 'internalNotesSection.text')) {
        data.internalNote = {
            uuid: v4(),
            newInternalNote: get(difference, 'internalNotesSection.text'),
        };
    }

    return data;
};

export const transformClosedTicketForReopen = (values, text) => {
    const data = {};
    const lastFollowUp = values.followups.reduce( (a, cur) => Date.parse(a.created) > Date.parse(cur.created) ? a : cur);
    data.ticket = {
        id: values.ticket.id,
        templateId: values.ticket.templateId
    };
    data.followups = {
        text,
        solutionGroup: {
            id: values.ticket.solutionGroup.id,
            shortName: values.ticket.solutionGroup.shortName
        },
        priority: values.ticket.priority,
        status: HDI_TICKET_STATUS_REOPENED,
        subject: {
            id: lastFollowUp.subject.id,
            shortName: lastFollowUp.subject.shortName
        }

    };

    return data;
};

export const findSolutionGroup = (id, list, formValues) => {
    if (!list || !list.length) {
        // dealer does not have access to the solutionGroup List cause he is never modifying it => can use current value
        const shortName = get(formValues, 'ticketSection.solutionGroup.shortName');
        const id = get(formValues, 'ticketSection.solutionGroup.id');
        return {shortName, id};
    }
    return list.find(item => item.id === id);
};
export const findSubject = (id, list) => {
    const selectedSubject = list.find(item => item.id === id);
    return {id: get(selectedSubject, "id"), shortName: get(selectedSubject, "subject")}
};

export const canShowSatisfactionPopUp = (ticketData, userDetail) => {
    const lastFollowUp = get(ticketData, 'followups', []).reduce( (a, cur) => Date.parse(a.created) > Date.parse(cur.created) ? a : cur, {});

    return (get(ticketData, 'ticket.status') === HDI_TICKET_STATUS_CLOSED &&
        getTicketOwner(userDetail, ticketData.ticket) === 'OWNER' &&
        get(userDetail, 'group') === 'UK' &&
        !get(ticketData, 'ticket.ticketSatisfactionChecked') &&
        lastFollowUp.creatorIpn !== userDetail.ipn
    );
};
