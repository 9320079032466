import React from 'react';
import { getFormInitialValues, getFormValues, reduxForm, isDirty } from 'redux-form'
import {FormattedMessage, injectIntl} from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { BUTTON_LABEL_SAVE, FORM_BUTTON_EDIT, FORM_BUTTON_SAVE, } from '../../../../../../constants/formConstants';
import { PrimaryButton } from '../../../../../common/Button';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import { StyledSpan } from '../../../../../common/StyledComponents';
import get from 'get-value';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import { diff } from 'deep-object-diff';

const SubmitButtons = (props) => {
    const { handleSubmit, submitting, initialValues, readOnly, userRights, isDirty, intl } = props;

    const renderEditButton = () => {
        return (
            <PrimaryButton onClick={
                handleSubmit(values =>
                    props.onSubmit({
                        submitBy: FORM_BUTTON_EDIT,
                    }))
            } className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.EDIT}/>
            </PrimaryButton>
        )
    };

    const renderBackButton = () => {
        return (
            <PrimaryButton onClick={(e) => {
                e.preventDefault();
                props.history.goBack();
            }} className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.BACK}/>
            </PrimaryButton>
        )
    };

    const renderBackButtonWithConfirmation = () => {
        return (
            <>
                <PrimaryButton onClick={(e) => {
                    if (window.confirm(intl.formatMessage(buttonMessages.BACK_CONFIRMATION))) {
                        e.preventDefault();
                        props.history.goBack();
                    } else {
                        e.preventDefault();
                    }
                }} className="btn mr-2 float-right">
                    <FormattedMessage {...buttonMessages.BACK}/>
                </PrimaryButton>
            </>
        )
    };

    const renderButton = (button, message, changeTo) => {
        if (button === FORM_BUTTON_SAVE) {
            const { formValues, initialValues } = props;
            const different = diff(initialValues, formValues);
            if (Object.entries(different).length === 0 && different.constructor === Object) {
                return;
            }
        }
        return (
            <PrimaryButton onClick={
                handleSubmit(values =>
                    props.onSubmit({
                        newValues: { ...values, ticketSection: { ...values.ticketSection, status: changeTo } },
                        oldValues: initialValues,
                        submitBy: button,
                    }))
            } className="btn mr-2 float-right" disabled={submitting}>
                <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
            </PrimaryButton>
        )
    };
    if (readOnly) {
        return (
            <div className="d-flex justify-content-end mt-3">
                <form className="form-group">
                    {renderBackButton()}
                    {userRights.canEdit && renderEditButton()}
                </form>
            </div>
        )
    }
    return (
        <div className="d-flex justify-content-between mt-3">
            <div><StyledSpan>*</StyledSpan> is mandatory</div>
            <form className="form-group">
                {get(initialValues, 'ticketSection.sfxNumber') && (isDirty ? renderBackButtonWithConfirmation() : renderEditButton())}
                {userRights.canSave && renderButton(FORM_BUTTON_SAVE, BUTTON_LABEL_SAVE, get(initialValues, 'ticketSection.status'))}
            </form>
        </div>
    )
};

SubmitButtons.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    readOnly: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    userRights: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
};

const mapStateToProps = (state, initialProps) => ({
    formValues: getFormValues(initialProps.form)(state),
    roles: state.profile.userDetail.roles,
    initialValues: getFormInitialValues(initialProps.form)(state),
    isDirty: isDirty(initialProps.form)(state)
});


export default reduxForm({})(withRouter(connect(mapStateToProps, {})(injectIntl(SubmitButtons))));
