import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {BoldPrimaryButton, PrimaryButton, SecondaryButton} from '../../common/Button';
import Select from 'react-select';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import groupMessages from '../../../intl/common/groupMessages';
import authManagementMessages from '../../../intl/admin/authManagementMessages';
import buttonMessages from "../../../intl/common/buttonMessages";

const Label = styled.label`
    font-weight: bold;
`;

const SelectAdminGroups = props => {
    const {
        adminData, intl: {formatMessage}, selectedOptions, groupsChanged, handleGroupSelectionChange,
        handleAllGroupsClick, handleSaveClick, handleCancelClick
    } = props;
    const options = Object.keys(adminData || {})
        .map(group => ({
            value: group,
            label: groupMessages[group] ? formatMessage(groupMessages[group]) : group
        }));
    const formattedSelectedOptions = (selectedOptions || []).map(group => ({
        value: group.value,
        label: groupMessages[group.value] ? formatMessage(groupMessages[group.value]) : group.value
    })).sort((option1, option2) => option1.value.localeCompare(option2.value));

    return (
        <div className="container-fluid">
            <div className="row mb-sm-2">
                <div className="col-sm-12">
                    <Label>
                        <FormattedMessage {...authManagementMessages.ADMIN_SELECT_ROLES}/>
                    </Label>
                    <PrimaryButton type="button" className="btn ml-sm-3" onClick={handleAllGroupsClick}>
                        <FormattedMessage {...buttonMessages.ALL}/>
                    </PrimaryButton>
                </div>
            </div>
            <div className="row mb-sm-3">
                <div className="col">
                    <Select isMulti isSearchable
                            value={formattedSelectedOptions}
                            options={options}
                            placeholder={formatMessage(authManagementMessages.ADMIN_PLACEHOLDER_SELECT_ROLES)}
                            onChange={handleGroupSelectionChange}/>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <SecondaryButton type="button"
                                     disabled={!groupsChanged}
                                     className="btn float-right"
                                     onClick={handleCancelClick}>
                        <FormattedMessage {...buttonMessages.CANCEL}/>
                    </SecondaryButton>
                </div>
                <div className="col-sm-6">
                    <BoldPrimaryButton type="button" className="btn float-left"
                                       disabled={!groupsChanged}
                                       onClick={handleSaveClick}>
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                </div>
            </div>
        </div>
    );
};

SelectAdminGroups.propTypes = {
    adminData: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    groupsChanged: PropTypes.bool.isRequired,
    handleGroupSelectionChange: PropTypes.func.isRequired,
    handleAllGroupsClick: PropTypes.func.isRequired,
    handleSaveClick: PropTypes.func.isRequired,
    handleCancelClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    adminData: state.adminRightsManagement.adminData,
    selectedOptions: state.adminRightsManagement.selectedAdminOptions,
    groupsChanged: state.adminRightsManagement.adminGroupsChanged
});

export default connect(mapStateToProps, {})(injectIntl(SelectAdminGroups))
