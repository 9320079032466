import React from 'react';
import { withRouter } from 'react-router-dom';
import { domains } from '../../constants/Utils';
import { ADMINISTRATION_PATH, HDI_PATH } from '../../routes/paths';

export const Domain = withRouter(({ component: Component, ...props }) => {
    let domain;
    if (props.location.pathname.startsWith(HDI_PATH) || props.location.pathname.startsWith(`${ADMINISTRATION_PATH}`)) {
        domain = domains;
    }
    return <Component domain={domain} {...props}/>;
});
