import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    ADMIN_COMMON_SETTING: {
        id: 'admin.sideMenu.commonSetting',
        defaultMessage: 'Common setting'
    },
    ADMIN_COMMON_SETTING_CLIENT_ID: {
        id: 'admin.sideMenu.commonSetting.clientIdSetting',
        defaultMessage: 'Client ID setting'
    },
    ADMIN_HDI_TICKET: {
        id: 'admin.sideMenu.hdiTicket',
        defaultMessage: 'HDI Tickets'
    },
    ADMIN_HDI_TICKET_SOLUTION_GROUP: {
        id: 'admin.sideMenu.hdiTicket.solutionGroup',
        defaultMessage: 'Solution Group'
    },
    ADMIN_HDI_TICKET_SOLUTION_GROUP_UK: {
        id: 'admin.sideMenu.hdiTicket.solutionGroupUK',
        defaultMessage: 'Department'
    },
    ADMIN_HDI_TICKET_TEMPLATE_GROUP: {
        id: 'admin.sideMenu.ticketTemplate.template',
        defaultMessage: 'Typical Incidents'
    },
    ADMIN_HDI_TICKET_TEMPLATE_GROUP_REQUEST: {
        id: 'admin.sideMenu.ticketTemplate.templateRequests',
        defaultMessage: 'Typical Requests'
    },
    ADMIN_HDI_TICKET_TEMPLATE_TOPICS: {
        id: 'admin.sideMenu.ticketTemplate.topics',
        defaultMessage: 'Topics'
    },
    ADMIN_HDI_EDITOR: {
        id: 'admin.sideMenu.editorTemplate',
        defaultMessage: 'Operators'
    },
    ADMIN_HDI_TICKET_FLOW: {
        id: 'admin.sideMenu.hdiTicket.flow',
        defaultMessage: 'Flow'
    },
    ADMIN_HDI_AUTH_MANAGEMENT: {
        id: 'admin.sideMenu.hdi.authorizationManagement',
        defaultMessage: 'Authorization management'
    },
    ADMINISTRATION: {
        id: 'topMenu.administration',
        defaultMessage: 'Administration'
    },
    HDI: {
        id: 'topMenu.hdi',
        defaultMessage: 'HDI'
    },
    HDI_LIST: {
        id: 'hdi.sideMenu.list.incidents',
        defaultMessage: 'Incident queue'
    },
    HDI_LIST_REQUEST: {
        id: 'hdi.sideMenu.list.requests',
        defaultMessage: 'Request queue'
    },
    HDI_SEARCH_LIST: {
        id: 'hdi.sideMenu.search.incidents',
        defaultMessage: 'Fulltext search'
    },
    HDI_NEW: {
        id: 'hdi.sideMenu.new',
        defaultMessage: 'New incident'
    },
    HDI_NEW_REQUEST: {
        id: 'hdi.sideMenu.newRequest',
        defaultMessage: 'New request'
    },
    HDI_EXPORT: {
        id: 'hdi.sideMenu.export',
        defaultMessage: 'Export'
    },
    ADMIN_HDI_AUTH_MANAGEMENT_ADMIN: {
        id: 'admin.sideMenu.hdi.authorizationManagement.admin',
        defaultMessage: 'Admin'
    },
    ADMIN_HDI_AUTH_MANAGEMENT_EDITOR: {
        id: 'admin.sideMenu.hdi.authorizationManagement.user',
        defaultMessage: 'Editor'
    },
    ADMIN_HDI_AUTH_MANAGEMENT_DEALER: {
        id: 'dealer.sideMenu.hdi.authorizationManagement.user',
        defaultMessage: 'Dealer'
    },
    ADMIN_HDI_SOLUTION_GROUP_NEW: {
        id: 'admin.sideMenu.hdi.solutionGroupManagement.new',
        defaultMessage: 'New'
    },
    ADMIN_HDI_SOLUTION_GROUP_LIST: {
        id: 'admin.sideMenu.hdi.solutionGroupManagement.List',
        defaultMessage: 'List'
    },
    ADMIN_HDI_SOLUTION_GROUP_DEFAULT: {
        id: 'admin.sideMenu.hdi.solutionGroupManagement.default',
        defaultMessage: 'Default groups'
    },
    ADMIN_HDI_SOLUTION_GROUP_DEFAULT_UK: {
        id: 'admin.sideMenu.hdi.solutionGroupManagementUK.default',
        defaultMessage: 'Default departments'
    },
    ADMIN_HDI_TEMPLATE_NEW: {
        id: 'admin.sideMenu.hdi.templateManagement.new',
        defaultMessage: 'New'
    },
    ADMIN_HDI_TEMPLATE_LIST: {
        id: 'admin.sideMenu.hdi.templateManagement.List',
        defaultMessage: 'List'
    },
    ADMIN_HDI_EDITOR_NEW: {
        id: 'admin.sideMenu.hdi.editorManagement.new',
        defaultMessage: 'Add operator'
    },
    ADMIN_HDI_EDITOR_LIST: {
        id: 'admin.sideMenu.hdi.editorManagement.List',
        defaultMessage: 'List'
    },
    ADMIN_HDI_WELCOME_TEXT: {
        id: 'admin.sideMenu.hdi.welcomeManagement',
        defaultMessage: 'Welcomepage text'
    },
    ADMIN_FREQUENT_QUESTION: {
        id: 'admin.sideMenu.frequentQuestions',
        defaultMessage: 'Frequent Questions'
    },
});


export default messages;
