import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { InputCheckBox } from '../../../../../../common/InputCheckbox';
import messages from '../../../../../../../intl/common/countryGroupMessages';
import { countries } from '../../../../../../../constants/Utils';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const CountrySection = (props) => {

    const { readOnly } = props;

    return (
        <>
            <hr/>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    {
                        Object.keys(countries).map((country, index) => {
                            return (
                                <div key={index} className="col-md-3 col-sm-6 pb-2">
                                    <InputCheckBox
                                        label={messages[country]}
                                        field={`country.${country}`}
                                        isDisabled={readOnly}
                                        isSmall/>
                                </div>
                            )
                        })
                    }
                </div>
            </StyledDiv>
        </>
    )

};

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountrySection);
