import React from 'react';
import styled from 'styled-components';
import { FormSection, reduxForm } from 'redux-form'
import { editorConstants } from '../../../../../constants/form/formEditorConstants';
import EditorFilter from './EditorFilter';
import { HDI_VIEW_TYPE_EDITOR } from '../../../../../constants/form/viewTypeConstants';

const StyledDiv = styled.div`
  border-radius: 10px;
  margin-bottom: 10px;
`;

const FilterForm = (props) => {
    const { form, role, defaultSG } = props;

    if (role === HDI_VIEW_TYPE_EDITOR) {
        return (
            <StyledDiv className="border border-secondary p-4 bg-white">
                <FormSection name={editorConstants.FORM_EDITOR_EDITOR_SECTION}>
                    <EditorFilter form={form} defaultSG={defaultSG}/>
                </FormSection>
                {/*<FormSection name={editorConstants.FORM_EDITOR_SOLUTION_GROUP_SECTION}>
                    <FullSearchFilter form={form}/>
                </FormSection>*/}
            </StyledDiv>
        )
    }
    return null;
};

export default reduxForm({})(FilterForm);
