import {
    HDI_TICKET_STATUS_OPENED,
    HDI_TICKET_STATUS_CLOSED,
    HDI_TICKET_STATUS_NEW,
    HDI_TICKET_STATUS_SUSPENDED,
    HDI_TICKET_STATUS_ON_HOLD,
    HDI_TICKET_STATUS_REOPENED,
} from './form/statusConstants';

export const ticketRights = {
    VIEW: {
        OWNER: {
            canAddAttach: false,
            canAddNotes: false,
            canClose: false,
            canReOpen: false,
            canSave: false,
            canEdit: true,
            isCreate: false,
            canAddInternalNotes: false,
            canShowInternalNotes: false,
        },
        EDITOR: {
            canAddAttach: false,
            canAddNotes: false,
            canClose: false,
            canReOpen: false,
            canSave: false,
            canEdit: true,
            isCreate: false,
            canAddInternalNotes: false,
            canShowInternalNotes: true,
        },
        EDITOR_NO_SG: {
            canAddAttach: false,
            canAddNotes: false,
            canClose: false,
            canReOpen: false,
            canSave: false,
            canEdit: false,
            isCreate: false,
            canAddInternalNotes: false,
            canShowInternalNotes: true,
        },
        ADMIN: {
            canAddAttach: false,
            canAddNotes: false,
            canClose: false,
            canReOpen: false,
            canSave: false,
            canEdit: false,
            isCreate: false,
            canAddInternalNotes: false,
            canShowInternalNotes: true,
        },
    },
    EDIT: {
        OWNER: {
            OPENED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: false,
                canShowInternalNotes: true,
            },
            REOPENED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: false,
                canShowInternalNotes: true,
            },
            NEW: {
                canAddAttach: false,
                canAddNotes: false,
                canClose: false,
                canReOpen: false,
                canSave: false,
                canEdit: false,
                isCreate: false,
                canAddInternalNotes: false,
                canShowInternalNotes: true,
            },
            ON_HOLD: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: false,
                canShowInternalNotes: true,
            },
            SUSPENDED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: false,
                canShowInternalNotes: true,
            },
            CLOSED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: true,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: false,
                canShowInternalNotes: true,
            }
        },
        EDITOR: {
            OPENED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            REOPENED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            NEW: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            ON_HOLD: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            SUSPENDED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            CLOSED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: true,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            }
        },
        EDITOR_NO_SG: {
            OPENED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            REOPENED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            NEW: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            ON_HOLD: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            SUSPENDED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            CLOSED: {
                canAddAttach: true,
                canAddNotes: false,
                canClose: false,
                canReOpen: true,
                canSave: false,
                canEdit: false,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            }
        },
        ADMIN: {
            OPENED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            REOPENED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            NEW: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            ON_HOLD: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            SUSPENDED: {
                canAddAttach: true,
                canAddNotes: true,
                canClose: true,
                canReOpen: false,
                canSave: true,
                canEdit: true,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            },
            CLOSED: {
                canAddAttach: true,
                canAddNotes: false,
                canClose: false,
                canReOpen: true,
                canSave: false,
                canEdit: false,
                isCreate: false,
                canAddInternalNotes: true,
                canShowInternalNotes: true,
                canShowHistory: true,
            }
        }
    }
};

export const createRights = {
    canAddAttach: true,
    canAddNotes: false,
    canClose: false,
    canReOpen: false,
    canSave: true,
    canEdit: false,
    isCreate: true,
    canAddInternalNotes: false,
    canShowInternalNotes: false,
    canShowHistory: false,
};
export const noRights = {
    canAddAttach: false,
    canAddNotes: false,
    canClose: false,
    canReOpen: false,
    canSave: false,
    canEdit: false,
    isCreate: false,
    canAddInternalNotes: false,
    canShowInternalNotes: false,
    canShowHistory: false,
};

export const editListButtonVisibility = {
    EDITOR: [
        HDI_TICKET_STATUS_OPENED,
        HDI_TICKET_STATUS_REOPENED,
        HDI_TICKET_STATUS_CLOSED,
        HDI_TICKET_STATUS_NEW,
        HDI_TICKET_STATUS_SUSPENDED,
        HDI_TICKET_STATUS_ON_HOLD,
    ],
    EDITOR_NO_SG: [],
    ADMIN: [],
    OWNER: [
        HDI_TICKET_STATUS_OPENED,
        HDI_TICKET_STATUS_REOPENED,
        HDI_TICKET_STATUS_CLOSED,
        HDI_TICKET_STATUS_SUSPENDED,
        HDI_TICKET_STATUS_ON_HOLD,
    ],
    NO_RIGHTS: []
};


