import get from 'get-value';

export const initLoadedWelcome = (welcomeData) => {
    return {
        selectSection: {
            language: get(welcomeData.message, 'group', { default: '' }),
        },
        descriptionSection: {
            message: get(welcomeData.message, 'text', { default: `` }),
        }
    }
};

export const initNewWelcome = () => {
    return {
        selectSection: {
            language: ''
        },
        descriptionSection: {
            message: ''
        }
    }
};

export const transformWelcomeForSave = (values) => {
    const { newValues } = values;

    return {
        group: newValues.selectSection.language,
        text: newValues.descriptionSection.message
    };
};

