import { put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import get from 'get-value';
import { errorAlert } from '../actions/alertsActions';
import alertMessages from '../intl/common/alertMessages';
import { validateFetchTemplate } from '../api/validation/template';
import { hdiTemplateWebsocketCommands } from '../constants/hdiTemplateWebsocketCommands';

export function* handleTemplateError(action) {
    const stateCorrelationId = get(yield select(state => state.adminTemplate), `correlationId`, { default: undefined });
    const { correlationId } = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('../list'));
    yield put({ type: hdiTemplateWebsocketCommands.HDI_TEMPLATE_RESET });
}

export function* handleTemplateFetchData(action) {
    const stateCorrelationId = get(yield select(state => state.adminTemplate), `correlationId`, { default: undefined });
    const { correlationId, payload } = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    const { error } = validateFetchTemplate(payload);
    if (error) {
        yield put(errorAlert(alertMessages.TEMPLATE_FETCH_SEND_ERROR, [error.toString()]));
        yield put({
            type: hdiTemplateWebsocketCommands.HDI_TEMPLATE_SEND_ERROR,
            payload: { correlationId }
        });
    }
}

function* templateSaga() {
    yield takeEvery(hdiTemplateWebsocketCommands.HDI_TEMPLATE_SEND_ERROR, handleTemplateError);
    yield takeEvery(hdiTemplateWebsocketCommands.HDI_TEMPLATE_DETAIL_SEND_ERROR, handleTemplateFetchData);
}

export default templateSaga;
