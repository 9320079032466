import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { change, formValueSelector, getFormInitialValues } from 'redux-form';
import formMessages from '../../../../../../../intl/admin/editorMessages';
import { InputCheckBox } from '../../../../../../common/InputCheckbox';
import { editorConstants } from '../../../../../../../constants/form/formEditorConstants';
import InputSelectFieldForObjectWithValidation from '../../../../../../common/InputSelectFieldForObjectWithValidation';
import { InputFieldWithValidation } from '../../../../../../common/InputFieldWithValidation';
import {isVisibleForCountry} from '../../../../../../hdi/ticket/Utils';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const EditorSection = (props) => {
    const {
        readOnly, reqFields, selectSolutionGroups, showDetail, userDetail
    } = props;

    return (
        <>
            <hr/>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                {showDetail && <>
                    <div className="form-row">
                        <div className="col-6 pb-2">
                            <InputFieldWithValidation
                                label={formMessages[editorConstants.FORM_LABEL_EDITOR_IPN]}
                                field={editorConstants.FORM_FIELD_EDITOR_IPN}
                                isDisabled
                                isSmall/>
                        </div>
                        <div className="col-6 pb-2">
                            <InputFieldWithValidation
                                label={formMessages[editorConstants.FORM_LABEL_EDITOR_NAME]}
                                field={editorConstants.FORM_FIELD_EDITOR_NAME}
                                isDisabled
                                isSmall/>
                        </div>
                    </div>
                </>}
                <div className="form-row">
                    <div className="col pb-2">
                        <InputCheckBox
                            label={formMessages[editorConstants.FORM_LABEL_EDITOR_ON_HOLD]}
                            field={editorConstants.FORM_FIELD_EDITOR_ON_HOLD}
                            isDisabled={readOnly}
                            isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col pb-2">
                        <InputCheckBox
                            label={formMessages[editorConstants.FORM_LABEL_EDITOR_SUSPEND]}
                            field={editorConstants.FORM_FIELD_EDITOR_SUSPEND}
                            isDisabled={readOnly}
                            isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col pb-2">
                        <InputSelectFieldForObjectWithValidation
                            label={isVisibleForCountry(userDetail.group) ? formMessages[editorConstants.FORM_LABEL_EDITOR_DEFAULT_SG_UK] : formMessages[editorConstants.FORM_LABEL_EDITOR_DEFAULT_SG]}
                            field={editorConstants.FORM_FIELD_EDITOR_DEFAULT_SG}
                            options={selectSolutionGroups}
                            isRequired={reqFields.includes(editorConstants.FORM_FIELD_EDITOR_DEFAULT_SG)}
                            isDisabled={readOnly}
                            isSmall/>
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

const mapStateToProps = (state, props) => ({
    editorData: state.adminEditor,
    userDetail: state.profile.userDetail,
    initialValues: getFormInitialValues(props.form)(state),
    selectSolutionGroups: formValueSelector(props.form)(state, `${editorConstants.FORM_EDITOR_SOLUTION_GROUP_SECTION}.${editorConstants.FORM_FIELD_EDITOR_SOLUTION_GROUP_ACCESS}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorSection);
