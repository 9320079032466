import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.title',
        defaultMessage: 'Administration module'
    },
    SELECT_COUNTRY: {
        id: 'admin.selectCountry',
        defaultMessage: 'Select Country'
    },
    SELECT_COUNTRIES: {
        id: 'admin.selectCountries',
        defaultMessage: 'Select Countries'
    },
    SELECT_SOLUTION_GROUPS: {
        id: 'admin.selectSolutionGroups',
        defaultMessage: 'Select Departments (only UK)'
    },
    SELECT_SOLUTION_GROUPS_PLACEHOLDER: {
        id: 'admin.selectSolutionGroupsPlaceholder',
        defaultMessage: 'Empty for all departments'
    },
    ACTION: {
        id: 'admin.action',
        defaultMessage: 'Action'
    },
});

export default messages;
