import React, { Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import landingPicture from '../../images/landing-picture.jpg';
import mainPageMessages from '../../intl/home/mainPageMessages';
import { connect } from 'react-redux';
import { clearAlerts } from '../../actions/alertsActions';
import { fetchWelcomeText } from '../../actions/admin/actionAdminWelcomeText';
import PropTypes from 'prop-types';

const Img = styled.img`
    height: 50%;
    width: 50%;
`;
Img.displayName = 'Img';

class HomePage extends Component {

    componentDidMount(): void {
        this.props.fetchWelcomeText(this.props.group);
    }

    render() {

        const { text } = this.props.welcomeData.message;

        return <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <h1 className="text-center"><FormattedMessage {...mainPageMessages.TITLE} /></h1>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="text-center">
                        {text}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="text-center">
                        <Img src={landingPicture}/>
                    </div>
                </div>
            </div>
        </div>
    }
}

HomePage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    group: PropTypes.string.isRequired,
    welcomeData: PropTypes.object.isRequired,
    fetchWelcomeText: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    welcomeData: state.adminWelcome,
    group: state.profile.userDetail.group,
});

export default connect(mapStateToProps, {
    clearAlerts,
    fetchWelcomeText
})(HomePage);
