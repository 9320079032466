import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import { Redirect, withRouter } from 'react-router-dom';
import { clearAlerts } from '../../../../actions/alertsActions';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import solutionGroupMessages from '../../../../intl/admin/solutionGroupMessages';
import ListSolutionGroupTable from './ListSolutionGroupTable';
import commonMessages from '../../../../intl/common/commonMessages';
import { countries } from '../../../../constants/Utils';
import formCountryMessages from '../../../../intl/common/countryGroupMessages';
import {isVisibleForCountry} from '../../template/utils';

class ListSolutionGroupPage extends Component {
    constructor(props) {
        super(props);
        const { intl } = props;
        this.state = {
            ratchet: false,
            solutionGroup: '',
            columnLibrary: [
                {
                    id: 'country',
                    accessor: row => this.formatCountryLabel(row.country, intl),
                    message: solutionGroupMessages.TABLE_COUNTRIES,
                    Filter: ({ filter, onChange }) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%', border: '1px solid #999999' }}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {Object.keys(countries).map((key, index) =>
                                <option key={index} value={key}>
                                    {formCountryMessages[key] ? intl.formatMessage(formCountryMessages[key]) : key}
                                </option>)}
                        </select>
                    ),
                },
                {
                    id: 'isActive',
                    accessor: row => row.solutionGroup.isActive ? 'YES' : 'NO',
                    message: solutionGroupMessages.TABLE_ACTIVE,
                    sortable: false,
                    Filter: ({ filter, onChange }) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%', border: '1px solid #999999' }}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            <option value="false">{intl.formatMessage(commonMessages.NO)}</option>
                            <option value="true">{intl.formatMessage(commonMessages.YES)}</option>
                        </select>
                    ),
                },
            ],
        };
    }

    formatCountryLabel = (value, intl) => {
        let result = '';
        if (value) {
            Object.keys(value)
                .filter(country => value[country] === true)
                .sort()
                .forEach(country => result += intl.formatMessage(formCountryMessages[country]) + ', ');
        }
        return result.replace(/,\s$/, '');
    };

    componentDidMount() {
        //this.props.clearAlerts();
    }

    handleEditClick = ({ id }) => {
        this.setState({ ratchet: true, solutionGroup: id });
    };

    render() {
        const { filter, userDetail } = this.props;
        const { columnLibrary, solutionGroup } = this.state;


        if (this.state.ratchet) {
            return <Redirect to={`/administration/solutionGroup/edit/${solutionGroup}`}/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={isVisibleForCountry(userDetail.group) ? <FormattedMessage {...solutionGroupMessages.TITLE_UK}/> : <FormattedMessage {...solutionGroupMessages.TITLE}/>}/>
                <ListSolutionGroupTable
                    columns={columnLibrary}
                    handleEditClick={this.handleEditClick} filter={filter}/>
            </div>
        );
    }
}

ListSolutionGroupPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    userDetail: PropTypes.object,
};

const mapStateToPropos = state => ({
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToPropos, {
    clearAlerts,
})(injectIntl(ListSolutionGroupPage))), ['HDI_ADMIN']);
