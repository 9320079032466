import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import formMessages from '../../../../../../intl/admin/welcomeMessages';
import { welcomeConstants } from '../../../../../../constants/form/formWelcomeConstants';
import { TextareaFieldWithValidation } from '../../../../../common/TextareaFieldWithValidation';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const DescriptionSection = (props) => {
    const {
        readOnly, reqFields
    } = props;

    return (
        <>
            <hr/>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col pb-2">
                        <TextareaFieldWithValidation
                            label={formMessages[welcomeConstants.FORM_LABEL_WELCOME_MESSAGE]}
                            field={welcomeConstants.FORM_FIELD_WELCOME_MESSAGE}
                            isRequired={reqFields.includes(welcomeConstants.FORM_FIELD_WELCOME_MESSAGE)}
                            isDisabled={readOnly}
                            rows={10}
                            isSmall/>
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

const mapStateToProps = (state) => ({
    welcomeData: state.adminWelcome,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionSection);
