import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {fetchUserRole, saveUserRole} from '../actions/actionRole';
import Loader from './Loader';
import GlobalStyle from '../theme/globalStyle';
import {initAxios} from '../api/rest';
import {authenticateAndRedirect, getAuthenticatedUser, processAuthenticationRedirect, treatEmptyIdpToken} from '../utils/auth';
import {connectWebsockets} from '../actions/actionWebsocket';
import WebsocketHeartbeat from './WebsocketHeartbeat';
import HotSwappingIntlProvider from '../intl/ConnectedIntlProvider';
import Alert from './common/alert/Alert';
import {Provider as AlertProvider} from 'react-alert';
import AppContent from './AppContent';
import get from 'get-value';

class App extends Component {
    componentDidMount() {
        if (App.redirectedFromIdp(this.props.history)) {
            this.processAuthenticationRedirectResult();
        } else {
            getAuthenticatedUser().then(authenticatedUser => {
                if (!authenticatedUser || Object.keys(authenticatedUser).length === 0 || authenticatedUser.expired) {
                    authenticateAndRedirect();
                } else if (!this.props.userDetail || Object.keys(this.props.userDetail).length === 0) {
                    this.processAuthenticatedUser(authenticatedUser);
                }
            });
        }
    }

    async componentDidUpdate() {
        if (this.props.userToBeCreated) {
            this.props.saveUserRole();
        }
    }

    static redirectedFromIdp({location}) {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');
        return (code && code !== null);
    }

    processAuthenticationRedirectResult = () => {
        processAuthenticationRedirect().then(
            ({user, lastVisitedPath}) => {
                const uid = get(user, 'profile.uid');
                if (!uid) {
                    treatEmptyIdpToken();
                } else {
                    this.processAuthenticatedUser(user);
                    this.props.history.push(lastVisitedPath);
                }
            },
            () => authenticateAndRedirect()
        );
    };

    processAuthenticatedUser = user => {
        initAxios(user);
        this.props.connectWebsockets(user.access_token);
        this.props.fetchUserRole(
            user.profile.uid,
            `${user.profile.given_name || user.profile.firstname || ''} ${user.profile.family_name || user.profile.lastname || ''}`,
        );
    };

    render() {
        const {isLoading} = this.props;
        if (isLoading) {
            return <Loader/>;
        }
        const {store} = this.props;
        return (
            <>
                <GlobalStyle/>
                <WebsocketHeartbeat/>
                <HotSwappingIntlProvider {...store}>
                    <AlertProvider template={Alert} position="top right">
                        <AppContent store={store}/>
                    </AlertProvider>
                </HotSwappingIntlProvider>
            </>
        );
    }
}

App.propTypes = {
    connectWebsockets: PropTypes.func.isRequired,
    fetchUserRole: PropTypes.func.isRequired,
    saveUserRole: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    userToBeCreated: PropTypes.bool,
    store: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userDetail: PropTypes.object
};

const mapStateToProps = state => ({
    isLoading: state.profile.isLoading,
    userToBeCreated: state.profile.userToBeCreated,
    userDetail: state.profile.userDetail,
});

export default withRouter(connect(mapStateToProps, {
    fetchUserRole,
    connectWebsockets,
    saveUserRole
})(App));
