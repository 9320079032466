import Joi from 'joi';
import { HDI_SG_LEVEL_L0, HDI_SG_LEVEL_L1, HDI_SG_LEVEL_L2 } from '../../constants/form/levelConstants';
import { countries } from '../../constants/Utils';

export function validateSolutionGroups(solutionGroups) {
    return Joi.validate(
        solutionGroups,
        Joi.object().keys({
            solutionGroup: Joi.object().required().keys({
                id: Joi.string().required(),
                shortName: Joi.string().required(),
                longName: Joi.string().required(),
                isActive: Joi.bool().required(),
                level: getLevelSchema().required()
            }),
            country: Joi.object().keys(getCountrySchema()),
            userEmail: Joi.array().items(Joi.any()).required(),
            emailList: Joi.array().required(),
        })
    );
}

export function validateSolutionGroupCreateEvent(solutionGroupCreateEvent) {
    return Joi.validate(
        solutionGroupCreateEvent,
        Joi.object().required().keys({
            solutionGroup: Joi.object().required().keys({
                shortName: Joi.string(),
                longName: Joi.string(),
                isActive: Joi.bool(),
                level: getLevelSchema().required()
            }),
            country: Joi.object().keys(getCountrySchema()),
            userEmail: Joi.array().items(Joi.any()),
            emailList: Joi.array(),
        })
    );
}

export function validateSolutionGroupUpdateEvent(solutionGroupUpdateEvent) {
    return Joi.validate(
        solutionGroupUpdateEvent,
        Joi.object().required().keys({
            solutionGroup: Joi.object().required().keys({
                id: Joi.string().required(),
                longName: Joi.string(),
                isActive: Joi.bool(),
                level: getLevelSchema(),
            }),
            country: Joi.object().keys(getCountrySchema()),
            emailList: Joi.array(),
            addedEditorEmail: Joi.array(),
            removedEditorEmail: Joi.array(),
            id: Joi.string().required()
        })
    );
}

function getCountrySchema() {
    const result = {};
    Object.keys(countries).forEach(country => result[countries[country]] = Joi.bool());
    return result;
}

export function getSolutionGroupSchema() {
    return Joi.object().keys({
        id: Joi.string().required(),
        shortName: Joi.string().required(),
        country: Joi.object().keys(getCountrySchema()),
        level: getLevelSchema()
    });
}


function getLevelSchema() {
    return Joi.string()
        .valid(
            HDI_SG_LEVEL_L0,
            HDI_SG_LEVEL_L1,
            HDI_SG_LEVEL_L2);
}
