import React from 'react';
import styled from 'styled-components';
import { FormSection, reduxForm } from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import RenderSyncErrors from '../../../../common/RenderSyncErrors';
import { scrollContentWrapperToTop } from '../../../../../utils/utils';
import formMessages from '../../../../../intl/admin/solutionGroupMessages';
import { intlSolutionGroupFieldMapping } from '../../../../../constants/fieldLabelMapping';
import { solutionGroupConstants } from '../../../../../constants/form/formSolutionGroupConstants';
import MainSection from './sections/MainSection';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const DefaultForm = (props) => {
    const { form, reqFields, readOnly, handleSubmit } = props;

    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form}
                              intlMessages={formMessages}
                              mapping={intlSolutionGroupFieldMapping}/>
            <FormSection name={solutionGroupConstants.FORM_SOLUTION_GROUP_MAIN_SECTION}>
                <MainSection form={form} />
            </FormSection>
            <SubmitButtons form={form}
                           reqFields={reqFields}
                           readOnly={readOnly}
                           onSubmit={handleSubmit}
                           onSubmitFail={scrollContentWrapperToTop}/>
        </StyledDiv>
    )
};

export default reduxForm({})(DefaultForm);
