import { hdiEditorWebsocketCommands, } from '../../constants/hdiEditorWebsocketCommands';
import get from 'get-value';

export const adminEditorList = (state = {
    correlationId: null,
    isLoading: false,
    isTableLoading: false,
    listData: [],
    pages: 0
}, action) => {
    switch (action.type) {
        case hdiEditorWebsocketCommands.HDI_EDITOR_DELETE_PENDING:
        case hdiEditorWebsocketCommands.HDI_EDITOR_LIST_FETCH_PENDING: {
            return Object.assign({}, state, { isTableLoading: true });
        }
        case hdiEditorWebsocketCommands.HDI_EDITOR_ROLE_DELETED: {
            const { correlationId, id } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isLoading: false,
                    isTableLoading: false,
                    listData: state.listData.filter(flow => flow.id !== id)
                });
            }
        }
        case hdiEditorWebsocketCommands.HDI_EDITOR_ROLE_DELETED_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, { isLoading: false });
            }
        }
        case hdiEditorWebsocketCommands.HDI_EDITOR_LIST_SEND: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isTableLoading: false,
                    listData: get(action, 'payload.result.data', { default: [] }),
                    pages: get(action, 'payload.result.pages', { default: 0 }),
                    correlationId
                });
            }
        }
        case hdiEditorWebsocketCommands.HDI_EDITOR_LIST_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, { isTableLoading: false, correlationId });
            }
        }
        default:
            return state;
    }
};
