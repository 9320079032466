import {defineMessages} from 'react-intl';
import { welcomeConstants } from '../../constants/form/formWelcomeConstants';

const messages = defineMessages({
    TITLE: {
        id: 'admin.hdi.welcome.title',
        defaultMessage: 'Welcome text'
    },
    [welcomeConstants.FORM_LABEL_WELCOME_LANGUAGE]: {
        id: 'hdi.administration.welcome.label.language',
        defaultMessage: 'Select language'
    },
    [welcomeConstants.FORM_LABEL_WELCOME_MESSAGE]: {
        id: 'hdi.administration.welcome.label.message',
        defaultMessage: 'Message'
    },
});

export default messages;
