import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../actions/alertsActions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../Loader';
import { initLoadedWelcome, transformWelcomeForSave } from './utils';
import { createReqFields } from '../../../constants/requiredFields';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import WelcomeForm from './forms/WelcomeForm';
import { fetchWelcomeText, resetWelcome, updateWelcomeText } from '../../../actions/admin/actionAdminWelcomeText';
import { HDI_WELCOME_FORM } from '../../../constants/formConstants';
import get from 'get-value';
import { FormattedMessage } from 'react-intl';
import welcomeMessages from '../../../intl/admin/welcomeMessages';

class ViewWelcomePage extends Component {

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetWelcome();
    }

    handleSubmit = (values) => {
        const dataForSend = transformWelcomeForSave(values);
        this.props.updateWelcomeText(dataForSend);
    };

    render() {
        const {
            welcomeData,
        } = this.props;

        if (welcomeData.isLoading) {
            return <Loader/>;
        }

        let initializationValue = initLoadedWelcome(welcomeData);

        const requiredFields = createReqFields['DEFAULT'][HDI_WELCOME_FORM];
        return (
            <div className="container-fluid">
                <PageHeader
                    title={<FormattedMessage {...welcomeMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <WelcomeForm
                            form={HDI_WELCOME_FORM}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            showForm={get(welcomeData, 'message.group', { default: false })}
                            handleSelectLanguage={this.handleSelectLanguage}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

ViewWelcomePage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    welcomeData: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any,
    fetchWelcomeText: PropTypes.func.isRequired,
    updateWelcomeText: PropTypes.func.isRequired,
    resetWelcome: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    clearAlerts: PropTypes.func.isRequired,
    welcomeData: state.adminWelcome,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchWelcomeText,
    updateWelcomeText,
    resetWelcome,
})(ViewWelcomePage)), ['HDI_ADMIN']);

