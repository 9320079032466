import {defineMessages} from 'react-intl';

const messages = defineMessages({
    cs: {
        id: 'locale.cs',
        defaultMessage: 'Czech'
    },
    da: {
        id: 'locale.da',
        defaultMessage: 'Danish'
    },
    en: {
        id: 'locale.en',
        defaultMessage: 'English'
    },
    fr: {
        id: 'locale.fr',
        defaultMessage: 'French'
    },
    hu: {
        id: 'locale.hu',
        defaultMessage: 'Hungarian'
    },
    nl: {
        id: 'locale.nl',
        defaultMessage: 'Dutch'
    },
    pl: {
        id: 'locale.pl',
        defaultMessage: 'Polish'
    },
    sk: {
        id: 'locale.sk',
        defaultMessage: 'Slovak'
    },
    sv: {
        id: 'locale.sv',
        defaultMessage: 'Swedish'
    },
});

export default messages;
