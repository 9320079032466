import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchLoadAllTicketData, fetchTicket, resetTicket, updateTicket } from '../../../../actions/hdi/actionTicket';
import Loader from '../../../Loader';
import { FORM_BUTTON_BACK, HDI_TICKET_FORM, } from '../../../../constants/formConstants';
import TicketForm from './forms/TicketForm';

import {
    getTicketOwner,
    getUserRole,
    getViewType,
    initLoadedTicket,
    setUserRights,
    transformUpdateTicketForSave,
    isVisibleForCountry
} from '../Utils';
import { editReqFields } from '../../../../constants/requiredFields';
import PageHeader from '../../../common/PageHeader';
import { formatTicketNumber } from '../../../../utils/utils';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import UploadingAttachments from './UploadingAttachments';
import { fetchSolutionGroupShortList } from '../../../../actions/admin/actionSolutionGroupListPage';
import { FormattedMessage } from 'react-intl';
import formMessages from '../../../../intl/hdi/formMessages';
import { ticketConstants } from '../../../../constants/form/formTicketConstants';
import { HDI_TICKET_STATUS_NEW } from '../../../../constants/form/statusConstants';
import {getEditorSolutionGroups} from '../../../../actions/admin/actionAdminEditor';
import {fetchTemplateShortList, fetchTemplateShortListAll} from '../../../../actions/admin/actionAdminTemplateListPage';

class EditTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = { ticketSubmittedAndShouldRedirect: false };
    }

    componentDidMount() {
        //this.props.clearAlerts();
        this.props.resetTicket();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchTicket(this.props.match.params.ticketUuid, this.props.loggedUser.ipn);
            this.props.fetchTemplateShortList();
            this.props.fetchTemplateShortListAll();
            if (getUserRole(this.props.loggedUser.roles) === 'EDITOR') {
                this.props.fetchSolutionGroupShortList();
                this.props.getEditorSolutionGroups()
            }
        }

    }

    handleSubmit = (values) => {
        const { submitBy } = values;
        const dataForSend = transformUpdateTicketForSave(values, this.props.adminSolutionGroupList.shortListData, this.props.templateShortListAll);
        this.props.updateTicket(dataForSend);
        if (!([FORM_BUTTON_BACK].includes(submitBy))) {
            this.setState({ ticketSubmittedAndShouldRedirect: true });
        }
    };

    render() {
        const {
            ticketData,
            loggedUser,
            attachmentsData,
            isSolutionGroupListLoading,
            isShortListAllLoading,
            userDetail
        } = this.props;

        let formType = false;
        if (get(ticketData, 'ticket.status', { default: false })) {
            formType = HDI_TICKET_FORM;
        }

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingAttachments attachments={attachmentsData.attachmentsForUpload}
                                         attachmentsUrl={ticketData.newAttachments}
                                         correlationId={ticketData.correlationId}
                                         isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                         formType={formType}/>
        }

        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !formType
            || isSolutionGroupListLoading
            || isShortListAllLoading
            || ((get(ticketData, "newAttachments.length") > 0) ? !attachmentsData.attachmentUploaded : false)
        ) {
            return <Loader/>;
        }

        if ((getTicketOwner(loggedUser, ticketData.ticket) === 'OWNER' &&
            !getTicketOwner(loggedUser, ticketData.ticket) !== 'EDITOR' &&
            ticketData.ticket.status === HDI_TICKET_STATUS_NEW) || (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.id', { default: false })  && ((get(ticketData, "newAttachments.length") > 0) ? attachmentsData.attachmentUploaded : true))) {
            return <Redirect to={{
                pathname: `/hdi/view/${ticketData.ticket.id}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        const requiredFields = editReqFields['DEFAULT']['EDITOR'][formType];
        const initializationValue = initLoadedTicket(ticketData);
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <>
                        {isVisibleForCountry(userDetail.group) ? <FormattedMessage {...formMessages.TITLE_REQUEST}/> : <FormattedMessage {...formMessages.TITLE}/>}{' - '}
                        {formatTicketNumber(ticketData.ticket.country, ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}
                        <small className="text-uppercase font-italic pull-right">
                            <FormattedMessage {...formMessages[ticketConstants.FORM_LABEL_STATUS]}/>: {initializationValue.ticketSection.status}
                        </small>
                    </>
                }/>
                <div className="container-fluid">
                    <div className="col-12">
                        {formType === HDI_TICKET_FORM &&
                        <TicketForm
                            form={formType}
                            initialValues={initializationValue}
                            userRights={setUserRights('EDIT', loggedUser, ticketData.ticket, ticketData.editor)}
                            viewType={getViewType(loggedUser)}
                            userCountry={userDetail.group}
                            enableReinitialize={true}
                            reqFields={requiredFields}
                            handleSubmit={this.handleSubmit}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

EditTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    resetTicket: PropTypes.func.isRequired,
    fetchTicket: PropTypes.func.isRequired,
    fetchSolutionGroupShortList: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateTicket: PropTypes.func.isRequired,
    intl: PropTypes.object,
    newFormType: PropTypes.bool,
    isSolutionGroupListLoading: PropTypes.bool.isRequired,
    ticketData: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    location: PropTypes.object,
    attachmentsData: PropTypes.object.isRequired,

};


const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail,
    ticketData: state.ticket,
    userDetail: state.profile.userDetail,
    attachmentsData: state.hdiAttachment,
    adminSolutionGroupList: state.adminSolutionGroupList,
    isSolutionGroupListLoading: state.adminSolutionGroupList.isSolutionGroupListLoading,
    templateShortList: state.adminTemplateList.shortListData,
    templateShortListAll: state.adminTemplateList.shortListAllData,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchTicket,
    updateTicket,
    resetTicket,
    fetchLoadAllTicketData,
    fetchSolutionGroupShortList,
    fetchTemplateShortList,
    fetchTemplateShortListAll,
    getEditorSolutionGroups
})(EditTicketPage)), ['HDI_EDITOR', 'HDI_ADMIN', 'HDI_USER']);
