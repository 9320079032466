import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    addDefaultSolvingGroupList,
    fetchEditor,
    resetEditor,
    updateEditor
} from '../../../../actions/admin/actionAdminEditor';
import Loader from '../../../Loader';
import { FORM_BUTTON_BACK, HDI_EDITOR_FORM, } from '../../../../constants/formConstants';
import EditorForm from './forms/EditorForm';

import { initLoadedEditor, transformUpdateEditorForSave } from '../utils';
import { editReqFields } from '../../../../constants/requiredFields';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import editorMessages from '../../../../intl/admin/editorMessages';
import { FormattedMessage } from 'react-intl';

class EditEditorPage extends Component {
    constructor(props) {
        super(props);
        this.state = { editorSubmittedAndShouldRedirect: false };
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetEditor();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchEditor(this.props.match.params.editorUuid);
        }
    }

    handleSubmit = (values) => {
        const { submitBy } = values;
        const dataForSend = transformUpdateEditorForSave(values, this.props.editorData);
        this.props.updateEditor(dataForSend);
        if (!([FORM_BUTTON_BACK].includes(submitBy))) {
            this.setState({ editorSubmittedAndShouldRedirect: true });
        }
    };

    handleAddToDefaultSolvingGroupList = (value) => {
        this.props.addDefaultSolvingGroupList(value);
    };

    render() {
        const {
            editorData,
        } = this.props;

        let formType = false;
        if (get(editorData, 'editor.ipn', { default: false })) {
            formType = HDI_EDITOR_FORM;
        }

        if (
            editorData.isEditorLoading
            || editorData.isEditorCreating
            || !formType
        ) {
            return <Loader/>;
        }

        const requiredFields = editReqFields['DEFAULT']['EDITOR'][formType];
        const initializationValue = initLoadedEditor(editorData);
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...editorMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <EditorForm
                            form={formType}
                            initialValues={initializationValue}
                            enableReinitialize={true}
                            showDetail
                            reqFields={requiredFields}
                            handleAddSG={this.handleAddToDefaultSolvingGroupList}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

EditEditorPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    resetEditor: PropTypes.func.isRequired,
    fetchEditor: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateEditor: PropTypes.func.isRequired,
    intl: PropTypes.object,
    editorData: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    location: PropTypes.object,
    addDefaultSolvingGroupList: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    editorData: state.adminEditor,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchEditor,
    updateEditor,
    resetEditor,
    addDefaultSolvingGroupList,
})(EditEditorPage)), ['HDI_ADMIN']);
