import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import styled from 'styled-components';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {downloadFile} from '../../../actions/actionFile';
import {ModalBody, ModalFooter, ModalWindow} from '../modal/Modal';
import frequentQuestionMessages from '../../../intl/admin/frequentQuestionMessages';
import buttonMessages from '../../../intl/common/buttonMessages';
import {fetchFrequentQuestion} from '../../../actions/admin/actionAdminFrequentQuestion';
import {PrimaryButton} from '../Button';
import Loader from '../../Loader';
import {StyledA, StyledForm} from '../StyledComponents';

const Button = styled(PrimaryButton)`
  margin-right: 5px;
`;

class FrequentQuestionModal extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchFrequentQuestion(this.props.group);
    }

    close = () => {
        this.props.reset();
        this.props.closeModal();
    };

    downloadFile = (attachment, questionId) => (e) => {
        e.preventDefault();
        this.props.downloadFile(attachment, questionId);
    };

    getFilteredFQ = () => {
        const { frequentQuestions, solutionGroup } = this.props

        return frequentQuestions.filter(fq => {
            if(!solutionGroup) return true
            return fq.solutionGroups ? fq.solutionGroups.some(sg => sg.shortName === solutionGroup) : false
        })
    }

    render() {
        const {show, isLoading} = this.props;

        const filteredFQ = this.getFilteredFQ()

        return (
            <ModalWindow
                isOpen={show}
                onRequestClose={this.close}
                shouldCloseOnOverlayClick={true}>
                <StyledForm>
                    {isLoading && <Loader/>}
                    {!isLoading && <>
                        <ModalBody>
                            {filteredFQ.length === 0 &&
                            <div>
                                <FormattedMessage {...frequentQuestionMessages.EMPTY}/>
                            </div>
                            }
                            {filteredFQ.length !== 0 &&
                            <div className="card rounded">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                    <tr className="d-flex">
                                        <th className="col-8">
                                            <FormattedMessage {...frequentQuestionMessages.FREQUENT_QUESTION}/>
                                        </th>
                                        <th className="col-4">
                                            <FormattedMessage {...frequentQuestionMessages.ATTACHMENT}/>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredFQ.map((fq) => (
                                        <tr className="d-flex" key={fq.uuid}>
                                            <td className="col-8">{fq.message}</td>
                                            {fq.attachment ?
                                                <td className="col-4">
                                                    <StyledA onClick={this.downloadFile(
                                                        fq.attachment, fq.uuid)}>
                                                        {`${fq.attachment.name}`}</StyledA>
                                                </td> :
                                                <td className="col-4"/>
                                            }
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            }
                        </ModalBody>
                    </>}
                    <ModalFooter>
                        <div className="row">
                            <div className="col">
                                <Button type="button" className="btn"
                                        onClick={this.close}>
                                    <FormattedMessage {...buttonMessages.CLOSE}/>
                                </Button>
                            </div>
                        </div>
                    </ModalFooter>

                </StyledForm>
            </ModalWindow>
        )
    }

}

FrequentQuestionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    frequentQuestions: PropTypes.array.isRequired,
    group: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    frequentQuestions: state.adminFrequentQuestion.frequentQuestions,
    isLoading: state.adminFrequentQuestion.isLoading,
});

export default reduxForm({
    form: 'FrequentQuestionModalForm',
    enableReinitialize: true,
})(injectIntl(connect(mapStateToProps, {fetchFrequentQuestion, downloadFile})(FrequentQuestionModal)));
