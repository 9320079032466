export const supportProperties = {
    DEFAULT: {
        responsible: 'HELPDESK CZ',
        phone: '+420 222 339 400',
        email: ' helpdesk.cz@renault.cz'
    },
    CZ: {
        responsible: 'HELPDESK CZ',
        phone: '+420 222 339 400',
        email: ' helpdesk.cz@renault.cz'
    },
    SK: {
        responsible: 'HELPDESK SK',
        phone: '+420 222 339 400',
        email: ' helpdesk.cz@renault.cz'
    },
    HU: {
        responsible: 'HELPDESK HU',
        phone: '+420 222 339 400',
        email: ' helpdesk.cz@renault.cz'
    },
    SE: {
        responsible: 'HELPDESK SE',
        phone: '+420 222 339 400',
        email: ' helpdesk.cz@renault.cz'
    },
    NO: {
        responsible: 'HELPDESK NO',
        phone: '+420 222 339 400',
        email: ' helpdesk.cz@renault.cz'
    },
    FI: {
        responsible: 'HELPDESK FI',
        phone: '+420 222 339 400',
        email: ' helpdesk.cz@renault.cz'
    },
    DK: {
        responsible: 'HELPDESK DK',
        phone: '+420 222 339 400',
        email: ' helpdesk.cz@renault.cz'
    },
    NL: {
        responsible: 'HELPDESK NL',
        phone: '+420 222 339 400',
        email: ' helpdesk.cz@renault.cz'
    },
    PL: {
        responsible: 'HELPDESK PL',
        phone: '+420 222 339 400',
        email: ' helpdesk.cz@renault.cz'
    },
    BE: {
        responsible: 'HELPDESK BE',
        phone: '+420 222 339 400',
        email: ' helpdesk.cz@renault.cz'
    },
    UK: {
        responsible: 'HELPDESK UK',
        phone: '+420 123 456 789',
        email: ' helpdesk.gb@renault.cz-invalid'
    },
    IE: {
        responsible: 'HELPDESK IE',
        phone: '+420 123 456 789',
        email: ' helpdesk.gb@renault.cz-invalid'
    }
};

export const localeToCodeMapping = {
    cs: 'CZ',
    en: 'DEFAULT',
    sk: 'SK',
    da: 'DK',
    sv: 'SE',
    nl: 'NL',
    no: 'NO',
    hu: 'HU',
    pl: 'PL',
    be: 'BE',
    uk: 'UK',
    mt: 'MT',
    cy: 'CY',
    ie: 'IE',
};

