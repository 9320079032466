import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { InputFieldWithValidation } from '../../../../../../common/InputFieldWithValidation';
import formMessages from '../../../../../../../intl/admin/templateMessages';
import { templateConstants } from '../../../../../../../constants/form/formTemplateConstants';
import ValidFormattedMessage from '../../../../../../common/ValidFormattedMessage';
import { TextareaFieldWithValidation } from '../../../../../../common/TextareaFieldWithValidation';
import { validEmails } from '../../../../../../common/validationCommons';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const DescriptionSection = (props) => {
    const {
        readOnly, reqFields
    } = props;

    return (
        <>
            <hr/>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col pb-2">
                        <InputFieldWithValidation
                            label={formMessages[templateConstants.FORM_LABEL_TEMPLATE_SUBJECT]}
                            field={templateConstants.FORM_FIELD_TEMPLATE_SUBJECT}
                            isDisabled={readOnly}
                            isRequired={reqFields.includes(templateConstants.FORM_FIELD_TEMPLATE_SUBJECT) && !readOnly}
                            isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col pb-2">
                        <InputFieldWithValidation
                            label={formMessages[templateConstants.FORM_LABEL_TEMPLATE_COPY]}
                            field={templateConstants.FORM_FIELD_TEMPLATE_COPY}
                            validations={[validEmails]}
                            isDisabled={readOnly}
                            isRequired={reqFields.includes(templateConstants.FORM_FIELD_TEMPLATE_COPY) && !readOnly}
                            maxLength={256}
                            isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col pb-2">
                        <label className="col-form-label pl-0 font-weight-bold">
                            <ValidFormattedMessage message={templateConstants.FORM_LABEL_TEMPLATE_DESCRIPTION}
                                                   intlMessages={formMessages}/>
                        </label>
                        <TextareaFieldWithValidation
                            field={templateConstants.FORM_FIELD_TEMPLATE_DESCRIPTION}
                            rows={6}
                            isDisabled={readOnly}
                            isRequired={reqFields.includes(templateConstants.FORM_FIELD_TEMPLATE_DESCRIPTION) && !readOnly}
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

const mapStateToProps = (state) => ({
    templateData: state.adminTemplate,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionSection);
