import { hdiTicketWebsocketCommands } from '../../constants/hdiTicketWebsocketCommands';
import { HDI_ATTACHMENTS_UPLOADING, HDI_ATTACHMENTS_UPLOADING_ERROR } from '../../actions/hdi/actionAttachments';

export const hdiAttachment = (state = {
    correlationId: null,
    isTicketAttachmentUploading: false,
    attachmentsForUpload: [],
    attachmentUploaded: false,
}, action) => {
    switch (action.type) {
        case hdiTicketWebsocketCommands.HDI_TICKET_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
                attachmentUploaded: false,
            });
        }
        case hdiTicketWebsocketCommands.HDI_GENERATED_PRESIGNED_URLS: {
            const { attachments } = action.payload;
            return Object.assign({}, state, {
                attachmentsForUpload: Array.isArray(attachments) ? attachments : [],
            });
        }
        case HDI_ATTACHMENTS_UPLOADING: {
            return Object.assign({}, state, { isTicketAttachmentUploading: true });
        }
        //att done + error
        case hdiTicketWebsocketCommands.HDI_ATTACHMENTS_SAVED_ERROR:
        case HDI_ATTACHMENTS_UPLOADING_ERROR: {
            return Object.assign({}, state, {
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
                attachmentUploaded: true,
            });
        }
        case hdiTicketWebsocketCommands.HDI_ATTACHMENTS_SAVED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
                attachmentUploaded: true,
            });
        }

        default:
            return state;
    }
};
