import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    HDI_WARN: {
        id: 'warnings.hdi.',
        defaultMessage: 'HDI Warning'
    },
});

export default messages;
