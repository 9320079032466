import moment from 'moment';

// in case the business rule of interval would change for more then one week there should be other solution used!!
export const isOlderThen2DaysWithoutWeekend = then => {
    if (!then) {
        return false;
    }

    const now = moment();
    let diff = moment(now).diff(moment(then), 'minutes');

    // for less than 2 days always fine
    const moreThenTwoDays = diff > 2 * 24 * 60;
    if (!moreThenTwoDays) {
        return false;
    }

    // for more then four days no need to count with weekend => always more than 2 days
    const moreThenFourDays = diff > 4 * 24 * 60;
    if (moreThenFourDays) {
        return true;
    }

    // subtract days if there was a weekend last two days
    const itIsTuesday = now.day() === 2;
    const itIsMonday = now.day() === 1;
    const itIsSunday = now.day() === 0;
    const itIsSaturday = now.day() === 6;

    if (itIsTuesday || itIsMonday) {
        // minus Sunday and Saturday
        diff = diff - 2 * 24 * 60;
    }
    if (itIsSunday) {
        // minus whole Saturday minus part of Sunday
        diff = diff - 24 * 60 - moment(now).diff(now.startOf('day'), 'minutes');
    }
    if (itIsSaturday) {
        // minus part of saturday
        diff = diff - moment(now).diff(now.startOf('day'), 'minutes');
    }

    return diff > 2 * 24 * 60;
};

// in case the business rule of interval would change for more then one week there should be other solution used!!
export const isOlderThen1DayWithoutWeekend = then => {
    if (!then) {
        return false;
    }

    const now = moment();
    let diff = moment(now).diff(moment(then), 'minutes');

    // for less than 1 day always fine
    const moreThenOneDay = diff > 24 * 60;
    if (!moreThenOneDay) {
        return false;
    }

    // for more then three days no need to count with weekend => always more than 1 day
    const moreThenThreeDays = diff > 3 * 24 * 60;
    if (moreThenThreeDays) {
        return true;
    }

    // subtract days if there was a weekend last two days
    const itIsMonday = now.day() === 1;
    const itIsSunday = now.day() === 0;
    const itIsSaturday = now.day() === 6;

    if (itIsMonday) {
        // minus Sunday and Saturday
        diff = diff - 2 * 24 * 60;
    }
    if (itIsSunday) {
        // minus whole Saturday minus part of Sunday
        diff = diff - 24 * 60 - moment(now).diff(now.startOf('day'), 'minutes');
    }
    if (itIsSaturday) {
        // minus part of saturday
        diff = diff - moment(now).diff(now.startOf('day'), 'minutes');
    }

    return diff > 24 * 60;
};
