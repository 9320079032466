import React from 'react'
import {reduxForm} from 'redux-form'
import ValidFormattedMessage from '../ValidFormattedMessage';
import {ticketConstants} from '../../../constants/form/formTicketConstants';
import formMessages from '../../../intl/hdi/formMessages';
import {TextareaFieldWithValidation} from '../TextareaFieldWithValidation';

let SatisfactionForm = props => {
    return (
        <form className="col-sm-12 pb-2">
            <label className="col-form-label pl-0 font-weight-bold">
                <ValidFormattedMessage message={ticketConstants.FORM_LABEL_NOTE}
                                       intlMessages={formMessages}/>
            </label>
            <TextareaFieldWithValidation
                field={ticketConstants.FORM_FIELD_NOTE}
                rows={6}
                isRequired
            />
        </form>
    )
}

SatisfactionForm = reduxForm({
    // a unique name for the form
    form: 'satisfaction'
})(SatisfactionForm);

export default SatisfactionForm;
