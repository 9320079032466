import { defineMessages } from 'react-intl';
import { ticketConstants } from '../../constants/form/formTicketConstants';
import { R_NET, SUBSIDIARY } from '../../constants/form/formSubstitutionConstants';

const messages = defineMessages({
    [ticketConstants.FORM_LABEL_SEARCH_CLIENT]: {
        id: 'hdi.list.filter.client',
        defaultMessage: 'Client'
    },
    [ticketConstants.FORM_LABEL_SEARCH_SOLUTION_GROUP]: {
        id: 'hdi.list.filter.sg',
        defaultMessage: 'Solution group'
    },
    [ticketConstants.FORM_LABEL_SEARCH_SOLUTION_GROUP_UK]: {
        id: 'hdi.list.filter.sgUK',
        defaultMessage: 'Department'
    },
    [ticketConstants.FORM_LABEL_SEARCH_FULL]: {
        id: 'hdi.list.filter.fullSearch',
        defaultMessage: 'Full search'
    },
    [SUBSIDIARY]: {
        id: 'hdi.client.subsidiary',
        defaultMessage: 'Subsidiary'
    },
    [R_NET]: {
        id: 'hdi.client.rNet',
        defaultMessage: 'R.Net'
    },
});

export default messages;
