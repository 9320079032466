export const hdiTicketWebsocketCommands = {
    //HDI Ticket List
    HDI_LIST_RECEIVE: 'getList',

    HDI_TICKET_LIST_PENDING: 'HDI_TICKET_LIST_PENDING',
    HDI_TICKET_LIST_SEND: 'HDI_TICKET_LIST_SEND',
    HDI_TICKET_LIST_SEND_ERROR: 'HDI_TICKET_LIST_SEND_ERROR',

    HDI_TICKET_LIST_FILTER_SET_CLIENT: 'HDI_TICKET_LIST_FILTER_SET_CLIENT',
    HDI_TICKET_LIST_FILTER_SET_SG: 'HDI_TICKET_LIST_FILTER_SET_SG',
    HDI_TICKET_LIST_FILTER_SET_FULLTEXT: 'HDI_TICKET_LIST_FILTER_SET_FULLTEXT',
    HDI_TICKET_LIST_SET_FILTERING: 'HDI_TICKET_LIST_SET_FILTERING',
    HDI_TICKET_LIST_SET_DEFAULT_PROPERTIES: 'HDI_TICKET_LIST_SET_DEFAULT_PROPERTIES',

    //HDI Ticket
    HDI_UPDATE_TICKET: 'updateTicket',
    HDI_CREATE_TICKET: 'createTicket',
    HDI_GET_TICKET: 'getTicket',
    HDI_UPDATE_TICKET_SATISFACTION_CHECK: 'updateTicketSatisfactionCheck',

    HDI_TICKET_UPDATE_SATISFACTION_CHECK: 'HDI_TICKET_UPDATE_SATISFACTION_CHECK',
    HDI_TICKET_LOAD_DATA: 'HDI_TICKET_LOAD_DATA',
    HDI_TICKET_LIST_RESET: 'HDI_TICKET_LIST_RESET',

    HDI_TICKET_DETAIL_PENDING: 'HDI_TICKET_DETAIL_PENDING',
    // HDI_TICKET_DETAIL_SEND: 'HDI_TICKET_DETAIL_SEND', // not used
    // HDI_TICKET_DETAIL_SEND_ERROR: 'HDI_TICKET_DETAIL_SEND_ERROR', // not used
    HDI_TICKET_SEND: 'HDI_TICKET_SEND',
    HDI_TICKET_SEND_ERROR: 'HDI_TICKET_SEND_ERROR',

    HDI_TICKET_CREATE_PENDING: 'HDI_TICKET_CREATE_PENDING',
    HDI_TICKET_CREATED: 'HDI_TICKET_CREATED',
    // HDI_TICKET_CREATE_SEND: 'HDI_TICKET_CREATE_SEND', // not used
    // HDI_TICKET_CREATE_SEND_ERROR: 'HDI_TICKET_CREATE_SEND_ERROR', // not used
    HDI_TICKET_CREATE_ERROR: 'HDI_TICKET_CREATE_ERROR',
    HDI_TICKET_CREATE_FATAL: 'HDI_TICKET_CREATE_FATAL',

    HDI_TICKET_UPDATE_PENDING: 'HDI_TICKET_UPDATE_PENDING',
    HDI_TICKET_UPDATED: 'HDI_TICKET_UPDATED',
    HDI_TICKET_UPDATE_ERROR: 'HDI_TICKET_UPDATE_ERROR',
    // HDI_TICKET_UPDATE_SEND: 'HDI_TICKET_UPDATE_SEND', // not used
    // HDI_TICKET_UPDATE_SEND_ERROR: 'HDI_TICKET_UPDATE_SEND_ERROR', // not used
    HDI_TICKET_UPDATE_FATAL: 'HDI_TICKET_UPDATE_FATAL',

    HDI_TICKET_RESET: 'HDI_TICKET_RESET',
    TICKET_SAVE_NEW_ERROR: 'TICKET_SAVE_NEW_ERROR',
    TICKET_SAVE_UPDATE_ERROR: 'TICKET_SAVE_UPDATE_ERROR',

    //Attachment
    HDI_CREATE_ATTACHMENTS: 'createAttachments',
    HDI_DOWNLOAD_PRESIGNED_URL: 'attachmentDownloadPreSignedUrl',
    HDI_GENERATED_PRESIGNED_URLS: 'HDI_GENERATED_PRESIGNED_URLS',
    HDI_ATTACHMENTS_SAVED: 'HDI_TICKET_ATTACHMENTS_SAVED',
    HDI_ATTACHMENTS_SAVED_ERROR: 'HDI_TICKET_ATTACHMENTS_SAVED_ERROR',

    // EXPORT
    HDI_TICKET_EXPORT_GET: 'getExport',
    HDI_TICKET_EXPORT_CREATE: 'createTicketExport',
    HDI_TICKET_EXPORT_DOWNLOAD_URL_GET: 'exportDownloadPreSignedUrl',
    HDI_TICKET_EXPORT_SEND: 'HDI_TICKET_EXPORT_FETCH_SUCCESS',
    HDI_TICKET_EXPORT_SEND_ERROR: 'HDI_TICKET_EXPORT_FETCH_ERROR',
    HDI_TICKET_EXPORT_STATUS: 'HDI_TICKET_EXPORT_STATUS',
    HDI_TICKET_EXPORT_ERROR: 'HDI_TICKET_EXPORT_ERROR',
    HDI_TICKET_EXPORT_DOWNLOAD: 'HDI_TICKET_EXPORT_DOWNLOAD',
};
