import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import formMessages from '../../../../../intl/hdi/filterMessages';
import { ticketConstants } from '../../../../../constants/form/formTicketConstants';
import InputSelectFieldWithValidation from '../.././../../common/InputSelectFieldWithValidation';
import { getUserType } from '../../../../../constants/Utils';
import InputSelectFieldForObjectWithValidation from '../../../../common/InputSelectFieldForObjectWithValidation';
import {isVisibleForCountry, listEditorSolutionGroups} from '../../Utils';
import {
    setClientFilter,
    setFilteringVariable,
    setSolutionGroupFilter
} from '../../../../../actions/hdi/actionListPage';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const EditorFilter = (props) => {
    const handleChangeClient = (e, v) => {
        if (v && v !== '') {
            props.setClientFilter(v, true);
        } else {
            props.setClientFilter(v, false);
        }
        props.setFilteringVariable(true);
    };

    const handleChangeSolutionGroup = (e, v) => {
        if (v && v !== '') {
            props.setSolutionGroupFilter(v, true);
        } else {
            props.setSolutionGroupFilter(v, false);
        }
        props.setFilteringVariable(true);
    };

    return (
        <StyledDiv className="">
            <div className="form-row">
                <div className="col-6 pb-2">
                    <InputSelectFieldWithValidation
                        label={formMessages[ticketConstants.FORM_LABEL_SEARCH_CLIENT]}
                        field={ticketConstants.FORM_FIELD_SEARCH_CLIENT}
                        intlMessages={formMessages}
                        options={getUserType}
                        handleChange={handleChangeClient}
                        isSmall/>
                </div>
                <div className="col-6 pb-2">
                    <InputSelectFieldForObjectWithValidation
                        label={isVisibleForCountry(props.userDetail.group) ? formMessages[ticketConstants.FORM_LABEL_SEARCH_SOLUTION_GROUP_UK] : formMessages[ticketConstants.FORM_LABEL_SEARCH_SOLUTION_GROUP]}
                        field={ticketConstants.FORM_FIELD_SEARCH_SOLUTION_GROUP}
                        options={listEditorSolutionGroups(props.editorSolutionGroupList)}
                        handleChange={handleChangeSolutionGroup}
                        isSmall/>
                </div>
            </div>
        </StyledDiv>
    )

};

const mapStateToProps = (state, props) => ({
    adminSolutionGroupList: state.adminSolutionGroupList,
    editorSolutionGroupList: state.adminEditor,
    userDetail: state.profile.userDetail,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
    setSolutionGroupFilter: (value, bool) => dispatch(setSolutionGroupFilter(value, bool)),
    setClientFilter: (value, bool) => dispatch(setClientFilter(value, bool)),
    setFilteringVariable: (bool) => dispatch(setFilteringVariable(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorFilter);
