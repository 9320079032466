import { defineMessages } from 'react-intl';

const messages = defineMessages({
    PLACEHOLDER_SELECT_USER: {
        id: 'admin.authorizationManagement.selectUser.placeholder',
        defaultMessage: 'Select a user'
    },
    SELECT_USER: {
        id: 'admin.authorizationManagement.selectUser',
        defaultMessage: 'Select User'
    },
    SELECT_USER_SEARCH: {
        id: 'admin.authorizationManagement.selectUser.search',
        defaultMessage: 'Start searching by name or IPN...'
    },
    ADMIN_PLACEHOLDER_SELECT_ROLES: {
        id: 'admin.authorizationManagement.selectRoles.placeholder',
        defaultMessage: 'Select roles'
    },
    ADMIN_SELECT_ROLES: {
        id: 'admin.authorizationManagement.selectRoles',
        defaultMessage: 'Select roles'
    }
});

export default messages;
