import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    clearAdminRightsManagementPageData,
    fetchAdminRightsManagementPageData,
    resetManageUser,
    saveAdminRightsManagementPageData,
    setAdminGroupsChanged,
    setInitialAdminGroupOptions,
    setManagedUser,
    setSelectedAdminGroupOptions
} from '../../../actions/admin/actionAdminRightsManagement';
import SelectUser from './SelectUser';
import Loader from '../../Loader';
import SelectAdminGroups from './SelectAdminGroups';
import { adminRoles } from '../../../constants/Utils';
import { clearAlerts } from '../../../actions/alertsActions';
import { handleFetchUsersError } from '../../../actions/actionRole';
import { handleUserSearch } from '../../../utils/userUtils';
import commonMessages from '../../../intl/common/commonMessages';
import PageHeader from '../../common/PageHeader';

class AdminRightsManagementPage extends Component {
    componentDidMount() {
        this.props.clearAdminRightsManagementPageData();
        this.props.resetManageUser();
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setAdminGroupsChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleGroupSelectionChange = newOptions => {
        this.setSelectedOptionsChanged(newOptions);
        this.props.setSelectedAdminGroupOptions(newOptions);
    };

    handleAllGroupsClick = () => {
        const selectedGroups = {};

        Object.keys(this.props.adminData || {}).forEach(
            groupIso => selectedGroups[groupIso] = true
        );

        const selectedOptions = this.setAdminDataAsGroupOptions(selectedGroups);
        this.setSelectedOptionsChanged(selectedOptions);
    };

    handleSaveClick = () => {
        const selectedGroups = {};
        Object.keys(adminRoles).forEach(
            groupKey => selectedGroups[adminRoles[groupKey]] = false
        );

        if (this.props.selectedOptions) {
            this.props.selectedOptions.forEach(
                ({ value }) => {
                    selectedGroups[value] = true;
                }
            )
        }

        this.props.saveAdminRightsManagementPageData(this.props.managedUser, this.props.domain, selectedGroups);
    };

    handleCancelClick = () => {
        this.props.setSelectedAdminGroupOptions(cloneDeep(this.props.initialOptions));
        this.props.setAdminGroupsChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearAdminRightsManagementPageData();
        this.props.fetchAdminRightsManagementPageData(user, this.props.domain);
    };

    setAdminDataAsGroupOptions = adminData => {
        const result = Object.keys(adminData || {}).map(groupIso => ({ value: groupIso }));
        this.props.setSelectedAdminGroupOptions(cloneDeep(result));
        return result;
    };

    setSelectedOptionsChanged = newOptions => this.props.setAdminGroupsChanged(
        JSON.stringify(
            cloneDeep(this.props.initialOptions)
                .sort((option1, option2) => option1.value.localeCompare(option2.value))
        ) !== JSON.stringify(newOptions.sort((option1, option2) => option1.value.localeCompare(option2.value)))
    );

    render() {
        const { isLoading, managedUser, adminData, domain } = this.props;
        if (isLoading) {
            return <Loader/>;
        }

        return (
            <div>
                <PageHeader title={
                    <FormattedMessage id="admin.title.auth.mng"
                                      defaultMessage="Authorization Management - {domain} - Administrator"
                                      values={{
                                          domain: <FormattedMessage {...commonMessages[domain]}/>
                                      }}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {
                    Object.keys(adminData).length > 0 && managedUser && managedUser.ipn &&
                    <SelectAdminGroups handleGroupSelectionChange={this.handleGroupSelectionChange}
                                       handleAllGroupsClick={this.handleAllGroupsClick}
                                       handleSaveClick={this.handleSaveClick}
                                       handleCancelClick={this.handleCancelClick}/>
                }
            </div>
        );
    }
}

AdminRightsManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    setSelectedAdminGroupOptions: PropTypes.func.isRequired,
    adminData: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    saveAdminRightsManagementPageData: PropTypes.func.isRequired,
    initialOptions: PropTypes.array.isRequired,
    setAdminGroupsChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearAdminRightsManagementPageData: PropTypes.func.isRequired,
    fetchAdminRightsManagementPageData: PropTypes.func.isRequired,
    setInitialAdminGroupOptions: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired,
    resetManageUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    managedUser: state.adminRightsManagement.managedUser,
    adminData: state.adminRightsManagement.adminData,
    initialOptions: state.adminRightsManagement.initialAdminOptions,
    selectedOptions: state.adminRightsManagement.selectedAdminOptions
});

export default connect(mapStateToProps, {
    setManagedUser,
    fetchAdminRightsManagementPageData,
    setSelectedAdminGroupOptions,
    setAdminGroupsChanged,
    setInitialAdminGroupOptions,
    saveAdminRightsManagementPageData,
    clearAdminRightsManagementPageData,
    clearAlerts,
    handleFetchUsersError,
    resetManageUser
})(injectIntl(AdminRightsManagementPage));
