import React from 'react';
import styled from 'styled-components';
import { FormSection, reduxForm } from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import RenderSyncErrors from '../../../../common/RenderSyncErrors';
import { scrollContentWrapperToTop } from '../../../../../utils/utils';
import formMessages from '../../../../../intl/admin/templateMessages';
import { intlTemplateFieldMapping } from '../../../../../constants/fieldLabelMapping';
import { templateConstants } from '../../../../../constants/form/formTemplateConstants';
import DescriptionSection from './sections/description/DescriptionSection';
import SolutionGroupSection from './sections/solutionGroup/SolutionGroupSection';
import CountrySection from './sections/country/CountrySection';
import Loader from '../../../../Loader';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const TemplateNewForm = (props) => {
    const { form, reqFields, showForm, isLoadingListSolutionGroup, isLoadingSelectSolutionGroup, readOnly, handleSubmit, handleSelectSolutionGroup} = props;

    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form}
                              intlMessages={formMessages}
                              mapping={intlTemplateFieldMapping}/>
            {!isLoadingListSolutionGroup ?
                <FormSection name={templateConstants.FORM_TEMPLATE_SOLUTION_GROUP_SECTION}>
                    <SolutionGroupSection form={form}
                                          handleSelectSolutionGroup={handleSelectSolutionGroup}
                                          reqFields={reqFields}
                                          readOnly={readOnly}/>
                </FormSection> : <Loader/>
            }
            {!showForm && isLoadingSelectSolutionGroup && <Loader />}
            {showForm && !isLoadingSelectSolutionGroup && <>
                <FormSection name={templateConstants.FORM_TEMPLATE_COUNTRY_SECTION}>
                    <CountrySection form={form}
                                    reqFields={reqFields}
                                    readOnly={readOnly}/>
                </FormSection>
                <FormSection name={templateConstants.FORM_TEMPLATE_DESCRIPTION_SECTION}>
                    <DescriptionSection form={form}
                                        reqFields={reqFields}
                                        readOnly={readOnly}/>
                </FormSection>
                < SubmitButtons form={form}
                                reqFields={reqFields}
                                readOnly={readOnly}
                                onSubmit={handleSubmit}
                                onSubmitFail={scrollContentWrapperToTop}/>
            </>}
        </StyledDiv>
    )
};

export default reduxForm({})(TemplateNewForm);
