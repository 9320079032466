import { hdiSolutionGroupWebsocketCommands, } from '../../constants/hdiSolutionGroupWebsocketCommands';

export const adminSolutionGroup = (state = {
    correlationId: null,
    isSolutionGroupLoading: false,
    isSolutionGroupCreating: false,
    created: false,
    solutionGroup: {},
    userEmail: [],
    emailList: [],
    country: {},
    availableName: true
}, action) => {
    switch (action.type) {
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isSolutionGroupLoading: false,
                isSolutionGroupCreating: false,
                created: false,
                solutionGroup: {},
                userEmail: [],
                emailList: [],
                country: {},
                availableName: true
            });
        }
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_UPDATE_PENDING:
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_CREATE_PENDING: {
            return Object.assign({}, state, {
                isSolutionGroupCreating: true,
                isSolutionGroupLoading: true,
            });
        }

        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_CREATED:
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_UPDATED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isSolutionGroupCreating: false,
                created: true,
                isSolutionGroupLoading: true,
                correlationId
            });
        }

        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_SEND: {
            const { correlationId, payload, error } = action.payload;
            const { solutionGroup, country, userEmail, emailList } = payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isSolutionGroupLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                isSolutionGroupLoading: false,
                correlationId,
                solutionGroup,
                country,
                userEmail,
                emailList
            });
        }
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isSolutionGroupCreating: false,
                isSolutionGroupLoading: false,
                correlationId,
            });
        }

        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_CREATE_ERROR:
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_UPDATE_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isSolutionGroupCreating: false,
                created: false,
                isSolutionGroupLoading: false,
                correlationId,
            });
        }

        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_DETAIL_FETCH_PENDING: {
            return Object.assign({}, state, { isSolutionGroupLoading: true });
        }

        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_DETAIL_SEND: {
            const { payload, error, correlationId } = action.payload;
            const { solutionGroup, country, userEmail, emailList } = payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isSolutionGroupLoading: false, correlationId });
            }
            return Object.assign({}, state, {
                isSolutionGroupLoading: false,
                correlationId,
                solutionGroup,
                country,
                userEmail: Array.isArray(userEmail) ? [...new Set(userEmail)] : [],
                emailList: Array.isArray(emailList) ? [...new Set(emailList)] : [],
            });
        }
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_DETAIL_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isSolutionGroupLoading: false, correlationId });
        }
        case hdiSolutionGroupWebsocketCommands.HDI_ADD_CUSTOM_EMAIL: {
            const { emails } = action.payload;
            return Object.assign({}, state, { emailList: [...new Set(emails)] })
        }
        case hdiSolutionGroupWebsocketCommands.SOLUTION_GROUP_SAVE_NEW_ERROR:
        case hdiSolutionGroupWebsocketCommands.SOLUTION_GROUP_SAVE_UPDATE_ERROR: {
            return Object.assign({}, state, { isSolutionGroupLoading: false });
        }

        case hdiSolutionGroupWebsocketCommands.SOLUTION_GROUP_SET_AVAILABLE_NAME: {
            const { availableName } = action.payload;
            return Object.assign({}, state, { availableName });
        }

        default:
            return state;
    }
};
