import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import formMessages from '../../../../../../../intl/admin/templateMessages';
import PropTypes from 'prop-types';
import { templateConstants } from '../../../../../../../constants/form/formTemplateConstants';
import { change } from 'redux-form';
import InputSelectFieldForObjectWithValidation from '../../../../../../common/InputSelectFieldForObjectWithValidation';
import {isVisibleForCountry} from '../../../../utils';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const SolutionGroupSection = (props) => {
    const { readOnly, handleSelectSolutionGroup, solutionGroupData, userDetail } = props;
    const { shortListData } = solutionGroupData;

    const handleChange = (event, value) => {
        handleSelectSolutionGroup(value);
    };

    return (
        <StyledDiv className="border border-secondary px-4 py-2 bg-white">
            <div className="form-row">
                <div className="col pb-2">
                    <InputSelectFieldForObjectWithValidation
                        label={isVisibleForCountry(userDetail.group) ? formMessages[templateConstants.FORM_LABEL_TEMPLATE_SOLUTION_GROUP_UK] : formMessages[templateConstants.FORM_LABEL_TEMPLATE_SOLUTION_GROUP]}
                        field={templateConstants.FORM_FIELD_TEMPLATE_SOLUTION_GROUP}
                        options={shortListData.map(item => ({ label: item.shortName, value: item.id }))}
                        handleChange={handleChange}
                        isDisabled={readOnly}
                        isSmall/>
                </div>
            </div>
        </StyledDiv>
    )
};

SolutionGroupSection.propTypes = {
    intl: PropTypes.object.isRequired,
    templateData: PropTypes.object.isRequired,
    userDetail: PropTypes.object,
};

const mapStateToProps = (state) => ({
    templateData: state.adminTemplate,
    solutionGroupData: state.adminSolutionGroupList,
    userDetail: state.profile.userDetail,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SolutionGroupSection));
