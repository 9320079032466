import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MetisMenu from 'react-metismenu';
import RouterLink from 'react-metismenu-router-link'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'font-awesome/css/font-awesome.css';
import { HdiMenuEditor, HdiMenuUser, HdiMenuUserUK, HdiMenuAdmin } from './structure/HdiMenu';
import { PRIMARY_DARK_GREY, PRIMARY_WHITE } from '../../../theme/colors';
import { moduleRoles } from '../../../utils/roles';
import { getAdministrationMenuAdmin } from './structure/AdministrationMenu';

const Menu = styled.div`
    padding-top: 15px;
    width: 283px;
    transition: all 0.5s ease;
    direction: rtl;
`;
Menu.displayName = 'Menu';

const ScrollBarLeft = styled.div`
    direction: ltr;
    transition: all 0.5s ease;
`;
ScrollBarLeft.displayName = 'ScrollBarLeft';

const Wrapper = styled.div`
    ${props => props.sideBarShow ? 'margin-left: -280px' : 'margin-left: 0px'};
    width: 308px;
    height: 100%;
    display: flex;
    transition: all 0.5s ease;
    padding-left: 3px;
`;
Wrapper.displayName = 'Wrapper';

const ChevronCol = styled.div`
    border-right: 1px solid ${PRIMARY_DARK_GREY};
    width: 28px;
    padding-top: 50px;
    margin-left: 3px;
`;

const ChevronButton = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 25px;
    cursor: pointer;
    height: 50px;
    background-color: ${PRIMARY_WHITE};
    border: 1px solid ${PRIMARY_DARK_GREY};
    border-right: none;
    border-radius: 0.3em 0 0 0.3em;
    margin-left: 3px;
`;
ChevronCol.displayName = 'ChevronCol';

const ChevronIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    margin-left: 5px;
`;
ChevronIcon.displayName = 'ChevronIcon';

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = { sideBarShow: false, sideBarContent: [] };
    }

    componentDidMount() {
        this.createSideBarContent();
    }

    componentDidUpdate() {
        this.createSideBarContent();
    }

    handleChevronClick = e => {
        e.preventDefault();
        this.setState({ sideBarShow: !this.state.sideBarShow });
    };

    createSideBarContent = () => {
        const { hdi = false, administration = false, roles, userDetail } = this.props;
        let sideBarContent = [];

        if (administration && (moduleRoles.isAdmin(roles) || moduleRoles.isPowerUser(roles))) {
            sideBarContent = getAdministrationMenuAdmin(roles, userDetail.group);
        } else if (hdi) {
            if (moduleRoles.isEditor(roles)) {
                sideBarContent = HdiMenuEditor;
            }
            if (moduleRoles.isAdmin(roles)) {
                sideBarContent = [
                    ...sideBarContent,
                    ...HdiMenuAdmin,
                ];
            }
            if(userDetail.group === "UK" || userDetail.group === "CY" || userDetail.group === "IE" || userDetail.group === "MT"){
                sideBarContent = [
                    ...sideBarContent,
                    ...HdiMenuUserUK,
                ];
            }else{
                sideBarContent = [
                    ...sideBarContent,
                    ...HdiMenuUser,
                ];
            }
        }
        if (JSON.stringify(this.state.sideBarContent) !== JSON.stringify(sideBarContent)) {
            this.setState({ sideBarContent });
        }
    };

    render() {
        return (
            <Wrapper sideBarShow={this.state.sideBarShow}>
                <Menu>
                    <ScrollBarLeft>
                        <MetisMenu
                            content={this.state.sideBarContent}
                            LinkComponent={RouterLink}
                            useExternalReduxStore={this.props.store}
                        />
                    </ScrollBarLeft>
                </Menu>
                <ChevronCol>
                    <ChevronButton sideBarShow={this.state.sideBarShow} onClick={this.handleChevronClick}>
                        <ChevronIcon icon={this.state.sideBarShow ? 'chevron-right' : 'chevron-left'}/>
                    </ChevronButton>
                </ChevronCol>
            </Wrapper>
        );
    }
}

SideMenu.propTypes = {
    store: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
    userDetail: state.profile.userDetail,
});

export default connect(mapStateToProps, {})(SideMenu);
