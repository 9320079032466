import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {connect} from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    addFrequentQuestion, fetchFrequentQuestion, moveFrequentQuestion
} from '../../../actions/admin/actionAdminFrequentQuestion';
import {PrimaryButton} from '../../common/Button';
import {Table, Wrapper} from '../../common/StyledComponents';
import {moduleRoles} from '../../../utils/roles';
import FrequentQuestionDialog from './FrequentQuestionDialog';
import FrequentQuestionRow from './FrequentQuestionRow';
import Loader from '../../Loader';
import buttonMessages from '../../../intl/common/buttonMessages';
import frequentQuestionMessages from '../../../intl/admin/frequentQuestionMessages';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import adminMessages from '../../../intl/admin/adminMessages';
import {countries, getAllCountries} from '../../../constants/Utils';
import { fetchSolutionGroupListPageData } from '../../../actions/admin/actionSolutionGroupListPage';

const Button = styled(PrimaryButton)`
  margin-right: 5px;
`;

class FrequentQuestionContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {isOpenAddFrequentQuestionDialog: false};
    }

    componentDidMount() {
        this.props.fetchFrequentQuestion(this.props.selectedGroup);
        this.props.fetchSolutionGroupListPageData(0, 100, [], [{ id: 'country', value: countries.UK }]);
    }

    showAddModal = () =>
        this.setState({
            isOpenAddFrequentQuestionDialog: true,
        });

    closeModal = () =>
        this.setState({
            isOpenAddFrequentQuestionDialog: false
        });

    saveModal = ({groups = [], solutionGroups = [], message = ''}, problemId, attachmentId, attachment) => {
        this.setState({
            isOpenAddFrequentQuestionDialog: false
        })

        const item = {
            message,
            uuid: problemId,
            groups: groups.map(group => group.value),
            solutionGroups,
            userName: this.props.profile.name,
            updated: new Date().getTime()
        }

        // only for UK
        if(groups.some(g => g.value === countries.UK)) {
            // if empty we set all solutionGroups
            if(solutionGroups.length === 0) {
                item.solutionGroups = this.props.solutionGroupList.map(item => ({ id: item.id, shortName: item.solutionGroup.shortName }))
            } else {
                // map back to previous values
                item.solutionGroups = solutionGroups.map(d => ({ id: d.value, shortName: d.label}))
            }
        }

        if (attachment) {
            item.attachment = {
                uuid: attachmentId,
                name: attachment.name,
                type: attachment.type
            }
        }
        this.props.addFrequentQuestion(item);
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }
        // no movement
        if (result.destination.index === result.source.index) {
            return;
        }
        //swap
        const item = {
            group: this.props.selectedGroup,
            position: result.destination.index,
            uuid: this.props.frequentQuestions[result.source.index].uuid,
        };
        this.props.moveFrequentQuestion(item, result.source.index);
    };

    render() {
        const {
            isLoading, frequentQuestions, intl: {formatMessage}, selectedGroup,
            roles, solutionGroupList, isSolutionGroupListLoading
        } = this.props;

        const isUK = selectedGroup === countries.UK
        const sgOptions = solutionGroupList.map(item => ({ value: item.id, label: item.solutionGroup.shortName }))

        return (
            <Wrapper>
                <h5><FormattedMessage {...frequentQuestionMessages.FREQUENT_QUESTION}/></h5>
                {(isLoading || isSolutionGroupListLoading) && <Loader/>}
                {!(isLoading || isSolutionGroupListLoading) && <>
                    <Button
                        disabled={frequentQuestions.length > 9}
                        className="btn btn-sm mb-sm-3"
                        onClick={this.showAddModal}>
                        <FormattedMessage {...buttonMessages.ADD}/>
                    </Button>
                    {frequentQuestions.length === 0 &&
                    <div>
                        <FormattedMessage {...frequentQuestionMessages.EMPTY}/>
                    </div>
                    }
                    {frequentQuestions.length !== 0 &&
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className="card rounded">
                            <Table className="table table-hover">
                                <thead className="thead-light">
                                <tr className="d-flex">
                                    <th className="col-1"/>
                                    <th className={isUK ? "col-1" : "col-2"}>
                                        <FormattedMessage {...frequentQuestionMessages.COUNTRY}/>
                                    </th>
                                    {isUK && <th className="col-2">
                                        <FormattedMessage {...frequentQuestionMessages.DEPARTMENT}/>
                                    </th>}
                                    <th className={isUK ? "col-2" : "col-3"}>
                                        <FormattedMessage {...frequentQuestionMessages.FREQUENT_QUESTION}/>
                                    </th>
                                    <th className="col-2">
                                        <FormattedMessage {...frequentQuestionMessages.ATTACHMENT}/>
                                    </th>
                                    <th className="col-2">
                                        <FormattedMessage {...frequentQuestionMessages.LAST_UPDATE}/>
                                    </th>
                                    <th className="col-2">
                                        <FormattedMessage {...adminMessages.ACTION}/>
                                    </th>
                                </tr>
                                </thead>
                                <Droppable droppableId="table">
                                    {provided => {
                                        return (
                                            <tbody
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                            {frequentQuestions.map((frequentQuestion, index) =>
                                                <Draggable key={index} draggableId={`id-${index}`} index={index}>
                                                    {provided => {
                                                        return (
                                                            <FrequentQuestionRow
                                                                provided={provided}
                                                                index={index}
                                                                data={frequentQuestion}
                                                                sgOptions={sgOptions}
                                                            />
                                                        )
                                                    }}
                                                </Draggable>
                                            )}
                                            </tbody>
                                        )
                                    }}
                                </Droppable>
                            </Table>
                        </div>
                    </DragDropContext>
                    }
                    <FrequentQuestionDialog
                        show={this.state.isOpenAddFrequentQuestionDialog}
                        closeModal={this.closeModal}
                        saveModal={this.saveModal}
                        group={formatMessage(countryGroupMessages[selectedGroup])}
                        initialValues={{
                            groups: [getAllCountries()
                                .map(group => ({
                                    value: group.group,
                                    label: formatMessage(countryGroupMessages[group.group]),
                                    isFixed: true
                                })).find(group => group.value === selectedGroup)]
                        }}
                        sgOptions={sgOptions}
                    />
                </>}
            </Wrapper>
        );
    }
}

FrequentQuestionContainer.propTypes = {
    roles: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    frequentQuestions: PropTypes.array.isRequired,
    moveFrequentQuestion: PropTypes.func.isRequired,
    addFrequentQuestion: PropTypes.func.isRequired,
    fetchFrequentQuestion: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    selectedGroup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminFrequentQuestion.isLoading,
    frequentQuestions: state.adminFrequentQuestion.frequentQuestions,
    moveFrequentQuestion: state.moveFrequentQuestion,
    addFrequentQuestion: state.addFrequentQuestion,
    fetchFrequentQuestion: state.fetchFrequentQuestion,
    roles: state.profile.userDetail.roles,
    profile: state.profile.userDetail,
    intl: state.intl,
    isSolutionGroupListLoading: state.adminSolutionGroupList.isTableLoading,
    solutionGroupList: state.adminSolutionGroupList.listData
});

export default connect(mapStateToProps, {
    moveFrequentQuestion,
    addFrequentQuestion,
    fetchFrequentQuestion,
    // fetchSolutionGroupShortList,
    fetchSolutionGroupListPageData
})(injectIntl(FrequentQuestionContainer));
