export const hdiWebsocketCommands = {
    HDI_ADMIN_ROLE_GET: 'getAdminRole',
    HDI_ADMIN_ROLE_SET: 'setAdminRole',
    HDI_DEALER_ROLE_GET: 'getDealerRole',
    HDI_DEALER_ROLE_SET: 'setDealerRole',
    HDI_USER_LIST_PREFERENCE_SET: 'setUserListPreference',
    HDI_USER_FULLSEARCH_PREFERENCE_SET: 'setUserFullsearchPreference',
    HDI_PREFERENCE_GET: 'getPreferences',
    HDI_ROLES_ACCEPTED: 'HDI_ROLES_ACCEPTED',
    HDI_ROLES_ERROR: 'HDI_ROLES_ERROR',
    HDI_ADMIN_ROLE_SEND: 'HDI_ADMIN_ROLE_SEND',
    HDI_ADMIN_ROLE_SEND_ERROR: 'HDI_ADMIN_ROLE_SEND_ERROR',
    HDI_DEALER_ROLE_SEND: 'HDI_DEALER_ROLE_SEND',
    HDI_DEALER_ROLE_SEND_ERROR: 'HDI_DEALER_ROLE_SEND_ERROR',
    HDI_MAIN_MESSAGES_ACCEPTED: 'HDI_MAIN_MESSAGES_ACCEPTED',
    HDI_MAIN_MESSAGES_ERROR: 'HDI_MAIN_MESSAGES_ERROR',
    HDI_MAIN_MESSAGES_SEND: 'HDI_MAIN_MESSAGES_SEND',
    HDI_MAIN_MESSAGES_SEND_ERROR: 'HDI_MAIN_MESSAGES_SEND_ERROR',
    HDI_GROUPS_MAIN_MESSAGES_SEND: 'HDI_GROUPS_MAIN_MESSAGES_SEND',
    HDI_GROUPS_MAIN_MESSAGES_SEND_ERROR: 'HDI_GROUPS_MAIN_MESSAGES_SEND_ERROR',

    HDI_PREFERENCES_ACCEPTED: 'HDI_PREFERENCES_ACCEPTED',
    HDI_PREFERENCES_ERROR: 'HDI_PREFERENCES_ERROR',
    HDI_PREFERENCES_SEND: 'HDI_PREFERENCES_SEND',
    HDI_PREFERENCES_SEND_ERROR: 'HDI_PREFERENCES_SEND_ERROR',
};
