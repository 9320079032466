import { hdiTemplateWebsocketCommands } from '../../constants/hdiTemplateWebsocketCommands';
import { errorAlert } from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';
import { validateNewTemplate, validateUpdateTemplate } from '../../api/validation/template';

export const updateTemplate = (data) => dispatch => {
    const { error } = validateUpdateTemplate(data);
    if (error) {
        dispatch(errorAlert(alertMessages.TEMPLATE_UPDATE_SEND_ERROR, [error.toString()]));
    } else {
        const { dealer, subsidiary } = data;
        if (dealer.length === 0 && subsidiary.length === 0) {
            dispatch(errorAlert(alertMessages.TEMPLATE_MISSING_COUNTRY_ERROR));
        } else {
            return dispatch({
                type: hdiTemplateWebsocketCommands.HDI_TEMPLATE_UPDATE_PENDING,
                hdiWebsocket: true,
                command: hdiTemplateWebsocketCommands.HDI_UPDATE_TEMPLATE,
                payload: {
                    payload: {
                        ...data
                    }
                }
            });
        }
    }
};

export const saveTemplate = (data) => dispatch => {
    const { error } = validateNewTemplate(data);
    if (error) {
        dispatch(errorAlert(alertMessages.HDI_TEMPLATE_CREATE_ERROR, [error.toString()]));
    } else {
        const { dealer, subsidiary } = data;
        if (dealer.length === 0 && subsidiary.length === 0) {
            dispatch(errorAlert(alertMessages.TEMPLATE_MISSING_COUNTRY_ERROR));
        } else {
            return dispatch({
                type: hdiTemplateWebsocketCommands.HDI_TEMPLATE_CREATE_PENDING,
                hdiWebsocket: true,
                command: hdiTemplateWebsocketCommands.HDI_CREATE_TEMPLATE,
                payload: {
                    payload: {
                        ...data,
                    }
                }
            });
        }
    }
};

export const fetchTemplate = (id) => dispatch => {
    return dispatch({
        type: hdiTemplateWebsocketCommands.HDI_TEMPLATE_DETAIL_PENDING,
        hdiWebsocket: true,
        command: hdiTemplateWebsocketCommands.HDI_GET_TEMPLATE,
        payload: {
            template: {
                id,
            },
        }
    });
};

export const deleteTemplate = (id) => dispatch => {
    return dispatch({
        type: hdiTemplateWebsocketCommands.HDI_TEMPLATE_DELETE_PENDING,
        hdiWebsocket: true,
        command: hdiTemplateWebsocketCommands.HDI_DELETE_TEMPLATE,
        payload: {
            payload: {
                id,
            }
        }
    });
};

export const resetTemplate = () => dispatch => {
    return dispatch({
        type: hdiTemplateWebsocketCommands.HDI_TEMPLATE_RESET,
    });
};
