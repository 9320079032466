import { defineMessages } from 'react-intl';

const messages = defineMessages({
    'cs-CZ': {
        id: 'location.cs-cz',
        defaultMessage: 'Czechia'
    },
    'fr-FR': {
        id: 'location.fr-fr',
        defaultMessage: 'France'
    },
    'sv-SE': {
        id: 'location.sv-se',
        defaultMessage: 'Sweden'
    },
    'en-GB': {
        id: 'location.en-gb',
        defaultMessage: 'United Kingdom'
    },
    'pl-PL': {
        id: 'location.pl-pl',
        defaultMessage: 'Poland'
    }
});

export default messages;
