import { hdiTemplateWebsocketCommands, } from '../../constants/hdiTemplateWebsocketCommands';
import { hdiSolutionGroupWebsocketCommands, } from '../../constants/hdiSolutionGroupWebsocketCommands';

export const adminTemplate = (state = {
    correlationId: null,
    isTemplateLoading: false,
    isTemplateCreating: false,
    isSelectSolutionGroup: false,
    isSelectCountry: false,
    showForm: false,
    created: false,
    template: {},
    dealer: [],
    subsidiary: [],
    solutionGroups: [],
    solutionGroup: {},
    isSolutionGroupListLoading: false,
    isSolutionGroupDetailLoading: false,
    originalData: {}
}, action) => {
    switch (action.type) {
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTemplateLoading: false,
                isTemplateCreating: false,
                isSolutionGroupListLoading: false,
                isSolutionGroupDetailLoading: false,
                created: false,
                showForm: false,
                template: {},
                solutionGroups: [],
                solutionGroup: {},
                dealer: [],
                subsidiary: [],
                originalData: {}
            });
        }
        //Solution Group list
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_COUNTRY_PENDING: {
            return Object.assign({}, state, {
                isSolutionGroupDetailLoading: true,
            });
        }
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_COUNTRY_SEND: {
            const { correlationId, error, payload } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isSolutionGroupDetailLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                isSolutionGroupDetailLoading: false,
                showForm: true,
                correlationId,
                solutionGroup: {
                    ...payload
                }
            });
        }
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_COUNTRY_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isSolutionGroupDetailLoading: false,
                showForm: false,
                correlationId,
            });
        }
        //Template section
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_UPDATE_PENDING:
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_CREATE_PENDING: {
            return Object.assign({}, state, {
                isTemplateCreating: true,
                isTemplateLoading: true,
            });
        }

        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_CREATED:
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_UPDATED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTemplateCreating: false,
                created: true,
                isTemplateLoading: true,
                correlationId
            });
        }
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_CREATE_ERROR:
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_UPDATE_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTemplateCreating: false,
                created: false,
                isTemplateLoading: false,
                correlationId,
            });
        }

        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_DETAIL_PENDING: {
            return Object.assign({}, state, { isTemplateLoading: true });
        }

        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_SEND:
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_DETAIL_SEND: {
            const { correlationId, payload: { solutionGroup, template, subsidiary, dealer }, error } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isTemplateLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                isTemplateLoading: false,
                isTemplateCreating: false,
                correlationId,
                template,
                solutionGroup,
                subsidiary,
                dealer,
                originalData: {
                    template,
                    solutionGroup,
                    subsidiary,
                    dealer,
                }
            });
        }
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_SEND_ERROR:
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_DETAIL_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTemplateCreating: false,
                isTemplateLoading: false,
                correlationId,
            });
        }
        default:
            return state;
    }
};
