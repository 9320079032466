export const editorConstants = {
    //Sections
    FORM_EDITOR_SOLUTION_GROUP_SECTION: 'solutionGroupSection',
    FORM_EDITOR_EDITOR_SECTION: 'editorSection',
    FORM_EDITOR_USER_SECTION: 'userSection',
    //Fields
    FORM_FIELD_EDITOR_NAME: 'name',
    FORM_FIELD_EDITOR_IPN: 'ipn',
    FORM_FIELD_EDITOR_ON_HOLD: 'isOnHold',
    FORM_FIELD_EDITOR_SUSPEND: 'isSuspend',
    FORM_FIELD_EDITOR_DEFAULT_SG: 'defaultSolutionGroup',
    FORM_FIELD_EDITOR_SOLUTION_GROUP_ACCESS: 'solutionGroups',
    //Labels
    FORM_LABEL_EDITOR_NAME: 'EDITOR_NAME',
    FORM_LABEL_EDITOR_IPN: 'EDITOR_IPN',
    FORM_LABEL_EDITOR_ON_HOLD: 'EDITOR_ON_HOLD',
    FORM_LABEL_EDITOR_SUSPEND: 'EDITOR_SUSPEND',
    FORM_LABEL_EDITOR_DEFAULT_SG: 'EDITOR_DEFAULT_SG',
    FORM_LABEL_EDITOR_DEFAULT_SG_UK: 'EDITOR_DEFAULT_SG_UK',
    FORM_LABEL_EDITOR_SOLUTION_GROUP_ACCESS: 'EDITOR_SOLUTION_GROUP_ACCESS',

    FORM_LABEL_EDITOR_SECTION: 'FORM_LABEL_EDITOR_SECTION',
    FORM_LABEL_USER_SECTION: 'FORM_LABEL_USER_SECTION',
    FORM_LABEL_EDITOR_SOLUTION_GROUP_SECTION: 'FORM_LABEL_EDITOR_SOLUTION_GROUP_SECTION',
    FORM_LABEL_EDITOR_SOLUTION_GROUP_SECTION_UK: 'FORM_LABEL_EDITOR_SOLUTION_GROUP_SECTION_UK',

    FORM_LABEL_EDITOR_N_A: 'FORM_LABEL_EDITOR_N_A',
};
