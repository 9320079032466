import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import Table from '../../../common/table/TicketTable';
import { IconEdit, PrimaryIcon } from '../../../common/Button';
import { debounce } from 'throttle-debounce';
import { Link } from 'react-router-dom';
import { editListButtonVisibility } from '../../../../constants/ticketRights';
import moment from 'moment';
import listMessages from '../../../../intl/hdi/listMessages';
import {
    fetchListPageData,
    setDefaultTableProperties,
    setFilteringVariable
} from '../../../../actions/hdi/actionListPage';
import { moduleRoles } from '../../../../utils/roles';
import get from 'get-value';
import {fetchTemplateShortList, fetchTemplateShortListAll} from '../../../../actions/admin/actionAdminTemplateListPage';
import {isOlderThen1DayWithoutWeekend, isOlderThen2DaysWithoutWeekend} from '../../../../utils/timeUtil';


class ListTable extends React.Component {

    handleFetchDataDebounced = state => {
        debounce(1000, this.handleFetchData(state));
    };

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults)
            .reduce((acc, key) => {
                if (sessionStorage.hasOwnProperty(key) && key.match(/^listTicket*/)) {
                    let value = sessionStorage.getItem(key);
                    value = JSON.parse(value);
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, defaults);
    }

    saveStateToSessionStorage() {
        for (let key in this.props.defaultProperties) {
            if (this.props.defaultProperties.hasOwnProperty(key) && key.match(/^listTicket*/)) {
                sessionStorage.setItem(key, JSON.stringify(this.props.defaultProperties[key]));
            }
        }
    }

    componentDidMount() {
        if(this.props.templateShortListAll.length <= 0){
            this.props.fetchTemplateShortListAll();
            this.props.fetchTemplateShortList();
        }

        window.addEventListener('beforeunload', this.saveStateToSessionStorage.bind(this));

        let defaultValues;
        const { userRoles, setDefaultTableProperties, loggedUser } = this.props;
        const defaultSolutionGroup = get(loggedUser, 'roles.editor.defaultSolutionGroup', { default: false });
        if (moduleRoles.isEditor(userRoles)) {
            defaultValues = {
                listTicketDefaultPageSize: 10,
                listTicketDefaultSort: [{ id: 'ticketNumber', desc: true }],
                listTicketDefaultFilters: defaultSolutionGroup ? [{
                    id: 'solutionGroup',
                    value: defaultSolutionGroup
                }] : [],
                listTicketFSDefaultPageSize: 10,
                listTicketFSDefaultSort: [{ id: 'ticketNumber', desc: true }],
                listTicketFSDefaultFilters: [],
            };
        } else {
            defaultValues = {
                listTicketDefaultPageSize: 10,
                listTicketDefaultSort: [{ id: 'ticketNumber', desc: true }],
                listTicketDefaultFilters: [],
                listTicketFSDefaultPageSize: 10,
                listTicketFSDefaultSort: [{ id: 'ticketNumber', desc: true }],
                listTicketFSDefaultFilters: [],
            };
        }

        const loadedState = this.loadStateFromSessionStorage(defaultValues);
        console.log("loadedState")
        setDefaultTableProperties(loadedState);

    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.saveStateToSessionStorage.bind(this));
        this.saveStateToSessionStorage();
    }

    handleFetchData = state => {
        this.props.setFilteringVariable(false);
        this.props.setDefaultTableProperties({
            listTicketDefaultPageSize: state.pageSize,
            listTicketDefaultSort: state.sorted,
            listTicketDefaultFilters: state.filtered,
            listTicketFSDefaultPageSize: this.props.defaultProperties.listTicketFSDefaultPageSize,
            listTicketFSDefaultSort: this.props.defaultProperties.listTicketFSDefaultSort,
            listTicketFSDefaultFilters: this.props.defaultProperties.listTicketFSDefaultFilters,
        });
        this.props.fetchListPageData(state.pageSize, state.sorted, state.cursor, state.isNextFromPivot, this.normalizeFiltered(state));
    };

    fetchStrategy = (state) => {
        // if (this.props.filtering)
            this.handleFetchDataDebounced(state); /*this.handleFetchData(state)*/
    };

    render() {
        const { columns, data, pages, isLoading, handleEditClick, defaultProperties, loggedUser, editorSolutionGroups, isNextPage, isNextFromPivot, templateShortList, templateShortListAll, intl, userRoles } = this.props;
        const { listTicketDefaultPageSize, listTicketDefaultSort, listTicketDefaultFilters } = defaultProperties;

        const modifiedcolumns = columns.map(oldData => (
        (get(oldData, "id") === "templateId") ?
            {
            ...oldData,
                Filter: ({ filter, onChange }) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: '100%', border: '1px solid #999999' }}
                        value={filter ? filter.value : ''}>
                        <option value="">{intl.formatMessage(listMessages.ALL)}</option>
                        {
                            (listTicketDefaultFilters.length > 0 && listTicketDefaultFilters.some(element => element.id ===  "solutionGroup")) ?
                                templateShortList.map(template => (
                                (template.solutionGroupId === get(listTicketDefaultFilters.find(element => element.id ===  "solutionGroup"),"value")) ?
                                    <option value={template.id}>{template.subject}</option>
                                    : ""
                            ))
                                :
                                (moduleRoles.isEditor(userRoles)) ?
                                    templateShortList.map((template, i) => (
                                        (editorSolutionGroups.find(element => element.id === template.solutionGroupId)) ?
                                            <option key={i} value={template.id}>{template.subject}</option>
                                            : ""
                                    ))
                                    :
                                    templateShortList.map((template, i) => (
                                        <option key={i} value={template.id}>{template.subject}</option>
                                    ))
                        }
                    </select>
                ),
            }
        : oldData

    ));

        const modifiedData = data.map(oldData => ({
            ...oldData,
            templateId: (templateShortListAll !== undefined && templateShortListAll !== null && templateShortListAll.length > 0) ?
                get(templateShortListAll.find(template => template.id === oldData.templateId), 'subject', "")
                : "",
            style: {
                bold: (oldData.status?.toLowerCase() === 'opened' || oldData.status?.toLowerCase() === 'reopened')
                    && isOlderThen2DaysWithoutWeekend(oldData.lastModify)
                    && moduleRoles.isEditor(userRoles),
                red: oldData.status?.toLowerCase() === 'new'
                    && isOlderThen1DayWithoutWeekend(oldData.createdDate)
                    && moduleRoles.isEditor(userRoles),
            }
        }));

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'ticketNumber',
                                    accessor: 'ticketNumber',
                                    message: listMessages.TABLE_TICKET_NUMBER,
                                    Cell: (row) => <Link style={{color: get(row, 'original.style.red') ? 'red' : undefined}}
                                                     to={`view/${row.original.id}`}>{row.value}</Link>
                                },
                                ...modifiedcolumns]}
                            toolButtons={[
                                {
                                    handleClick: handleEditClick,
                                    component: <PrimaryIcon className="btn btn-sm"
                                                            title="Edit"
                                                            type="button"
                                                            key="editButton">
                                        <IconEdit/>
                                    </PrimaryIcon>
                                }
                            ]}
                            isLoading={isLoading}
                            data={modifiedData}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            handleOnFilteredChange={() => {
                                this.props.setFilteringVariable(true);
                            }}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={listTicketDefaultFilters}
                            defaultSorting={listTicketDefaultSort}
                            defaultPageSize={listTicketDefaultPageSize}
                            buttonVisibility={editListButtonVisibility}
                            tableType="TICKET"
                            loggedUser={loggedUser}
                            editorSG={editorSolutionGroups}
                        />
                    </div>
                </div>
            </div>
        )
    }

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date || filterItem.id === 'createdDate' || filterItem.id === 'lastModify') {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };
}

ListTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    filtering: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    editorSolutionGroups: PropTypes.array.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    pages: PropTypes.number.isRequired,
    fetchListPageData: PropTypes.func.isRequired,
    columns: PropTypes.array,
    userRoles: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    defaultProperties: PropTypes.object.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    setFilteringVariable: PropTypes.func.isRequired,
    setDefaultTableProperties: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.listTicket.isTableLoading,
    data: state.listTicket.listData,
    isNextPage: state.listTicket.isNextPage,
    isNextFromPivot: state.listTicket.isNextFromPivot,
    pages: state.listTicket.pages,
    userRoles: state.profile.userDetail.roles,
    loggedUser: state.profile.userDetail,
    filtering: state.listTicket.filtering,
    defaultProperties: state.listTicket.defaultProperties,
    editorSolutionGroups: state.adminEditor.editorSolutionGroups,
    templateShortList: state.adminTemplateList.shortListData,
    templateShortListAll: state.adminTemplateList.shortListAllData,
});

export default connect(mapStateToProps, {
    fetchListPageData,
    setFilteringVariable,
    setDefaultTableProperties,
    fetchTemplateShortList,
    fetchTemplateShortListAll,
})(ListTable);
