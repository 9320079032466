import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import Table from '../../../common/table/TicketTable';
import { debounce } from 'throttle-debounce';
import { Link } from 'react-router-dom';
import moment from 'moment';
import listMessages from '../../../../intl/hdi/listMessages';
import {
    fetchListPageData,
    setDefaultTableProperties,
    setFilteringVariable
} from '../../../../actions/hdi/actionListPage';


class FulltextSearchListTable extends React.Component {

    handleFetchDataDebounced = state => {
        debounce(1000, this.handleFetchData(state));
    };

    // componentDidMount() {
    //     setDefaultTableProperties({
    //         listTicketDefaultPageSize: 10,
    //         listTicketDefaultSort: [{ id: 'ticketNumber', desc: true }],
    //         listTicketDefaultFilters: this.props.defaultProperties.listTicketDefaultFilters,
    //         listTicketFSDefaultPageSize: 10,
    //         listTicketFSDefaultSort: [{ id: 'ticketNumber', desc: true }],
    //         listTicketFSDefaultFilters: [],
    //     });
    // }

    handleFetchData = state => {
        this.props.setFilteringVariable(false);
        this.props.setDefaultTableProperties({
            listTicketDefaultPageSize: this.props.defaultProperties.listTicketDefaultPageSize,
            listTicketDefaultSort: this.props.defaultProperties.listTicketDefaultSort,
            listTicketDefaultFilters: this.props.defaultProperties.listTicketDefaultFilters,
            listTicketFSDefaultPageSize: state.pageSize,
            listTicketFSDefaultSort: state.sorted,
            listTicketFSDefaultFilters: state.filtered
        });
        if(this.props.isLoading === false){
            this.props.fetchListPageData(state.pageSize, state.sorted, state.cursor, state.isNextFromPivot, this.normalizeFiltered(state));
        }
    };

    fetchStrategy = (state) => {
            this.handleFetchDataDebounced(state); /*this.handleFetchData(state)*/
    };

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date) {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const { columns, data, pages, isLoading, defaultProperties } = this.props;
        const { listTicketFSDefaultPageSize, listTicketFSDefaultSort, listTicketFSDefaultFilters } = defaultProperties;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'ticketNumber',
                                    accessor: 'ticketNumber',
                                    message: listMessages.TABLE_TICKET_NUMBER,
                                    filterable: false,
                                    Cell: (row) => <Link
                                        to={`view/${row.original.id}`}>{row.value}</Link>,
                                },
                                ...columns]}
                            toolButtons={[]}
                            isLoading={isLoading}
                            data={data}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            handleOnFilteredChange={() => {
                                this.props.setFilteringVariable(true);
                            }}
                            defaultFiltered={listTicketFSDefaultFilters}
                            defaultSorting={listTicketFSDefaultSort}
                            defaultPageSize={listTicketFSDefaultPageSize}
                            tableType="FULLSEARCH"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

FulltextSearchListTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    filtering: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number.isRequired,
    fetchListPageData: PropTypes.func.isRequired,
    columns: PropTypes.array,
    userRoles: PropTypes.object.isRequired,
    defaultProperties: PropTypes.object.isRequired,
    setFilteringVariable: PropTypes.func.isRequired,
    setDefaultTableProperties: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.listTicket.isTableLoading,
    data: state.listTicket.listData,
    pages: state.listTicket.pages,
    userRoles: state.profile.userDetail.roles,
    filtering: state.listTicket.filtering,
    defaultProperties: state.listTicket.defaultProperties,
});

export default connect(mapStateToProps, {
    fetchListPageData,
    setFilteringVariable,
    setDefaultTableProperties
})(FulltextSearchListTable);
