import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    HDI_PATH,
    HDI_TICKET_EXPORT_PATH,
    HDI_TICKET_LIST_PATH,
    HDI_TICKET_NEW_PATH,
    HDI_TICKET_SEARCH_PATH
} from '../../routes/paths';
import ListPage from './ticket/list/ListPage';
import { domains } from '../../constants/Utils';
import ViewTicketPage from './ticket/detail/ViewTicketPage';
import EditTicketPage from './ticket/detail/EditTicketPage';
import NewTicketPage from './ticket/detail/NewTicketPage';
import { HDI_TICKET_FORM } from '../../constants/formConstants';
import FulltextSearchListPage from './ticket/fullsearch/FulltextSearchListPage';
import ExportPage from './export/ExportPage';

const FlexWrapper = styled.div`
    padding-left: 0;
    display:flex;
`;

class Hdi extends Component {

    render() {
        return (
            <FlexWrapper>
                <Switch>
                    <Route exact path={HDI_PATH}
                           render={() => <Redirect to={HDI_TICKET_LIST_PATH}/>}/>
                    <Route exact path={HDI_TICKET_LIST_PATH}
                           render={() => <ListPage domain={domains}/>}/>
                    <Route exact path={HDI_TICKET_SEARCH_PATH}
                           render={() => <FulltextSearchListPage domain={domains}/>}/>
                    <Route exact path={HDI_TICKET_EXPORT_PATH}
                           component={ExportPage}/>
                    <Route exact path={HDI_PATH + '/:view(view)/:ticketUuid(.*)'}
                           render={() => <ViewTicketPage domain={domains}/>}/>
                    <Route exact path={HDI_PATH + '/:edit(edit)/:ticketUuid(.*)'}
                           render={() => <EditTicketPage domain={domains}/>}/>
                    <Route exact path={HDI_TICKET_NEW_PATH}
                           render={() => <NewTicketPage formType={HDI_TICKET_FORM}/>}/>
                </Switch>
            </FlexWrapper>
        );
    }
}

Hdi.propTypes = {
    userDetail: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    isToggled: state.menu.isToggled,
    userDetail: state.profile.userDetail,
});

export default connect(mapStateToProps, null)(Hdi);
