import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../Loader';
import { initNewEditor, transformNewEditorForSave } from '../utils';
import { createReqFields } from '../../../../constants/requiredFields';
import PageHeader from '../../../common/PageHeader';
import get from 'get-value';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import { ADMINISTRATION_EDITOR_PATH } from '../../../../routes/paths';
import {
    addDefaultSolvingGroupList,
    checkEditor,
    fetchEditor,
    resetEditor,
    saveEditor
} from '../../../../actions/admin/actionAdminEditor';
import { handleUserSearch } from '../../../../utils/userUtils';
import { handleFetchUsersError } from '../../../../actions/actionRole';
import { resetManageUser, setManagedUser } from '../../../../actions/admin/actionAdminRightsManagement';
import EditorNewForm from './forms/EditorNewForm';
import { HDI_EDITOR_FORM } from '../../../../constants/formConstants';
import editorMessages from '../../../../intl/admin/editorMessages';
import { FormattedMessage } from 'react-intl';

class NewEditorPage extends Component {
    constructor(props) {
        super(props);
        this.state = { editorSubmittedAndShouldRedirect: false };
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetManageUser();
        this.props.resetEditor();
    }

    handleSubmit = (values) => {
        const dataForSend = transformNewEditorForSave(values.newValues);
        this.props.saveEditor(dataForSend);
        this.setState({ editorSubmittedAndShouldRedirect: true });
    };

    handleAddToDefaultSolvingGroupList = (value) => {
        this.props.addDefaultSolvingGroupList(value);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.props.checkEditor(user.ipn);
    };

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    render() {
        const {
            editorData,
            userDetail,
        } = this.props;

        if (this.state.editorSubmittedAndShouldRedirect && !editorData.isEditorCreating && !editorData.isEditorLoading && get(editorData, 'editor.ipn', { default: false })) {
            return <Redirect to={{
                pathname: `${ADMINISTRATION_EDITOR_PATH}/view/${editorData.editor.ipn}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (editorData.isEditorCreating
            || editorData.isEditorLoading
            || this.state.editorSubmittedAndShouldRedirect
        ) {
            return <Loader/>;
        }

        let initializationValue = initNewEditor(userDetail, editorData);

        const requiredFields = createReqFields['DEFAULT'][HDI_EDITOR_FORM];
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...editorMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <EditorNewForm
                            form={HDI_EDITOR_FORM}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            isLoadedData={!editorData.alreadyExist && editorData.showCreating}
                            showError={!editorData.showCreating && editorData.alreadyExist}
                            isEditorChecking={editorData.isEditorChecking}
                            handleAddSG={this.handleAddToDefaultSolvingGroupList}
                            handleUserSelection={this.handleUserSelection}
                            handleUserSearch={this.handleUserSearch}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

NewEditorPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    editorData: PropTypes.object.isRequired,
    fetchEditor: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any,
    resetEditor: PropTypes.func.isRequired,
    saveEditor: PropTypes.func.isRequired,
    addDefaultSolvingGroupList: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    checkEditor: PropTypes.func.isRequired,
    resetManageUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    clearAlerts: PropTypes.func.isRequired,
    editorData: state.adminEditor,
    userDetail: state.profile.userDetail,
    managedUser: state.adminRightsManagement.managedUser,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchEditor,
    saveEditor,
    resetEditor,
    addDefaultSolvingGroupList,
    handleFetchUsersError,
    setManagedUser,
    checkEditor,
    resetManageUser
})(NewEditorPage)), ['HDI_ADMIN']);

