export const substitutionConstants = {
    //Sections
    FORM_SUBSTITUTION_SECTION: 'substitutionSection',
    //Fields
    FORM_FIELD_SUBSTITUTION_USER: 'substitutionUser',
    FORM_FIELD_SUBSTITUTION_TYPE: 'substitutionType',
    FORM_FIELD_SUBSTITUTION_IPN: 'substitutionIpn',
    FORM_FIELD_SUBSTITUTION_SURNAME: 'substitutionSurname',
    FORM_FIELD_SUBSTITUTION_NAME: 'substitutionName',
    FORM_FIELD_SUBSTITUTION_COUNTRY: 'substitutionCountry',
    FORM_FIELD_SUBSTITUTION_BIR: 'substitutionBir',
    FORM_FIELD_SUBSTITUTION_COMPANY: 'substitutionCompany',
    //Labels
    FORM_LABEL_SUBSTITUTION_USER: 'SUBSTITUTION_USER',
    FORM_LABEL_SUBSTITUTION_TYPE: 'SUBSTITUTION_TYPE',
    FORM_LABEL_SUBSTITUTION_IPN: 'SUBSTITUTION_IPN',
    FORM_LABEL_SUBSTITUTION_SURNAME: 'SUBSTITUTION_SURNAME',
    FORM_LABEL_SUBSTITUTION_NAME: 'SUBSTITUTION_NAME',
    FORM_LABEL_SUBSTITUTION_COUNTRY: 'SUBSTITUTION_COUNTRY',
    FORM_LABEL_SUBSTITUTION_BIR: 'SUBSTITUTION_BIR',
    FORM_LABEL_SUBSTITUTION_COMPANY: 'SUBSTITUTION_COMPANY',
};

export const SUBSIDIARY = 'SUBSIDIARY';
export const R_NET = 'R_NET';
