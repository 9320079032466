export const hdiFrequentQuestionsWebsocketCommands = {
    // FILE
    HDI_FREQUENT_QUESTION_FILE_DOWNLOAD: 'frequentQuestionAttachmentDownload',
    HDI_FREQUENT_QUESTION_FILE_UPLOAD: 'frequentQuestionAttachmentUpload',
    // FREQUENT QUESTION
    HDI_FREQUENT_QUESTION_GET: 'getFrequentQuestions',
    HDI_FREQUENT_QUESTION_CREATE: 'createFrequentQuestion',
    HDI_FREQUENT_QUESTION_MOVE: 'moveFrequentQuestion',
    HDI_FREQUENT_QUESTION_DELETE: 'deleteFrequentQuestion',
    HDI_FREQUENT_QUESTION_UPDATE: 'updateFrequentQuestion',
    // FREQUENT QUESTION
    HDI_FREQUENT_QUESTION_ERROR: 'HDI_FREQUENT_QUESTIONS_ERROR',
    HDI_FREQUENT_QUESTION_SEND: 'HDI_FREQUENT_QUESTIONS_SEND',
    HDI_FREQUENT_QUESTION_SEND_ERROR: 'HDI_FREQUENT_QUESTIONS_SEND_ERROR',
    HDI_FREQUENT_QUESTION_ATTACHMENTS_DOWNLOAD: 'HDI_FREQUENT_QUESTIONS_ATTACHMENTS_DOWNLOAD',
    HDI_FREQUENT_QUESTION_ATTACHMENTS_UPLOAD: 'HDI_FREQUENT_QUESTIONS_ATTACHMENTS_UPLOAD',
};
