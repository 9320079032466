import {
    HDI_EDITOR_FORM,
    HDI_SOLUTION_GROUP_FORM,
    HDI_TEMPLATE_FORM,
    HDI_TICKET_FORM,
    HDI_WELCOME_FORM,
} from './formConstants';
import { ticketConstants } from './form/formTicketConstants';
import { solutionGroupConstants } from './form/formSolutionGroupConstants';
import { editorConstants } from './form/formEditorConstants';
import { templateConstants } from './form/formTemplateConstants';
import { welcomeConstants } from './form/formWelcomeConstants';

export const editReqFields = {
    'DEFAULT': {
        'EDITOR': {
            [HDI_TICKET_FORM]: [
                //ticketConstants.FORM_FIELD_DESCRIPTION,
            ],
            [HDI_SOLUTION_GROUP_FORM]: [
                solutionGroupConstants.FORM_FIELD_SG_LONG_NAME,
                solutionGroupConstants.FORM_FIELD_SG_ACTIVE,
                solutionGroupConstants.FORM_FIELD_SG_COUNTRIES,
                solutionGroupConstants.FORM_FIELD_SG_LEVEL
            ],
            [HDI_EDITOR_FORM]: [
                //editorConstants.FORM_FIELD_EDITOR_DEFAULT_SG,
                editorConstants.FORM_FIELD_EDITOR_SOLUTION_GROUP_ACCESS,
            ],
            [HDI_TEMPLATE_FORM]: [
                templateConstants.FORM_FIELD_TEMPLATE_SOLUTION_GROUP,
                templateConstants.FORM_FIELD_TEMPLATE_SUBJECT,
                templateConstants.FORM_FIELD_TEMPLATE_DESCRIPTION,
            ],
        }
    },
};

export const createReqFields = {
    'DEFAULT': {
        [HDI_TICKET_FORM]: [
            ticketConstants.FORM_FIELD_DESCRIPTION,
            ticketConstants.FORM_FIELD_SUBJECT
        ],
        [HDI_SOLUTION_GROUP_FORM]: [
            solutionGroupConstants.FORM_FIELD_SG_NAME,
            solutionGroupConstants.FORM_FIELD_SG_LONG_NAME,
            solutionGroupConstants.FORM_FIELD_SG_ACTIVE,
            solutionGroupConstants.FORM_FIELD_SG_COUNTRIES,
            solutionGroupConstants.FORM_FIELD_SG_LEVEL
        ],
        [HDI_EDITOR_FORM]: [
            editorConstants.FORM_FIELD_EDITOR_DEFAULT_SG,
            editorConstants.FORM_FIELD_EDITOR_SOLUTION_GROUP_ACCESS,
        ],
        [HDI_TEMPLATE_FORM]: [
            templateConstants.FORM_FIELD_TEMPLATE_SOLUTION_GROUP,
            templateConstants.FORM_FIELD_TEMPLATE_SUBJECT,
            templateConstants.FORM_FIELD_TEMPLATE_DESCRIPTION,
        ],
        [HDI_WELCOME_FORM]: [
            welcomeConstants.FORM_FIELD_WELCOME_MESSAGE
        ],
    }
};

