import { defineMessages } from 'react-intl';
import { R_NET, SUBSIDIARY, substitutionConstants } from '../../constants/form/formSubstitutionConstants';

export const messages = defineMessages({
    [substitutionConstants.FORM_LABEL_SUBSTITUTION_TYPE]: {
        id: 'user.type',
        defaultMessage: 'Client'
    },
    [SUBSIDIARY]: {
        id: 'user.type.subsidiary',
        defaultMessage: 'Subsidiary'
    },
    [R_NET]: {
        id: 'user.type.rNet',
        defaultMessage: 'Dealer'
    },
    [substitutionConstants.FORM_LABEL_SUBSTITUTION_NAME]: {
        id: 'user.substitution.name',
        defaultMessage: 'Name'
    },
    [substitutionConstants.FORM_LABEL_SUBSTITUTION_SURNAME]: {
        id: 'user.substitution.surname',
        defaultMessage: 'Surname'
    },
    [substitutionConstants.FORM_LABEL_SUBSTITUTION_IPN]: {
        id: 'user.substitution.ipn',
        defaultMessage: 'IPN'
    },
    [substitutionConstants.FORM_LABEL_SUBSTITUTION_COUNTRY]: {
        id: 'user.substitution.country',
        defaultMessage: 'Country'
    },
    [substitutionConstants.FORM_LABEL_SUBSTITUTION_BIR]: {
        id: 'user.substitution.bir',
        defaultMessage: 'BIR'
    },
    [substitutionConstants.FORM_LABEL_SUBSTITUTION_COMPANY]: {
        id: 'user.substitution.company',
        defaultMessage: 'Company'
    },
    [substitutionConstants.FORM_LABEL_SUBSTITUTION_USER]: {
        id: 'user.substitution.user',
        defaultMessage: 'Substitution as user'
    },
});

export default messages;
