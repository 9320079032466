import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'redux-form';
import { RequiredSpan } from './StyledComponents';
import { RenderMultipleInput } from './FormField';
import { required } from './validationCommons';

const InputMultipleInputField = (props) => {
    const { label, field, options, isDisabled, inputValue, value, validations, isRequired, placeholder, onHandleChange, isMulti, isSearchable, isClearable, onHandleInputChange, onHandleKeyDown } = props;

    let newValidations = validations;
    if (isRequired) {
        validations.push(required);
    }

    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
                {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>
            }
            <Field name={field}
                   component={RenderMultipleInput}
                   validate={newValidations}
                   inputValue={inputValue}
                   value={value}
                   options={options}
                   isMulti={isMulti}
                   isSearchable={isSearchable}
                   isDisabled={isDisabled}
                   isClearable={isClearable}
                   handleChange={onHandleChange}
                   handleInputChange={onHandleInputChange}
                   handleKeyDown={onHandleKeyDown}
                   placeholder={placeholder}/>
        </div>
    )
};
export default (injectIntl(InputMultipleInputField));
