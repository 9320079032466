import React, { Component } from 'react';
import Loader from '../../../Loader';
import { HDI_DEFAULT_SOLUTION_GROUP_FORM, } from '../../../../constants/formConstants';
import PropTypes from 'prop-types';
import PageHeader from '../../../common/PageHeader';
import { initLoadedSolutionGroups, transformUpdateSolutionGroupsForSave } from './utils';
import DefaultForm from './form/DefaultForm';
import { clearAlerts } from '../../../../actions/alertsActions';
import {
    fetchDefaultSolutionGroup,
    resetDefaultSolutionGroup,
    updateDefaultSolutionGroup
} from '../../../../actions/admin/actionSolutionGroupDefault';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import get from 'get-value';
import solutionGroupMessages from '../../../../intl/admin/solutionGroupMessages';
import {isVisibleForCountry} from '../../template/utils';

class DefaultSolutionGroupPage extends Component {

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetDefaultSolutionGroup();
        this.props.fetchDefaultSolutionGroup();
    }

    handleSubmit = (values) => {
        const { data } = this.props;
        const dataForSend = transformUpdateSolutionGroupsForSave(values, data);
        this.props.updateDefaultSolutionGroup(dataForSend);
    };

    render() {
        const {
            isLoading,
            data,
            userDetail
        } = this.props;

        let loadingChecker = true;
        if (get(data, 'CZ.solutionGroups', { default: false })) {
            loadingChecker = false;
        }

        const initializationValue = initLoadedSolutionGroups(data);
        if (isLoading || loadingChecker) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={isVisibleForCountry(userDetail.group) ? <FormattedMessage {...solutionGroupMessages.DEFAULT_TITLE_UK}/> : <FormattedMessage {...solutionGroupMessages.DEFAULT_TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <DefaultForm
                            form={HDI_DEFAULT_SOLUTION_GROUP_FORM}
                            initialValues={initializationValue}
                            enableReinitialize={true}
                            reqFields={[]}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    data: state.adminSolutionGroupDefault.data,
    userDetail: state.profile.userDetail,
    isLoading: state.adminSolutionGroupDefault.isLoading,
});

DefaultSolutionGroupPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    resetDefaultSolutionGroup: PropTypes.func.isRequired,
    fetchDefaultSolutionGroup: PropTypes.func.isRequired,
    updateDefaultSolutionGroup: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    userDetail: PropTypes.object,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    resetDefaultSolutionGroup,
    fetchDefaultSolutionGroup,
    updateDefaultSolutionGroup
})(injectIntl(DefaultSolutionGroupPage))), ['HDI_ADMIN']);

