import { put, select, takeEvery } from 'redux-saga/effects';
import { hdiTicketWebsocketCommands } from '../constants/hdiTicketWebsocketCommands';
import { push } from 'react-router-redux';
import get from 'get-value';
import { fetchSolutionGroupShortList } from '../actions/admin/actionSolutionGroupListPage';
import { fetchTicket } from '../actions/hdi/actionTicket';
import { validateFetchTicket } from '../api/validation/ticket';
import { errorAlert } from '../actions/alertsActions';
import alertMessages from '../intl/common/alertMessages';

export function* handleTicketError(action) {
    const stateCorrelationId = get(yield select(state => state.ticket), `correlationId`, { default: undefined });
    const { correlationId } = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('/hdi'));
    yield put({ type: hdiTicketWebsocketCommands.HDI_TICKET_RESET });
}

export function* handleTicketLoadData(action) {
    const stateCorrelationId = get(yield select(state => state.ticket), `correlationId`, { default: undefined });
    const { correlationId, id, ipn } = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(fetchTicket(id, ipn));
    yield put(fetchSolutionGroupShortList());
}

export function* handleTicketFetchData(action) {
    const stateCorrelationId = get(yield select(state => state.ticket), `correlationId`, { default: undefined });
    const { correlationId, payload } = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    const { error } = validateFetchTicket(payload);
    if (error) {
        yield put(errorAlert(alertMessages.TICKET_FETCH_SEND_ERROR, [error.toString()]));
        //yield put ({ type: hdiTicketWebsocketCommands.TICKET_SAVE_NEW_ERROR });
        yield put({ type: hdiTicketWebsocketCommands.HDI_TICKET_SEND_ERROR, payload: { correlationId } });
    }
}

function* ticketSaga() {
    yield takeEvery(hdiTicketWebsocketCommands.HDI_TICKET_UPDATE_FATAL, handleTicketError);
    yield takeEvery(hdiTicketWebsocketCommands.HDI_TICKET_CREATE_FATAL, handleTicketError);
    yield takeEvery(hdiTicketWebsocketCommands.HDI_TICKET_SEND_ERROR, handleTicketError);
    yield takeEvery(hdiTicketWebsocketCommands.HDI_TICKET_LOAD_DATA, handleTicketLoadData);
    yield takeEvery(hdiTicketWebsocketCommands.HDI_TICKET_SEND, handleTicketFetchData);
}

export default ticketSaga;
