import { put, select, takeEvery } from 'redux-saga/effects';
import { hdiWebsocketCommands } from '../constants/hdiWebsocketCommands';
import { errorAlert } from '../actions/alertsActions';
import { extractErrorText } from '../actions/actionUtils';
import { cloneDeep } from 'lodash';
import { adminRoles, dealerRoles } from '../constants/Utils';
import { validateAdminRoles, validateDealerRoles } from '../api/validation/role';
import get from 'get-value';
import {
    HDI_ADMIN_GROUPS_CHANGED,
    HDI_ADMIN_RIGHTS_FETCH_SUCCESS, HDI_DEALER_GROUPS_CHANGED, HDI_DEALER_RIGHTS_FETCH_SUCCESS,
    HDI_INITIAL_ADMIN_GROUP_OPTIONS, HDI_INITIAL_DEALER_GROUP_OPTIONS,
    HDI_RIGHTS_ERROR,
    HDI_SELECTED_ADMIN_GROUP_OPTIONS, HDI_SELECTED_DEALER_GROUP_OPTIONS,
} from '../actions/admin/actionAdminRightsManagement';

function* handleFetchAdminData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `correlationId`, { default: undefined });
        const { correlationId } = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const emptyAdminData = {};
        Object.keys(adminRoles).forEach(groupKey => emptyAdminData[adminRoles[groupKey]] = false);
        const { error } = validateAdminRoles(cloneDeep(action.payload.groupPermission));
        if (error && action.payload.groupPermission) {
            yield put({ type: HDI_RIGHTS_ERROR, payload: { correlationId } });
            yield put(errorAlert(`Retrieved invalid admin rights from backend`, [error.toString()]));
        } else {
            const finalResult = (action.payload.groupPermission ? Object.assign(emptyAdminData, action.payload.groupPermission) : emptyAdminData);
            yield put({
                type: HDI_ADMIN_RIGHTS_FETCH_SUCCESS,
                payload: { finalResult, correlationId },
            });

            const result = Object.keys(finalResult)
                .filter(group => finalResult[group] === true)
                .map(group => ({ value: group }));
            yield put({
                type: HDI_SELECTED_ADMIN_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: HDI_INITIAL_ADMIN_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: HDI_ADMIN_GROUPS_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({ type: HDI_RIGHTS_ERROR });
        yield put(errorAlert(...extractErrorText(e, `Cannot fetch Rights Management admin data`)));
    }
}

function* handleFetchDealerData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `correlationId`, { default: undefined });
        const { correlationId } = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const emptyDealerData = {};
        Object.keys(dealerRoles).forEach(groupKey => emptyDealerData[dealerRoles[groupKey]] = false);
        const { error } = validateDealerRoles(cloneDeep(action.payload.groupPermission));
        if (error && action.payload.groupPermission) {
            yield put({ type: HDI_RIGHTS_ERROR, payload: { correlationId } });
            yield put(errorAlert(`Retrieved invalid dealer rights from backend`, [error.toString()]));
        } else {
            const finalResult = (action.payload.groupPermission ? Object.assign(emptyDealerData, action.payload.groupPermission) : emptyDealerData);
            yield put({
                type: HDI_DEALER_RIGHTS_FETCH_SUCCESS,
                payload: { finalResult, correlationId },
            });

            const result = Object.keys(finalResult)
                .filter(group => finalResult[group] === true)
                .map(group => ({ value: group }));
            yield put({
                type: HDI_SELECTED_DEALER_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: HDI_INITIAL_DEALER_GROUP_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: HDI_DEALER_GROUPS_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({ type: HDI_RIGHTS_ERROR });
        yield put(errorAlert(...extractErrorText(e, `Cannot fetch Rights Management dealer data`)));
    }
}


function* rightsManagementSaga() {
    yield takeEvery(hdiWebsocketCommands.HDI_ADMIN_ROLE_SEND, handleFetchAdminData);
    yield takeEvery(hdiWebsocketCommands.HDI_DEALER_ROLE_SEND, handleFetchDealerData);
}

export default rightsManagementSaga;
