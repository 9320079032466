import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import get from 'get-value';
import PropTypes from 'prop-types';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { clearAlerts } from '../../../../actions/alertsActions';
import listMessages from '../../../../intl/hdi/listMessages';
import Loader from '../../../Loader';
import PageHeader from '../../../common/PageHeader';
import DatePickerTableComponent from '../../../common/datePicker/DatePickerTableComponent';
import formStatesMessages from '../../../../intl/common/formStatesMessages';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import { getPreferences, updateUserFullsearchPreference } from '../../../../actions/hdi/actionPreferences';
import FilterForm from './filter/FilterForm';
import { getUserRole } from '../Utils';
import FulltextSearchListTable from './FulltextSearchListTable';

const StyledPicky = styled(Picky)`
    button:first-child{
        border-radius: 0.3em;
    };
`;

class FulltextSearchListPage extends Component {
    constructor(props) {
        super(props);
        const { intl } = props;
        this.state = {
            ratchet: false,
            ticketId: '',
            columnLibrary: [
                {
                    id: 'status',
                    accessor: 'status',
                    message: listMessages.TABLE_STATUS,
                    filterable: false,
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'created',
                    accessor: 'createdDate',
                    filterable: false,
                    message: listMessages.TABLE_CREATION_TIME,
                    Filter: ({ filter, onChange }) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    Cell: e => <>
                        <FormattedDate value={new Date(e.value)}/> <FormattedTime
                        value={new Date(e.value)}/></>,
                },
                {
                    id: 'creatorIpn',
                    accessor: 'creatorIpn',
                    message: listMessages.TABLE_CREATED_BY,
                    filterable: false,
                },
                {
                    id: 'subject',
                    accessor: 'subject',
                    message: listMessages.TABLE_SUBJECT,
                    filterable: false
                },
                {
                    id: 'country',
                    accessor: 'country',
                    message: listMessages.TABLE_COUNTRY,
                    filterable: false
                },
                {
                    id: 'description',
                    accessor: 'description',
                    message: listMessages.TABLE_DESCRIPTION,
                    filterable: false
                }
            ]
        };
    }

    componentDidMount() {
        this.props.getPreferences(this.props.loggedUser.ipn);
        //this.props.clearAlerts();
    }

    selectMultipleOption = (value) => {
        this.props.updateUserFullsearchPreference(this.props.loggedUser.ipn,
            this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    render() {
        const { intl, preferences, filter, isSolutionGroupListLoading, isEditorLoading } = this.props;
        const { columnLibrary } = this.state;

        if (isSolutionGroupListLoading || isEditorLoading || preferences.isLoading) {
            return <Loader/>
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...listMessages.TITLE_FULLSEARCH}/>}/>
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span
                            className="font-weight-bold"><FormattedMessage {...listMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={columnLibrary.filter(item => !get(preferences, 'menuFullsearch', { default: [] }).includes(item.id)).map(col => {
                                return { id: col.id, message: intl.formatMessage(col.message) }
                            })}
                            options={columnLibrary.map(col => {
                                return { id: col.id, message: intl.formatMessage(col.message) }
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="col-12">
                        <FilterForm form={'TICKET_SEARCH'}
                                    enableReinitialize={true}
                                    role={getUserRole(this.props.loggedUser.roles)}
                                    handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
                <FulltextSearchListTable
                    columns={columnLibrary.filter(item => !get(preferences, 'menuFullsearch', { default: [] }).includes(item.id))}
                    handleEditClick={this.handleEditClick}
                    filter={filter}/>
            </div>
        );
    }
}

FulltextSearchListPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    getPreferences: PropTypes.func.isRequired,
    updateUserFullsearchPreference: PropTypes.func.isRequired,
    domain: PropTypes.string.isRequired,
    loggedUser: PropTypes.object.isRequired,
    userRoles: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    preferences: PropTypes.object.isRequired,
    defaultProperties: PropTypes.object.isRequired,
    isEditorLoading: PropTypes.bool,
};

const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    userRoles: state.profile.userDetail.roles,
    preferences: state.preferences,
    isEditorLoading: state.adminEditor.isEditorLoading,
    defaultProperties: state.listTicket.defaultProperties,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateUserFullsearchPreference,
    getPreferences,
})(injectIntl(FulltextSearchListPage))), ['HDI_EDITOR']);

