import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import formMessages from '../../../../../../../intl/common/formUserType';
import { change } from 'redux-form';
import { ticketConstants } from '../../../../../../../constants/form/formTicketConstants';
import { substitutionConstants } from '../../../../../../../constants/form/formSubstitutionConstants';
import { InputSelectUserFieldWithValidation } from './InputSelectUserFieldWithValidation';
import { handleFetchUsersError } from '../../../../../../../actions/actionRole';
import { handleUserSearch } from '../../../../../../../utils/userUtils';
import buttonMessages from '../../../../../../../intl/common/buttonMessages';
import ValidFormattedMessage from '../../../../../../common/ValidFormattedMessage';
import { PrimaryButton } from '../../../../../../common/Button';
import { resetSubstitutionUser, setSubstitutionUser } from '../../../../../../../actions/hdi/actionTicket';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const SubstitutionSection = (props) => {
    const { readOnly, change, resetSubstitutionUser, setSubstitutionUser, isSubstituted } = props;

    const handleUserSelection = user => {
        setSubstitutionUser(user);
        change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_SUBSTITUTION}`, user.ipn);
    };

    const handleUserSearching = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, props.handleFetchUsersError);
    };

    const handleReset = () => {
        resetSubstitutionUser();
        change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_SUBSTITUTION}`, '');
    };

    return (
        <>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-10 pb-2">
                        <InputSelectUserFieldWithValidation
                            label={formMessages[substitutionConstants.FORM_LABEL_SUBSTITUTION_USER]}
                            field={ticketConstants.FORM_FIELD_SUBSTITUTION}
                            handleUserSelection={handleUserSelection}
                            handleUserSearch={handleUserSearching}
                            isDisabled={readOnly}
                            isSmall
                        />
                    </div>
                    { isSubstituted &&
                        <div className="col pb-2 align-self-end">
                            <PrimaryButton onClick={handleReset} className="btn mr-2 float-right">
                                <ValidFormattedMessage Message message={'RESET'} intlMessages={buttonMessages}/>
                            </PrimaryButton>
                        </div>
                    }
                </div>
            </StyledDiv>
        </>
    )
};

const mapStateToProps = (state, props) => ({
    isSubstituted: state.ticket.substitutionUser
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
    handleFetchUsersError: error => dispatch(handleFetchUsersError(error)),
    resetSubstitutionUser: () => dispatch(resetSubstitutionUser()),
    setSubstitutionUser: (user) => dispatch(setSubstitutionUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubstitutionSection);
