import { defineMessages } from 'react-intl';
import { ticketConstants } from '../../constants/form/formTicketConstants';

const messages = defineMessages({
    TITLE: {
        id: 'hdi.type.hdi',
        defaultMessage: 'Incident'
    },
    TITLE_REQUEST: {
        id: 'hdi.type.hdiRequest',
        defaultMessage: 'Request'
    },
    UPLOADING_ATTACHMENTS: {
        id: 'hdi.ticket.attachments.uploadingAttachments',
        defaultMessage: 'Ticket was saved, uploading attachments...'
    },
    ATTACHMENTS_SECTION: {
        id: 'hdi.ticket.header.attachments',
        defaultMessage: 'Attachments'
    },
    ATTACHMENTS: {
        id: 'hdi.ticket.header.attachments.all',
        defaultMessage: 'Attachments'
    },
    TICKET: {
        id: 'hdi.ticket.ticket',
        defaultMessage: 'Ticket'
    },
    TICKET_SECTION: {
        id: 'hdi.ticket.header.dealerInformation',
        defaultMessage: 'Dealer information'
    },
    NOTE_SECTION: {
        id: 'hdi.ticket.header.notes',
        defaultMessage: 'Notes'
    },
    INTERNAL_NOTE_SECTION: {
        id: 'hdi.ticket.header.internalNotes',
        defaultMessage: 'Internal Notes'
    },
    [ticketConstants.FORM_LABEL_NAME]: {
        id: 'hdi.ticket.label.name',
        defaultMessage: 'Name'
    },
    [ticketConstants.FORM_LABEL_EMAIL]: {
        id: 'hdi.ticket.label.email',
        defaultMessage: 'E-mail'
    },
    [ticketConstants.FORM_LABEL_TELEPHONE_NO]: {
        id: 'hdi.ticket.label.telephoneNumber',
        defaultMessage: 'Telephone number'
    },
    [ticketConstants.FORM_LABEL_DESCRIPTION]: {
        id: 'hdi.ticket.table.description',
        defaultMessage: 'Description'
    },
    [ticketConstants.FORM_LABEL_NOTES_HISTORY]: {
        id: 'hdi.ticket.label.notesHistory',
        defaultMessage: 'Notes history'
    },
    [ticketConstants.FORM_LABEL_NOTE]: {
        id: 'hdi.ticket.label.note',
        defaultMessage: 'New note'
    },
    [ticketConstants.FORM_LABEL_SUBJECT]: {
        id: 'hdi.ticket.label.subject',
        defaultMessage: 'Subject'
    },
    [ticketConstants.FORM_LABEL_SUBJECT_UK]: {
        id: 'hdi.ticket.label.subjectUk',
        defaultMessage: 'Topic'
    },
    [ticketConstants.FORM_LABEL_STATUS]: {
        id: 'hdi.ticket.label.status',
        defaultMessage: 'Status'
    },
    [ticketConstants.FORM_LABEL_COMMENT]: {
        id: 'hdi.ticket.label.comment',
        defaultMessage: 'Comment'
    },
    [ticketConstants.FORM_LABEL_ALIAS]: {
        id: 'hdi.ticket.label.alias',
        defaultMessage: 'Alias'
    },
    [ticketConstants.FORM_FIELD_DESCRIPTION]: {
        id: 'hdi.ticket.label.description',
        defaultMessage: 'Problem description'
    },
    [ticketConstants.FORM_LABEL_TEMPLATE_TYPE]: {
        id: 'hdi.ticket.label.type',
        defaultMessage: 'Typical incidents'
    },
    [ticketConstants.FORM_LABEL_TEMPLATE_TYPE_REQUEST]: {
        id: 'hdi.ticket.label.typeRequest',
        defaultMessage: 'Typical requests'
    },
    [ticketConstants.FORM_LABEL_TEMPLATE_TOPIC]: {
        id: 'hdi.ticket.label.topic',
        defaultMessage: 'Topic'
    },
    [ticketConstants.FORM_LABEL_COPY_EMAIL]: {
        id: 'hdi.ticket.label.copyEmail',
        defaultMessage: 'Copy to (email)'
    },
    [ticketConstants.FORM_LABEL_CREATOR_IPN]: {
        id: 'hdi.ticket.label.creatorIpn',
        defaultMessage: 'IPN'
    },
    DEALER_NAME: {
        id: 'hdi.ticket.label.dealerName',
        defaultMessage: 'Dealer name'
    },
    DEALER_NUMBER: {
        id: 'hdi.ticket.label.dealerNumber',
        defaultMessage: 'Dealer No'
    },
    [ticketConstants.FORM_LABEL_PRIORITY]: {
        id: 'hdi.ticket.label.priority',
        defaultMessage: 'Priority'
    },
    [ticketConstants.FORM_LABEL_SOLUTION_GROUP]: {
        id: 'hdi.ticket.label.solutionGroup',
        defaultMessage: 'Solution Group'
    },
    [ticketConstants.FORM_LABEL_SOLUTION_GROUP_UK]: {
        id: 'hdi.ticket.label.solutionGroupUK',
        defaultMessage: 'Department'
    },
    [ticketConstants.FORM_LABEL_TOPIC]: {
        id: 'hdi.ticket.label.topic',
        defaultMessage: 'Topic'
    },
    [ticketConstants.FORM_LABEL_TYPE_INCIDENT]: {
        id: 'hdi.ticket.label.incidentType',
        defaultMessage: 'Incident type'
    },
    [ticketConstants.FORM_LABEL_TYPE_REQUEST]: {
        id: 'hdi.ticket.label.requestType',
        defaultMessage: 'Request type'
    },
    [ticketConstants.FORM_LABEL_INTERNAL_NOTES_HISTORY]: {
        id: 'hdi.ticket.label.internalNotesHistory',
        defaultMessage: 'Internal Notes History'
    },
    [ticketConstants.FORM_LABEL_INTERNAL_NOTE]: {
        id: 'hdi.ticket.label.internalNote',
        defaultMessage: 'New note'
    },
});

export default messages;
