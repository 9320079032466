import { hdiTemplateWebsocketCommands, } from '../../constants/hdiTemplateWebsocketCommands';
import get from 'get-value';

export const adminTemplateList = (state = {
    correlationId: null,
    isLoading: false,
    isTableLoading: false,
    listData: [],
    shortListData: [],
    isShortListAllLoading: false,
    shortListAllData: [],
    pages: 0
}, action) => {
    switch (action.type) {
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_DELETE_PENDING:
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_LIST_PENDING: {
            return Object.assign({}, state, { isTableLoading: true });
        }
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_DELETED: {
            const { correlationId, id } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isLoading: false,
                    isTableLoading: false,
                    listData: state.listData.filter(flow => flow.id !== id)
                });
            }
        }
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_DELETE_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, { isLoading: false });
            }
        }

        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_LIST_SEND: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isTableLoading: false,
                    listData: get(action, 'payload.result.data', { default: [] }),
                    pages: get(action, 'payload.result.pages', { default: 0 }),
                    correlationId
                });
            }
        }
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_LIST_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, { isTableLoading: false, correlationId });
            }
        }

        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_SHORT_LIST_PENDING: {
            return Object.assign({}, state, {
                isLoading: true,
            });
        }
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_SHORT_LIST_SEND: {
            const { correlationId, error, templates } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                isLoading: false,
                correlationId,
                shortListData: templates
            });
        }
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_SHORT_LIST_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                correlationId,
            });
        }

        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_SHORT_LIST_ALL_PENDING: {
            return Object.assign({}, state, {
                isShortListAllLoading: true,
            });
        }
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_SHORT_LIST_ALL_SEND: {
            const { correlationId, error, templates } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                isShortListAllLoading: false,
                correlationId,
                shortListAllData: templates
            });
        }
        case hdiTemplateWebsocketCommands.HDI_TEMPLATE_SHORT_LIST_ALL_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isShortListAllLoading: false,
                correlationId,
            });
        }


        default:
            return state;
    }
};
