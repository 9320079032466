import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TABLE_TICKET_NUMBER: {
        id: 'hdi.list.table.ticketNo',
        defaultMessage: 'Ticket No.'
    },
    TABLE_CREATED_BY: {
        id: 'hdi.list.table.createdBy',
        defaultMessage: 'Created by'
    },
    TABLE_CREATION_TIME: {
        id: 'hdi.list.table.hdiCreationTime',
        defaultMessage: 'Creation Time'
    },
    TABLE_LAST_MODIFY: {
        id: 'hdi.list.table.lastModify',
        defaultMessage: 'Last modification'
    },
    TABLE_BRAND: {
        id: 'hdi.list.table.brand',
        defaultMessage: 'Brand'
    },
    TABLE_SOLUTION_GROUP: {
        id: 'hdi.list.table.solutionGroup',
        defaultMessage: 'Solution Group'
    },
    TABLE_SOLUTION_GROUP_UK: {
        id: 'hdi.list.table.solutionGroupUK',
        defaultMessage: 'Department'
    },
    TABLE_FROM_SOLUTION_GROUP: {
        id: 'hdi.list.table.flow',
        defaultMessage: 'Flow'
    },
    TABLE_LAST_STATUS_DATE: {
        id: 'hdi.list.table.lastStatusDate',
        defaultMessage: 'Update Date'
    },
    TABLE_COMPANY: {
        id: 'hdi.list.table.company',
        defaultMessage: 'Company (BIR)'
    },
    TITLE: {
        id: 'hdi.list.title',
        defaultMessage: 'Incident queue'
    },
    TITLE_REQUEST: {
        id: 'hdi.list.titleRequest',
        defaultMessage: 'Request queue'
    },
    TITLE_FULLSEARCH: {
        id: 'hdi.list.title.fullsearch',
        defaultMessage: 'Fulltext Search'
    },
    VISIBLE_COLUMNS: {
        id: 'hdi.list.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },
    TABLE_STATUS: {
        id: 'hdi.list.table.status',
        defaultMessage: 'Status'
    },
    TABLE_SUBJECT: {
        id: 'hdi.list.table.subject',
        defaultMessage: 'Subject'
    },
    TABLE_TEMPLATE: {
        id: 'hdi.list.table.template',
        defaultMessage: 'Template'
    },
    TABLE_TEMPLATE_UK: {
        id: 'hdi.list.table.templateUK',
        defaultMessage: 'Topic'
    },
    TABLE_DESCRIPTION: {
        id: 'hdi.list.table.description',
        defaultMessage: 'Description'
    },
    TABLE_COUNTRY: {
        id: 'hdi.list.table.country',
        defaultMessage: 'Country'
    },
    TABLE_COMMENT: {
        id: 'hdi.list.table.comment',
        defaultMessage: 'Comment'
    },
    TABLE_NOTE_2: {
        id: 'hdi.list.table.note2',
        defaultMessage: 'Alt Note'
    },
    ALL: {
        id: 'common.hdi.status.all',
        defaultMessage: 'All'
    },
});

export default messages;
