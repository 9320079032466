import React, {Component} from 'react';
import styled from 'styled-components';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import {FormattedDate, FormattedMessage, FormattedTime, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {PrimaryButton} from '../../common/Button';
import {InputFieldWithValidation} from '../../common/InputFieldWithValidation';
import RadioFields from '../../common/RadioFields';
import InputSelectFieldWithValidation from '../../common/InputSelectFieldWithValidation';
import buttonMessages from '../../../intl/common/buttonMessages';
import exportConstantMessages from '../../../intl/common/exportConstantMessages';
import exportMessages from '../../../intl/hdi/exportMessages';
import DatePickerComponent from '../../common/datePicker/DatePickerComponent';
import moment from 'moment';
import PropTypes from 'prop-types';
import charsetMessages from '../../../intl/common/charsetMessages';
import {
    CSV,
    HDI_EXPORT_FORM_FIELD_CHARSET,
    HDI_EXPORT_FORM_FIELD_FROM,
    HDI_EXPORT_FORM_FIELD_TO,
    HDI_EXPORT_FORM_FIELD_TYPE,
    HDI_EXPORT_FORM_GROUP,
    HDI_EXPORT_FORM_SOLUTION_GROUP,
    ticketExportTypes
} from '../../../constants/exportConstants';
import countryMessages from '../../../intl/common/countryGroupMessages';
import {countries} from '../../../constants/Utils';
import {isVisibleForCountry} from '../ticket/Utils';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

class ExportOrderForm extends Component {
    render() {
        const {handleSubmit, exportType = {}, exportRecord, downloadExport, startDate, endDate, charSets, editorSolutionGroups, userDetail} = this.props;
        const editorGroups = Object.keys(countries) || [];

        return (
            <StyledDiv className="border border-secondary p-4 bg-white">
                <div className="row justify-content-center">
                    <div className="col-xl-8 col-md-12">
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={exportMessages.COUNTRY}
                                                                field={HDI_EXPORT_FORM_GROUP}
                                                                options={editorGroups}
                                                                intlMessages={countryMessages}
                                                                isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={isVisibleForCountry(userDetail.group) ? exportMessages.SOLUTION_GROUP_UK : exportMessages.SOLUTION_GROUP}
                                                                field={HDI_EXPORT_FORM_SOLUTION_GROUP}
                                                                customOptions={(editorSolutionGroups || []).map(e => ({id: e.id, translation: e.shortName}))}
                                                                isSmall/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <Field component={DatePickerComponent}
                                       selectsStart
                                       label={exportMessages.DATE_FROM}
                                       name={HDI_EXPORT_FORM_FIELD_FROM}
                                       startDate={startDate}
                                       endDate={endDate}
                                       parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <Field component={DatePickerComponent}
                                       selectsEnd
                                       label={exportMessages.DATE_TO}
                                       name={HDI_EXPORT_FORM_FIELD_TO}
                                       startDate={startDate}
                                       endDate={endDate}
                                       parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <RadioFields
                                    label={exportMessages.TYPE}
                                    field={HDI_EXPORT_FORM_FIELD_TYPE}
                                    options={ticketExportTypes}
                                    intlMessages={exportMessages}/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={exportMessages.EXPORT_CHARSET}
                                                                field={HDI_EXPORT_FORM_FIELD_CHARSET}
                                                                options={charSets}
                                                                intlMessages={charsetMessages}
                                                                isDisabled={!exportType}
                                                                noEmpty
                                                                isSmall/>
                            </div>
                        </div>
                        <form className="form-group">
                            {(Object.keys(exportRecord).length > 0) &&
                            <div className="">
                                <div>
                                    <span className="font-weight-bold">
                                        <FormattedMessage {...exportMessages.LAST_EXPORT}/>{' '}
                                    </span>
                                    <FormattedDate value={new Date(exportRecord.created)}/> <FormattedTime
                                    value={new Date(exportRecord.created)}/>
                                </div>
                                <div>
                                    <span className="font-weight-bold">
                                        <FormattedMessage {...exportMessages.EXPORT_STATUS}/>{' '}
                                    </span>
                                    <FormattedMessage {...exportConstantMessages[exportRecord.status]}/>
                                </div>
                            </div>
                            }
                            <PrimaryButton type="button"
                                           className="btn mr-2 float-left"
                                           onClick={handleSubmit}>
                                <FormattedMessage {...buttonMessages.EXPORT}/>
                            </PrimaryButton>
                            {(Object.keys(exportRecord).length > 0) && exportRecord.status === 'FINISH' &&
                            <PrimaryButton type="button"
                                           className="btn mr-2 float-right"
                                           onClick={downloadExport}>
                                <FormattedMessage {...buttonMessages.DOWNLOAD}/>
                            </PrimaryButton>
                            }
                        </form>
                    </div>
                </div>
            </StyledDiv>
        );
    }
}

ExportOrderForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    exportType: PropTypes.bool.isRequired,
    exportRecord: PropTypes.object,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    charSets: PropTypes.array,
    downloadExport: PropTypes.any,
    userRoles: PropTypes.any,
    editorSolutionGroups: PropTypes.any,
    userDetail: PropTypes.any,
};

const selector = (form, ...other) => (formValueSelector(form))(...other);

const mapStateToProps = (state, initialProps) => ({
    startDate: selector(initialProps.form, state, HDI_EXPORT_FORM_FIELD_FROM),
    endDate: selector(initialProps.form, state, HDI_EXPORT_FORM_FIELD_TO),
    exportType: (selector(initialProps.form, state, HDI_EXPORT_FORM_FIELD_TYPE) === CSV),
    exportRecord: state.exportTickets.exportRecord,
    charSets: state.profile.userDetail.charSets,
    userRoles: state.profile.userDetail.roles,
    userDetail: state.profile.userDetail,
    editorSolutionGroups: state.adminEditor.editorSolutionGroups,
});

export default reduxForm({
    enableReinitialize: true,
})(injectIntl(connect(mapStateToProps, {})(ExportOrderForm)));
