export const welcomeConstants = {
    //Sections
    FORM_WELCOME_SELECT_SECTION: 'selectSection',
    FORM_WELCOME_DESCRIPTION_SECTION: 'descriptionSection',
    //Fields
    FORM_FIELD_WELCOME_LANGUAGE: 'language',
    FORM_FIELD_WELCOME_MESSAGE: 'message',
    //Labels
    FORM_LABEL_WELCOME_LANGUAGE: 'WELCOME_LANGUAGE',
    FORM_LABEL_WELCOME_MESSAGE: 'WELCOME_MESSAGE',

    FORM_LABEL_WELCOME_DESCRIPTION_SECTION: 'FORM_LABEL_WELCOME_DESCRIPTION_SECTION',
    FORM_LABEL_TEMPLATE_SELECT_SECTION: 'FORM_LABEL_WELCOME_SELECT_SECTION',

};
