import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    CONFIRMATION_DIALOG_BODY: {
        id: 'confirmation.dialog.body',
        defaultMessage: 'Are you sure you want to {action}?'
    },
    CONFIRMATION_DIALOG_TITLE: {
        id: 'confirmation.dialog.title',
        defaultMessage: 'Confirmation'
    }
});

export default messages;
