import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    NEW: {
        id: 'form.status.new',
        defaultMessage: 'New'
    },
    OPENED: {
        id: 'form.status.opened',
        defaultMessage: 'Opened'
    },
    REOPENED: {
        id: 'form.status.reopened',
        defaultMessage: 'Reopened'
    },
    CLOSED: {
        id: 'form.status.closed',
        defaultMessage: 'Closed'
    },
    STATUS_HISTORY: {
        id: 'form.header.statusHistory',
        defaultMessage: 'History of Changes'
    },
    ON_HOLD: {
        id: 'form.status.onHold',
        defaultMessage: 'On hold'
    },
    SUSPENDED: {
        id: 'form.status.suspended',
        defaultMessage: 'Suspended'
    },
    NEWOPENED: {
        id: 'form.status.newOrOpened',
        defaultMessage: 'New or Opened'
    }
});

export default messages;
