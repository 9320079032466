import { defineMessages } from 'react-intl';
import { solutionGroupConstants } from '../../constants/form/formSolutionGroupConstants';

const messages = defineMessages({
    TITLE: {
        id: 'admin.hdi.solutionGroup.title',
        defaultMessage: 'Solution Group'
    },
    TITLE_UK: {
        id: 'admin.hdi.solutionGroupUK.title',
        defaultMessage: 'Department'
    },
    DEFAULT_TITLE: {
        id: 'admin.hdi.solutionGroup.default.title',
        defaultMessage: 'Default Solution Groups'
    },
    DEFAULT_TITLE_UK: {
        id: 'admin.hdi.solutionGroupUK.default.title',
        defaultMessage: 'Default Departments'
    },
    TABLE_NAME: {
        id: 'admin.hdi.solutionGroup.table.name',
        defaultMessage: 'Name'
    },
    TABLE_COUNTRIES: {
        id: 'admin.hdi.solutionGroup.table.countries',
        defaultMessage: 'Countries'
    },
    PLACEHOLDER_SELECT_USERS: {
        id: 'admin.hdi.solutionGroup.selectUsers.placeholder',
        defaultMessage: 'Select user'
    },
    TABLE_ACTIVE: {
        id: 'admin.hdi.solutionGroup.active',
        defaultMessage: 'Active'
    },
    [solutionGroupConstants.FORM_LABEL_SG_ACTIVE]: {
        id: 'hdi.administration.label.active',
        defaultMessage: 'Active'
    },
    [solutionGroupConstants.FORM_LABEL_SG_LONG_NAME]: {
        id: 'hdi.administration.label.longName',
        defaultMessage: 'Long name'
    },
    [solutionGroupConstants.FORM_LABEL_SG_NAME]: {
        id: 'hdi.administration.label.name',
        defaultMessage: 'Name'
    },
    [solutionGroupConstants.FORM_LABEL_SG_LEVEL]: {
        id: 'hdi.administration.label.level',
        defaultMessage: 'Level'
    },
    [solutionGroupConstants.FORM_LABEL_SG_COUNTRIES]: {
        id: 'hdi.administration.label.country',
        defaultMessage: 'Country'
    },
    [solutionGroupConstants.FORM_LABEL_SG_USERS]: {
        id: 'hdi.administration.label.users',
        defaultMessage: 'Copy to (e-mail)'
    },
    [solutionGroupConstants.FORM_LABEL_SG_BASE_INFO]: {
        id: 'hdi.administration.label.baseInfo',
        defaultMessage: 'Base information'
    },
    [solutionGroupConstants.FORM_LABEL_SG_MANUAL_EMAIL]: {
        id: 'hdi.administration.label.manualEmail',
        defaultMessage: 'New email'
    },
    [solutionGroupConstants.FORM_LABEL_SG_MANUAL_EMAIL_LIST]: {
        id: 'hdi.administration.label.manualEmailList',
        defaultMessage: 'Custom emails'
    },
    [solutionGroupConstants.FORM_LABEL_DEFAULT_SG]: {
        id: 'hdi.administration.label.defaultSg',
        defaultMessage: 'Default solution group'
    },
});

export default messages;
