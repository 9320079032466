import { adminRoles, dealerRoles } from '../../constants/Utils';
import Joi from 'joi';
import { getEditorSchema } from './editor';

export function validateUserDetail(userDetail) {
    return Joi.validate(
        userDetail,
        Joi.object().required().keys({
            ipn: Joi.string().required(),
            name: Joi.string().required(),
            roles: getRolesSchema(),
            settings: Joi.object().keys(getSettingsSchema()),
            dealerName: Joi.any(),
            dealerNumber: Joi.any(),
            telNo: Joi.any(),
            group: Joi.any(),
            mail: Joi.string().required(),
            charSets: Joi.array().required()
        })
    )
}

export function validateAdminRoles(adminRoles) {
    return Joi.validate(
        adminRoles,
        Joi.object().required().keys(getAdminGroupsSchema())
    );
}

export function validateDealerRoles(adminRoles) {
    return Joi.validate(
        adminRoles,
        Joi.object().required().keys(getDealerGroupsSchema())
    );
}

export function validateEditorData(editorRoles) {
    return Joi.validate(
        editorRoles,
        getEditorSchema().required()
    );
}

function getRolesSchema() {
    return Joi.object().keys({
        admin: Joi.object().keys({
            groupPermission: Joi.object().keys(getAdminGroupsSchema())
        }),
        editor: getEditorSchema(),
        dealer: Joi.object().keys({
            groupPermission: Joi.object().keys(getDealerGroupsSchema())
        }),
        powerUser: Joi.object().max(0)
    });
}

function getAdminGroupsSchema() {
    const result = {};
    Object.keys(adminRoles).forEach(group => result[adminRoles[group]] = Joi.boolean().required());
    return result;
}

function getDealerGroupsSchema() {
    const result = {};
    Object.keys(dealerRoles).forEach(group => result[dealerRoles[group]] = Joi.boolean().required());
    return result;
}

export function getSettingsSchema() {
    return {
        language: Joi.string(),
        locale: Joi.string(),
    };
}


