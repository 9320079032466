import React from 'react';
import styled from 'styled-components';
import { FormSection, reduxForm } from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import RenderSyncErrors from '../../../../common/RenderSyncErrors';
import TicketSection from './sections/ticket/TicketSection';
import { scrollContentWrapperToTop } from '../../../../../utils/utils';
import NotesSection from './sections/notes/NotesSection';
import formMessages from '../../../../../intl/hdi/formMessages';
import { intlFieldMapping } from '../../../../../constants/fieldLabelMapping';
import AttachmentSection from './sections/attachments/AttachmentSection';
import TemplateSection from './sections/templates/TemplateSection';
import InfoSection from './sections/ticket/InfoSection';
import { isEditorView } from '../../Utils';
import SubstitutionSection from './sections/substitution/SubstitutionSection';
import { ticketConstants } from '../../../../../constants/form/formTicketConstants';
import { substitutionConstants } from '../../../../../constants/form/formSubstitutionConstants';
import EditorsNotesSection from './sections/editorsNotes/InternalNoteSection';
import TicketStatusHistory from '../../../../common/TicketStatusHistory';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const TicketForm = (props) => {
    const { form, userRights, reqFields, readOnly, handleSubmit, viewType, userCountry } = props;
    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={intlFieldMapping}/>
            {userRights.isCreate && isEditorView(viewType) && <>
                <FormSection name={substitutionConstants.FORM_SUBSTITUTION_SECTION}>
                    <SubstitutionSection
                        form={form}
                        userRights={userRights}
                        readOnly={readOnly}
                        reqFields={reqFields}
                    />
                </FormSection>
                <hr/>
            </>
            }
            {userRights.isCreate && <>
                <FormSection name={ticketConstants.FORM_TEMPLATE_SECTION}>
                    <TemplateSection form={form} userRights={userRights} readOnly={readOnly}/>
                </FormSection>
                <hr/>
            </>
            }
            {!userRights.isCreate && isEditorView(viewType) && <>
                <FormSection name={ticketConstants.FORM_TICKET_SECTION}>
                    <InfoSection form={form} viewType={viewType} userRights={userRights} reqFields={reqFields}
                                 readOnly/>
                </FormSection>
                <hr/>
            </>
            }
            <FormSection name={ticketConstants.FORM_TICKET_SECTION}>
                <TicketSection form={form} viewType={viewType} userRights={userRights} reqFields={reqFields}
                               readOnly={readOnly} userCountry={userCountry}/>
            </FormSection>
            <FormSection name={ticketConstants.FORM_NOTES_SECTION}>
                <NotesSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            {isEditorView(viewType) &&
                <FormSection name={ticketConstants.FORM_INTERNAL_NOTES_SECTION}>
                    <EditorsNotesSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
                </FormSection>
            }
            <FormSection name={ticketConstants.FORM_ATTACHMENTS_SECTION}>
                <AttachmentSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            {!userRights.isCreate &&
                <TicketStatusHistory form={form} />
            }
            <SubmitButtons form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}
                           onSubmit={handleSubmit} onSubmitFail={scrollContentWrapperToTop}/>
        </StyledDiv>
    )
};

export default reduxForm({})(TicketForm);
