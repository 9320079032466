import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'redux-form';
import { RequiredSpan } from './StyledComponents';
import { RenderSelectInput } from './FormField';

const InputMultipleSelectField = (props) => {
    const { label, field, options, isDisabled, isRequired, placeholder, onHandle, isMulti, isSearchable, isClearable, closeMenuOnSelect } = props;
    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
                {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>
            }
            <Field name={field}
                   component={RenderSelectInput}
                   options={options}
                   onChange={onHandle}
                   isMulti={isMulti}
                   isSearchable={isSearchable}
                   isDisabled={isDisabled}
                   isClearable={isClearable}
                   closeMenuOnSelect={closeMenuOnSelect}
                   placeholder={placeholder}/>
        </div>
    )
};
export default (injectIntl(InputMultipleSelectField));
