import Joi from 'joi';
import { getSolutionGroupSchema } from './solutionGroup';

export function validateEditor(editor) {
    return Joi.validate(
        editor,
        Joi.object().keys({
            editor: Joi.object().required().keys({
                id: Joi.string(),
                ipn: Joi.string().required(),
                name: Joi.string().required(),
                isOnHold: Joi.bool().required(),
                isSuspend: Joi.bool().required(),
                defaultSolutionGroup: Joi.any()
            }),
            solutionGroups: Joi.array().required().items(getSolutionGroupSchema()),
            availableSolutionGroups: Joi.array().items(getSolutionGroupSchema().required()),
            id: Joi.string()
        })
    );
}

export function validateEditorCreateEvent(editor) {
    return Joi.validate(
        editor,
        Joi.object().keys({
            editor: Joi.object().required().keys({
                ipn: Joi.string().required(),
                name: Joi.string().required(),
                isOnHold: Joi.bool().required(),
                isSuspend: Joi.bool().required(),
                defaultSolutionGroup: Joi.string().required()
            }),
            addedSolutionGroup: Joi.array().items(getSolutionGroupSchema().required()),
            id: Joi.string().required(),
        })
    );
}

export function validateEditorUpdateEvent(editor) {
    return Joi.validate(
        editor,
        Joi.object().keys({
            editor: Joi.object().required().keys({
                id: Joi.string().required(),
                ipn: Joi.string(),
                isOnHold: Joi.bool(),
                isSuspend: Joi.bool(),
                defaultSolutionGroup: Joi.string().allow(['', null]),
            }),
            addedSolutionGroup: Joi.array().items(getSolutionGroupSchema()),
            removedSolutionGroup: Joi.array().items(getSolutionGroupSchema()),
            id: Joi.string().required(),
        })
    );
}

export function getEditorSchema() {
    return Joi.object().keys({
        id: Joi.string(),
        ipn: Joi.string().required(),
        name: Joi.string().required(),
        isOnHold: Joi.bool().required(),
        isSuspend: Joi.bool().required(),
        defaultSolutionGroup: Joi.string().allow(['', null]),
    });
}

