import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../../Loader';
import { FORM_BUTTON_EDIT, HDI_EDITOR_FORM, } from '../../../../constants/formConstants';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ADMINISTRATION_EDITOR_NEW_PATH, ADMINISTRATION_EDITOR_PATH } from '../../../../routes/paths';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import { fetchEditor, resetEditor, updateEditor } from '../../../../actions/admin/actionAdminEditor';
import { initLoadedEditor } from '../utils';
import EditorForm from './forms/EditorForm';
import editorMessages from '../../../../intl/admin/editorMessages';

class ViewEditorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToNew: false,
            redirectToEdit: false,
            editorSubmitted: false,
            hasToBeUpdated: false,
            storedEditorData: {},
            newInitData: {},
        };
    }

    componentDidMount() {
        this.props.clearAlerts();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchEditor(this.props.match.params.editorUuid);
        }
    }

    componentWillUnmount() {
        this.props.resetEditor();
    }

    handleSubmit = (values) => {
        const { submitBy } = values;

        switch (submitBy) {
            case FORM_BUTTON_EDIT: {
                this.setState({
                    editorSubmitted: true,
                    redirectToEdit: true
                });
                break;
            }
            default: {
            }
        }
    };

    render() {
        const {
            editorData,
        } = this.props;

        let formType = false;
        if (get(editorData, 'editor.ipn', { default: false })) {
            formType = HDI_EDITOR_FORM;
        }

        if (this.state.redirectToNew && this.state.editorSubmitted) {
            if (this.state.hasToBeUpdated) {
                this.props.updateEditor(this.state.storedEditorData);
            }
            return <Redirect to={{
                pathname: ADMINISTRATION_EDITOR_NEW_PATH,
                initData: this.state.newInitData,
            }}/>;
        } else if (this.state.editorSubmitted && this.state.redirectToEdit) {
            return <Redirect to={{
                pathname: `${ADMINISTRATION_EDITOR_PATH}/edit/${editorData.editor.ipn}`,
            }}/>;
        } else if (this.state.editorSubmitted) {
            this.props.updateEditor(this.state.storedEditorData);
            this.setState({ editorSubmitted: false, storedEditorData: {}, hasToBeUpdated: false });
        }

        if (
            editorData.isEditorLoading
            || editorData.isEditorCreating
            || !formType
        ) {
            return <Loader/>;
        }
        const initializationValue = initLoadedEditor(editorData);
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...editorMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <EditorForm form={formType}
                                    initialValues={initializationValue}
                                    enableReinitialize={true}
                                    showDetail
                                    reqFields={[]}
                                    readOnly
                                    handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    editorData: state.adminEditor,
    userDetail: state.profile.userDetail,
});

ViewEditorPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    editorData: PropTypes.object.isRequired,
    fetchEditor: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    updateEditor: PropTypes.func.isRequired,
    resetEditor: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchEditor,
    clearAlerts,
    resetEditor,
    updateEditor,
})(injectIntl(ViewEditorPage))), ['HDI_ADMIN']);

