import React from 'react';
import styled from 'styled-components';
import { FormSection, reduxForm } from 'redux-form'
import { editorConstants } from '../../../../../constants/form/formEditorConstants';
import FullSearchFilter from './FullSearchFilter';

const StyledDiv = styled.div`
  border-radius: 10px;
  margin-bottom: 10px;
`;

const FilterForm = (props) => {
    const { form } = props;

    return (
        <StyledDiv className="border border-secondary p-4 bg-white">
            <FormSection name={editorConstants.FORM_EDITOR_SOLUTION_GROUP_SECTION}>
                <FullSearchFilter form={form}/>
            </FormSection>
        </StyledDiv>
    )
};

export default reduxForm({})(FilterForm);
