import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../Loader';
import { initNewSolutionGroup, transformNewSolutionGroupForSave } from '../utils';
import { createReqFields } from '../../../../constants/requiredFields';
import PageHeader from '../../../common/PageHeader';
import get from 'get-value';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {
    addCustomEmail,
    fetchSolutionGroup,
    fetchSolutionGroupsPromiseError,
    resetSolutionGroup,
    saveSolutionGroup
} from '../../../../actions/admin/actionSolutionGroup';
import SolutionGroupForm from './forms/SolutionGroupForm';
import { ADMINISTRATION_SOLUTION_GROUP_PATH } from '../../../../routes/paths';
import { HDI_SOLUTION_GROUP_FORM } from '../../../../constants/formConstants';
import solutionGroupMessages from '../../../../intl/admin/solutionGroupMessages';
import { FormattedMessage } from 'react-intl';
import {isVisibleForCountry} from '../../template/utils';

class NewSolutionGroupPage extends Component {
    constructor(props) {
        super(props);
        this.state = { solutionGroupSubmittedAndShouldRedirect: false };
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetSolutionGroup();
    }

    handleSubmit = (values) => {
        if (!this.props.availableName) {
            this.props.fetchSolutionGroupsPromiseError();
        } else {
            const dataForSend = transformNewSolutionGroupForSave(values.newValues);
            this.props.saveSolutionGroup(dataForSend);
            this.setState({ solutionGroupSubmittedAndShouldRedirect: true });
        }
    };

    render() {
        const {
            sgData,
            userDetail,
        } = this.props;

        if (this.state.solutionGroupSubmittedAndShouldRedirect && !sgData.isSolutionGroupCreating && !sgData.isSolutionGroupLoading && get(sgData, 'solutionGroup.id', { default: false })) {
            return <Redirect to={{
                pathname: `${ADMINISTRATION_SOLUTION_GROUP_PATH}/view/${sgData.solutionGroup.id}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (sgData.isSolutionGroupCreating
            || sgData.isSolutionGroupLoading
            || this.state.solutionGroupSubmittedAndShouldRedirect
        ) {
            return <Loader/>;
        }

        let initializationValue = initNewSolutionGroup();

        const requiredFields = createReqFields['DEFAULT'][HDI_SOLUTION_GROUP_FORM];
        return (
            <div className="container-fluid">
                <PageHeader
                    title={isVisibleForCountry(userDetail.group) ? <FormattedMessage {...solutionGroupMessages.TITLE_UK}/> : <FormattedMessage {...solutionGroupMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <SolutionGroupForm
                            form={HDI_SOLUTION_GROUP_FORM}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

NewSolutionGroupPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    sgData: PropTypes.object.isRequired,
    fetchSolutionGroup: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any,
    resetSolutionGroup: PropTypes.func.isRequired,
    saveSolutionGroup: PropTypes.func.isRequired,
    isLoadingProfile: PropTypes.bool.isRequired,
    availableName: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    clearAlerts: PropTypes.func.isRequired,
    sgData: state.adminSolutionGroup,
    userDetail: state.profile.userDetail,
    isLoadingProfile: state.profile.isLoading,
    availableName: state.adminSolutionGroup.availableName,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchSolutionGroup,
    saveSolutionGroup,
    resetSolutionGroup,
    addCustomEmail,
    fetchSolutionGroupsPromiseError
})(NewSolutionGroupPage)), ['HDI_USER', 'HDI_EDITOR', 'HDI_ADMIN']);

