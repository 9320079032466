export const solutionGroupConstants = {
    //Sections
    FORM_SOLUTION_GROUP_INFO_SECTION: 'solutionGroupSection',
    FORM_SOLUTION_GROUP_MAIN_SECTION: 'mainSection',
    FORM_SOLUTION_GROUP_COUNTRY_SECTION: 'countrySection',
    FORM_SOLUTION_GROUP_USER_SECTION: 'usersSection',
    //Fields
    FORM_FIELD_SG_NAME: 'shortName',
    FORM_FIELD_SG_LONG_NAME: 'longName',
    FORM_FIELD_SG_ACTIVE: 'isActive',
    FORM_FIELD_SG_LEVEL: 'level',
    FORM_FIELD_SG_COUNTRIES: 'countries',
    FORM_FIELD_SG_COUNTRY: 'country',
    FORM_FIELD_DEFAULT_SG: 'defaultSG',
    FORM_FIELD_SG_USERS: 'users',
    FORM_FIELD_SG_MANUAL_EMAIL: 'manualEmail',
    FORM_FIELD_SG_MANUAL_EMAIL_LIST: 'emails',
    //Labels
    FORM_LABEL_SG_NAME: 'SG_NAME',
    FORM_LABEL_SG_LONG_NAME: 'SG_LONG_NAME',
    FORM_LABEL_SG_ACTIVE: 'SG_ACTIVE',
    FORM_LABEL_SG_LEVEL: 'SG_LEVEL',
    FORM_LABEL_SG_COUNTRIES: 'SG_COUNTRIES',
    FORM_LABEL_SG_USERS: 'SG_USERS',
    FORM_LABEL_SG_BASE_INFO: 'SG_BASE_INFO',
    FORM_LABEL_SG_MANUAL_EMAIL: 'SG_MANUAL_EMAIL',
    FORM_LABEL_SG_MANUAL_EMAIL_LIST: 'SG_MANUAL_EMAIL_LIST',
    FORM_LABEL_DEFAULT_SG: 'DEFAULT_SG',

};
