import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { InputFieldWithValidation } from '../../../../../../common/InputFieldWithValidation';
import { change, getFormInitialValues, getFormValues } from 'redux-form';
import formMessages from '../../../../../../../intl/hdi/formMessages';
import formTicketTypeMessages from '../../../../../../../intl/common/formTicketTypeMessages';
import { TextareaFieldWithValidation } from '../../../../../../common/TextareaFieldWithValidation';
import { isEditorView, isVisibleForCountry } from '../../../../Utils';
import { ticketConstants } from '../../../../../../../constants/form/formTicketConstants';
import InputSelectFieldWithValidation from '../../../../../../common/InputSelectFieldWithValidation';
import { getIncidentType } from '../../../../../../../constants/Utils';
import { validEmailArray } from '../../../../../../common/validationCommons';
import InputMultipleInputField from '../../../../../../common/InputMultipleInputField';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const createOption = (label) => ({
    label,
    value: label,
});

class TicketSection extends Component {

    state = {
        inputValue: '',
    };

    handleChange = (value) => {
        const { change } = this.props;
        change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_COPY_EMAIL}`, value)
    };
    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    };

    handleKeyDown = (event) => {
        const { change, formValues: { ticketSection: { copyEmail } } } = this.props;
        const { inputValue } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                change(`${ticketConstants.FORM_TICKET_SECTION}.${ticketConstants.FORM_FIELD_COPY_EMAIL}`, [...copyEmail, createOption(inputValue)])
                this.setState({
                    inputValue: ''
                });
                event.preventDefault();
                break;
            default: {

            }
        }
    };

    render() {
        const {readOnly, reqFields, userRights, viewType, userCountry, userDetail} = this.props;

        const { inputValue } = this.state;

        return (
            <>
                <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                    {!isVisibleForCountry(userCountry) && isEditorView(viewType) &&
                    <div className="form-row">
                        <div className="col pb-2">
                            <InputSelectFieldWithValidation
                                label={isVisibleForCountry(userDetail.group) ? formMessages[ticketConstants.FORM_LABEL_TYPE_REQUEST] : formMessages[ticketConstants.FORM_LABEL_TYPE_INCIDENT]}
                                field={ticketConstants.FORM_FIELD_TYPE_INCIDENT}
                                options={getIncidentType}
                                intlMessages={formTicketTypeMessages}
                                isDisabled={readOnly}
                                isSmall/>
                        </div>
                    </div>
                    }
                    <div className="form-row">
                        <div className="col pb-2">
                            <InputMultipleInputField
                                placeholder=""
                                label={formMessages[ticketConstants.FORM_LABEL_COPY_EMAIL]}
                                field={ticketConstants.FORM_FIELD_COPY_EMAIL}
                                validations={[validEmailArray]}
                                inputValue={inputValue}
                                isMulti={true}
                                isClearable={true}
                                isSearchable={false}
                                isDisabled={readOnly}
                                onHandleChange={this.handleChange}
                                onHandleInputChange={this.handleInputChange}
                                onHandleKeyDown={this.handleKeyDown}
                                isRequired={reqFields.includes(ticketConstants.FORM_FIELD_COPY_EMAIL) && !readOnly}
                                isSmall/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col pb-2">
                            <InputFieldWithValidation
                                label={formMessages[ticketConstants.FORM_LABEL_SUBJECT]}
                                field={ticketConstants.FORM_FIELD_SUBJECT}
                                isDisabled={readOnly || userRights.canEdit}
                                isRequired={reqFields.includes(ticketConstants.FORM_FIELD_SUBJECT) && !readOnly}
                                isSmall/>
                        </div>
                    </div>
                    {!isVisibleForCountry(userCountry) &&
                    <div className="form-row">
                        <div className="col pb-2">
                            <InputFieldWithValidation
                                label={formMessages[ticketConstants.FORM_LABEL_ALIAS]}
                                field={ticketConstants.FORM_FIELD_ALIAS}
                                isDisabled={readOnly}
                                isRequired={reqFields.includes(ticketConstants.FORM_FIELD_ALIAS) && !readOnly}
                                isSmall/>
                        </div>
                    </div>
                    }
                    {isEditorView(viewType) &&
                    <div className="form-row">
                        <div className="col pb-2">
                            <InputFieldWithValidation
                                label={formMessages[ticketConstants.FORM_LABEL_COMMENT]}
                                field={ticketConstants.FORM_FIELD_COMMENT}
                                isDisabled={readOnly}
                                isRequired={reqFields.includes(ticketConstants.FORM_FIELD_COMMENT) && !readOnly}
                                isSmall/>
                        </div>
                    </div>
                    }
                    <div className="form-row">
                        <div className="col-sm-12 pb-2">
                            <TextareaFieldWithValidation
                                label={formMessages[ticketConstants.FORM_LABEL_DESCRIPTION]}
                                field={ticketConstants.FORM_FIELD_DESCRIPTION}
                                rows={10}
                                isDisabled={readOnly || userRights.canEdit}
                                isRequired={reqFields.includes(ticketConstants.FORM_FIELD_DESCRIPTION) && !readOnly}
                            />
                        </div>
                    </div>
                </StyledDiv>
            </>
        )
    }
};

const mapStateToProps = (state, props) => ({
    roles: state.profile.userDetail.roles,
    initialValues: getFormInitialValues(props.form)(state, `ticketSection`),
    userDetail: state.profile.userDetail,
    formValues: getFormValues(props.form)(state),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketSection);
