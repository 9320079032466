import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../../Loader';
import { FORM_BUTTON_EDIT, HDI_SOLUTION_GROUP_FORM, } from '../../../../constants/formConstants';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ADMINISTRATION_SOLUTION_GROUP_NEW_PATH, ADMINISTRATION_SOLUTION_GROUP_PATH } from '../../../../routes/paths';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {
    fetchSolutionGroup,
    resetSolutionGroup,
    updateSolutionGroup
} from '../../../../actions/admin/actionSolutionGroup';
import { initLoadedSolutionGroup } from '../utils';
import SolutionGroupForm from './forms/SolutionGroupForm';
import solutionGroupMessages from '../../../../intl/admin/solutionGroupMessages';
import {isVisibleForCountry} from '../../template/utils';

class ViewSolutionGroupPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToNew: false,
            redirectToEdit: false,
            solutionGroupSubmitted: false,
            hasToBeUpdated: false,
            storedSolutionGroupData: {},
            newInitData: {},
        };
    }

    componentDidMount() {
        this.props.clearAlerts();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchSolutionGroup(this.props.match.params.solutionGroupUuid, this.props.userDetail.ipn);
        }
    }

    componentWillUnmount() {
        this.props.resetSolutionGroup();
    }

    handleSubmit = (values) => {
        const { submitBy } = values;

        switch (submitBy) {
            case FORM_BUTTON_EDIT: {
                this.setState({
                    solutionGroupSubmitted: true,
                    redirectToEdit: true
                });
                break;
            }
            default: {
            }
        }
    };

    render() {
        const {
            solutionGroupData,
            userDetail,
        } = this.props;

        let formType = false;
        if (get(solutionGroupData, 'solutionGroup.shortName', { default: false })) {
            formType = HDI_SOLUTION_GROUP_FORM;
        }

        if (this.state.redirectToNew && this.state.solutionGroupSubmitted) {
            if (this.state.hasToBeUpdated) {
                this.props.updateSolutionGroup(this.state.storedSolutionGroupData);
            }
            return <Redirect to={{
                pathname: ADMINISTRATION_SOLUTION_GROUP_NEW_PATH,
                initData: this.state.newInitData,
            }}/>;
        } else if (this.state.solutionGroupSubmitted && this.state.redirectToEdit) {
            return <Redirect to={{
                pathname: `${ADMINISTRATION_SOLUTION_GROUP_PATH}/edit/${solutionGroupData.solutionGroup.id}`,
            }}/>;
        } else if (this.state.solutionGroupSubmitted) {
            this.props.updateSolutionGroup(this.state.storedSolutionGroupData);
            this.setState({ solutionGroupSubmitted: false, storedSolutionGroupData: {}, hasToBeUpdated: false });
        }

        const initializationValue = initLoadedSolutionGroup(solutionGroupData);
        if (
            solutionGroupData.isSolutionGroupLoading
            || solutionGroupData.isSolutionGroupCreating
            || !formType
        ) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={isVisibleForCountry(userDetail.group) ? <FormattedMessage {...solutionGroupMessages.TITLE_UK}/> : <FormattedMessage {...solutionGroupMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <SolutionGroupForm
                            form={formType}
                            initialValues={initializationValue}
                            enableReinitialize={true}
                            reqFields={[]}
                            readOnly
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    solutionGroupData: state.adminSolutionGroup,
    userDetail: state.profile.userDetail,
});

ViewSolutionGroupPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    solutionGroupData: PropTypes.object.isRequired,
    fetchSolutionGroup: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    updateSolutionGroup: PropTypes.func.isRequired,
    resetSolutionGroup: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchSolutionGroup,
    clearAlerts,
    resetSolutionGroup,
    updateSolutionGroup,
})(injectIntl(ViewSolutionGroupPage))), ['HDI_ADMIN']);

