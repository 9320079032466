import { hdiWebsocketCommands } from '../../constants/hdiWebsocketCommands';
import {
    HDI_ADMIN_GROUPS_CHANGED,
    HDI_ADMIN_RIGHTS_CLEAR,
    HDI_ADMIN_RIGHTS_FETCH_PENDING,
    HDI_ADMIN_RIGHTS_FETCH_SUCCESS,
    HDI_ADMIN_RIGHTS_SAVE_PENDING,
    HDI_DEALER_GROUPS_CHANGED,
    HDI_DEALER_RIGHTS_CLEAR,
    HDI_DEALER_RIGHTS_FETCH_PENDING,
    HDI_DEALER_RIGHTS_FETCH_SUCCESS,
    HDI_DEALER_RIGHTS_SAVE_PENDING,
    HDI_INITIAL_ADMIN_GROUP_OPTIONS,
    HDI_INITIAL_DEALER_GROUP_OPTIONS,
    HDI_MANAGED_USER,
    HDI_MANAGED_USER_RESET,
    HDI_RIGHTS_ERROR,
    HDI_SELECTED_ADMIN_GROUP_OPTIONS,
    HDI_SELECTED_DEALER_GROUP_OPTIONS,
} from '../../actions/admin/actionAdminRightsManagement';

export const adminRightsManagement = (state = {
    isLoading: false,
    managedUser: {},
    adminData: {},
    initialAdminOptions: [],
    selectedAdminOptions: null,
    adminGroupsChanged: false,
    dealerData: {},
    initialDealerOptions: [],
    selectedDealerOptions: null,
    dealerGroupsChanged: false,
}, action) => {
    switch (action.type) {
        case HDI_MANAGED_USER: {
            return Object.assign({}, state, {
                adminCountriesChanged: false,
                managedUser: action.payload
            });
        }
        case HDI_MANAGED_USER_RESET: {
            return Object.assign({}, state, {
                isLoading: false,
                managedUser: {},
                adminData: {},
                initialAdminOptions: [],
                selectedAdminOptions: null,
                adminGroupsChanged: false,
                dealerData: {},
                initialDealerOptions: [],
                selectedDealerOptions: null,
                dealerGroupsChanged: false,
            });
        }
        case HDI_ADMIN_RIGHTS_FETCH_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case HDI_ADMIN_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                adminData: action.payload.finalResult,
                correlationId: action.payload.correlationId
            });
        }
        case hdiWebsocketCommands.HDI_ADMIN_ROLE_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoading: false, correlationId });
        }
        case HDI_INITIAL_ADMIN_GROUP_OPTIONS: {
            return Object.assign({}, state, { initialAdminOptions: action.payload, })
        }
        case HDI_SELECTED_ADMIN_GROUP_OPTIONS: {
            return Object.assign({}, state, { selectedAdminOptions: action.payload })
        }
        case HDI_ADMIN_GROUPS_CHANGED: {
            return Object.assign({}, state, { adminGroupsChanged: action.payload })
        }
        case HDI_ADMIN_RIGHTS_SAVE_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case HDI_ADMIN_RIGHTS_CLEAR: {
            return Object.assign({}, state, { adminData: {} });
        }
        case HDI_DEALER_RIGHTS_FETCH_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case HDI_DEALER_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                dealerData: action.payload.finalResult,
                correlationId: action.payload.correlationId
            });
        }
        case hdiWebsocketCommands.HDI_DEALER_ROLE_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoading: false, correlationId });
        }
        case HDI_INITIAL_DEALER_GROUP_OPTIONS: {
            return Object.assign({}, state, { initialDealerOptions: action.payload, })
        }
        case HDI_SELECTED_DEALER_GROUP_OPTIONS: {
            return Object.assign({}, state, { selectedDealerOptions: action.payload })
        }
        case HDI_DEALER_GROUPS_CHANGED: {
            return Object.assign({}, state, { dealerGroupsChanged: action.payload })
        }
        case HDI_DEALER_RIGHTS_SAVE_PENDING: {
            return Object.assign({}, state, { isLoading: true });
        }
        case HDI_DEALER_RIGHTS_CLEAR: {
            return Object.assign({}, state, { adminData: {} });
        }

        case hdiWebsocketCommands.HDI_ROLES_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoading: false, correlationId });
        }
        case HDI_RIGHTS_ERROR: {
            const { correlationId } = action.payload;
            return Object.assign({}, state, { isLoading: false, correlationId });
        }
        default: {
            return state;
        }
    }
};
