import React from 'react';
import PropTypes from 'prop-types';
import FileIcon, {defaultStyles} from 'react-file-icon';
import {extractFileExtension} from '../../utils/utils';

const FileIconWithName = ({file: {name = ''}, showIcon = false, showName = false, onClick}) => <>
    {
        showIcon &&
        <div onClick={onClick}>
            <FileIcon extension={extractFileExtension(name)} size={50} {...defaultStyles[extractFileExtension(name)]}/>
        </div>
    }
    {
        showName &&
        <span className={showIcon ? 'ml-sm-3' : ''} onClick={onClick}>{name}</span>
    }
</>;

FileIconWithName.propTypes = {
    file: PropTypes.object.isRequired,
    showIcon: PropTypes.bool,
    showName: PropTypes.bool,
    onClick: PropTypes.func
};

export default FileIconWithName;
