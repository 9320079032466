import { hdiSolutionGroupWebsocketCommands, } from '../../constants/hdiSolutionGroupWebsocketCommands';
import get from 'get-value';

export const adminSolutionGroupList = (state = {
    correlationId: null,
    isLoading: false,
    isTableLoading: false,
    isSolutionGroupListLoading: false,
    listData: [],
    shortListData: [],
    pages: 0,
}, action) => {
    switch (action.type) {
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_LIST_FETCH_PENDING: {
            return Object.assign({}, state, { isTableLoading: true });
        }
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_LIST_SEND: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isTableLoading: false,
                    listData: get(action, 'payload.result.data', { default: [] }),
                    pages: get(action, 'payload.result.pages', { default: 0 }),
                    correlationId
                });
            }
        }
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_LIST_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, { isTableLoading: false, correlationId });
            }
        }
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_SHORT_LIST_PENDING: {
            return Object.assign({}, state, {
                isSolutionGroupListLoading: true,
            });
        }
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_SHORT_LIST_SEND: {
            const { correlationId, error, solutionGroups } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, { isSolutionGroupListLoading: false, correlationId });
            }

            return Object.assign({}, state, {
                isSolutionGroupListLoading: false,
                correlationId,
                shortListData: solutionGroups
            });
        }
        case hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_SHORT_LIST_SEND_ERROR: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isSolutionGroupListLoading: false,
                correlationId,
            });
        }
        default:
            return state;
    }
};
