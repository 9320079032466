import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../Loader';
import { initNewTemplate, transformNewTemplateForSave, isVisibleForCountry } from '../utils';
import { createReqFields } from '../../../../constants/requiredFields';
import PageHeader from '../../../common/PageHeader';
import get from 'get-value';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import { ADMINISTRATION_TEMPLATE_PATH } from '../../../../routes/paths';
import TemplateNewForm from './forms/TemplateNewForm';
import { fetchTemplate, resetTemplate, saveTemplate } from '../../../../actions/admin/actionAdminTemplate';
import { fetchSolutionGroupShortList } from '../../../../actions/admin/actionSolutionGroupListPage';
import { fetchSolutionGroupCountry } from '../../../../actions/admin/actionSolutionGroup';
import { HDI_TEMPLATE_FORM } from '../../../../constants/formConstants';
import templateMessages from '../../../../intl/admin/templateMessages';
import { FormattedMessage } from 'react-intl';

class NewTemplatePage extends Component {
    constructor(props) {
        super(props);
        this.state = { templateSubmittedAndShouldRedirect: false };
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetTemplate();
        this.props.fetchSolutionGroupShortList();
    }

    handleSubmit = (values) => {
        const dataForSend = transformNewTemplateForSave(values.newValues, this.props.templateData);
        this.props.saveTemplate(dataForSend);
        this.setState({ templateSubmittedAndShouldRedirect: true });
    };

    handleSelectSolutionGroup = (value) => {
        this.props.fetchSolutionGroupCountry(value);

    };

    render() {
        const {
            templateData,
            solutionGroupData,
            userDetail,
        } = this.props;

        if (this.state.templateSubmittedAndShouldRedirect && !templateData.isTemplateCreating && !templateData.isTemplateLoading && get(templateData, 'template.id', { default: false })) {
            return <Redirect to={{
                pathname: `${ADMINISTRATION_TEMPLATE_PATH}/edit/${templateData.template.id}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (templateData.isTemplateCreating
            || templateData.isTemplateLoading
            || this.state.templateSubmittedAndShouldRedirect
        ) {
            return <Loader/>;
        }

        let initializationValue = initNewTemplate(userDetail, templateData);

        const requiredFields = createReqFields['DEFAULT'][HDI_TEMPLATE_FORM];
        return (
            <div className="container-fluid">
                <PageHeader title={isVisibleForCountry(userDetail.group) ? <FormattedMessage {...templateMessages.TITLE_UK}/> : <FormattedMessage {...templateMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="col-12">
                        <TemplateNewForm
                            form={HDI_TEMPLATE_FORM}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            showForm={templateData.showForm}
                            isLoadingListSolutionGroup={solutionGroupData.isSolutionGroupListLoading}
                            isLoadingSelectSolutionGroup={templateData.isSolutionGroupDetailLoading}
                            handleSelectSolutionGroup={this.handleSelectSolutionGroup}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

NewTemplatePage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    templateData: PropTypes.object.isRequired,
    solutionGroupData: PropTypes.object.isRequired,
    fetchTemplate: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any,
    resetTemplate: PropTypes.func.isRequired,
    saveTemplate: PropTypes.func.isRequired,
    fetchSolutionGroupCountry: PropTypes.func.isRequired,
    fetchSolutionGroupShortList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    clearAlerts: PropTypes.func.isRequired,
    templateData: state.adminTemplate,
    solutionGroupData: state.adminSolutionGroupList,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchTemplate,
    saveTemplate,
    resetTemplate,
    fetchSolutionGroupShortList,
    fetchSolutionGroupCountry
})(NewTemplatePage)), ['HDI_ADMIN']);

