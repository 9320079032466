import get from 'get-value';
import { diff } from 'deep-object-diff';

export const initLoadedSolutionGroups = (data) => {
    const result = {};
    Object.keys(data).map(item => {
        return result[item] = get(data[item], 'defaultSolutionGroup.id', { default: undefined })
    });
    return {
        mainSection: {
            ...result
        }
    };
};

export const transformUpdateSolutionGroupsForSave = (values, data) => {
    const { oldValues, newValues } = values;
    const difference = diff(oldValues, newValues);
    const output = {};
    if (get(difference, 'mainSection')) {
        const dif = get(difference, 'mainSection');
        const result = {};
        Object.keys(dif).forEach(item => {
            const find = Object.keys(data).find(country => country === item);
            if (find) {
                return result[item] = data[find].solutionGroups.find(i => i.id === dif[item])
            }
        });
        output.changeDefaultSolutionGroup = {
            ...result
        };
    }
    return output;
};
