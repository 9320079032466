import { put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import get from 'get-value';
import { errorAlert } from '../actions/alertsActions';
import alertMessages from '../intl/common/alertMessages';
import { hdiSolutionGroupWebsocketCommands } from '../constants/hdiSolutionGroupWebsocketCommands';
import { validateSolutionGroups } from '../api/validation/solutionGroup';

export function* handleSolutionGroupError(action) {
    const stateCorrelationId = get(yield select(state => state.adminSolutionGroup), `correlationId`, { default: undefined });
    const { correlationId } = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('../list'));
    yield put({ type: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_RESET });
}

export function* handleSolutionGroupFetchData(action) {
    const stateCorrelationId = get(yield select(state => state.adminSolutionGroup), `correlationId`, { default: undefined });
    const { correlationId, payload } = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    const { error } = validateSolutionGroups(payload);
    if (error) {
        yield put(errorAlert(alertMessages.SOLUTION_GROUP_FETCH_SEND_ERROR, [error.toString()]));
        yield put({
            type: hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_SEND_ERROR,
            payload: { correlationId }
        });
    }
}

function* solutionGroupSaga() {
    yield takeEvery(hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_SEND_ERROR, handleSolutionGroupError);
    yield takeEvery(hdiSolutionGroupWebsocketCommands.HDI_TICKET_SOLUTION_GROUP_SEND, handleSolutionGroupFetchData);
}

export default solutionGroupSaga;
